import React, { useCallback } from "react";
import { useMatch } from "react-router-dom";

import { toggleAppFavoris } from "api";
import { t } from "utils/i18n";
import { AppGalaxyInfo } from "types/Galaxy";
import { Fa } from "composants/Icon";
import { useSelector } from "react-redux";
import { ReducerState } from "reducers";

interface AddToFavoriteProps {
  favoris: AppGalaxyInfo[];
  afterToggle(newMenu: AppGalaxyInfo[]): void;
}

function selectCurrentFocusCodeGalaxie(state: ReducerState, currentCode: string | undefined) {
  if (!currentCode) return null;

  const currentFocusId = state.dashboard.selected[currentCode];

  if (!currentFocusId) return null;

  const focus = state.dashboard.dashboardDefinitions[currentCode].find(
    it => it.focusId === currentFocusId
  );

  if (!focus) return null;

  return focus.focusCode;
}

function AddToFavorite({ favoris, afterToggle }: AddToFavoriteProps) {
  const match = useMatch("/page/:code/*");
  const currentCode = match?.params.code;

  const selectCurrentGalaxyFocusCode = useCallback(
    (state: ReducerState) => selectCurrentFocusCodeGalaxie(state, currentCode),
    [currentCode]
  );
  const currentGalaxyFocusCode = useSelector(selectCurrentGalaxyFocusCode);

  function toggleFavoris() {
    if (currentCode) {
      toggleAppFavoris(currentCode, currentGalaxyFocusCode)
        .then(res => {
          afterToggle(res.data);
        })
        .catch(() => {
          console.error(
            "error during operation toggleMenuToFavoris with the code : " + currentCode
          );
        });
    }
  }

  if (currentCode) {
    const isWarningColor = favoris.filter(it => it.code === currentCode).length > 0;

    return (
      <a
        title={t("commun_ajouter_au_favoris")}
        className="is-size-5 has-text-white"
        onClick={toggleFavoris}
        style={{ marginLeft: "0.5em", marginRight: 5, top: 5 }}
      >
        <span className="icon">
          <Fa icon={[isWarningColor ? "fas" : "fal", "star"]} transform="down-1" />
        </span>
      </a>
    );
  } else {
    return null;
  }
}

export default AddToFavorite;
