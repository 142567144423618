import React, { FC, useContext, useMemo, useEffect, useReducer } from "react";
import JsxParser from "react-jsx-parser";

import {
  Grid,
  Columns,
  Column,
  Stack,
  ButtonLink,
  Link,
  Avatar,
  Loader,
  Notice,
  Tooltip,
  Card,
  Badge,
  Steps,
  gridPos
} from "@axin-org/comet";

import {
  ResponsiveContainer,
  LineChart,
  AreaChart,
  Area,
  Line,
  BarChart,
  Bar,
  RadialBarChart,
  RadialBar,
  ComposedChart,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  PieChart,
  Pie,
  ScatterChart,
  Scatter,
  ReferenceLine,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  LabelList,
  Label,
  Text,
  Cell,
  ResponsiveContainerProps
} from "recharts";

import { Link as RouterLink } from "react-router-dom";
import { URL_DATA, URL_DOWNLOAD_VIA_GED } from "customGlobal";
import { GalaxieContext } from "containers/galaxy/GalaxieContext";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import ProcessusLink from "composants/processus/ProcessusLink";
import { Fa } from "composants/Icon";
import auth from "auth";
import { ProcessusDefinitionNature, ProcessusDefinition } from "types/Processus";
import { ActionTypeData } from "reducers/Action";
import { Action } from "redux";
import { getProcessusDefinitionByCompositeId } from "api/processus";
import { apply, tw } from "twind";
import { isEqual } from "lodash-es";
import { useQuery } from "react-query";

function withResponsiveContainer<P extends object>(WrappedComponent: React.ComponentType<P>) {
  if (WrappedComponent === undefined || WrappedComponent === null) {
    throw new Error("cannot use withLabel with and undefined or null component");
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  const displayName = `ResponsiveContainer(${wrappedComponentName})`;

  const WithResponsiveContainer: FC<P & ResponsiveContainerProps> = props => {
    const {
      width,
      height,
      minWidth,
      minHeight,
      debounce = 100,
      aspect,
      children,
      ...restProps
    } = props as ResponsiveContainerProps;
    return (
      <ResponsiveContainer
        aspect={aspect}
        width={width}
        height={height}
        minWidth={minWidth}
        minHeight={minHeight}
        debounce={debounce}
      >
        <WrappedComponent {...(restProps as P)}>{children}</WrappedComponent>
      </ResponsiveContainer>
    );
  };
  WithResponsiveContainer.displayName = displayName;

  return WithResponsiveContainer;
}

type ContextValue = ((value: string) => void) | undefined;
const MarkdownDisplayContext = React.createContext<ContextValue>(undefined);

const MenuNavBar: FC<{ menuList: string }> = ({ menuList }) => {
  const onChange = useContext(MarkdownDisplayContext);

  const menus = menuList.split(";").filter(el => el.length > 0);

  return (
    <>
      {menus.map(menu => {
        // 0 : label
        // 1 : value
        const labelValue = menu.split(",");
        return (
          <a key={menu} className="navbar-item" onClick={() => onChange && onChange(labelValue[1])}>
            {labelValue[0]}
          </a>
        );
      })}
    </>
  );
};

interface ReducerCustomProcessusLinkState {
  definition: ProcessusDefinition | null;
  status: "initial" | "loaded" | "empty";
}
type ReducerCustomProcessusLinkAction =
  | ActionTypeData<"loaded", ProcessusDefinition>
  | Action<"empty">;

function reducerCustomProcessuslink(
  state: ReducerCustomProcessusLinkState = { definition: null, status: "initial" },
  action: ReducerCustomProcessusLinkAction
): ReducerCustomProcessusLinkState {
  switch (action.type) {
    case "loaded":
      return {
        definition: action.payload,
        status: "loaded"
      };

    case "empty":
      return {
        definition: null,
        status: "empty"
      };
    default:
      return state;
  }
}

const MarkdownProcessusLink: FC<React.PropsWithChildren<{
  entityIds: string | string[];
  tableName: string;
  id: string;
  type: ProcessusDefinitionNature;
  label: string;
  rapide?: boolean;
  apercu?: boolean;
  className?: string;
  style?: React.CSSProperties;
}>> = ({ children, entityIds, tableName, type, id, label, rapide, apercu, ...props }) => {
  const galaxy = useContext(GalaxieContext);

  const { data: definition, status } = useQuery(
    ["processus", galaxy.sjmoCode ?? "defaultSjmoCode", id],
    () =>
      getProcessusDefinitionByCompositeId(galaxy.sjmoCode ?? "defaultSjmoCode", id)
        .then(res => {
          if (res.data && label) {
            res.data.label = label;
          }
          return res.data;
        })
        .catch(() => {
          return null;
        })
  );

  return (
    <ProcessusProvider
      sjmoCode={galaxy.sjmoCode || "DEFAULT"}
      tableName={tableName || galaxy.mainEntityTableName || "default"}
      selected={entityIds}
      onAfterSaveProcess={galaxy.refreshListenerAndCallback}
    >
      {status === "success" && definition && <ProcessusLink definition={definition} {...props} />}
      {status === "error" && (
        <button {...(props as any)}>
          erreur configuration processus <pre>{id}</pre>
        </button>
      )}
    </ProcessusProvider>
  );
};

MarkdownProcessusLink.defaultProps = {
  entityIds: [],
  style: { marginBottom: 5 }
};

const HEADING_STYLES = {
  h1: apply`text-5xl`,
  h2: apply`text-4xl`,
  h3: apply`text-3xl`,
  h4: apply`text-2xl`,
  h5: apply`text-xl`,
  h6: apply`text-lg`
} as const;

const components: Record<string, any> = {
  OutputTextDouble(props: any) {
    return (
      <div className="field is-horizontal">
        <div className="field-label is-normal has-text-left">
          <label className="label">{props.label1}</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className="control">
              <output className="input">{props.value1}</output>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal" style={{ marginLeft: "10px" }}>
              <label className="label">{props.label2}</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <output className="input">{props.value2}</output>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
  OutputText(props: any) {
    return (
      <div className="field is-horizontal">
        <div className="field-label is-normal has-text-left">
          <label className="label">{props.label}</label>
        </div>

        <div className="field-body">
          <div className="field">
            <div className="control">
              <output className="input">{props.value}</output>
            </div>
          </div>
        </div>
      </div>
    );
  },
  Navbar: (props: any) => {
    return (
      <nav className="navbar" role="navigation" aria-label="dropdown navigation">
        <div className="navbar-item has-dropdown is-hoverable">
          <a className="navbar-link">{props.buttonLabel}</a>
          <div className="navbar-dropdown">
            <MenuNavBar menuList={props.content} />
          </div>
        </div>
      </nav>
    );
  },
  Img: ({ documentId, isRounded, src, children, style, ...props }: any) => {
    const url =
      src === undefined && documentId === undefined && documentId === ""
        ? "https://bulma.io/images/placeholders/128x128.png"
        : src !== undefined && src !== null && src !== ""
        ? src
        : `${URL_DATA()}/document/${documentId}?&access_token=${auth.token}`;

    return (
      <figure className="image is-128x128 is-marginless">
        <img {...props} src={url} style={isRounded ? { borderRadius: 5, ...style } : style} />
      </figure>
    );
  },
  Icon: ({ type = "fas", icon, transform, size, color }: any) => {
    return <Fa icon={[type, icon]} transform={transform} size={size} color={color} />;
  },
  // COMET
  Box: ({ as: Comp = "div", className, ...props }: any) => (
    <Comp className={tw(className)} {...props} />
  ),
  Grid,
  GridCol: ({ start, end, span, className, ...props }: any) => {
    return (
      <div
        className={tw(gridPos({ colStart: start, colEnd: end, colSpan: span, className }))}
        {...props}
      />
    );
  },
  Columns,
  Column,
  Stack,
  ButtonLink: ({ children, to, ...props }: any) => (
    <ButtonLink {...props} asChild>
      <RouterLink to={to}>{children}</RouterLink>
    </ButtonLink>
  ),
  Link: ({ children, to, ...props }: any) => (
    <Link {...props} asChild>
      <RouterLink to={to}>{children}</RouterLink>
    </Link>
  ),
  Avatar,
  Image: (props: any) => <img {...props} className={tw(props.className)} />,
  ImageDoc: (props: any) => {
    const src =
      props.documentId === undefined || props.documentId === null || props.documentId === ""
        ? "https://bulma.io/images/placeholders/128x128.png"
        : `${URL_DOWNLOAD_VIA_GED()}/document/${props.documentId}?access_token=${auth.token}`;

    return (
      <img
        {...props}
        alt={props.id}
        className={tw(props.className, "max-w-full max-h-full w-auto h-auto")}
        src={src}
      />
    );
  },
  Loader,
  Notice,
  Tooltip,
  Heading: ({ as: Comp = "h1", className, ...props }: any) => {
    const selected = HEADING_STYLES[Comp];
    return <Comp className={tw(className, selected)} {...props} />;
  },
  Card,
  Badge,
  Steps: Steps,
  StepItem: Steps.Step,
  // END COMET
  // RECHARTS
  RadialBarChart,
  ResponsiveRadialBarChart: withResponsiveContainer(RadialBarChart),
  ComposedChart,
  ResponsiveComposedChart: withResponsiveContainer(ComposedChart),
  RadarChart,
  ResponsiveRadarChart: withResponsiveContainer(RadarChart),
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  PieChart,
  ResponsivePieChart: withResponsiveContainer(PieChart),
  Pie,
  LineChart,
  ResponsiveLineChart: withResponsiveContainer(LineChart),
  Line,
  AreaChart,
  ResponsiveAreaChart: withResponsiveContainer(AreaChart),
  Area,
  BarChart,
  ResponsiveBarChart: withResponsiveContainer(BarChart),
  Bar,
  ScatterChart,
  ResponsiveScatterChart: withResponsiveContainer(ScatterChart),
  Scatter,
  ResponsiveContainer,
  RadialBar,
  ResponsiveRadialBar: withResponsiveContainer(RadialBar as any),
  ReferenceLine,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  ChartTooltip,
  Legend,
  LabelList,
  Label,
  Text,
  Cell,
  ProcessusLink: MarkdownProcessusLink
};

interface GenericMarkdownDisplayProps {
  value: string;
  style?: any;
}

const MarkdownDisplay: FC<GenericMarkdownDisplayProps> = React.memo(
  ({ value, style }) => {
    // const compiledValue = useMarkdown(value);

    const validValue = useMemo(() => value?.replace(/\\n/g, "\n") ?? "", [value]);

    return (
      <div className="content" style={{ ...style, width: "100%", height: "100%" }}>
        <JsxParser components={components} jsx={validValue} />
        {/* {compiledValue ? compiledValue.tree : null} */}
      </div>
    );
  },
  (prev, next) => prev.value === next.value && isEqual(prev.style, next.style)
);

// le wrapper permet de placer le context pour avoir le onChange sur les composants
// qui peuvent intéragir avec le parent (type NavBar)
const MarkdownWrapper: FC<GenericMarkdownDisplayProps & { onChange?(value: string): void }> = ({
  onChange,
  ...props
}) => {
  return (
    <MarkdownDisplayContext.Provider value={onChange}>
      <MarkdownDisplay {...props} />
    </MarkdownDisplayContext.Provider>
  );
};

export default MarkdownWrapper;
