import { useContext, useMemo, useState } from "react";
import { useRegisterProcessus, useStableProcessusId } from "features/processus/processusManager";
import { ProcessusContext, ProcessusContextProps } from "./ProcessusProvider";
import { ProcessusDefinition, ProcessusDefinitionNature } from "types/Processus";
import { Pojo } from "types/Galaxy";
import { getDimensionFromEvent } from "utils/component.utils";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";

export function useProcessus(definition: ProcessusDefinition) {
  const context = useContext(ProcessusContext);
  if (context === null) {
    throw new Error("cannot use ProcessLink without ProcessusContext");
  }

  const [
    managerState,
    { register: registerProcessus, reset: resetProcessus }
  ] = useRegisterProcessus();

  const navigate = useNavigate();

  const [showCalendarModal, setShowCalendarModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [position, setPosition] = useState<{ x: number; y: number } | null>(null);

  const stableProcessusId = useStableProcessusId(definition.id, context.selected ?? [], undefined);

  const machineId = managerState.context.info[stableProcessusId];
  const currentProcessusMachine = managerState.context.processus[machineId ?? ""];

  const hasProcessusStateSpawned =
    currentProcessusMachine !== undefined &&
    !currentProcessusMachine.getSnapshot().matches("error") &&
    !currentProcessusMachine.getSnapshot().matches("done");

  const editionDefault = useMemo(() => {
    let editionDefault: "rapide" | "apercu" | undefined = undefined;
    if (definition.type === "edition") {
      if (definition.apercu) editionDefault = "apercu";
      if (definition.rapide) editionDefault = "rapide";
    }
    return editionDefault;
  }, [
    definition.type === "edition" && definition.apercu,
    definition.type === "edition" && definition.rapide,
    definition.type
  ]);

  const usableContext = context as ProcessusContextProps;

  const isDisabled =
    definition.needEntity && (!usableContext.selected || usableContext.selected.length === 0);

  function launch(editionType?: "rapide" | "apercu") {
    if (isDisabled) return;

    if (currentProcessusMachine !== undefined) {
      const snapshot = currentProcessusMachine.getSnapshot();

      if (snapshot.matches("error")) {
        resetProcessus(definition.id, usableContext.selected);
      }
    }

    if (
      (definition.type === "traitement" && definition.isAdvanced) ||
      (definition.type === "edition" && definition.isAdvanced)
    ) {
      openAdvanced(
        navigate,
        usableContext.sjmoCode,
        definition.id,
        "",
        definition.type,
        usableContext.selected,
        null,
        definition.needEntity,
        undefined,
        editionType,
        usableContext.onAfterSaveProcess
      );
      return;
    }
    registerProcessus(
      {
        module: usableContext.sjmoCode,
        compositeID: definition.id,
        type: definition.type,
        selected: definition.needEntity && usableContext.selected ? usableContext.selected : [],
        // il faut rajouter la saisie de l'heure d'execution
        executeAt: undefined,
        editionType: editionType ?? editionDefault,
        label: definition.label
      },
      usableContext.onAfterSaveProcess
    );
  }

  function onClick(e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) {
    // sur un button, ça peut pas arriver, seulement sur le ProcessusLink déprécié
    const editionType = (e.target as HTMLElement)?.dataset.editionType as
      | "rapide"
      | "apercu"
      | undefined;

    if (usableContext.isDirty()) {
      setShowConfirmModal(true);
      setPosition(getDimensionFromEvent(e as any));
    } else {
      launch(editionType);
    }
  }

  return {
    modal: {
      calendar: {
        show: showCalendarModal,
        set: setShowCalendarModal
      },
      confirm: {
        show: showConfirmModal,
        set: setShowConfirmModal
      },
      position
    },
    button: {
      isDisabled,
      onClick
    },
    editionDefault,
    stableProcessusId: stableProcessusId,
    hasProcessusStateSpawned,
    context: usableContext,
    launch
  };
}

export function openAdvanced(
  navigate: ReturnType<typeof useNavigate>,
  module: string,
  processID: string,
  navigationUrl: string,
  type: ProcessusDefinitionNature,
  selected: Pojo[] | undefined,
  delay: string | null,
  needEntity: boolean,
  label?: string,
  editionType?: string,
  refresh?: () => void
) {
  const params = new URLSearchParams();
  params.set("sjmoCode", module);
  params.set("process", processID);
  params.set("navigationUrl", navigationUrl);
  params.set("type", type);
  if (delay) {
    params.set("delay", delay);
    params.set("forAll", "true");
  }
  if (label) {
    params.set("processLabel", label);
  }
  if (editionType) {
    params.set("editionType", editionType);
  }

  const uuid = nanoid();
  (window as any)[uuid] = () => refresh?.();

  navigate(
    {
      search: params.toString()
    },
    {
      state: { selected, refresh: uuid, needEntity }
    }
  );
}
