import React, { FC, useState, useEffect, SyntheticEvent } from "react";
import Modal from "composants/Modal/Modal";
import { Field } from "composants/form";
import { ComponentState } from "types/Component";
import { getCollator } from "utils/network.utils";
import { NumPad } from "./component/NumPad";
import { FunctionList } from "./component/FunctionList";
import { uuidv4 } from "utils/uuid.utils";
import { t } from "utils/i18n";
import { Trans } from "react-i18next";

interface ComputedColumnDialogProps {
  columns: ComponentState[];
  computedColumns: ComputedColumnProperties[];
  onClose(): void;
  onValidate(calcProp: ComputedColumnProperties): void;
  onDelete(index: number): void;
  onClear(): void;
}

export interface ComputedColumnProperties {
  id: string;
  label: string;
  mask?: string;
  expression: string;
}

export function useComputedColumn() {
  const [computedColumns, setComputedColumns] = useState<ComputedColumnProperties[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return { computedColumns, setComputedColumns, isOpen, toggle };
}

function initCalcProp(): ComputedColumnProperties {
  return {
    id: uuidv4(),
    expression: "",
    label: ""
  };
}

const ComputedColumnDialog: FC<ComputedColumnDialogProps> = props => {
  const [selectedCalc, setSelectedCalc] = useState("new");
  const [currentComputedProp, setComputedProp] = useState<ComputedColumnProperties>(initCalcProp());

  useEffect(() => {
    if (selectedCalc === "new") {
      setComputedProp(initCalcProp());
    } else {
      const computedProp = props.computedColumns.find(el => el.id === selectedCalc);
      computedProp && setComputedProp(computedProp);
    }
  }, [selectedCalc]);

  function onChange(e: SyntheticEvent<any>) {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;

    setComputedProp({ ...currentComputedProp, [field]: value });
  }

  function appendToExpression(value: string) {
    const newVal = `${currentComputedProp.expression} ${value}`;
    setComputedProp({
      ...currentComputedProp,
      expression: newVal
    });
  }

  function onValidate() {
    props.onValidate(currentComputedProp);

    setComputedProp(initCalcProp());
  }

  function deleteCurrentCalcProp() {
    const index = props.computedColumns.findIndex(el => el.id === selectedCalc);

    setSelectedCalc("new");
    index !== -1 && props.onDelete(index);
  }

  function onClear() {
    props.onClear();
    setSelectedCalc("new");
  }

  // on fait un sort des colonnes par ordre alphabétique en fonction de la langue utilisateur
  const collator = getCollator();
  const sortedColumns = [...props.columns].sort((colA, colB) =>
    collator.compare(colA.label, colB.label)
  );

  return (
    <Modal
      title={
        <>
          <Trans i18nKey="commun_calculer">Calculer</Trans>
          <span className="is-pulled-right">
            <button className="button is-text is-small" onClick={onClear}>
              <Trans i18nKey="commun_vider" />
            </button>
          </span>
        </>
      }
      onClose={props.onClose}
      validateComponent={
        <button className="button is-success is-rounded" onClick={onValidate}>
          {t("commun_valider")}
        </button>
      }
      hideCancel
    >
      <div>
        <Field isHorizontal={false} label={t("commun_calcul")}>
          <div className="select">
            <select
              name="calcul"
              value={selectedCalc}
              onChange={e => setSelectedCalc(e.currentTarget.value)}
            >
              <option value="new">{t("commun_nouveau_calcul")}</option>
              {props.computedColumns.map(col => {
                return (
                  <option key={col.id} value={col.id}>
                    {col.label}
                  </option>
                );
              })}
            </select>
          </div>
          <button
            className="button ml-5 is-text is-rounded"
            onClick={deleteCurrentCalcProp}
            disabled={selectedCalc === "new"}
          >
            {t("commun_supprimer")}
          </button>
        </Field>
        <Field isHorizontal={false} label={t("commun_entete_colonne")}>
          <input
            className="input"
            name="label"
            value={currentComputedProp.label}
            onChange={onChange}
          />
        </Field>

        <Field isHorizontal={false} label={t("commun_expression_calcul")}>
          <textarea
            className="textarea"
            name="expression"
            value={currentComputedProp.expression}
            onChange={onChange}
          />
        </Field>
      </div>
      <div className="flex">
        <section style={{ width: "30rem" }}>
          <p className="has-text-centered pb-5">{t("commun_colonnes")}</p>
          <ul style={{ height: 200, overflowY: "scroll" }}>
            {sortedColumns.map(col => {
              return (
                <li key={col.column}>
                  <button className="button is-text" onClick={() => appendToExpression(col.column)}>
                    {col.label} ({col.column})
                  </button>
                </li>
              );
            })}
          </ul>
        </section>
        <section style={{ width: "calc(14px * 4)", margin: "0 5rem" }}>
          <p className="has-text-centered pb-5">{t("commun_calcul")}</p>
          <NumPad onClick={appendToExpression} />
        </section>
        <section style={{ width: "15rem" }}>
          <p className="has-text-centered pb-5">{t("commun_fonctions_et_operateurs")}</p>
          <FunctionList onClick={appendToExpression} style={{ height: 200, overflowY: "scroll" }} />
        </section>
      </div>
    </Modal>
  );
};

export default ComputedColumnDialog;
