import {
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
  HANDLE_API_MESSAGE,
  REMOVE_NOTIFICATION_BY_ID
} from "constant/common";
import { Message } from "types/Message";
import { uuidv4 } from "utils/uuid.utils";

export function deleteNotificationAtIndex(index: number) {
  return {
    type: REMOVE_NOTIFICATION,
    payload: index
  };
}

export function deleteNotificationById(id: string) {
  return {
    type: REMOVE_NOTIFICATION_BY_ID,
    payload: id
  };
}

export function deleteAllNotifications() {
  return {
    type: REMOVE_ALL_NOTIFICATIONS
  };
}

export interface HandleApiMessageAction {
  type: string;
  payload: Message;
  meta: {
    raf: boolean;
    autoclose: boolean;
  };
  [key: string]: any;
}

export function addMessage(message: Message, autoclose: boolean = false): HandleApiMessageAction {
  return {
    type: HANDLE_API_MESSAGE,
    payload: { ...message, id: uuidv4() },
    meta: { raf: true, autoclose }
  };
}
