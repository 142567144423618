import React, { Component, FC, useContext, useMemo, useCallback, useReducer } from "react";
import { DndProvider, DndProviderProps } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ActionTypeData } from "reducers/Action";
import produce from "immer";

export interface DragAndDropBlockContextProps {
  locked: Record<string, boolean>;
  lockDragForNodeType(nodeType: string): void;
  unlockDragForNodeType(nodeType: string): void;
}

export const DragAndDropBlockContext = React.createContext<DragAndDropBlockContextProps>({
  locked: {},
  lockDragForNodeType: nodeType => {
    console.log("locked " + nodeType);
  },
  unlockDragForNodeType: nodeType => {
    console.log("unlocked " + nodeType);
  }
});

function reducer(
  state: Record<string, boolean>,
  action: ActionTypeData<"LOCK", string> | ActionTypeData<"UNLOCK", string>
) {
  switch (action.type) {
    case "LOCK":
      return produce(state, draft => {
        draft[action.payload] = true;
      });
    case "UNLOCK":
      return produce(state, draft => {
        draft[action.payload] = false;
      });
    default:
      return state;
  }
}

export const DragAndDropBlocContextProvider: FC<React.PropsWithChildren<{}>> = props => {
  const [locked, dispatch] = useReducer(reducer, {});

  const lockDragForNodeType = useCallback((nodeType: string) => {
    dispatch({ type: "LOCK", payload: nodeType });
  }, []);

  const unlockDragForNodeType = useCallback((nodeType: string) => {
    dispatch({ type: "UNLOCK", payload: nodeType });
  }, []);

  const contextValues = useMemo(() => {
    return { locked, lockDragForNodeType, unlockDragForNodeType };
  }, [locked, lockDragForNodeType, unlockDragForNodeType]);

  return (
    <DragAndDropBlockContext.Provider value={contextValues}>
      {props.children}
    </DragAndDropBlockContext.Provider>
  );
};

export function withDndBlockContext<P extends object>(WrappedComponent: React.ComponentType<P>) {
  if (WrappedComponent === undefined || WrappedComponent === null) {
    throw new Error("cannot use withLabel with and undefined or null component");
  }

  const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || "Component";
  const displayName = `withDndBlock(${wrappedComponentName})`;

  const WithDndBlock: FC<P> = props => {
    const context = useContext(DragAndDropBlockContext);
    return <WrappedComponent {...props} {...context} />;
  };

  WithDndBlock.displayName = displayName;

  return WithDndBlock;
}

// react-dnd n'est pas à jour avec la version de typescript nécessaire
// il y a donc une erreur TS si on utilise le composant de la lib directement
// parce qu'il pense que children n'est pas accepté.
// on customise le type du composant avant usage pour éviter le soucis
const CustomDndProvider: FC<DndProviderProps<unknown, unknown> & {
  children?: React.ReactNode;
}> = DndProvider;
const DragNDropContext: FC<{ children?: React.ReactNode }> = ({ children }) => {
  return <CustomDndProvider backend={HTML5Backend}>{children}</CustomDndProvider>;
};

export default DragNDropContext;
