import React, { Component, SyntheticEvent } from "react";
import { ADMIN_GALAXIE } from "./AdminGalaxy";
import { getColumnDefinition } from "api/column";
import { ComponentState, ComponentReadOnly } from "types/Component";
import { t } from "utils/i18n";
import Modal from "composants/Modal/Modal";
import { BlockComponent } from "composants/group/GroupComponent";
import { Pojo } from "types/Galaxy";
import { convertValue } from "utils/entities.utils";
import { Message } from "types/Message";
import { findAll } from "api";

function mockUpFonction() {
  return () => {
    return;
  };
}

interface CreateFocusProps {
  title?: string;
  sjfsCode?: string;
  syjModuleId?: string;
  sysMenuGroupeId?: string;
  personnelId?: string;
  sjfgPrivilegie: boolean;
  sjfrPrivilegie: boolean;
  sjfuPrivilegie: boolean;
  sjfgPosition?: number;
  sjfrPosition?: number;
  sjfuPosition?: number;
}

interface ModalCreateGalaxyFocusProps {
  createAction: "USER" | "ROLE" | "GLOBAL";
  sjmoCode: string;
  onClose(): void;
  onCreateOrUpdateGalaxyFocus(params: {
    currentSjmoCode: string;
    title: string;
    position: number;
    privilegie: boolean;
    code: string;
    syjModuleId: string;
    sysMenuGroupeId: string;
    personnelId: string;
    callback: () => void;
  }): void;
  addMessage(message: Message): void;
}

interface ModalCreateGalaxyFocusState {
  compos: ComponentState[];
  entity: CreateFocusProps;
}

class ModalCreateGalaxyFocus extends Component<
  ModalCreateGalaxyFocusProps,
  ModalCreateGalaxyFocusState
> {
  state: ModalCreateGalaxyFocusState = {
    compos: [],
    entity: { sjfgPrivilegie: false, sjfrPrivilegie: false, sjfuPrivilegie: false }
  };

  private titleCompo: ComponentState = {
    column: "title",
    label: t("commun_titre"),
    typeCompo: "I",
    contentSize: 100,
    position: 0,
    compoVisible: true,
    readOnly: ComponentReadOnly.DEFAULT,
    disabled: false,
    mandatory: true,
    additionalClause: [],
    sortClause: [],
    wvi: false,
    options: {}
  } as any;

  componentDidMount() {
    getColumnDefinition(ADMIN_GALAXIE, "syjFocus", ["sjfsCode"]).then(response => {
      this.setState({
        compos: [this.titleCompo, ...response.data]
      });

      if (this.props.createAction === "GLOBAL") {
        getColumnDefinition(ADMIN_GALAXIE, "syjFocusGlobal", [
          "syjModuleId",
          "sjfgPrivilegie",
          "sjfgPosition"
        ]).then(responseAss => {
          this.setState({
            compos: [...this.state.compos, ...responseAss.data]
          });
        });
      } else if (this.props.createAction === "ROLE") {
        getColumnDefinition(ADMIN_GALAXIE, "syjFocusRole", [
          "syjModuleId",
          "sysMenuGroupeId",
          "sjfrPrivilegie",
          "sjfrPosition"
        ]).then(responseAss => {
          this.setState({
            compos: [...this.state.compos, ...responseAss.data]
          });
        });
      } else {
        getColumnDefinition(ADMIN_GALAXIE, "syjFocusUser", [
          "syjModuleId",
          "personnelId",
          "sjfuPrivilegie",
          "sjfuPosition"
        ]).then(responseAss => {
          this.setState({
            compos: [...this.state.compos, ...responseAss.data]
          });
        });
      }
    });

    findAll({
      sjmoCode: ADMIN_GALAXIE,
      tableName: "syjModule",
      filter: "q=sjmoCode==" + this.props.sjmoCode
    }).then(response =>
      this.setState({ entity: { ...this.state.entity, syjModuleId: response.data.data[0].id } })
    );
  }

  renderForm = () => {
    return (
      <BlockComponent
        sjmoCode={ADMIN_GALAXIE}
        entity={this.state.entity}
        compos={this.state.compos}
        wviState={{}}
        contextMenu={mockUpFonction}
        onBlur={mockUpFonction}
        onChange={this.onChange}
        onValueChange={this.onValueChange}
        onItemChange={this.onItemChange}
      />
    );
  };

  render() {
    return (
      <Modal
        onClose={this.props.onClose}
        onValidate={this.onSave}
        title={t("commun_creation_focus")}
      >
        {this.state.compos.length > 2 && this.renderForm()}
      </Modal>
    );
  }

  onChange = (e: SyntheticEvent<any>) => {
    const field: string = e.currentTarget.name;

    const val = convertValue(e);
    this.setState({ entity: { ...this.state.entity, [field]: val } });
  };

  onValueChange = (field: string | undefined, value: any) => {
    if (!field) return;
    this.setState({ entity: { ...this.state.entity, [field]: value } });
  };

  onItemChange = (selectedItem: Pojo, field?: string) => {
    if (field) {
      this.setState({
        entity: { ...this.state.entity, [field]: selectedItem ? selectedItem.id : undefined }
      });
    }
  };

  onSave = () => {
    let isComplete = false;
    let values = {
      currentSjmoCode: this.props.sjmoCode,
      title: this.state.entity.title,
      code: this.state.entity.sjfsCode,
      position: 0,
      syjModuleId: this.state.entity.syjModuleId,
      personnelId: this.state.entity.personnelId,
      sysMenuGroupeId: this.state.entity.sysMenuGroupeId,
      privilegie: false,
      callback: this.props.onClose
    };

    if (values.title && values.code && values.syjModuleId) {
      if (this.props.createAction === "GLOBAL") {
        values.position = this.state.entity.sjfgPosition ?? 0;
        values.privilegie = this.state.entity.sjfgPrivilegie;
        isComplete = true;
      } else if (this.props.createAction === "ROLE" && values.sysMenuGroupeId) {
        values.position = this.state.entity.sjfrPosition ?? 0;
        values.privilegie = this.state.entity.sjfrPrivilegie;
        isComplete = true;
      } else if (this.props.createAction === "USER" && values.personnelId) {
        values.position = this.state.entity.sjfuPosition ?? 0;
        values.privilegie = this.state.entity.sjfuPrivilegie;
        isComplete = true;
      }
    }

    if (isComplete) {
      this.props.onCreateOrUpdateGalaxyFocus(values as any);
    } else {
      const message: Message = {
        type: "DANGER",
        message: t("commun_champs_obligatoire_non_rempli"),
        target: "GLOBAL"
      };
      this.props.addMessage(message);
    }
  };
}

export default ModalCreateGalaxyFocus;
