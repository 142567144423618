import React, {
  FC,
  CSSProperties,
  useState,
  useRef,
  useEffect,
  useReducer,
  useContext,
  SyntheticEvent
} from "react";
import classNames from "classnames";
import { List, CellMeasurerCache, CellMeasurer } from "react-virtualized";
import debounce from "lodash-es/debounce";
import axios, { CancelTokenSource } from "axios";
import { SortableHandle, SortableElement } from "react-sortable-hoc";

import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownContext,
  DropDownContextProps
} from "composants/DropDown/Dropdown";
import { Fa } from "composants/Icon";
import { t, parseDateFromPattern, dateMatchPattern, getDatePattern } from "utils/i18n";

import { findViewComplex, FindViewComplexParams } from "api";
import { Pojo } from "types/Galaxy";
import { mapToRSQL, chooseOperatorStrByValue, RSQLOperator } from "utils/query.utils";

import { AxiosResponse } from "axios";
import { PagedResource } from "types/Search";
import { FilterProperties } from "types/InteractiveReport";

import "./HeaderDynamicTable.css";
import Loader from "composants/Loader";
import Draggable from "react-draggable";
import { DatatableSort } from "composants/datatable/Table";
import { ComponentState } from "types/Component";

import { searchTerm } from "../hook/useInteractiveReport";
import { track } from "tracking";
import { useMemo } from "react";
import { getUserLang } from "utils/network.utils";

const HeaderTitle = SortableHandle<{ label: string }>((props: { label: string }) => {
  return <span className="datatable-header-cell--label">{props.label}</span>;
});

const HeaderSortable = SortableElement<
  DropDownContextProps & {
    className?: string;
    style?: CSSProperties;
    label: string;
    order: DatatableSort;
    onResize(deltaX: number): void;
  }
>(
  (
    props: DropDownContextProps & {
      className?: string;
      style?: CSSProperties;
      label: string;
      order: DatatableSort;
      onResize(deltaX: number): void;
    }
  ) => {
    const isDragging = useRef(false);
    return (
      <div
        role="button"
        ref={props.buttonRef}
        className={classNames(props.className, "cursor-pointer has-text-weight-semibold", {
          "has-text-white has-background-link": props.isActive,
          "has-text-link": !props.isActive
        })}
        onClick={e => {
          if (isDragging.current) {
            isDragging.current = false;
          } else {
            props.isActive ? props.onClose() : props.onOpen();
          }
        }}
        style={props.style}
      >
        <div className="datatable-header-cell--title">
          <Draggable
            axis="x"
            onDrag={(e, { deltaX }) => {
              isDragging.current = true;
              props.onResize(deltaX);
            }}
            position={{ x: 0, y: 0 }}
          >
            <span className="datatable-header-cell--resize" style={{ paddingRight: 5 }}>
              ⋮
            </span>
          </Draggable>
          <HeaderTitle label={props.label} />
          {props.order && (
            <Fa
              className="has-text-grey"
              icon={props.order === "ASC" ? "sort-amount-up" : "sort-amount-down"}
              title={props.order === "ASC" ? t("commun_ascendant") : t("commun_descendant")}
              size="sm"
              fixedWidth
            />
          )}
        </div>
      </div>
    );
  }
);

export const HeaderDynamicTable: FC<React.PropsWithChildren<{
  index: number;
  label: string;
  className?: string;
  style: CSSProperties;
  width: number;
  order: DatatableSort;
  isBreakRow: boolean;
  disabledBreakRow: boolean;
  onClickAsc(): void;
  onClickDesc(): void;
  onClickBreak(): void;
  onResize(deltaX: number): void;
}>> = ({
  className,
  style,
  index,
  label,
  width,
  order,
  isBreakRow,
  disabledBreakRow = false,
  onClickAsc,
  onClickDesc,
  onClickBreak,
  onResize,
  children
}) => {
  return (
    <Dropdown autoclose>
      <DropdownButton
        render={param => {
          return (
            <HeaderSortable
              {...param}
              index={index}
              label={label}
              style={style}
              className={classNames(className, "has-text-weight-semibold", {
                "has-text-white has-background-link": param.isActive,
                "has-text-link": !param.isActive
              })}
              onResize={onResize}
              order={order}
            />
          );
        }}
      />
      <DropdownMenu
        style={{ width: width }}
        marginTop={0}
        render={param => {
          if (!param.isActive) {
            return null;
          }

          return (
            <>
              <div className="dropdown-item">
                <div className="flex justify-around">
                  <a
                    className={classNames("dynamic-table-header--action", {
                      "has-background-link": order === "ASC",
                      "has-text-white": order === "ASC"
                    })}
                    title={t("commun_ascendant")}
                    onClick={() => {
                      onClickAsc();
                      param.onClose();
                    }}
                  >
                    <Fa icon={["fal", "sort-amount-up"]} size="lg" fixedWidth />
                  </a>
                  <a
                    title={t("commun_descendant")}
                    className={classNames("dynamic-table-header--action", {
                      "has-background-link": order === "DESC",
                      "has-text-white": order === "DESC"
                    })}
                    onClick={() => {
                      onClickDesc();
                      param.onClose();
                    }}
                  >
                    <Fa icon={["fal", "sort-amount-down"]} size="lg" fixedWidth />
                  </a>
                  <a
                    className={classNames("dynamic-table-header--action", {
                      "has-background-link": isBreakRow,
                      "has-text-white": isBreakRow,
                      "has-text-grey": disabledBreakRow
                    })}
                    title={t("commun_ajouter_une_rupture")}
                    onClick={() => {
                      if (!disabledBreakRow) {
                        onClickBreak();
                        param.onClose();
                      }
                    }}
                  >
                    <Fa icon={["fal", "outdent"]} size="lg" fixedWidth />
                  </a>
                </div>
              </div>
              {children}
            </>
          );
        }}
      />
    </Dropdown>
  );
};

interface LocalEntities {
  entities: Pojo[];
  totalRecords: number;
}

type ActionLocalEntities = {
  type: "ADD_ENTITIES";
  payload: { data: Pojo[]; totalRecords: number };
};

function reducerLocalEntities(state: LocalEntities, action: ActionLocalEntities): LocalEntities {
  switch (action.type) {
    case "ADD_ENTITIES":
      return { entities: action.payload.data, totalRecords: action.payload.data.length };

    default:
      return state;
  }
}

const findViewComplexDebounced = debounce(
  (
    param: FindViewComplexParams,
    validate: (res: AxiosResponse<PagedResource<Pojo>>) => void,
    cancelToken: CancelTokenSource
  ) => {
    findViewComplex(param, cancelToken)
      .then(res => validate(res))
      .catch(e => console.error(e));
  },
  500,
  { maxWait: 2000 }
);

export const DistinctListSuggestion: FC<{
  tableName: string;
  column: string;
  columns: ComponentState[];
  width: number;
  search: {
    term: string;
    targetColumn: string;
    filters: FilterProperties[];
  };
  interactions: Record<string, any>;
  isStaticHTML: boolean;
  onSelectFilter(column: string, value: any): void;
}> = props => {
  const dropdownParam = useContext(DropdownContext);
  const [term, setTerm] = useState("");
  const measurerCache = useRef<CellMeasurerCache | null>(null);

  const [tableEntities, dispatch] = useReducer(reducerLocalEntities, {
    entities: [],
    totalRecords: 0
  });

  const colDefinition = useMemo(() => {
    return props.columns.find(c => c.column === props.column);
  }, [props.column, props.columns]);

  const filterHookDeps = props.search.filters.map(f => `${f.column}=${f.value}`).join("&");
  useEffect(() => {
    let filters = [...props.search.filters];

    if (term !== "") {
      let operator: RSQLOperator = "OPER_LIKE_ANYWHERE";
      let replacedTerm: any = term;

      if (colDefinition) {
        const userDatePattern = getDatePattern(getUserLang());

        if (
          colDefinition.typeCompo === "NUMBER" ||
          (colDefinition.typeCompo === "LINK" && colDefinition.isNumber)
        ) {
          const int = Number.parseInt(term, 10);
          const float = Number.parseFloat(term);

          if (!isNaN(int) && int.toString() === term) replacedTerm = int;
          if (!isNaN(float) && float.toString() === term) replacedTerm = float;
        } else if (colDefinition.typeCompo === "DATE" && dateMatchPattern(term, userDatePattern)) {
          replacedTerm = parseDateFromPattern(term, userDatePattern);
        }

        operator = chooseOperatorStrByValue(replacedTerm);
      }
      filters.push({ type: "normal", column: props.column, operator, value: term });
    }

    let rsqlFilters = searchTerm(
      props.columns,
      props.search.targetColumn,
      props.search.term,
      filters
    );

    const rsql = mapToRSQL(props.interactions);
    if (rsqlFilters !== undefined) {
      rsql.and(rsqlFilters);
    }

    rsql.orderBy.add(props.column, "asc");

    const source = axios.CancelToken.source();

    findViewComplexDebounced(
      {
        tableName: props.tableName,
        includes: [props.column],
        groupBy: { columns: [props.column], functionList: [] },
        filter: rsql.build(),
        size: false
      },
      res => {
        dispatch({
          type: "ADD_ENTITIES",
          payload: { data: res.data.data, totalRecords: res.data.meta.totalRecords }
        });
      },
      source
    );

    return function cleanup() {
      source.cancel("unmount during the fetch");
      findViewComplexDebounced.cancel();
    };
  }, [
    term,
    props.tableName,
    props.column,
    filterHookDeps,
    props.search.filters,
    props.search.term,
    props.search.targetColumn,
    props.columns,
    props.interactions
  ]);

  function getMeasurerCache() {
    if (!measurerCache.current) {
      measurerCache.current = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: props.isStaticHTML ? 100 : 50,
        minHeight: 50
      });
    }

    return measurerCache.current;
  }

  function onClickButton(e: SyntheticEvent<HTMLParagraphElement>) {
    props.onSelectFilter(props.column, e.currentTarget.dataset.value);
    dropdownParam.isActive && dropdownParam.onClose();
    track("ir::header-button::filter");
  }

  return (
    <>
      {!props.isStaticHTML && (
        <div className="dropdown-item">
          <input
            className="input"
            placeholder={t("commun_filtrer")}
            value={term}
            onChange={e => setTerm(e.target.value)}
            onKeyDown={e => {
              if (e.key === "Enter") {
                props.onSelectFilter(props.column, e.currentTarget.value);
                track("ir::header-input::filter");
              }
            }}
          />
        </div>
      )}
      <List
        height={300}
        width={props.width}
        rowHeight={getMeasurerCache().rowHeight}
        containerRole="listbox"
        deferredMeasurementCache={getMeasurerCache()}
        noContentRenderer={() => {
          return (
            <div className="flex justify-center pt-5">
              <div>
                <Loader />
              </div>
            </div>
          );
        }}
        rowCount={tableEntities.totalRecords}
        rowRenderer={({ key, index, style, parent }) => {
          const value =
            tableEntities.entities[index] && tableEntities.entities[index][props.column];

          const existingFilter = props.search.filters.find(
            el => el.column === props.column && el.value === value
          );

          const isCurrentValueSelected = existingFilter !== undefined && existingFilter !== null;

          return (
            <CellMeasurer
              key={key}
              cache={getMeasurerCache()}
              parent={parent}
              rowIndex={index}
              columnIndex={0}
            >
              {props.isStaticHTML ? (
                <p
                  role="option"
                  aria-selected={isCurrentValueSelected}
                  className="has-text-centered cursor-pointer p-6 dynamic-list-item"
                  style={style}
                  dangerouslySetInnerHTML={{ __html: value }}
                  data-value={value}
                  onClick={onClickButton}
                />
              ) : (
                <p
                  role="option"
                  aria-selected={isCurrentValueSelected}
                  className="flex justify-center items-center has-text-centered p-6 cursor-pointer dynamic-list-item"
                  style={style}
                  data-value={value}
                  onClick={onClickButton}
                >
                  {value}
                </p>
              )}
            </CellMeasurer>
          );
        }}
      />
    </>
  );
};
