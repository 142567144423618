import * as Expr from "../expr/Expr";
import { GS, RSQLOperator, GSBuilder } from "utils/query.utils";
import { TokenType } from "../Token";

type GSVisitorType =
  | GS
  | { type: "GSIDENTIFIER"; name: string }
  | { type: "GSLITERAL"; value: any };

export class AstToGSNode implements Expr.Visitor<GSVisitorType> {
  static visit(expr: Expr.Expr, env: Record<string, any>): GS {
    const visitor = new AstToGSNode(env);
    return expr.accept(visitor) as GS;
  }

  constructor(private env: Record<string, any>) {}

  private getValue(expr: GSVisitorType) {
    let value;
    switch (expr.type) {
      case "GSIDENTIFIER":
        if (this.env[expr.name] === undefined)
          throw new Error(`Value for ${expr.name} not found in the environment`);
        value = this.env[expr.name];
        break;

      case "GSLITERAL":
        value = expr.value;
        break;
      default:
        throw new Error(`Unexpected type to extract value : ${expr.type}`);
    }
    return value;
  }

  visitBinaryExpr(expr: Expr.Binary): GSVisitorType {
    let left = expr.left.accept(this);
    let operator = getOperator(expr.operator.lexeme);
    let right = expr.right.accept(this);

    if (left.type !== "GSIDENTIFIER") {
      throw new Error(`Expect an identifier before ${expr.operator.lexeme}`);
    }

    let value: any = this.getValue(right);
    return GSBuilder.Comparison(left.name, operator, value);
  }

  visitBetweenExpr(expr: Expr.Between): GSVisitorType {
    let identifier = expr.identifier.accept(this);
    let left = expr.left.accept(this);
    let right = expr.right.accept(this);

    if (identifier.type !== "GSIDENTIFIER") {
      throw new Error(`Expect an identifier before BETWEEN`);
    }

    let valueLeft = this.getValue(left);
    let valueRight = this.getValue(right);

    return GSBuilder.AND(
      GSBuilder.Comparison(identifier.name, "OPER_GE", valueLeft),
      GSBuilder.Comparison(identifier.name, "OPER_LE", valueRight)
    );
  }

  visitInExpr(expr: Expr.In): GSVisitorType {
    let identifier = expr.identifier.accept(this);
    let conditions = expr.conditions.map(it => it.accept(this));

    if (identifier.type !== "GSIDENTIFIER") {
      throw new Error(`Expect an identifier before IN`);
    }

    if (conditions.filter(c => c.type != "GSLITERAL").length > 0) {
      throw new Error(`Expect a literal as IN value`);
    }

    let inValues = conditions.map(c => c.type === "GSLITERAL" && c.value);
    return GSBuilder.Comparison(identifier.name, expr.negate ? "OPER_NOT_IN" : "OPER_IN", inValues);
  }

  visitLogicalExpr(expr: Expr.Logical): GSVisitorType {
    let left = expr.left.accept(this);
    let right = expr.right.accept(this);

    switch (expr.operator.type) {
      case TokenType.AND:
        return GSBuilder.AND(left as GS, right as GS);
      case TokenType.OR:
        return GSBuilder.OR(left as GS, right as GS);
      default:
        throw new Error(`[Error ${expr.operator.line}] Unknown operator ${expr.operator.lexeme}`);
    }
  }

  visitGroupingExpr(expr: Expr.Grouping): GSVisitorType {
    return expr.expression.accept(this);
  }

  visitIdentifier(expr: Expr.Identifier): GSVisitorType {
    return { type: "GSIDENTIFIER", name: expr.value };
  }

  visitVariable(expr: Expr.Variable): GSVisitorType {
    if (this.env[expr.name] === undefined)
      throw new Error(`Value for ${expr.name} not found in the environment`);

    let value = this.env[expr.name];
    return { type: "GSLITERAL", value };
  }

  visitLiteralExpr(expr: Expr.Literal): GSVisitorType {
    return { type: "GSLITERAL", value: expr.value };
  }

  visitNullableExpr(expr: Expr.Nullable): GSVisitorType {
    if (!(expr.expression instanceof Expr.Identifier)) {
      throw new Error("Expect an identifier before IS NULL");
    }

    return GSBuilder.Comparison(expr.expression.value, "OPER_NULL", null);
  }

  visitNonNullableExpr(expr: Expr.NonNullable): GSVisitorType {
    if (!(expr.expression instanceof Expr.Identifier)) {
      throw new Error("Expect an identifier before IS NOT NULL");
    }

    return GSBuilder.Comparison(expr.expression.value, "OPER_NOT_NULL", null);
  }
}

function getOperator(value: string): RSQLOperator {
  switch (value) {
    case "=":
      return "OPER_EQ";
    case "!=":
      return "OPER_NE";
    case ">=":
      return "OPER_GE";
    case ">":
      return "OPER_GT";
    case "<=":
      return "OPER_LE";
    case "LIKE_ANYWHERE":
      return "OPER_LIKE_ANYWHERE";
    case "LIKE_END":
      return "OPER_LIKE_END";
    case "LIKE_START":
      return "OPER_LIKE_START";
    case "<":
      return "OPER_LT";
    case "NOT_EQUALS":
      return "OPER_NE";
    case "IS_NOT_NULL":
      return "OPER_NOT_NULL";
    case "IS_NULL":
      return "OPER_NULL";
    default:
      return "OPER_EQ";
  }
}
