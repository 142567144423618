export enum TokenType {
  // Single-character tokens
  PAREN_LEFT,
  PAREN_RIGHT,
  COMMA,
  //MINUS, PLUS, SLASH, STAR,

  // One or two character tokens
  BANG_EQUAL,
  EQUAL,
  GREATER,
  GREATER_EQUAL,
  LESS,
  LESS_EQUAL,

  // Literals
  VARIABLE,
  IDENTIFIER,
  STRING,
  NUMBER,

  // Keywords
  AND,
  OR,
  TRUE,
  FALSE,
  NULL,
  IS,
  NOT,
  BETWEEN,
  IN,
  EOF
}

export class Token {
  constructor(
    public type: TokenType,
    public lexeme: string,
    public literal: any,
    public line: number
  ) {}
}
