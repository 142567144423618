import React, { FC } from "react";
import { tw } from "twind";

const SocietyLabelTopBar: FC<{}> = props => {
  return (
    <div className={tw("text-white font-bold")}>{sessionStorage.getItem("societeEnCours")}</div>
  );
};

export default SocietyLabelTopBar;
