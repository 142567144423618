import { Token } from "../Token";

export interface Visitor<R> {
  visitBinaryExpr(expr: Binary): R;
  visitBetweenExpr(expr: Between): R;
  visitInExpr(expr: In): R;
  visitLogicalExpr(expr: Logical): R;
  visitGroupingExpr(expr: Grouping): R;
  visitIdentifier(expr: Identifier): R;
  visitLiteralExpr(expr: Literal): R;
  visitVariable(expr: Variable): R;
  visitNullableExpr(expr: Nullable): R;
  visitNonNullableExpr(expr: NonNullable): R;
}

export abstract class Expr {
  public abstract accept<T>(visitor: Visitor<T>): T;
}

export class Logical extends Expr {
  constructor(public left: Expr, public operator: Token, public right: Expr) {
    super();
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitLogicalExpr(this);
  }
}

export class Nullable extends Expr {
  constructor(public expression: Expr) {
    super();
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitNullableExpr(this);
  }
}

export class NonNullable extends Expr {
  constructor(public expression: Expr) {
    super();
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitNonNullableExpr(this);
  }
}

export class Binary extends Expr {
  constructor(public left: Expr, public operator: Token, public right: Expr) {
    super();
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitBinaryExpr(this);
  }
}

export class Between extends Expr {
  constructor(public identifier: Expr, public left: Expr, public right: Expr) {
    super();
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitBetweenExpr(this);
  }
}
export class In extends Expr {
  constructor(public identifier: Expr, public negate: Boolean, public conditions: Expr[]) {
    super();
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitInExpr(this);
  }
}

export class Grouping extends Expr {
  constructor(public expression: Expr) {
    super();
  }
  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitGroupingExpr(this);
  }
}

export class Identifier extends Expr {
  constructor(public value: string) {
    super();
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitIdentifier(this);
  }
}

export class Variable extends Expr {
  public name: string;
  constructor(name: string) {
    super();
    this.name = name.substring(1);
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitVariable(this);
  }
}

export class Literal extends Expr {
  constructor(public value: unknown) {
    super();
  }

  public accept<T>(visitor: Visitor<T>): T {
    return visitor.visitLiteralExpr(this);
  }
}
