import React, { PureComponent, FC } from "react";
import { SATELLITES } from "constant/satellite";
import { AllSatelliteState } from "reducers/modules/satellites";
import { Link } from "react-router-dom";

// import { Spring, config, } from "react-spring/renderprops";
import { useSpring, config, animated } from "react-spring";
import { Fa } from "composants/Icon";
import { formatNumber } from "utils/i18n";
import { compose } from "redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

type IndicateurBarSattelliteAllProps = { satellites: AllSatelliteState } & {
  className: string;
  openSatelliteParams(satellite: string, contextId?: string): string;
};

const formatIntegerBetweenHundred = compose(
  formatNumber,
  (number: number) => Math.max(0, number),
  (number: number) => Math.min(100, number),
  Math.round
);

const Indicateur: FC<{
  className?: string;
  params: string;
  icon: any;
  count: number;
  title: string;
}> = ({ className, params, icon, count, title }) => {
  const props = useSpring<{ count: number }>({
    config: config.gentle,
    count: count || 0
  });

  const [t] = useTranslation();

  return (
    <span>
      <Link className="has-text-white" to={{ search: params }} title={t(title)}>
        <span className={classNames("fa-layers fa-fw fa-2x", className)}>
          <Fa icon={["fal", icon]} transform="shrink-1" />
          <animated.span
            className="fa-layers-counter"
            style={{ opacity: props.count.interpolate(c => (c === 0 ? 0 : 1)) }}
          >
            {props.count.interpolate(c => {
              return c === 0 ? "" : formatIntegerBetweenHundred(c) || "";
            })}
          </animated.span>
        </span>
      </Link>
    </span>
  );
};

class IndicateurBarSattellite extends PureComponent<IndicateurBarSattelliteAllProps> {
  private lastCount: Record<string, number> = {};

  componentDidMount() {
    for (let sat in SATELLITES) {
      if (this.props.satellites[sat]) {
        this.lastCount[sat] = 0;
      }
    }
  }

  componentDidUpdate(prevProps: IndicateurBarSattelliteAllProps) {
    for (let sat in SATELLITES) {
      if (this.props.satellites[sat] && this.props.satellites[sat].visible) {
        this.lastCount[sat] = Math.max(0, Math.min(prevProps.satellites[sat].count, 100));
      }
    }
  }

  buildBageList() {
    let result = [];
    for (const sat in SATELLITES) {
      // Les clés dans icons, satelliteReducer et userSetting reducer sont identiques
      if (this.props.satellites[sat].visible) {
        result.push(this.buildOneBadge(sat));
      }
    }
    return result;
  }

  buildOneBadge(sat: string) {
    let params = this.props.openSatelliteParams(sat, this.props.satellites.context.id);
    const count = this.props.satellites[sat].count;

    return (
      <Indicateur
        key={sat}
        className={this.props.className}
        params={params}
        icon={SATELLITES[sat].icon}
        count={count}
        title={SATELLITES[sat].title}
      />
    );
  }

  render() {
    return <>{this.buildBageList()}</>;
  }
}

export default IndicateurBarSattellite;
