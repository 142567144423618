import { combineReducers } from "redux";
import SatellitesReducer, { AllSatelliteState } from "./modules/satellites";
import UserSettingsReducer, { UserSettingsState } from "./modules/UserSettingsReducer";
import GalaxiesReducer, { GalaxiesState } from "./modules/galaxies";
import EntitiesReducer, { EntitesState } from "./modules/EntitiesReducer";
import DashboardReducer, { DashboardState } from "./modules/dashboard/DashboardReducer";
import ExpertReducer, { ExpertState } from "./modules/expert/ExpertReducer";
import MessageReducer, { MessageReducerState } from "./modules/MessageReducer";
import DatatableReducer, { ReducerDatatableState } from "./modules/DatatableReducer";
import ContextMenuReducer, { ContextMenuState } from "./modules/ContextMenuReducer";
import ProcessusReducer, { ProcessusState } from "./modules/processus/ProcessusReducer";
import treeReducer, { TreeReducerState } from "./modules/TreeReducer";
import InteractionReducer, { InteractionReducerState } from "./modules/InteractionReducer";
import AdminGalaxyReducer, { AdminGalaxyState } from "./admin/AdminGalaxyReducer";
import EmailReducer, { EmailReduxState } from "./modules/email/EmailReducer";
import AdminMiniExpertReducer, { AdminMiniExpertState } from "./admin/AdminMiniExpertReducer";
import AdminCreatorReducer, { AdminCreatorReducerState } from "./admin/AdminCreator";
import AdminDatatableReducer, { AdminDatatableReducerState } from "./admin/AdminDatatableReducer";
import DraggableGroupListReducer, {
  DraggableGroupListReducerState
} from "./modules/DraggableGroupListReducer";
import ApiOkReducer, { ApiOkReducerState } from "reducers/modules/ApiDataOkReducer";
import AppReducer, { AppState, reducerGalaxyState, GalaxyState } from "./modules/AppReducer";
import notificationReducer, { NotificationState } from "./modules/NotificationReducer";

export interface ReducerState {
  app: AppState;
  galaxyState: GalaxyState;
  galaxies: GalaxiesState;
  dashboard: DashboardState;
  expert: ExpertState;
  entities: EntitesState;
  satellites: AllSatelliteState;
  userSettings: UserSettingsState;
  messages: MessageReducerState;
  datatable: ReducerDatatableState;
  contextMenu: ContextMenuState;
  interactions: InteractionReducerState;
  processus: ProcessusState;
  tree: TreeReducerState;
  adminGalaxy: AdminGalaxyState;
  email: EmailReduxState;
  adminMiniExpert: AdminMiniExpertState;
  adminCreator: AdminCreatorReducerState;
  adminDatatable: AdminDatatableReducerState;
  draggableGroupList: DraggableGroupListReducerState;
  apiDataOkReducer: ApiOkReducerState;
  notifications: NotificationState;
}

const rootReducer = combineReducers<ReducerState>({
  app: AppReducer,
  galaxyState: reducerGalaxyState,
  satellites: SatellitesReducer,
  galaxies: GalaxiesReducer,
  entities: EntitiesReducer,
  userSettings: UserSettingsReducer,
  contextMenu: ContextMenuReducer,
  dashboard: DashboardReducer,
  expert: ExpertReducer,
  messages: MessageReducer,
  datatable: DatatableReducer,
  interactions: InteractionReducer,
  processus: ProcessusReducer,
  tree: treeReducer,
  adminGalaxy: AdminGalaxyReducer,
  email: EmailReducer,
  adminMiniExpert: AdminMiniExpertReducer,
  adminCreator: AdminCreatorReducer,
  adminDatatable: AdminDatatableReducer,
  draggableGroupList: DraggableGroupListReducer,
  apiDataOkReducer: ApiOkReducer,
  notifications: notificationReducer
});

export default rootReducer;
