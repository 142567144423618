import Keycloak from "keycloak-js";
import Cookie from "js-cookie";

import { OAUTH_URL, REALM, CLIENT_ID } from "customGlobal";
import { analytics } from "tracking";

export const COOKIE_TOKEN = "octal-token";
export const COOKIE_TOKEN_REFRESH = "octal-token-refresh";
export const COOKIE_APP_ID = "octal-token-id";

const auth = new Keycloak({
  clientId: CLIENT_ID(),
  realm: REALM(),
  url: OAUTH_URL()
});

export type KeycloakTokenCustomEvent = CustomEvent<{ token: string }>;
export const KEYCLOAK_UPDATE_TOKEN_CUSTOM_EVENTS = "__keycloak_token_expired";

auth.onTokenExpired = () => {
  updateToken();
};

export async function initAuth() {
  try {
    const authenticated = await auth.init({
      onLoad: "login-required",
      checkLoginIframe: window.location.protocol === "https:",
      enableLogging: true,
      // on utilise l'ancien token en premier lors de l'initialisation
      // pour éviter une redirection si tout est bon.
      token: Cookie.get(COOKIE_TOKEN),
      refreshToken: Cookie.get(COOKIE_TOKEN_REFRESH),
      idToken: Cookie.get(COOKIE_APP_ID),
      timeSkew: 0
    });

    if (authenticated) {
      updateCookie();
      const userName: string | undefined = (auth.tokenParsed as any)?.user_name;
      userName && analytics.identify(userName);
    }
    return authenticated;
  } catch (e) {
    resetCookie();
    return false;
  }
}

export async function updateToken(): Promise<void> {
  try {
    const refreshed = await auth.updateToken(15);
    if (refreshed) {
      updateCookie();
      let customEvent = new CustomEvent(KEYCLOAK_UPDATE_TOKEN_CUSTOM_EVENTS, {
        detail: { token: auth.token }
      });
      window.dispatchEvent(customEvent);
    }
  } catch {
    console.log("failed to refresh the token");
  }
}

function updateCookie() {
  // on sauvegarde en STRICT dans les cookies le token + token refresh.
  Cookie.set(COOKIE_TOKEN, auth.token || "", { expires: 1, sameSite: "strict" });
  Cookie.set(COOKIE_TOKEN_REFRESH, auth.refreshToken || "", {
    expires: 1,
    sameSite: "strict"
  });
  Cookie.set(COOKIE_APP_ID, auth.idToken || "", { expires: 1, sameSite: "strict" });
}

function resetCookie() {
  // on sauvegarde en STRICT dans les cookies le token + token refresh.
  Cookie.remove(COOKIE_TOKEN);
  Cookie.remove(COOKIE_TOKEN_REFRESH);
  Cookie.remove(COOKIE_APP_ID);
}

/**
 * Méthode de déconnexion de l'application
 * - on revoke le token d'authentification
 * -
 */
export function disconnect() {
  sessionStorage.clear();
  resetCookie();
  auth.logout();
}

export function reconnect() {
  sessionStorage.clear();
  resetCookie();
  auth.login();
}

export function getAccountURI() {
  return auth.createAccountUrl();
}

if (import.meta.env.DEV) (window as any).auth = auth;

export default auth;
