import React, {
  Component,
  SyntheticEvent,
  CSSProperties,
  FC,
  useState,
  useRef,
  useEffect,
  useContext
} from "react";
import classNames from "classnames";
import { Portal } from "../Portal";
import { uuidv4 } from "utils/uuid.utils";
import { getPositionOverlay } from "utils/component.utils";

export interface DropDownContextProps {
  isActive: boolean;
  buttonRef: React.RefObject<any>;
  menuId: string;
  onOpen(): void;
  onClose(): void;
}

function noop() {
  /*empty*/
}

export const DropdownContext = React.createContext<DropDownContextProps>({
  isActive: false,
  onOpen: noop,
  onClose: noop,
  buttonRef: React.createRef(),
  menuId: uuidv4()
});

interface DropdownProps {
  className?: string;
  autoclose?: boolean;
  disabled?: boolean;
}

/**
 * Utiliser à la place composants/dropdown/Menu avec Overlay pour avoir une Portal.
 * @deprecated
 */
export const Dropdown: React.FC<React.PropsWithChildren<DropdownProps>> = props => {
  const [isActive, setIsActive] = useState(false);
  const refParent = useRef<HTMLDivElement>(null);
  const refButton = useRef<HTMLElement | null>(null);
  const menuId = useRef<string | null>(null);

  function getMenuId() {
    if (menuId.current == null) {
      menuId.current = uuidv4();
    }

    return menuId.current;
  }

  function onOpen() {
    if (props.disabled) {
      return;
    }

    setIsActive(true);
  }

  function onClose() {
    setIsActive(false);
  }

  const valueProvider = React.useMemo(() => {
    return {
      isActive,
      onOpen,
      onClose,
      buttonRef: refButton,
      menuId: getMenuId()
    };
  }, [isActive]);

  useEffect(() => {
    function onCloseEventListener(e: MouseEvent) {
      const isButtonMenu = refButton.current?.contains(e.target as Node);
      if (isButtonMenu) return;

      // on récupère en fonction de l'id à la place de dropdown-content, car si on utilise directement
      // DropdownRaw, il n'y a pas de classe associé au menu dans le dom. Le seul moyen
      // est alors de récupérer via l'id.
      if (e.target == refButton.current || refButton.current?.contains(e.target as Node)) return;

      const el = e.target && (e.target as HTMLElement).closest(`[id="${getMenuId()}"]`);

      if (!el || el.id !== getMenuId()) {
        onClose();
      }
    }

    if (props.autoclose && isActive) {
      document.addEventListener("click", onCloseEventListener);
    }

    return function cleanup() {
      if (props.autoclose) {
        document.removeEventListener("click", onCloseEventListener);
      }
    };
  }, [props.autoclose, isActive]);

  return (
    <div
      ref={refParent}
      className={classNames("dropdown", props.className, {
        "is-active": isActive
      })}
    >
      <DropdownContext.Provider value={valueProvider}>{props.children}</DropdownContext.Provider>
    </div>
  );
};

interface DropdownButtonProps {
  title?: string;
  render(params: DropDownContextProps): JSX.Element | JSX.Element[] | null;
}

export const DropdownButton: FC<DropdownButtonProps> = props => {
  const params = useContext(DropdownContext);

  return (
    <div title={props.title} className="dropdown-trigger">
      {props.render(params)}
    </div>
  );
};

interface DropdownMenuProps {
  className?: string;
  backgroundClass?: string;
  style?: CSSProperties;
  marginTop?: number;
  isRight?: boolean;
  render(params: DropDownContextProps): JSX.Element | JSX.Element[] | null;
}

type DropdownPortalAllProps = DropdownMenuProps & DropDownContextProps;

const DropdownPortal: FC<DropdownPortalAllProps> = props => {
  const [width, setWidth] = React.useState<number | null>(null);
  const divMenuRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (divMenuRef.current) {
      const rectMenu = divMenuRef.current.getBoundingClientRect();
      setWidth(rectMenu.width);
    }
  }, []);

  const rect = getPositionOverlay(props.buttonRef.current.getBoundingClientRect());

  const { marginTop = 10 } = props;
  return (
    <Portal>
      {props.backgroundClass && <div className={props.backgroundClass} />}
      <div
        id={props.menuId}
        ref={divMenuRef}
        className={props.className}
        role="menu"
        style={{
          position: "absolute",
          opacity: width === null ? 0 : 1,
          width: width || undefined,
          left: rect.x - (props.isRight ? (width || 200) - rect.width : 0),
          top: rect.y + marginTop,
          ...props.style
        }}
      >
        {props.render(props)}
      </div>
    </Portal>
  );
};

export const DropdownMenuRaw: FC<DropdownMenuProps> = props => {
  const params = useContext(DropdownContext);

  if (!params.buttonRef.current) {
    return null;
  }

  if (params.isActive) {
    return <DropdownPortal {...params} {...props} />;
  } else {
    return null;
  }
};

export const DropdownMenu: FC<DropdownMenuProps> = ({ className, ...props }) => {
  return <DropdownMenuRaw {...props} className={classNames("dropdown-content", className)} />;
};
