import { ComponentState, ComponentReadOnly } from "types/Component";
import { LOCAL_STORAGE_GRID_PADDING, GridPaddingType } from "./table/datatableBehavior";
import { uuidv4 } from "utils/uuid.utils";

const TYPE_COMPO_SELECTION = "DATATABLE_INTERNAL_SELECTION";
const SELECTION_COMPONENT: ComponentState = {
  id: uuidv4(),
  column: "selection",
  typeCompo: TYPE_COMPO_SELECTION,
  label: "",
  labelSize: 40,
  contentSize: 40,
  position: 0,
  readOnly: ComponentReadOnly.DEFAULT,
  disabled: ComponentReadOnly.DEFAULT,
  mandatory: false,
  mask: "",
  joinTableName: "",
  joinDisplayedFields: "",
  joinListFields: "",
  wvi: false,
  joinListFiltre: "",
  additionalClause: undefined as any,
  compoVisible: true
};

export const DATATABLE_SELECTION = {
  TYPE: TYPE_COMPO_SELECTION,
  COMPONENT: SELECTION_COMPONENT
};

const TYPE_COMPO_EXPAND = "DATATABLE_INTERNAL_EXPAND";
const ROW_EXPAND_COMPONENT: ComponentState = {
  id: uuidv4(),
  column: "rowExpand",
  typeCompo: TYPE_COMPO_EXPAND,
  label: "",
  labelSize: 40,
  contentSize: 40,
  position: 1,
  readOnly: ComponentReadOnly.DEFAULT,
  disabled: ComponentReadOnly.DEFAULT,
  mandatory: false,
  mask: "",
  joinTableName: "",
  joinDisplayedFields: "",
  joinListFields: "",
  wvi: false,
  joinListFiltre: "",
  additionalClause: undefined as any,
  compoVisible: true
};

export const DATATABLE_EXPAND = {
  TYPE: TYPE_COMPO_EXPAND,
  COMPONENT: ROW_EXPAND_COMPONENT
};

const TYPE_COMPO_ACTION = "DATATABLE_INTERNAL_ACTION";
const ACTION_COMPONENT: ComponentState = {
  id: uuidv4(),
  column: "action",
  typeCompo: TYPE_COMPO_ACTION,
  label: "",
  labelSize: 40,
  contentSize: 40,
  position: 0,
  readOnly: ComponentReadOnly.DEFAULT,
  disabled: ComponentReadOnly.DEFAULT,
  mandatory: false,
  mask: "",
  joinTableName: "",
  joinDisplayedFields: "",
  joinListFields: "",
  wvi: false,
  joinListFiltre: "",
  additionalClause: undefined as any,
  compoVisible: true
};

export const DATATABLE_ACTION = {
  TYPE: TYPE_COMPO_ACTION,
  COMPONENT: ACTION_COMPONENT
};
