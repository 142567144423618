export const FETCH_EXPERT_MENU = "FETCH_EXPERT_MENU";
export const FETCH_EXPERT_MENU_SUCCESS = "FETCH_EXPERT_MENU_SUCCESS";

export const FETCH_EXPERT_GROUP = "FETCH_EXPERT_GROUP";
export const FETCH_EXPERT_GROUP_SUCCESS = "FETCH_EXPERT_GROUP_SUCCESS";

export const FETCH_EXPERT_HEADER = "FETCH_EXPERT_HEADER";
export const FETCH_EXPERT_HEADER_SUCCESS = "FETCH_EXPERT_HEADER_SUCCESS";
export const CLEAR_EXPERT_HEADER = "CLEAR_EXPERT_HEADER";

export const ON_CLOSE_EXPERT_CALLBACK = "ON_CLOSE_EXPERT_CALLBACK";
