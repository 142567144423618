import { apiAdn, getUIContext } from "./common";
import { AxiosPromise } from "axios";
import { ComponentState } from "types/Component";

export function getColumnDefinition(
  sjmoCode: string,
  table: string,
  columns?: string[]
): AxiosPromise<ComponentState[]> {
  const params = getUIContext({ sjmoCode });
  params.append("table", table);

  if (columns) {
    params.append("columns", columns.join(","));
  }

  return apiAdn.get(`/columns?${params}`);
}

export function getColumnDefinitionByTableAndColumn(tableName: string, field: string) {
  return apiAdn.get<string>(`/columns/${tableName}/${field}`);
}
