import {
  FIND_ONE,
  VALUE_CHANGE,
  ADD_ENTITY,
  FIND_ONE_SUCCESS,
  ADD_ALL_ENTITIES,
  REMOVE_ENTITIES,
  SAVE_OR_UDPATE_ENTITY,
  SAVE_PICKLIST_RESULT,
  CREATE_ONE_ENTITY,
  DELETE_ONE_ENTITY
} from "constant/entities";
import { Dispatch } from "redux";
import { Pojo } from "types/Galaxy";

export function onValueChange(
  sjmoCode: string,
  tableName: string,
  key: string, // id
  ctrlkey: string,
  value: any
): any {
  return (dispatch: Dispatch<any>): Promise<any> => {
    dispatch({
      type: VALUE_CHANGE,
      payload: {
        sjmoCode,
        tableName,
        key,
        ctrlkey,
        value
      }
    });

    return Promise.resolve();
  };
}

export function findOne(
  sjmoCode: string,
  tableName: string,
  id: string,
  includeStyle: boolean = false,
  labelContext?: string,
  labelDetailsContext?: string
) {
  return {
    type: FIND_ONE,
    payload: {
      sjmoCode,
      tableName,
      id,
      includeStyle,
      labelContext,
      labelDetailsContext
    }
  };
}

export function findOneSuccess(sjmoCode: string, value: any, tableName: string, cle: string) {
  return {
    type: FIND_ONE_SUCCESS,
    payload: {
      sjmoCode,
      value,
      tableName,
      cle
    }
  };
}

export function addEntity(
  sjmoCode: string,
  tableName: string,
  key: string, // id
  value: any,
  reset: boolean = false
): any {
  return (dispatch: Dispatch<any>): Promise<any> => {
    dispatch({
      type: ADD_ENTITY,
      payload: {
        sjmoCode,
        tableName,
        key,
        value,
        reset
      }
    });

    return Promise.resolve();
  };
}

export function addAllEntities(
  sjmoCode: string,
  tableName: string,
  pojos: Pojo[],
  reset: boolean = false
): any {
  return (dispatch: Dispatch<any>): Promise<any> => {
    dispatch({
      type: ADD_ALL_ENTITIES,
      payload: {
        sjmoCode,
        tableName,
        pojos,
        reset
      }
    });

    return Promise.resolve();
  };
}

export function removeEntities(sjmoCode: string, tableName: string, ids: (number | string)[]) {
  return {
    type: REMOVE_ENTITIES,
    payload: {
      sjmoCode,
      tableName,
      ids
    }
  };
}

/**
 * Enregistre une nouvelle entitée en base et charge cette entité dans redux
 * On ne prend pas un Pojo en entré car pour créer on a pojo.id = undefined
 *
 * @export
 * @param {string} sjmoCode
 * @param {string} tableName
 * @param {*} pojo
 * @returns
 */
export function createOneEntity(sjmoCode: string, tableName: string, pojo: any) {
  return {
    type: CREATE_ONE_ENTITY,
    payload: {
      sjmoCode,
      tableName,
      pojo
    }
  };
}

export function deleteOneEntity(sjmoCode: string, tableName: string, id: String | number) {
  return {
    type: DELETE_ONE_ENTITY,
    payload: { sjmoCode, tableName, id }
  };
}

export function saveOrUpdateEntity(
  sjmoCode: string,
  tableName: string,
  id: string,
  urlAfterSave?: string,
  callback?: () => void
) {
  return {
    type: SAVE_OR_UDPATE_ENTITY,
    payload: {
      sjmoCode,
      tableName,
      id,
      urlAfterSave,
      callback
    }
  };
}

/**
 * Fonction générique utilisé pour enregistré le résultat d'une picklist.
 *
 * Elle appelle le service eponyme.
 * Ce service delete en base tout les éléments dans oldIdToDelete.
 * Puis insert les éléments passés dans chosen.
 *
 * Le résultat est ensuite répercuté sur le réduxstate
 *
 * ATTENTION : cette méthode n'appel pas le pré-record les entités en entrée doivent être prête à l'enregistrement.
 *
 * @export
 * @param {sjmoCode} sjmoCode
 * @param {Pojo} chosen
 * @param {string} tableNme
 * @param {string[]} oldIdToDelete
 */
export function savePickListResult(
  sjmoCode: string,
  tableName: string,
  chosen: Pojo[],
  oldIdToDelete: string[]
) {
  return {
    type: SAVE_PICKLIST_RESULT,
    payload: { sjmoCode, tableName, chosen, oldIdToDelete }
  };
}
