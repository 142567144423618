export const INIT_AUTOCOMPLETE = "INIT_AUTOCOMPLETE";
export const REMOVE_AUTOCOMPLETE = "REMOVE_AUTOCOMPLETE";

export const GET_AUTOCOMPLETE_ENTITY = "GET_AUTOCOMPLETE_ENTITY";
export const GET_AUTOCOMPLETE_ENTITY_ERROR = "GET_AUTOCOMPLETE_ENTITY_ERROR";
export const GET_AUTOCOMPLETE_ENTITIES = "GET_AUTOCOMPLETE_ENTITIES";
export const UPDATE_SELECTED_AUTCOMPLETE = "UPDATE_SELECTED_AUTCOMPLETE";

export const START_LOADING_AUTOCOMPLETE = "START_LOADING_AUTOCOMPLETE";
export const STOP_LOADING_AUTOCOMPLETE = "STOP_LOADING_AUTOCOMPLETE";
export const UPDATE_ITEMS_AUTOCOMPLETE = "UPDATE_ITEMS_AUTOCOMPLETE";
export const GET_ITEMS_AUTOCOMPLETE = "GET_ITEMS_AUTOCOMPLETE";

export const UPDATE_INPUT_VALUE_AUTOCOMPLETE = "UPDATE_INPUT_VALUE_AUTOCOMPLETE";
export const UPDATE_VALUE_AUTOCOMPLETE = "UPDATE_VALUE_AUTOCOMPLETE";
