import { takeEvery } from "redux-saga/effects";
import { HandleApiMessageAction } from "actions/messages";
import { HANDLE_API_MESSAGE } from "constant/common";

import { NotificationGroup } from "types/Notification";

import toaster from "composants/toaster/toaster";

import { uuidv4 } from "utils/uuid.utils";
import { format } from "date-fns";

function* watchHandeApiMessage(action: HandleApiMessageAction) {
  const message = action.payload;

  yield window.requestIdleCallback(() => {
    if (message.message) {
      toaster.notify({
        id: message.code ?? uuidv4(),
        group: NotificationGroup.DEFAULT,
        title: message.message,
        priority: message.type === "DANGER" ? "HIGH" : "NORMAL",
        intent: message.type,
        createdAt: format(Date.now())
      });

      if (message.stackTrace) {
        console.groupCollapsed(message.message || message.code);
        console.table(message.stackTrace);
        console.groupEnd();
      }
    }
  });
}

export default [takeEvery(HANDLE_API_MESSAGE, watchHandeApiMessage)];
