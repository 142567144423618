import React, { FC } from "react";
import { Dropdown, DropdownButton, DropdownMenu } from "composants/DropDown/Dropdown";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { Fa } from "composants/Icon";

interface RefLinkProps {
  baseUrlLinkRef: string;
  linkRef: string | string[];
  linkRefLabel?: string;
}
export const RefLink: FC<RefLinkProps> = ({ baseUrlLinkRef, linkRef, linkRefLabel }) => {
  let preparedLinkRef: string[] = Array.isArray(linkRef)
    ? linkRef
    : linkRef.indexOf(",") !== -1
    ? linkRef.split(",")
    : [linkRef];

  return (
    <>
      {linkRefLabel && (
        <span className="is-size-5">
          <Trans i18nKey={linkRefLabel}>vers l'entité généré</Trans>
        </span>
      )}
      <Dropdown className="ml-7 mb-6" autoclose>
        <DropdownButton
          render={params => {
            return (
              <div ref={params.buttonRef}>
                <Link
                  className="underline-link is-capitalized is-size-5 has-text-weight-semibold"
                  to={baseUrlLinkRef + "/" + preparedLinkRef[0]}
                >
                  {preparedLinkRef[0]}
                </Link>
                {preparedLinkRef.length > 1 && (
                  <a href="#">
                    <span className="icon" onClick={params.onOpen}>
                      <Fa icon="caret-down" aria-label="open" />
                    </span>
                  </a>
                )}
              </div>
            );
          }}
        />
        <DropdownMenu
          render={() => {
            return (
              <div style={{ maxHeight: 300, overflowY: "auto" }}>
                {preparedLinkRef.slice(1).map(lr => (
                  <Link
                    className="dropdown-item underline-link is-capitalized is-size-5 has-text-weight-semibold"
                    to={baseUrlLinkRef + "/" + lr}
                  >
                    {lr}
                  </Link>
                ))}
              </div>
            );
          }}
        />
      </Dropdown>
    </>
  );
};

export function calculateLineTotalHT(
  qteCommandeeUv: number,
  prixUnitaireUvMaj: number,
  tauxRemise: number
) {
  return qteCommandeeUv * prixUnitaireUvMaj * (1 - tauxRemise / 100);
}
