import React, { createContext, FC, useContext, useRef, useEffect, useMemo } from "react";
import { createPortal } from "react-dom";

interface PortalProps {
  domRef?: string | React.RefObject<any>;
}

export const PortalContext = createContext<PortalProps>({});

const PortalInternal: FC<React.PropsWithChildren<PortalProps>> = props => {
  const domRef = useRef<HTMLDivElement | null>(null);

  // Permet d'initialiser qu'une seule fois l'élement dans le dom
  function getDomElement() {
    if (domRef.current === null) {
      domRef.current = document.createElement("div");
    }
    return domRef.current;
  }

  useEffect(() => {
    const domElement = getDomElement();

    // si on a fourni un id pour notre render
    // on utilise celui ci pour ajouter l'élément
    // au dom.
    // s'il n'y a pas de dom de référence
    // on ajoute directement au body
    if (props.domRef) {
      let element: HTMLElement | null;
      if (typeof props.domRef === "string") {
        element = document.getElementById(props.domRef);
      } else {
        element = props.domRef.current;
      }
      element && element.appendChild(domElement);
    } else {
      document.body.appendChild(domElement);
    }

    // on cleanup en sorti les éléments ajouté dans le dom pour être sûr de
    // ne pas rendre le navigateur de plus en plus lent
    // quand on ouvre un certain nombre de dialogue
    return function cleanup() {
      if (props.domRef) {
        let element: HTMLElement | null;
        if (typeof props.domRef === "string") {
          element = document.getElementById(props.domRef);
        } else {
          element = props.domRef.current;
        }
        element && element.removeChild(domElement);
      } else {
        document.body.removeChild(domElement);
      }
      domRef.current = null;
    };
  }, [props.domRef]);

  return createPortal(props.children, getDomElement());
};

export const PortalProvider: FC<React.PropsWithChildren<PortalProps>> = ({ children, domRef }) => {
  const value = useMemo(() => ({ domRef }), [domRef]);
  return <PortalContext.Provider value={value}>{children}</PortalContext.Provider>;
};

export const Portal: FC<React.PropsWithChildren<Partial<PortalProps>>> = ({
  children,
  domRef: overrideRef
}) => {
  const { domRef } = useContext(PortalContext);
  return <PortalInternal domRef={overrideRef || domRef}>{children}</PortalInternal>;
};
