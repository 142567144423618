import React, { FC } from "react";

import { CollapseComponent, Menu as MenuComponent } from "./Menu";

import { Menu } from "types/Menu";
import Scrollbars from "react-custom-scrollbars";
import { track } from "tracking";
import { useParams, NavLink } from "react-router-dom";

interface MenuNavigationExpertProps {
  basePath: string;
  menu: Menu[];
}

const MenuNavigationExpert: FC<MenuNavigationExpertProps> = props => {
  const params = useParams();

  let defaultOpen = undefined;
  props.menu.forEach(menu => {
    if (menu.code === params.expertId) {
      defaultOpen = menu.code;
    }
    menu.children.forEach(subMenu => {
      if (subMenu.code === params.expertId) {
        defaultOpen = menu.code;
      }
    });
  });

  return (
    <Scrollbars autoHide>
      <CollapseComponent
        defaultOpen={defaultOpen ? { [defaultOpen]: true } : undefined}
        render={({ open, onClickMenu }) => {
          return (
            <aside className="menu">
              <ul className="menu-list">
                <MenuComponent
                  open={open}
                  menu={props.menu}
                  classNames={{ ulClassName: "menu-list" }}
                  onClickMenu={key => {
                    onClickMenu(key);
                    track("menu::click");
                  }}
                  render={(item, onClickMenuItem) => {
                    const linkUrl = `${props.basePath}/${item.code}`;
                    if (onClickMenuItem && item.children.length > 0) {
                      return (
                        <>
                          <NavLink
                            className={isActive =>
                              `has-text-weight-semibold ${isActive.isActive ? "is-active" : ""}`
                            }
                            onClick={onClickMenuItem}
                            style={{ display: "inline-block", width: "100%" }}
                            to={linkUrl}
                          >
                            {item.label}
                          </NavLink>
                        </>
                      );
                    } else {
                      return (
                        <NavLink
                          className={isActive =>
                            `subtitle is-6 ${isActive.isActive ? "is-active" : ""}`
                          }
                          to={linkUrl}
                          onClick={() => {
                            track("menu::click::item");
                          }}
                        >
                          {item.label}
                        </NavLink>
                      );
                    }
                  }}
                />
              </ul>
            </aside>
          );
        }}
      />
    </Scrollbars>
  );
};

export default MenuNavigationExpert;
