import { combineReducers } from "redux";
import reducerGalaxyLoader, { GalaxyLoaderState } from "./galaxiesInformations.reducer";
import reducerGalaxyActive, { GalaxyActiveState } from "./galaxiesActive.reducer";

/**
 * State des reducers du dossier reducers/modules/galaxies
 */
export interface GalaxiesState {
  loader: GalaxyLoaderState;
  active: GalaxyActiveState;
}

/**
 * On combine les différents reducers du dossier galaxies
 */
export default combineReducers<GalaxiesState>({
  loader: reducerGalaxyLoader,
  active: reducerGalaxyActive
});
