import { useLayoutEffect } from "react";

export function useLockBodyScroll() {
  // useLayoutEffect allow to lock the screen **before** the modal is visible to the user.
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.documentElement).overflow;

    // Prevent scrolling on mount
    document.documentElement.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => {
      document.documentElement.style.overflow = originalStyle;
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
}
