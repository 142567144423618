import React, { FC, useState, SyntheticEvent, FormEvent } from "react";
import Modal from "composants/Modal/Modal";

import { t } from "utils/i18n";
import { uuidv4 } from "utils/uuid.utils";
import { Field } from "composants/form";
import { SaveProperties } from "types/InteractiveReport";
import { InteractiveReportConfiguration } from "types/Component";
import { ReducerState } from "reducers";
import { useDispatch, useSelector } from "react-redux";

interface SaveDialogProps {
  currentFocus: InteractiveReportConfiguration | null;
  onClose(): void;
  onValidate(saveProperties: SaveProperties): void;
  onDelete(id: number | string): void;
}

function initialSaveProperties(
  id: string | number | undefined | null,
  label: string | undefined | null,
  isGlobal?: boolean | undefined | null
): SaveProperties {
  return { id: id || uuidv4(), label: label || "", position: 0, isGlobal: isGlobal || false };
}

export const SaveDialog: FC<SaveDialogProps> = props => {
  const isAnExistingFocus = props.currentFocus && props.currentFocus.id !== undefined;

  const [currentProperties, setCurrentProperties] = useState<SaveProperties>(
    initialSaveProperties(
      props.currentFocus && props.currentFocus.id,
      props.currentFocus && props.currentFocus.label,
      props.currentFocus && props.currentFocus.isGlobal
    )
  );

  function makeNewDefinition() {
    setCurrentProperties(initialSaveProperties(null, ""));
  }

  function onChange(e: SyntheticEvent<any>) {
    const field = e.currentTarget.name;
    const value =
      e.currentTarget.type === "checkbox" ? e.currentTarget.checked : e.currentTarget.value;

    setCurrentProperties({
      ...currentProperties,
      [field]: value
    });
  }

  function onValidate(e: FormEvent) {
    e.preventDefault();
    // on autorise la sauvegarde sur des nouveaux focus **et** si le focus appartient à l'utilisateur
    if (
      !props.currentFocus ||
      props.currentFocus.isUserFocus ||
      (!props.currentFocus.isUserFocus && props.currentFocus.id !== currentProperties.id)
    ) {
      props.onValidate(currentProperties);
    }
  }

  function selectLangAndSuperUser({ userSettings }: ReducerState) {
    return { lang: userSettings.lang, adminLevel: userSettings.adminLevel };
  }

  const { adminLevel } = useSelector(selectLangAndSuperUser);

  return (
    <Modal title={t("commun_enregistrer_etat")} onClose={props.onClose} hideFooter>
      <form onSubmit={onValidate}>
        <Field label={t("commun_titre")} isHorizontal>
          <input
            className="input"
            required
            name="label"
            value={currentProperties.label}
            onChange={onChange}
          />
        </Field>
        {/* {adminLevel > 0 && ( */}
        <Field label={t("commun_global")} isHorizontal>
          <label className="checkbox">
            <input
              type="checkbox"
              name="isGlobal"
              checked={currentProperties.isGlobal}
              onChange={onChange}
            />
          </label>
        </Field>
        {/* )} */}
        <div className="field is-grouped">
          <div className="control">
            <input
              type="submit"
              className="button is-primary"
              value={t("commun_valider")}
              disabled={
                props.currentFocus &&
                !props.currentFocus.isUserFocus &&
                props.currentFocus.id === currentProperties.id
                  ? true
                  : false
              }
            />
          </div>
          <div className="control">
            <button
              type="button"
              className="button"
              disabled={!isAnExistingFocus}
              onClick={() => {
                props.currentFocus &&
                  props.currentFocus.id &&
                  props.onDelete(props.currentFocus.id);
              }}
            >
              {t("commun_supprimer")}
            </button>
          </div>
          <div className="control">
            <button
              type="button"
              className="button"
              disabled={
                !isAnExistingFocus ||
                (props.currentFocus !== null &&
                  props.currentFocus !== undefined &&
                  currentProperties.id !== props.currentFocus.id)
              }
              onClick={makeNewDefinition}
            >
              {t("commun_cliquer_pour_nouveau_focus")}
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
