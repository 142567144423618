import React, { FC, useState, useEffect } from "react";
import { ADMIN_GALAXIE } from "./AdminGalaxy";
import { ComponentState } from "types/Component";
import { t } from "utils/i18n";
import Modal from "composants/Modal/Modal";
import InputTextAndLabel from "composants/input/InputTextAndLabel";
import { convertValue } from "utils/entities.utils";
import { Message } from "types/Message";
import { findAll } from "api";

interface ModalEditGalaxyFocusProps {
  sjmoCode: string;
  createAction: "USER" | "ROLE" | "GLOBAL";
  galaxyFocuses: any;
  syjModuleId?: string;
  personnelId?: string;
  selectedFocus: string;
  onClose(): void;
  onCreateOrUpdateGalaxyFocus(params: {
    currentSjmoCode: string;
    title: string;
    id?: string;
    position?: number;
    associationId?: String;
    focusCode?: String;
    groupe?: String;
    privilegie: boolean;
    code: string;
    syjModuleId: string;
    sysMenuGroupeId: string;
    personnelId: string;
    callback: () => void;
  }): void;
  addMessage(message: Message): void;
}

const ModalEditGalaxyFocus: FC<ModalEditGalaxyFocusProps> = props => {
  const [focus, setFocus] = useState(props.galaxyFocuses);
  const [selectedFocus, setSelectedFocus] = useState("");
  const [title, setTitle] = useState("");
  const [lvAccess, setLvAccess] = useState("");
  const [groupe, setGroupe] = useState("");
  const [focusCode, setFocusCode] = useState("");
  const [syjModuleId, setSyjModuleId] = useState("");
  const [associationId, setAssociationId] = useState("");

  useEffect(() => {
    focus.map((e: any) => {
      if (e.focusId == props.selectedFocus) {
        setSelectedFocus(e.focusId);
        setTitle(e.focusLabel);
        setLvAccess(e.position);
        setAssociationId(e.associationId);
        setGroupe(e.focusNature);
        setFocusCode(e.focusCode);
        findAll({
          sjmoCode: ADMIN_GALAXIE,
          tableName: "syjModule",
          filter: "q=sjmoCode==" + props.sjmoCode
        }).then(response => setSyjModuleId(response.data.data[0].id));
      }
    });
  }, []);

  const onSave = () => {
    let isComplete = false;
    let values = {
      title: title,
      code: props.selectedFocus,
      syjModuleId: syjModuleId,
      id: selectedFocus,
      currentSjmoCode: props.sjmoCode,
      associationId: associationId,
      focusCode: focusCode,
      position: lvAccess,
      groupe: groupe,
      callback: props.onClose
    };

    if (title != null && lvAccess != null) isComplete = true;
    if (isComplete) {
      props.onCreateOrUpdateGalaxyFocus(values as any);
      // props.onClose();
    } else {
      const message: Message = {
        type: "DANGER",
        message: t("commun_champs_obligatoire_non_rempli"),
        target: "GLOBAL"
      };
      props.addMessage(message);
    }
  };
  const renderForm = () => {
    return (
      <>
        <InputTextAndLabel
          label={t("commun_titre")}
          id={"titleId"}
          value={title}
          onChange={e => {
            const titleinput = convertValue(e);
            setTitle(titleinput);
          }}
          required={true}
        />

        <InputTextAndLabel
          label={t("commun_position")}
          id={"lvAccessId"}
          value={lvAccess}
          onChange={e => {
            const lvinput = convertValue(e);
            setLvAccess(lvinput);
          }}
          required={true}
        />
      </>
    );
  };

  return (
    <Modal
      onClose={e => props.onClose()}
      onValidate={onSave}
      title={t("commun_edition_focus") + " | " + title + " | " + groupe}
    >
      {renderForm()}
    </Modal>
  );
};

export default ModalEditGalaxyFocus;
