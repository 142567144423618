import { GS } from "../query.utils";
import { Token, TokenType } from "./Token";
import { Scanner } from "./Scanner";
import * as Expr from "./expr/Expr";

class ParseError extends Error {}

export class Parser {
  private current: number = 0;
  private lastError: ParseError | null = null;

  constructor(private tokens: Token[]) {}

  static scanAndParse(source: string) {
    let scanner = new Scanner(source);

    let tokens = scanner.scanTokens();
    let parser = new Parser(tokens);

    let expr = parser.parse();

    if (expr == null && parser.lastError != null) throw parser.lastError;

    return expr;
  }

  parse(): Expr.Expr | null {
    try {
      // on commence par block car c'est partie la moins importante de notre langage.
      return this.block();
    } catch (e) {
      if (e instanceof ParseError) {
        this.lastError = e;
        return null;
      }

      throw e;
    }
  }

  block(): Expr.Expr {
    return this.or();
  }

  or(): Expr.Expr {
    // on récupère l'expression de gauche.
    let expr = this.and();

    // on parse les OR tant qu'il y en a
    while (this.match(TokenType.OR)) {
      // l'operateur que l'on applique
      let operator = this.previous();
      // la partie de droite de l'expression OR
      let right = this.and();
      // l'expression de remplacement de celle initiale.
      expr = new Expr.Logical(expr, operator, right);
    }

    return expr;
  }

  and(): Expr.Expr {
    // on récupère l'expression de gauche
    let expr = this.expression();

    // on parse les AND tant qu'il y en a
    while (this.match(TokenType.AND)) {
      // operateur
      let operator = this.previous();
      // expression de droite
      let right = this.expression();
      // l'expression de remplacement de celle initiale.
      expr = new Expr.Logical(expr, operator, right);
    }

    return expr;
  }

  expression(): Expr.Expr {
    // expression de gauche
    let expr = this.equality();
    // on vérifie si on est dans le cas d'un is / between / in
    if (expr instanceof Expr.Identifier) {
      if (this.match(TokenType.IS)) {
        // Si on a IS NOT NULL, on a une Expression NonNullable
        if (this.match(TokenType.NOT) && this.match(TokenType.NULL)) {
          return new Expr.NonNullable(expr);
        }
        // Si on a IS NULL, on a une Expression Nullable
        else if (this.match(TokenType.NULL)) {
          return new Expr.Nullable(expr);
        }

        // problème d'écriture, on arrête ici la lecture de notre code.
        throw new ParseError("'NOT NULL' or 'NULL' is expected after 'IS' expression");
      }

      if (this.match(TokenType.BETWEEN)) {
        let left = this.equality();
        this.consume(TokenType.AND, "Expect AND after left condition");
        let right = this.equality();

        return new Expr.Between(expr, left, right);
      }

      let negateIn = false;
      if (
        this.match(TokenType.IN) ||
        ((negateIn = this.match(TokenType.NOT)) && this.match(TokenType.IN))
      ) {
        this.consume(TokenType.PAREN_LEFT, "Expect '(' after in.");
        let conditions: Expr.Expr[] = [];
        conditions.push(this.equality());

        while (this.match(TokenType.COMMA)) {
          conditions.push(this.equality());
        }

        this.consume(TokenType.PAREN_RIGHT, "Expect ')' at the end of the in.");

        return new Expr.In(expr, negateIn, conditions);
      }
    }

    return expr;
  }

  equality(): Expr.Expr {
    // expression de gauche
    let expr = this.comparison();

    // est-ce que l'on a des != ou = ? On parse tant que l'on en a.
    while (this.match(TokenType.BANG_EQUAL, TokenType.EQUAL)) {
      // on récupère l'operateur
      let operator = this.previous();
      // on récupère l'expression de droite
      let right = this.comparison();

      // on modifie l'expression initiale.
      expr = new Expr.Binary(expr, operator, right);
    }

    return expr;
  }

  comparison(): Expr.Expr {
    // expression de gauche
    let expr = this.primary();

    // on parse tant que l'on a > >= < <=
    while (
      this.match(TokenType.GREATER, TokenType.GREATER_EQUAL, TokenType.LESS, TokenType.LESS_EQUAL)
    ) {
      // operateur
      let operator = this.previous();
      // expression de droite
      let right = this.primary();

      // on modifie l'expression initiale.
      expr = new Expr.Binary(expr, operator, right);
    }

    return expr;
  }

  primary(): Expr.Expr {
    if (this.match(TokenType.FALSE)) return new Expr.Literal(false);
    if (this.match(TokenType.TRUE)) return new Expr.Literal(true);
    // gestion du null
    if (this.match(TokenType.NULL)) return new Expr.Literal(null);

    // gestion des strings & nombres.
    if (this.match(TokenType.NUMBER, TokenType.STRING))
      return new Expr.Literal(this.previous().literal);

    // gestion des colonnes
    if (this.match(TokenType.IDENTIFIER)) return new Expr.Identifier(this.previous().lexeme);

    if (this.match(TokenType.VARIABLE)) return new Expr.Variable(this.previous().lexeme);

    // si on a une parenthèse, on a un groupe.
    // on a donc une nouvelle expression à parser.
    // La partie recursive est donc ici.
    if (this.match(TokenType.PAREN_LEFT)) {
      // on récupère l'expression
      let expr = this.block();
      // on vérifie que l'on a bien une fermeture de parenthèse.
      this.consume(TokenType.PAREN_RIGHT, "Expect ')' after expression.");
      // on retourne notre groupe.
      return new Expr.Grouping(expr);
    }

    // Si on arrive ici, c'est que l'on a un problème de syntaxe.
    throw this.error(this.peek(), "Expect expression.");
  }

  match(...types: TokenType[]) {
    for (let type of types) {
      if (this.check(type)) {
        this.advance();
        return true;
      }
    }
    return false;
  }

  check(type: TokenType) {
    if (this.isAtEnd()) return false;
    return this.peek().type == type;
  }

  consume(type: TokenType, message: string) {
    if (this.check(type)) return this.advance();

    throw this.error(this.peek(), message);
  }

  error(token: Token, message: string) {
    return new ParseError(`[line ${token.line}] ${message}`);
  }

  advance() {
    if (!this.isAtEnd()) this.current++;
    return this.previous();
  }

  isAtEnd() {
    return this.peek().type == TokenType.EOF;
  }

  peek() {
    return this.tokens[this.current];
  }

  previous() {
    return this.tokens[this.current - 1];
  }
}
