import produce from "immer";

import Action from "reducers/Action";
import {
  INIT_DGLIST_DEFINITION_SUCCESS,
  GET_FILTERABLE_LIST_DATA_SUCCESS,
  DGLIST_TEMPORARY_LOADING,
  DG_LIST_CLEAR_DATA
} from "constant/draggableGroupList";
import { DGListDefinition } from "types/draggableGroupList";
import { TreeDraggableCell } from "types/Tree";
import { listAsMapOfIndex } from "utils/entities.utils";

export interface DraggableGroupListReducerState {
  [sjmoCode: string]: { [panelId: number]: DGListDefinition };
}

export default function reducer(
  state: DraggableGroupListReducerState = {},
  action: Action<{ sjmoCode: string; ctrlKey: string }>
): DraggableGroupListReducerState {
  switch (action.type) {
    case INIT_DGLIST_DEFINITION_SUCCESS:
      return initDGListDefinition(state, action.payload as any);
    case GET_FILTERABLE_LIST_DATA_SUCCESS:
      return initDGListData(state, action.payload as any);
    case DGLIST_TEMPORARY_LOADING:
      return setTemporaryLoading(state, action.payload as any);
    case DG_LIST_CLEAR_DATA:
      return clearData(state, action.payload as any);
    default:
      return state;
  }
}

function initDGListDefinition(
  state: DraggableGroupListReducerState = {},
  payload: { sjmoCode: string; panelId: number; definition: DGListDefinition }
) {
  const { sjmoCode, panelId, definition } = payload;
  return produce(state, draft => {
    if (!state[sjmoCode]) {
      draft[sjmoCode] = {};
    }
    draft[sjmoCode][panelId] = definition;
  });
}

/**
 * Mise en place des vraies données dans la liste
 * Selon le cas on remplace ou on enrichie
 * @param state
 * @param payload
 */
function initDGListData(
  state: DraggableGroupListReducerState,
  payload: {
    sjmoCode: string;
    panelId: number;
    definitionId: number;
    data: TreeDraggableCell[];
    rowCount: number;
    replace: boolean;
    first: number;
  }
) {
  const { sjmoCode, panelId, definitionId, data, rowCount, replace, first } = payload;
  return produce(state, draft => {
    const def = draft[sjmoCode][panelId];

    def.groups[definitionId].data = replace
      ? (listAsMapOfIndex(data as any) as any)
      : { ...def.groups[definitionId].data, ...listAsMapOfIndex(data as any, first) };
    def.groups[definitionId].rowCount = rowCount;
  });
}

/**
 * Mise en place des donnée temporaire utiliser pour la gestion de la liste virtuelle.
 * @param state
 * @param payload
 */
function setTemporaryLoading(
  state: DraggableGroupListReducerState,
  payload: {
    sjmoCode: string;
    panelId: string;
    definitionId: number;
    loading: { [id: number]: "LOADIN_POJO" };
  }
) {
  const { sjmoCode, panelId, definitionId, loading } = payload;
  return produce(state, draft => {
    const def = draft[sjmoCode][panelId].groups[definitionId];
    def.data = { ...def.data, ...loading };
  });
}

function clearData(
  state: DraggableGroupListReducerState,
  payload: {
    sjmoCode: string;
    panelId: number;
    definitionId: number;
  }
) {
  const { sjmoCode, panelId, definitionId } = payload;
  return produce(state, draft => {
    const def = draft[sjmoCode][panelId];
    def.groups[definitionId].data = {};
  });
}
