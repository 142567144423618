import Raven from "raven-js";
import { SENTRY_URL, ENVIRONMENT, NODE_ENV } from "customGlobal";

// fonction d'initialisation du logger (sentry)
export default function registerLogger(): void {
  const url = SENTRY_URL();
  const env = NODE_ENV();
  if (env === "production" && url) {
    const environment = ENVIRONMENT() || env;

    // configuration de sentry
    Raven.config(url, {
      release: "todo", // Le numéro de version doit être récupéré depuis la base via userSettings.ts/getVersion
      environment: environment
    }).install();
  }
}
