import React, { FC, useEffect, useState } from "react";
import { t, formatDate, formatDateRelative } from "utils/i18n";
import { parse, getHours, getMinutes, differenceInDays } from "date-fns";

import { showContextInfo } from "api/contextMenu";
import { useGetComponentState } from "composants/GetComponent";
import { GSBuilder, GSVisitorCallback } from "utils/query.utils";
import { findOne } from "api";
import { Pojo } from "types/Galaxy";
import Axios, { AxiosPromise } from "axios";

interface ContextInfoProps {
  contextColumnName: string | null;
  contextTableName: string | null;
  origineTableId: string | null;
  contextTableId: string | null;
}

const visitorToJSX: GSVisitorCallback<React.ReactNode> = {
  And: (node, visitor) => {
    let nodes = [];
    for (let n of node.nodes) {
      nodes.push(GSBuilder.visit(n, visitor));
    }
    return <ul>{nodes}</ul>;
  },
  Or: (node, visitor) => {
    let nodes = [];
    for (let n of node.nodes) {
      nodes.push(GSBuilder.visit(n, visitor));
    }
    return <ul>{nodes}</ul>;
  },
  Comparison: node => {
    return (
      <li key={node.field}>
        {node.field} {node.value ? <strong>{node.value}</strong> : null}
      </li>
    );
  }
};

function formatDateSuivi(date: string) {
  const parsedDate = parse(date);
  if (differenceInDays(new Date(), parsedDate) < 7) {
    return formatDateRelative(date);
  }

  if (getHours(parsedDate) === 0 && getMinutes(parsedDate) === 0) {
    return formatDate(date, {
      day: "numeric",
      month: "long",
      year: "numeric"
    });
  }

  return formatDate(date, {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
}

const ContextInfo: FC<ContextInfoProps> = props => {
  const [help, setHelp] = useState("");
  const [currentEntity, setCurrentEntity] = useState<Pojo | null>(null);

  const { state, computedColumnsState } = useGetComponentState("CTX_INFO", props.contextTableName, [
    props.contextColumnName ?? ""
  ]);

  useEffect(() => {
    if (props.contextColumnName && props.contextTableName) {
      showContextInfo(props.contextColumnName, props.contextTableName)
        .then(res => {
          setHelp(res.data);
        })
        .catch(err => {
          console.groupCollapsed("erreur lors de la récupération de l'aide'");
          console.error(err);
          console.groupEnd();
        });
    }
  }, [props.contextColumnName, props.contextTableName]);

  useEffect(() => {
    if (!props.contextTableName || !props.origineTableId) return;

    const source = Axios.CancelToken.source();
    findOne({ tableName: props.contextTableName, id: props.origineTableId }, source)
      .then(res => setCurrentEntity(res.data))
      .catch(err => {
        console.error(
          `error during fetch of ${props.contextTableName} with id ${props.origineTableId}`
        );
        setCurrentEntity(null);
      });

    return () => {
      source.cancel();
    };
  }, [props.contextTableName, props.origineTableId]);

  return (
    <div>
      <div className="field is-horizontal">
        <div className="field-label is-normal has-text-left">
          <label className="label">{t("commun_table")}</label>
        </div>

        <div className="field-body">
          <div className="field">
            <div className="control">
              <output className="input">{props.contextTableName}</output>
            </div>
          </div>
        </div>
      </div>
      <div className="field is-horizontal">
        <div className="field-label is-normal has-text-left">
          <label className="label">{t("commun_colonne")}</label>
        </div>

        <div className="field-body">
          <div className="field">
            <div className="control">
              <output className="input">{props.contextColumnName}</output>
            </div>
          </div>
        </div>
      </div>
      {state === "OK" &&
        computedColumnsState.columns.length > 0 &&
        computedColumnsState.columns[0].typeCompo === "GS" && (
          <div className="field is-horizontal">
            <div className="field-label is-normal has-text-left">
              <label className="label">{t("commun_recherche")}</label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <div className="container content">
                    {GSBuilder.visit(
                      computedColumnsState.columns[0].additionalClause,
                      visitorToJSX
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      <div className="field is-horizontal">
        <div className="field-label is-normal has-text-left">
          <label className="label">{t("commun_suivi_modification")}</label>
        </div>
        <div className="field-body">
          {currentEntity && (
            <ul>
              {currentEntity.dtMaj && (
                <li className="mb-5">
                  {t("commun_suivi_modification_maj", {
                    date: currentEntity.dtMaj,
                    user: currentEntity.loginMaj
                  })}
                </li>
              )}
              <li>
                {t("commun_suivi_modification_cre", {
                  date: currentEntity.dtCre,
                  user: currentEntity.loginCre
                })}
              </li>
            </ul>
          )}
        </div>
      </div>
      <pre className="p-8">{help}</pre>
    </div>
  );
};

export default ContextInfo;
