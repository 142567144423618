import { AxiosPromise } from "axios";
import { getUIContext, apiAdn } from "./common";
import { Pojo } from "types/Galaxy";
import { FlatTreeNode, RoughTreeData } from "types/Tree";
import { ADMIN_MINI_EXPERT_TREE_MODULE } from "constant/adminMiniExpert";

export interface AdminMiniExpertActionType {
  sjmoCode: string;
  focusId: string;
  current: RoughTreeData;
  nextParent: RoughTreeData;
  nextIndex: number;
}

export function getColumns(
  sjmoCode: string,
  tableId: string
): AxiosPromise<{ tableName: string; columns: Pojo[] }> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/miniexpert/column?${params}&tableId=${tableId}`);
}

export function getMiniExpertTreeData(
  sjmoCode: string,
  focusId: string
): AxiosPromise<{ treeData: FlatTreeNode[]; treeId: number }> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/miniexpert/tree?${params}&focusId=${focusId}`);
}

export function callAdminMiniExpertOnInsertNode(nodeParams: AdminMiniExpertActionType) {
  const params = getUIContext({ sjmoCode: nodeParams.sjmoCode });

  const body = {
    id: nodeParams.current.id,
    parentCible: nodeParams.nextParent ? nodeParams.nextParent.id : null,
    position: nodeParams.nextIndex,
    nature: nodeParams.current.nature,
    parentNature: nodeParams.nextParent ? nodeParams.nextParent.nature : null,
    title: nodeParams.current.title,
    focusId: nodeParams.focusId
  };

  return apiAdn.post(`/admin/miniexpert/tree/node?${params}`, body);
}

export function callAdminMiniExpertOnMoveNode(nodeParams: AdminMiniExpertActionType) {
  const params = getUIContext({ sjmoCode: nodeParams.sjmoCode });

  const body = {
    id: nodeParams.current.id,
    parentCible: nodeParams.nextParent ? nodeParams.nextParent.id : null,
    position: nodeParams.nextIndex,
    nature: nodeParams.current.nature,
    parentNature: nodeParams.nextParent ? nodeParams.nextParent.nature : null,
    title: nodeParams.current.title,
    focusId: nodeParams.focusId
  };

  return apiAdn.put(`/admin/miniexpert/tree/node?${params}`, body);
}

export function callAdminMiniExpertOnDeleteNode(nodeParams: {
  current: RoughTreeData;
  sjmoCode: string;
  focusId: string;
}): AxiosPromise<FlatTreeNode[]> {
  const params = getUIContext({ sjmoCode: nodeParams.sjmoCode });

  return apiAdn.delete(
    `/admin/miniexpert/tree/node?${params}&id=${nodeParams.current.id}&nature=${
      nodeParams.current.nature
    }&focusId=${nodeParams.focusId}`
  );
}

export function saveNewMiniExpert(createParam: {
  sjmoCode: string;
  syjTablesId: string;
  sjemCode: string;
  sjemSjrbResourceKey: string;
  title: string;
  privilegie: boolean;
  syjModuleId?: string;
  sysMenuGroupeId?: string;
  personnelId?: string;
}): AxiosPromise<{ id: string; tableId: string }> {
  const params = getUIContext({ sjmoCode: createParam.sjmoCode });

  return apiAdn.post(`/admin/miniexpert/?${params}`, createParam);
}

export function deleteMiniExpertFocusApi(focusId: number): AxiosPromise<{ id: string }> {
  const params = getUIContext({ sjmoCode: ADMIN_MINI_EXPERT_TREE_MODULE });
  return apiAdn.delete(`/admin/miniexpert/${focusId}?${params}`);
}
