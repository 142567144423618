import React, { FC, useState, useEffect } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import { fetchMailData } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { addMessage } from "actions/messages";
import { t } from "utils/i18n";
import Scrollbars from "react-custom-scrollbars";
import MailList, { Mail } from "./MailList";
import TabHeader from "./TabHeader";
import { TEXT_FORMAT } from "../RichTextEditor/TextEditor";

const TabHistoriqueMail: FC<TabSatelliteProps> = props => {
  const [mails, setMails] = useState<Mail[]>([]);

  const { contextId, tableName, countAction } = props;
  useEffect(() => {
    if (contextId) {
      countAction(tableName, contextId);

      fetchMailData(tableName, contextId as string)
        .then(response => {
          setMails(response.data);
        })
        .catch(e => {
          const er = e as AxiosError<any>;
          if (!er.response) {
            return;
          }

          const message: Message = {
            code: er.response.data.code,
            message: t(er.response.data.message),
            type: er.response.data.type,
            target: er.response.data.target
          };
          addMessage(message);
        });
    }
  }, [contextId, countAction, tableName]);

  return (
    <>
      <TabHeader
        i18nKey="commun_mails_liees"
        count={props.count}
        tableName={props.tableName}
        contextId={props.contextId}
        sjmoCode={props.sjmoCode}
      />
      <Scrollbars autoHide style={{ height: props.height }}>
        <MailList tableName={props.tableName} mails={mails} textType={TEXT_FORMAT} />
      </Scrollbars>
    </>
  );
};

export default TabHistoriqueMail;
