import React, { Component, SyntheticEvent, CSSProperties, FC } from "react";
import { Trans } from "react-i18next";
import { format } from "date-fns";
import { Link } from "react-router-dom";
import produce from "immer";
import classNames from "classnames";
import get from "lodash-es/get";

import { Pojo } from "types/Galaxy";

import { findOne, findAll } from "api";

import { Row, Col } from "composants/Layout";
import { convertValue } from "utils/entities.utils";
import { executeKanbanLaunch, executeProcessLaunch } from "api/kanban";
import { KanbanDatatable } from "./KanbanDatatable";
import { getUserLang } from "utils/network.utils";
import { calculateLineTotalHT } from "./common";
import { initRsqlCriteria } from "utils/query.utils";
import { RSQLFilterExpression, Operators } from "rsql-criteria-typescript";

interface KanbanModalGcoCdeState {
  parent: Pojo | null;
  details: Pojo[];
  createdBl: Pojo[];
  loadingValidation: boolean;
}

export class KanbanModalGcoCde extends Component<
  { sjmoCode: string; id: string; kanbanDefinitionId: string; processId: string },
  KanbanModalGcoCdeState
> {
  state: KanbanModalGcoCdeState = {
    parent: null,
    details: [],
    createdBl: [],
    loadingValidation: false
  };

  private headerList = [
    "ligne",
    "article",
    "désignation",
    // "qté",
    // "UV",
    "qté à livrer",
    "reste à livrer",
    "PV maj up",
    "total HT"
  ];

  private datatableHeaderComponent = {
    "total HT": (key: string) => (
      <th key={key} className="text-right">
        {key}
      </th>
    ),
    default: (key: string) => <th key={key}>{key}</th>
  };

  get validDetails() {
    return this.state.details.filter(line => line.drvRalUv > 0);
  }

  findCdeAndLines = async () => {
    const resParent = await findOne({
      tableName: "cdeClient",
      id: this.props.id,
      includeJoinParent: true
    });

    const rsql = initRsqlCriteria();
    rsql.filters.and(new RSQLFilterExpression("cdeClientId.id", Operators.Like, this.props.id));
    // BLOCAGE qui vient de forms
    rsql.filters.and(
      new RSQLFilterExpression(
        "clientIdBl.clntCode",
        Operators.Like,
        resParent.data.clientIdBl.clntCode
      )
    );
    rsql.orderBy.add("id", "asc");

    const resDetails = await findAll({
      tableName: "ligneCdeClient",
      filter: rsql.build(),
      contextKey: "kanbanCdeDetailAdvanced1LigneCdeClient"
    });

    this.setState({ parent: resParent.data, details: resDetails.data.data });
  };

  componentDidMount() {
    this.findCdeAndLines();
  }

  updateQteALivrer = (index: number, e: SyntheticEvent<HTMLInputElement>) => {
    const newState = produce(this.state, draft => {
      draft.details[index].drvQteALivrerUv = convertValue(e);
    });

    this.setState(newState);
  };

  launch = () => {
    this.setState({ loadingValidation: true }, () => {
      if (this.props.kanbanDefinitionId) {
        executeKanbanLaunch(
          this.props.sjmoCode,
          this.props.kanbanDefinitionId,
          // on envoi seulement les lignes sur lesquelles il reste des quantités à livrer
          this.validDetails
        )
          .then(res => {
            this.setState({ createdBl: [...this.state.createdBl, ...res.data] });
          })
          .catch(() => console.error("cannot execute launch cde-to-bl"))
          .then(() => {
            this.findCdeAndLines().then(() => {
              this.setState({ loadingValidation: false });
            });
          });
      } else {
        executeProcessLaunch(
          this.props.sjmoCode,
          this.props.processId,
          // on envoi seulement les lignes sur lesquelles il reste des quantités à livrer
          this.validDetails
        )
          .then(res => {
            this.setState({ createdBl: [...this.state.createdBl, ...res.data] });
          })
          .catch(() => console.error("cannot execute launch cde-to-bl"))
          .then(() => {
            this.findCdeAndLines().then(() => {
              this.setState({ loadingValidation: false });
            });
          });
      }
    });
  };

  render() {
    const { parent: entity } = this.state;

    if (entity === null) {
      return null;
    }

    const { drvTotalAPayer: total, drvTotalTtc: totalTTC, drvTotalHt: totalHT } = entity;

    const currencyFormatter = new Intl.NumberFormat(getUserLang(), {
      style: "currency",
      currency: entity.deviseId.id
    });

    const numberFormatter = new Intl.NumberFormat(getUserLang(), {
      maximumFractionDigits: 2
    });

    return (
      <>
        <div style={{ padding: "0 0 2em 0" }}>
          <Row>
            <Col span={4}>
              <div
                className="is-size-1 has-text-black-ter has-text-weight-light"
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <div>
                  <Link
                    to={`/page/OGCO006/${entity.id}`}
                    title="naviguer vers la commande"
                    className="underline-link"
                  >
                    {entity.id}
                  </Link>
                </div>
              </div>
            </Col>
            <Col
              style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end" }}
            >
              <div className="is-size-3 has-text-black-ter has-text-weight-light">
                <strong>
                  <Link
                    to={`/page/OGCO001/${entity.clientIdCde.id}`}
                    title="naviguer vers le client"
                    className="underline-link"
                  >
                    {get(entity, "clientIdCde.clntNom")}
                  </Link>
                </strong>
                {" ("}
                {get(entity, "clientIdCde.id")}
                {")"}
              </div>
              <div className="is-size-3 has-text-black-ter has-text-weight-light">
                {get(entity, "societeId.sociLibelle")}
              </div>
              <div className="buttons">
                <button
                  onClick={this.launch}
                  className={classNames("button is-link", {
                    "is-loading": this.state.loadingValidation
                  })}
                  disabled={this.validDetails.length <= 0}
                >
                  Passer en bl &rarr;
                </button>
                {this.state.createdBl.length > 0 && (
                  <NavigationBl createdBl={this.state.createdBl} />
                )}
              </div>
            </Col>
          </Row>
          <div className="is-size-6 has-text-black-ter has-text-weight-light">
            par <strong>{get(entity, "personnelIdCde.persNom")}</strong> le{" "}
            <strong>{format(entity.cdclDtSaisie, "DD/MM/YYYY")}</strong>
          </div>

          <div className="is-size-6 has-text-black-ter has-text-weight-light">
            {entity.cdclReferenceClient ? (
              <>
                référence <strong>{entity.cdclReferenceClient}</strong>
              </>
            ) : (
              "aucune référence"
            )}
          </div>

          <div className="is-size-6 has-text-black-ter has-text-weight-light">
            service : <strong>{entity.serviceId?.servLibelle}</strong>
          </div>
        </div>
        <div>
          <KanbanDatatable
            header={this.headerList}
            renderHeader={key =>
              this.datatableHeaderComponent[key] === undefined
                ? this.datatableHeaderComponent["default"](key)
                : this.datatableHeaderComponent[key](key)
            }
            totalHT={currencyFormatter.format(totalHT)}
            totalTTC={currencyFormatter.format(totalTTC)}
            total={currencyFormatter.format(total)}
          >
            {this.state.details.map((line, index) => {
              const verticalAlign: CSSProperties = { verticalAlign: "middle" };
              return (
                <tr key={line.id}>
                  <th style={verticalAlign}>{line.lcclLigne}</th>
                  <td style={verticalAlign}>{line.articleId}</td>

                  <td style={verticalAlign}>{line.lcclDesignation}</td>
                  {/* <td>{line.lcclQteCommandeeUv}</td>
                  <td>{line.uniteIdUv}</td> */}
                  <td style={verticalAlign}>
                    <input
                      className="input"
                      type="number"
                      min={0}
                      max={line.drvRalUv}
                      value={line.drvQteALivrerUv}
                      onChange={e => this.updateQteALivrer(index, e)}
                    />
                  </td>
                  <td style={verticalAlign}>{line.drvRalUv}</td>
                  <td style={verticalAlign}>{numberFormatter.format(line.lcclPrixUnitaireUv)}</td>
                  <td style={verticalAlign} className="text-right">
                    <strong>
                      {currencyFormatter.format(
                        calculateLineTotalHT(
                          line.lcclQteCommandeeUv,
                          line.lcclPrixUnitaireUv,
                          line.lcclTauxRemise
                        )
                      )}
                    </strong>
                  </td>
                </tr>
              );
            })}
          </KanbanDatatable>
        </div>
      </>
    );
  }
}

const NavigationBl: FC<{ createdBl: Pojo[] }> = ({ createdBl }) => {
  const idNotNull = createdBl.filter(bl => bl.id !== null).length > 0;

  const ids =
    createdBl.length > 1 ? "ids=" + createdBl.map(bl => bl.id).join(",") + "&suiviOpen=true" : "";

  return idNotNull && createdBl.length > 0 ? (
    <Link
      to={`/page/OGCO008/${createdBl[0].id}?${ids}`}
      className="button is-link is-outlined is-large is-fullwidth mt-7"
    >
      <Trans i18nKey="commun_naviguer_bl" values={{ nbCde: createdBl.length }} /> &rarr;
    </Link>
  ) : null;
};
