import React, {
  FC,
  PureComponent,
  HTMLProps,
  Component,
  SyntheticEvent,
  KeyboardEvent,
  CSSProperties,
  MouseEvent,
  useState,
  useEffect
} from "react";
import { useDrag, useDrop } from "react-dnd";

import get from "lodash-es/get";
import classNames from "classnames";

import "./Kanban.css";
import { Pojo } from "types/Galaxy";
import { Scroll } from "../Scroll";
import DragNDropContext from "composants/DragNDropContext";
import { Fa } from "composants/Icon";

import { DropdownButton, Dropdown, DropdownMenu } from "composants/DropDown/Dropdown";
import FilterBarComponent from "composants/datatable/FilterBarComponent";
import { FilterBar } from "types/Search";
import { FilterBarDefinition } from "types/Component";
import { convertValue } from "utils/entities.utils";
import { Spring } from "react-spring/renderprops";
import { Trans } from "react-i18next";

const ItemsTypes = {
  CARD: "CARD"
};

interface KanbanContextProps {
  width: number;
  height: number;
  canMove: boolean;
  canReorder: boolean;
  filterBarFilters: Record<string, FilterBarDefinition[]>;
  filterBar: Record<string, FilterBar>;
  selectAll: Record<string, boolean>;
  selectedCards: Record<string, Pojo[]>;
  filterBarSelectedFilterChange?(codeLane: String, selectedId: string): void;
  filterBarStartDateChange?(codeLane: String, newDate: Date): void;
  filterBarEndDateChange?(codeLane: String, newDate: Date): void;
  loadMoreRow?(laneId: string): void;
  moveCard?(laneId: string, draggedId: string, currentId: string): void;
  changeLane?(entity: Pojo, laneId: string): void;
  onSelectCard?(entity: Pojo, laneId: string): void;
  onValidateLane?(laneId: string): void;
  onFilter?(laneId: string, value: string): void;
  onSelectAllChange?(laneId: string): void;
}

const KanbanContext = React.createContext<KanbanContextProps>({
  width: 300,
  height: 700,
  canMove: false,
  canReorder: false,
  filterBarFilters: {},
  filterBar: {},
  selectAll: {},
  selectedCards: {}
});

type CardWrapperAllProps = {
  id: string;
  entity: Pojo;
  laneId: string;
  moveCard?(draggedId: string, currentId: string): void;
  changeLane?(entity: Pojo, laneId: string): void;
  onSelectCard?(e: MouseEvent): void;
  onOpen(e: MouseEvent): void;
} & HTMLProps<HTMLDivElement>;

type DraggableProps = {
  canMove: boolean;
  canReorder: boolean;
};

type KanbanCardProps = CardWrapperAllProps & {
  isSelected: boolean;
} & DraggableProps;

function KanbanCard(props: KanbanCardProps) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemsTypes.CARD,
    item: { id: props.id, entity: props.entity, laneId: props.laneId },
    canDrag: props.canMove || props.canReorder,
    end: (_, monitor) => {
      if (!monitor) {
        return;
      }

      const monitorItem = monitor.getItem() as CardWrapperAllProps;
      const dropResult = monitor.getDropResult() as any;

      if (
        props.changeLane &&
        dropResult &&
        dropResult.laneId !== monitorItem.laneId &&
        props.canMove
      ) {
        props.changeLane(monitorItem.entity, dropResult.laneId);
      }
    },
    collect: monitor => ({ isDragging: monitor.isDragging() })
  });

  const [, drop] = useDrop(() => ({
    accept: ItemsTypes.CARD,
    hover: (item: KanbanCardProps, monitor) => {
      if (!monitor) {
        return;
      }
      const draggedId = (monitor.getItem() as any).id;

      if (draggedId !== item.id && item.moveCard && item.canReorder) {
        item.moveCard(draggedId, item.id);
      }
    }
  }));

  const {
    className,
    children,
    canMove,
    canReorder,
    moveCard,
    changeLane,
    laneId,
    isSelected,
    entity,
    onSelectCard,
    onOpen,
    ...otherProps
  } = props;

  return (
    <div
      ref={ref => {
        drag(ref);
        drop(ref);
      }}
      className={classNames(className, { "is-active": isSelected })}
      {...otherProps}
      style={{ ...otherProps.style, opacity: isDragging ? 0 : 1 }}
      onMouseUp={isDragging ? undefined : onSelectCard}
      onDoubleClick={isDragging ? undefined : onOpen}
    >
      {children}
    </div>
  );
}

export const CardWrapper: FC<CardWrapperAllProps> = props => {
  return (
    <KanbanContext.Consumer>
      {({ moveCard, changeLane, canMove, canReorder, selectedCards }) => {
        const isSelected =
          (selectedCards[props.laneId] || []).findIndex(
            p => props.entity && p.id === props.entity.id
          ) !== -1;
        return (
          <KanbanCard
            {...props}
            canMove={canMove}
            canReorder={canReorder}
            moveCard={moveCard as any}
            changeLane={changeLane}
            isSelected={isSelected}
          />
        );
      }}
    </KanbanContext.Consumer>
  );
};

const ValidateLane: FC<{ laneId: string; disabled: boolean }> = ({ laneId, disabled }) => {
  return (
    <KanbanContext.Consumer>
      {({ onValidateLane }) => {
        return (
          <button
            className="button is-rounded is-small is-blue-light is-pulled-right"
            aria-label="Validate"
            disabled={disabled}
            onClick={() => {
              if (!disabled && onValidateLane) {
                onValidateLane(laneId);
              }
            }}
          >
            <Fa icon="check" fixedWidth />
          </button>
        );
      }}
    </KanbanContext.Consumer>
  );
};

const FilterLane: FC<{ laneId: string }> = ({ laneId }) => {
  return (
    <KanbanContext.Consumer>
      {({
        filterBarFilters,
        filterBar,
        filterBarSelectedFilterChange,
        filterBarStartDateChange,
        filterBarEndDateChange,
        selectAll,
        onSelectAllChange
      }) => {
        let dangerActive =
          [
            get(filterBar, [laneId, "filterBarId"], null),
            get(filterBar, [laneId, "startDate"], null),
            get(filterBar, [laneId, "endDate"], null)
          ].filter(it => it !== null).length > 0;

        return (
          <Dropdown autoclose>
            <DropdownButton
              render={params => {
                return (
                  <a ref={params.buttonRef} onClick={params.onOpen}>
                    <Fa icon="filter" className={classNames(dangerActive && "has-text-danger")} />
                  </a>
                );
              }}
            />
            <DropdownMenu
              className="shadow-lg p-7"
              style={{
                border: "1px solid hsl(0, 0%, 85%)",
                background: "linear-gradient(to bottom, hsl(0, 0%, 98%), hsl(0, 0%, 94%))"
              }}
              render={() => {
                return (
                  <>
                    <div className="level">
                      <FilterBarComponent
                        className="is-centered"
                        filters={filterBarFilters[laneId] || []}
                        selectedFilter={get(filterBar, [laneId, "filterBarId"], null)}
                        startDate={get(filterBar, [laneId, "startDate"], null)}
                        endDate={get(filterBar, [laneId, "endDate"], null)}
                        selectedFilterChange={e =>
                          filterBarSelectedFilterChange &&
                          filterBarSelectedFilterChange(laneId, convertValue(e))
                        }
                        startDateChange={e =>
                          filterBarStartDateChange &&
                          filterBarStartDateChange(laneId, convertValue(e))
                        }
                        endDateChange={e =>
                          filterBarEndDateChange && filterBarEndDateChange(laneId, convertValue(e))
                        }
                      />
                    </div>
                    <label className="checkbox">
                      <input
                        type="checkbox"
                        checked={selectAll[laneId]}
                        onChange={() => onSelectAllChange && onSelectAllChange(laneId)}
                      />
                      <Trans i18nKey="commun_selectionner_tout" />
                    </label>
                  </>
                );
              }}
            />
          </Dropdown>
        );
      }}
    </KanbanContext.Consumer>
  );
};

type KanbanTitleProps = {
  laneId: string;
  disabledValidation: boolean;
  onFilter(filter: string): void;
};
type KanbanTitleState = {
  value: string;
};
export class KanbanTitle extends PureComponent<
  React.PropsWithChildren<KanbanTitleProps>,
  KanbanTitleState
> {
  state: KanbanTitleState = {
    value: ""
  };

  onChange = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      value: e.currentTarget.value
    });
  };

  onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      this.props.onFilter(this.state.value);
    }
  };

  render() {
    return (
      <div className="kanban-title has-text-centered has-text-weight-light flex justify-between flex-col">
        <div className="relative">
          <div className="absolute pin-l pl-7">
            {/* <Dot
              color={
                this.props.disabledValidation ? "rgba(32, 156, 238, 0.25)" : "rgb(32, 156, 238)"
              }
              title="color"
              size={24}
              style={{ position: "relative", top: -2 }}
              hasShadow={false}
            /> */}
            <FilterLane laneId={this.props.laneId} />
          </div>
          <span style={{ whiteSpace: "nowrap" }}>{this.props.children}</span>
          <span className="absolute pin-r pr-7 pt-8">
            <ValidateLane laneId={this.props.laneId} disabled={this.props.disabledValidation} />
          </span>
        </div>
        <div>
          <input
            className="input"
            style={{ width: "90%" }}
            value={this.state.value}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
          />
        </div>
      </div>
    );
  }
}

interface KanbanLaneAllProps {
  id: string;
}

class KanbanLanePlaceHolder extends Component<{ isOver: boolean; isOverSameId: boolean }> {
  from: CSSProperties = { opacity: 0, height: 0 };
  to: CSSProperties = { height: 100, opacity: 1 };

  innerRender = (styles: object) => (
    <div
      className="kanban-content-placeholder is-uppercase is-size-7 has-text-weight-normal"
      style={styles}
    >
      <Trans i18nKey="commun_deposer_ici">Déposer ici</Trans>
    </div>
  );

  render() {
    return this.props.isOver && !this.props.isOverSameId ? (
      <Spring from={this.from} to={this.to}>
        {this.innerRender}
      </Spring>
    ) : null;
  }
}

type KanbanLaneAllPropsWithReactDnd = KanbanLaneAllProps;

export function KanbanLane(props: React.PropsWithChildren<KanbanLaneAllPropsWithReactDnd>) {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemsTypes.CARD,
    drop: (props: KanbanLaneAllPropsWithReactDnd, monitor) => {
      if (!monitor) {
        return;
      }

      const { id } = props;
      return {
        laneId: id
      };
    },

    hover: (props, monitor) => {
      if (!monitor) {
        return;
      }

      const { id } = props;

      const draggedObj = monitor.getItem() as any;

      if (id === draggedObj.laneId) {
        setIsOverSameId(true);
        return;
      }

      return monitor.isOver();
    },
    collect: monitor => ({ isOver: monitor.isOver() })
  }));

  const [isOverSameId, setIsOverSameId] = useState(false);

  useEffect(() => {
    // lorsque le over n'est plus présent, on passe la variable à false.
    // elle pourra être passé à true à nouveau par react-dnd si besoin.
    if (isOverSameId && !isOver) {
      setIsOverSameId(false);
    }
  }, [isOverSameId, isOver]);

  const { id, children } = props;
  return (
    <KanbanContext.Consumer>
      {({ width, height, loadMoreRow }) => (
        <div id={id} className="kanban-lane" style={{ minWidth: width, maxWidth: width }}>
          {
            <div ref={drop}>
              <Scroll
                className="kanban-content word-break"
                style={{ height }}
                onScrollEnd={() => loadMoreRow && loadMoreRow(id)}
              >
                <KanbanLanePlaceHolder isOver={isOver} isOverSameId={isOverSameId} />
                {children}
              </Scroll>
            </div>
          }
        </div>
      )}
    </KanbanContext.Consumer>
  );
}

export const KanbanHeaderLane: FC<{
  id: string;
  title: string;
  disabledValidation: boolean;
}> = ({ id, title, disabledValidation }) => {
  return (
    <KanbanContext.Consumer>
      {({ width, onFilter }) => {
        return (
          <div className="kanban-lane" style={{ minWidth: width, maxWidth: width }}>
            <KanbanTitle
              laneId={id}
              onFilter={value => onFilter && onFilter(id, value)}
              disabledValidation={disabledValidation}
            >
              {title}
            </KanbanTitle>
          </div>
        );
      }}
    </KanbanContext.Consumer>
  );
};

class KanbanInternal extends Component<
  React.PropsWithChildren<{
    height: number;
    width: number;
    canMove: boolean;
    canReorder: boolean;
    lanes: { title: string; id: string; disabledValidation: boolean }[];
    filterBarFilters: Record<string, FilterBarDefinition[]>;
    filterBar: Record<string, FilterBar>;
    selectAll: Record<string, boolean>;
    selectedCards: Record<string, Pojo[]>;
    filterBarSelectedFilterChange?(codeLane: String, selectedId: string): void;
    filterBarStartDateChange?(codeLane: String, newDate: Date): void;
    filterBarEndDateChange?(codeLane: String, newDate: Date): void;
    loadMoreRow?(laneId: string): void;
    moveCard?(draggedId: string, currentId: string): void;
    changeLane?(entity: Pojo, laneId: string): void;
    onSelectCard?(entity: Pojo, laneId: string): void;
    onValidateLane?(laneId: string): void;
    onFilter?(laneId: string, value: string): void;
    onSelectAllChange?(laneId: string): void;
  }>
> {
  render() {
    const { lanes, children, ...otherProps } = this.props;

    return (
      <DragNDropContext>
        <KanbanContext.Provider value={otherProps}>
          <div style={{ overflowX: "scroll" }}>
            <div style={{ width: otherProps.width * lanes.length }}>
              <div className="kanban-header">
                {lanes.map(lane => (
                  <KanbanHeaderLane
                    key={lane.id}
                    id={lane.id}
                    title={lane.title}
                    disabledValidation={lane.disabledValidation}
                  />
                ))}
              </div>
              <div className="kanban-body">{children}</div>
            </div>
          </div>
        </KanbanContext.Provider>
      </DragNDropContext>
    );
  }
}

export const Kanban = KanbanInternal;
