import React, { CSSProperties } from "react";
import { SizeAndPositionData, VisibleCellRange, Map, GridCellRenderer } from "react-virtualized";
import { MeasuredCellParent } from "react-virtualized/dist/es/CellMeasurer";
import {
  RowBreakSubtotalRendererProps,
  rowBreakRenderer,
  RowBreakRendererProps,
  rowBreakSubtotalRenderer
} from "./RowBreak";
import { MAX_SIZE_BREAK_BOTTOM, MAX_SIZE_BREAK, MAX_SIZE_EXPAND } from "./cellRangeRenderer";

interface RenderBreakProps {
  rowIndex: number;
  rowDatum: SizeAndPositionData;
  top: number;
  isScrolling: boolean;
  scrollLeft: number;
  verticalOffsetAdjustment: number;
  horizontalOffsetAdjustment: number;
  cellCache: Map<any>;
  parent: MeasuredCellParent;
  visibleRowIndices: VisibleCellRange;
  canCacheStyle: boolean;
  styleCache: Map<any>;
}

interface RenderBreakRowProps extends RenderBreakProps {
  breakRowLabel: (index: number) => string;
}

export function renderBreakRow({
  rowIndex,
  rowDatum,
  top,
  verticalOffsetAdjustment,
  horizontalOffsetAdjustment,
  isScrolling,
  cellCache,
  breakRowLabel,
  visibleRowIndices,
  canCacheStyle,
  styleCache
}: RenderBreakRowProps) {
  let key = `${rowIndex}-break`;

  let style: CSSProperties;
  if (canCacheStyle && styleCache[key]) {
    style = styleCache[key];
  } else {
    style = {
      height: MAX_SIZE_BREAK,
      left: 0,
      position: "absolute",
      top,
      width: "100%",
      padding: "0.125em",
      backgroundColor: "hsl(0, 0%, 90%)"
    };

    styleCache[key] = style;
  }

  let rowBreakRendererProps: RowBreakRendererProps = { key, rowIndex, style, breakRowLabel };

  let renderedCell;
  if (isScrolling && !horizontalOffsetAdjustment && !verticalOffsetAdjustment) {
    if (!cellCache[key]) {
      cellCache[key] = rowBreakRenderer(rowBreakRendererProps);
    }

    renderedCell = cellCache[key];

    // If the user is no longer scrolling, don't cache cells.
    // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
  } else {
    renderedCell = rowBreakRenderer(rowBreakRendererProps);
  }

  return renderedCell ? renderedCell : null;
}

interface RenderBreakSubtotalProps extends RenderBreakProps {
  breakRowSubtotal: (index: number) => string;
}

export function renderBreakSubtotal({
  rowIndex,
  rowDatum,
  top,
  verticalOffsetAdjustment,
  horizontalOffsetAdjustment,
  isScrolling,
  cellCache,
  breakRowSubtotal,
  visibleRowIndices,
  canCacheStyle,
  styleCache
}: RenderBreakSubtotalProps) {
  let key = `${rowIndex}-break-subtotal`;

  let style: CSSProperties;
  if (canCacheStyle && styleCache[key]) {
    style = styleCache[key];
  } else {
    style = {
      height: MAX_SIZE_BREAK_BOTTOM,
      left: 0,
      position: "absolute",
      top,
      width: "100%",
      padding: "0.125em",
      backgroundColor: "hsl(0, 0%, 98%)",
      borderTop: "1px solid #dedede"
    };

    styleCache[key] = style;
  }

  let rowBreakSubtotalRendererProps: RowBreakSubtotalRendererProps = {
    key,
    rowIndex,
    style,
    breakSubtotalLabel: breakRowSubtotal
  };

  let renderedCell;
  if (isScrolling && !horizontalOffsetAdjustment && !verticalOffsetAdjustment) {
    if (!cellCache[key]) {
      cellCache[key] = rowBreakSubtotalRenderer(rowBreakSubtotalRendererProps);
    }

    renderedCell = cellCache[key];

    // If the user is no longer scrolling, don't cache cells.
    // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
  } else {
    renderedCell = rowBreakSubtotalRenderer(rowBreakSubtotalRendererProps);
  }

  return renderedCell ? renderedCell : null;
}

interface RowExpandableProps {
  rowExpandable?: Record<string, boolean>;
  rowExpandableRenderer: GridCellRenderer;
}

export interface RenderRowExpandableProps extends RowExpandableProps {
  rowIndex: number;
  rowDatum: SizeAndPositionData;
  isScrolling: boolean;
  scrollLeft: number;
  verticalOffsetAdjustment: number;
  horizontalOffsetAdjustment: number;
  cellCache: Map<any>;
  parent: MeasuredCellParent;
  visibleRowIndices: VisibleCellRange;
  canCacheStyle: boolean;
  styleCache: Map<any>;
}

/**
 * Permet de préparer et appeler rowExpandRenderer
 * @param param0 paramètre de render de notre rowExpand
 */
export function renderRowExpandable({
  parent,
  rowDatum,
  scrollLeft,
  verticalOffsetAdjustment = 0,
  isScrolling,
  rowIndex,
  visibleRowIndices,
  cellCache = {},
  rowExpandableRenderer,
  horizontalOffsetAdjustment,
  canCacheStyle,
  styleCache
}: RenderRowExpandableProps) {
  // positionnement de notre expand
  let key = `${rowIndex}-expand`;

  let style: CSSProperties;
  if (canCacheStyle && styleCache[key]) {
    style = styleCache[key];
  } else {
    style = {
      height: MAX_SIZE_EXPAND,
      left: 0,
      position: "absolute",
      top: rowDatum.offset + verticalOffsetAdjustment + (rowDatum.size - MAX_SIZE_EXPAND),
      width: "100%",
      boxShadow: "0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1)",
      borderRadius: 2,
      zIndex: 1
    };

    styleCache[key] = style;
  }

  let cellExpandRendererParams = {
    columnIndex: 0,
    isScrolling,
    isVisible: true,
    key,
    parent: parent as any,
    rowIndex,
    style
  };

  const rowVisible =
    (rowIndex >= visibleRowIndices.start && rowIndex <= visibleRowIndices.stop) ||
    (rowIndex + 1 >= visibleRowIndices.start && rowIndex + 1 <= visibleRowIndices.stop);

  let renderedCell;
  if (isScrolling && !horizontalOffsetAdjustment && !verticalOffsetAdjustment) {
    if (!cellCache[key]) {
      cellCache[key] = rowExpandableRenderer(cellExpandRendererParams);
    }

    renderedCell = cellCache[key];

    // If the user is no longer scrolling, don't cache cells.
    // This makes dynamic cell content difficult for users and would also lead to a heavier memory footprint.
  } else {
    renderedCell = rowExpandableRenderer(cellExpandRendererParams);
  }

  return rowVisible && renderedCell ? renderedCell : null;
}
