import React, { Component, SyntheticEvent, KeyboardEvent } from "react";
import debounce from "lodash-es/debounce";
import get from "lodash-es/get";
import { Row, Col } from "../Layout";
import { InputTextAndLabel } from "../input/InputTextAndLabel";
import { convertValue, listToMap } from "utils/entities.utils";
import { t } from "utils/i18n";
import EditeurCommentaire from "./EditeurCommentaire";
import { Commentaire } from "./TabCommentaire";
import { ToolbarButtonOverride } from "../datatable/Toolbar";
import { fetchOneCommentaireText, getFocus, getColumn, getDatatableFilters } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { addMessage } from "actions/messages";
import { GenericDatatable } from "../datatable";
import { DatatableSort } from "../datatable/table/datatableBehavior";
import { Pojo } from "types/Galaxy";
import { RSQLCriteria } from "rsql-criteria-typescript";
import { initRsqlCriteria, mapToRSQL } from "utils/query.utils";
import { fecthFilteredComments } from "api/satellites";
import { ComponentState, FilterBarDefinition } from "types/Component";
import { addAllEntities } from "actions";
import { connect } from "react-redux";
import { FilterBar } from "types/Search";
import { format } from "date-fns";
import { Button } from "composants/button";
import { Trans } from "react-i18next";

/**
 * Cette fonction prend une liste et selectionne le premier s'il existe plutto que de prendre un élément unique
 * car les différents cas d'appel correspondent tous à ce cas particulier
 * @param origine
 */
function mapDefinitionToFilterBar(origine: FilterBarDefinition[]) {
  if (origine.length > 0) {
    return {
      filterBarId: origine[0].filterBarId,
      startDate: origine[0].startDate ? format(origine[0].startDate) : null,
      endDate: origine[0].endDate ? format(origine[0].endDate) : null,
      filterBarDefaultDtFilter: ""
    };
  } else {
    return undefined;
  }
}

interface DupplicationCommentaireReduxState {}

interface DupplicationCommentaireReduxFn {
  addAllEntities(sjmoCode: string, tableName: string, pojos: Pojo[], reset: boolean): any;
}

interface DupplicationCommentaireProps {
  tableName: string;
  contextId: string;
  sjmoCode: string;
  currentText: any;
  currentType: string;
  currentLang: string;
  selectedComment?: Commentaire;
  forceUpdate: boolean;
  onChangeText(text: any): void;
  onChangeType(type: string): void;
  onChangeLang(lang: string): void;
  save(): void;
  duplicating(selected?: Commentaire): void;
  transfert(source: string): void;
}

interface DupplicationCommentaireState {
  searchQuery: string;
  sourceText: string;
  comments: Record<string, Pojo | "LOADING_POJO">;
  totalCount: number;
  currentText: string;
  currentLang: string;
  currentType: string;
  commentColumns: ComponentState[];
  // Sauvegarde du dernier rsql utilisé afin de le reprendre lorsque l'utilisateur fait une recherche APRES avoir filtré
  first: number;
  size: number;
  lastRsql: RSQLCriteria;
  filters: FilterBarDefinition[];
  filterBar?: FilterBar;
}

const DATATABLE_BUTTON_VISISIBLITY: ToolbarButtonOverride = {
  add: false,
  save: false,
  delete: false,
  focus: false,
  processus: false,
  satellite: false,
  exportExcel: false,
  exportPdf: false,
  refresh: true
};

type DupplicationCommentaireAllProps = DupplicationCommentaireProps &
  DupplicationCommentaireReduxState &
  DupplicationCommentaireReduxFn;

class DupplicationCommentaire extends Component<
  DupplicationCommentaireAllProps,
  DupplicationCommentaireState
> {
  state: DupplicationCommentaireState = {
    searchQuery: "",
    sourceText: "",
    currentText: "",
    currentLang: "",
    currentType: "",
    comments: {},
    totalCount: 0,
    commentColumns: [],
    first: 0,
    size: 10,
    lastRsql: initRsqlCriteria(),
    filters: []
  };

  fetchComments = debounce((first = 0, size = 10, reset: boolean = true) => {
    return fecthFilteredComments(
      this.props.sjmoCode,
      this.state.searchQuery,
      first,
      size,
      this.state.lastRsql.build(),
      this.state.filterBar
    )
      .then(response => {
        const listWithPojo = response.data.data;

        // Création de listes contenant un seul exemplaire de chaque jointure
        let langue: Pojo[] = [];
        let systable: Pojo[] = [];
        let personnel: Pojo[] = [];
        let sysCommentaire: Pojo[] = [];
        let commentaireType: Pojo[] = [];

        // Création d'une liste où les pojo dans commentaires sont remplacer par leur id
        let listWithId: Pojo[] = [];

        listWithPojo.forEach(c => {
          this.addUnique(langue, c, "langueId.id");
          this.addUnique(systable, c, "sysTableId.id");
          this.addUnique(personnel, c, "personnelId.id");
          this.addUnique(sysCommentaire, c, "sysCommentaireId.id");
          this.addUnique(commentaireType, c, "commentaireTypeId.id");

          let commentWithoutJoin = c;
          commentWithoutJoin.langueId = get(c, "langueId.id", null);
          commentWithoutJoin.sysTableId = get(c, "sysTableId.id", null);
          commentWithoutJoin.personnelId = get(c, "personnelId.id", null);
          commentWithoutJoin.sysCommentaireId = get(c, "sysCommentaireId.id", null);
          commentWithoutJoin.commentaireTypeId = get(c, "commentaireTypeId.id", null);
          listWithId.push(commentWithoutJoin);
        });

        // Ces listes sont envoyé dans redux
        this.props.addAllEntities(this.props.sjmoCode, "langue", langue, false);
        this.props.addAllEntities(this.props.sjmoCode, "sysTable", systable, false);
        this.props.addAllEntities(this.props.sjmoCode, "personnel", personnel, false);
        this.props.addAllEntities(this.props.sjmoCode, "sysCommentaire", sysCommentaire, false);
        this.props.addAllEntities(this.props.sjmoCode, "commentaireType", commentaireType, false);

        // Cette liste est envoyer en data dans la table
        const entities = reset
          ? listToMap(response.data.data, first)
          : { ...this.state.comments, ...listToMap(listWithId, first) };

        /*
        ATTENTION le double setState est voulu ; car si on met à jours les deux données en même temps
        alors qu'il y a un refresh de la grid en cours l'application crash sur un "cannot found index 6 of 0..0"
        cette correction est temporaire le temps de refaire cette interface au propre. 
         */
        if (reset) {
          this.setState({
            totalCount: response.data.meta.totalRecords,
            sourceText: ""
          });
        } else {
          this.setState({
            totalCount: response.data.meta.totalRecords
          });
        }

        this.setState({ comments: entities });
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (er.response) {
          const message: Message = {
            code: er.response.data.code,
            message: t(er.response.data.message),
            type: er.response.data.type,
            target: er.response.data.target
          };
          addMessage(message);
        }
      });
  }, 600);

  addUnique = (array: Pojo[], p: Pojo, member: string) => {
    if (
      get(p, member, null) != null &&
      array.findIndex(l => get(l, "id", null) === get(p, member, null)) === -1
    ) {
      array.push(get(p, member.substring(0, member.indexOf("."))));
    }
  };

  componentDidMount() {
    this.getCommentColumns();
    this.fetchComments();
  }

  getCommentColumns = () => {
    getFocus(this.props.sjmoCode, "commentaireEntete", "dtDupplicationCommentaire")
      .then(response => {
        if (response.data.length > 0) {
          return getColumn(this.props.sjmoCode, response.data[0].focusId);
        }
        return new Promise<{ data: any }>(resolve => {
          resolve({ data: [] });
        });
      })
      .then(responseColumn => {
        this.setState({ commentColumns: responseColumn.data });
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });

    getDatatableFilters(this.props.sjmoCode, "duplicationCommentaireCtrlKey")
      .then(response => {
        let privilegie: FilterBarDefinition[] = [];
        if (response.data.length > 0) {
          privilegie = response.data.filter(f => f.privilegie);
        }
        this.setState({
          filters: response.data,
          filterBar: mapDefinitionToFilterBar(privilegie)
        });
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  };

  changeDatatableFilter = (name: string, value: string) => {
    if (name === "filterBarId") {
      const filterBar = this.state.filters.filter(f => f.filterBarId === value);
      this.setState({ filterBar: mapDefinitionToFilterBar(filterBar) }, this.fetchComments);
    } else {
      this.setState(
        { filterBar: { ...(this.state.filterBar as FilterBar), [name]: value } },
        this.fetchComments
      );
    }
  };

  changeSourceText = (entity: Pojo | "LOADING_POJO") => {
    if (entity !== "LOADING_POJO") {
      fetchOneCommentaireText(entity.id.toString())
        .then(response => {
          this.setState({ sourceText: response.data });
        })
        .catch(e => {
          const er = e as AxiosError<any>;
          if (!er.response) {
            return;
          }

          const message: Message = {
            code: er.response.data.code,
            message: t(er.response.data.message),
            type: er.response.data.type,
            target: er.response.data.target
          };
          addMessage(message);
        });
    }
  };

  changeSourceTextById = (id: number | string) => {
    fetchOneCommentaireText(id.toString())
      .then(response => {
        this.setState({ sourceText: response.data });
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  };

  clearSourceText = () => {
    this.setState({ sourceText: "" });
  };

  loadMoreData = ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
    return this.fetchComments(startIndex, stopIndex - startIndex, false) ?? Promise.resolve();
  };

  updateSearch = (filter?: Record<string, any>, sort?: Record<string, DatatableSort>) => {
    const rsql = mapToRSQL(filter, sort);
    return new Promise<void>(resolve => {
      this.setState({ lastRsql: rsql }, () =>
        this.fetchComments(0, 10, true)
          ?.then(() => resolve())
          .catch(() => resolve())
      );
    });
  };

  render() {
    return (
      <Row style={{ width: "100%" }}>
        <Col span={6}>
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <InputTextAndLabel
                  id="txrSearchField"
                  value={this.state.searchQuery}
                  onChange={(e: SyntheticEvent<any>) => {
                    const val = convertValue(e);
                    this.setState({ searchQuery: val != null ? val : "" });
                  }}
                  label={t("commun_recherche")}
                  onKeyDown={(e: KeyboardEvent<any>) => {
                    if (e.ctrlKey && e.key === "Enter") {
                      const val = convertValue(e);
                      this.setState({ searchQuery: val != null ? val : "" }, () =>
                        this.fetchComments()
                      );
                    }
                  }}
                />
              </div>
              <div className="level-item">
                <Button onClick={() => this.fetchComments()} type="submit">
                  <Trans i18nKey="commun_recherche">Rechercher</Trans>
                </Button>
              </div>
            </div>
          </nav>

          <div className="commentaire-entete-table">
            <GenericDatatable
              toolbarButtonVisibility={DATATABLE_BUTTON_VISISIBLITY}
              sjmoCode={this.props.sjmoCode}
              tableName="listeGenerique"
              tableCtrlKey="dupplicationCommentaire"
              onRowSelect={this.changeSourceTextById}
              onRowUnselect={this.clearSourceText}
              loadMoreData={this.loadMoreData}
              updateSearch={this.updateSearch}
              entities={this.state.comments}
              originalColumns={this.state.commentColumns}
              totalRecords={this.state.totalCount}
              satellites={[]}
              showActionColumn={false}
              onRowClick={this.changeSourceText}
              flagDirtyEntities={false}
              filters={this.state.filters}
              filterBar={this.state.filterBar}
              onChangeFilterBar={this.changeDatatableFilter}
            />
          </div>

          <div className="box commentaire-source">
            <p dangerouslySetInnerHTML={{ __html: this.state.sourceText }} />
          </div>
        </Col>
        <Col span={6}>
          <EditeurCommentaire
            sjmoCode={this.props.sjmoCode}
            currentLang={this.props.currentLang as string}
            currentType={this.props.currentType as string}
            currentText={this.props.currentText}
            selectedComment={this.props.selectedComment}
            editing={this.props.duplicating}
            save={this.props.save}
            onChangeLang={this.props.onChangeLang}
            onChangeType={this.props.onChangeType}
            onChangeText={this.props.onChangeText}
            forceUpdate={this.props.forceUpdate}
            style={{ height: "35vh", overflow: "auto" }}
          >
            <div className="level-item">
              <button
                className="button is-link"
                onClick={() => this.props.transfert(this.state.sourceText)}
              >
                {t("commun_transferer")}
              </button>
            </div>
          </EditeurCommentaire>
        </Col>
      </Row>
    );
  }
}

function mapStateToProps() {
  return {};
}

export default connect<DupplicationCommentaireReduxState, DupplicationCommentaireReduxFn>(
  mapStateToProps,
  { addAllEntities }
)(DupplicationCommentaire);
