import React, { Component, SyntheticEvent, FC, useState, useEffect } from "react";
import {
  ComponentFilterCheckbox,
  ComponentFilterInput,
  ComponentFilterSelect
} from "./SortableElements";
import { Item } from "types/Component";
import { TypeSimpleComponent } from "enum";
import { format } from "date-fns";
import { Menu } from "composants/DropDown/Menu";
import { formatDate } from "utils/i18n";
import { Fa } from "composants/Icon";
import { useTranslation } from "react-i18next";
import { Field } from "composants/form/Form";
import Control from "composants/form/Control";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { convertValue } from "utils/entities.utils";

export interface FilterProps {
  filterOpen: boolean;
  name: string;
  value: any;
  onFilterChange(name: string, value: any, operator?: string): void;
  onKeyDown(e: SyntheticEvent<any>): void;
}

export const CheckboxFilter: FC<FilterProps> = ({
  filterOpen,
  name,
  value,
  onFilterChange,
  onKeyDown,
  ...props
}) => {
  function onChange(e: SyntheticEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;
    const value = convertValue(e);
    const operator = e.currentTarget.dataset.operator;
    onFilterChange(name, value, operator);
  }

  return (
    <ComponentFilterCheckbox
      {...props}
      name={name}
      filterOpen={filterOpen}
      filterValue={value}
      onFilterChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};

export const InputFilter: FC<FilterProps> = ({
  filterOpen,
  name,
  value,
  onFilterChange,
  onKeyDown
}) => {
  function onChange(e: SyntheticEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;
    const value = convertValue(e);
    const operator = e.currentTarget.dataset.operator;
    onFilterChange(name, value, operator);
  }

  return (
    <ComponentFilterInput
      name={name}
      filterOpen={filterOpen}
      filterValue={value}
      onFilterChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};

const SUPPORTED_OPERATOR = ["=", ">=", "<=", ">", "<"];

function getIconFromOperator(operator: string): IconName | undefined {
  switch (operator) {
    case "=":
      return "equals";
    case ">=":
      return "greater-than-equal";
    case "<=":
      return "less-than-equal";
    case ">":
      return "greater-than";
    case "<":
      return "less-than";

    default:
      return undefined;
  }
}

function extractDateFromSearchFilter(searchFilter: string) {
  let valueToFormat: string = searchFilter ?? "";
  for (let op of SUPPORTED_OPERATOR) {
    if (valueToFormat.startsWith(op)) {
      return valueToFormat.substr(op.length);
    }
  }
  return valueToFormat;
}

export const CalendarFilter: FC<FilterProps> = ({
  filterOpen,
  name,
  value,
  onFilterChange,
  onKeyDown
}) => {
  const [operator, setOperator] = useState(SUPPORTED_OPERATOR[0]);
  const [t] = useTranslation();

  let valueToFormat: string = extractDateFromSearchFilter(value);
  let icon = getIconFromOperator(operator);

  const finalValue = valueToFormat ? format(valueToFormat, "YYYY-MM-DD") : "";

  function onChange(e: SyntheticEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;
    const value = convertValue(e);
    const operator = e.currentTarget.dataset.operator;
    onFilterChange(name, value, operator);
  }

  function onChangeSelect(e: SyntheticEvent<HTMLSelectElement>) {
    const operator = e.currentTarget.value;
    setOperator(operator);

    if (value) {
      const date = extractDateFromSearchFilter(value);
      onFilterChange(name, date, operator);
    }
  }

  return filterOpen ? (
    <Menu autoclose>
      <Menu.Button className="button is-small">
        {finalValue ? (
          <>
            {icon && (
              <span className="mr-8 has-text-weight-bold has-text-link">
                <Fa icon={["fad", icon]} />
              </span>
            )}
            <span>{formatDate(finalValue)}</span>
          </>
        ) : (
          <span className="has-text-grey">{t("commun_filtrer")}</span>
        )}
        <span className="icon is-small">
          <Fa icon={["fal", "angle-down"]} />
        </span>
      </Menu.Button>
      <Menu.Overlay>
        <Menu.WhenVisible>
          {() => (
            <div className="p-7">
              <label className="label is-small">{t("commun_saisir_filtre")}</label>
              <Field addons>
                <Control>
                  <div className="select is-small">
                    <select value={operator} onChange={onChangeSelect}>
                      {SUPPORTED_OPERATOR.map(op => {
                        return (
                          <option key={op} value={op}>
                            {op}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Control>
                <Control>
                  <ComponentFilterInput
                    name={name}
                    type="date"
                    filterOpen={filterOpen}
                    filterValue={finalValue}
                    onFilterChange={onChange}
                    onKeyDown={onKeyDown}
                    data-operator={operator !== "=" ? operator : undefined}
                    data-initial-appearance="true"
                  />
                </Control>
              </Field>
            </div>
          )}
        </Menu.WhenVisible>
      </Menu.Overlay>
    </Menu>
  ) : null;
};

export const CalendarHourFilter: FC<FilterProps> = ({
  filterOpen,
  name,
  value,
  onFilterChange,
  onKeyDown
}) => {
  const finalValue = value ? format(value, "YYYY-MM-DDTHH:mm") : "";

  function onChange(e: SyntheticEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;
    const value = convertValue(e);
    const operator = e.currentTarget.dataset.operator;
    onFilterChange(name, value, operator);
  }

  return (
    <ComponentFilterInput
      name={name}
      type="datetime-local"
      filterOpen={filterOpen}
      filterValue={finalValue}
      onFilterChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};

export const SelectFilter: FC<FilterProps & { options: Item[] | undefined }> = ({
  filterOpen,
  name,
  value,
  onFilterChange,
  onKeyDown,
  options = []
}) => {
  function onChange(e: SyntheticEvent<HTMLInputElement>) {
    const name = e.currentTarget.name;
    const value = convertValue(e);
    const operator = e.currentTarget.dataset.operator;
    onFilterChange(name, value, operator);
  }

  return (
    <ComponentFilterSelect
      name={name}
      filterOpen={filterOpen}
      filterValue={value}
      onFilterChange={onChange}
      onKeyDown={onKeyDown}
      options={options}
    />
  );
};

export const AutocompleteFilter: FC<FilterProps> = props => {
  return <InputFilter {...props} />;
};

export const OelFilter: FC = () => {
  return <div />;
};
