import React, { FC, CSSProperties, SyntheticEvent } from "react";

const SUPPORTED_OPERATOR = [
  // simple operator
  "!=",
  "<",
  "<=",
  "=",
  ">",
  ">=",
  "ABS",
  "ADD_MONTHS",
  "AND",
  "BETWEEN",
  "CASE",
  "CEIL",
  "CHR",
  "COALESCE",
  "COS",
  "CURRENT_DATE",
  "CURRENT_TIMESTAMP",
  "DECODE",
  "ELSE",
  "END",
  "EXP",
  "GREATEST",
  "IN",
  "INITCAP",
  "INSTR",
  "IS",
  "LAST_DAY",
  "LEAST",
  "LENGTH",
  "LIKE",
  "LOG",
  "LOWER",
  "LPAD",
  "LTRIM",
  "MOD",
  "MONTHS_BETWEEN",
  "NEXT_DAY",
  "NOT",
  "NOT BETWEEN",
  "NOT IN",
  "NOT LIKE",
  "NULL",
  "NVL",
  "OR",
  "POWER",
  "REGEXP_INSTR",
  "REGEXP_LIKE",
  "REGEXP_REPLACE",
  "REGEXP_SUBSTR",
  "REPLACE",
  "ROUND",
  "RPAD",
  "RTRIM",
  "SIGN",
  "SIN",
  "SQRT",
  "SUBSTR",
  "SYSDATE",
  "SYSTIMESTAMP",
  "THEN",
  "TO_CHAR",
  "TO_DATE",
  "TO_TIMESTAMP",
  "TRANSLATE",
  "TRIM",
  "TRUNC",
  "UPPER",
  "WHEN"
];

export const FunctionList: FC<{ style?: CSSProperties; onClick(value: string): void }> = props => {
  function onClick(e: SyntheticEvent<HTMLAnchorElement>) {
    const value = e.currentTarget.dataset.function;
    value && props.onClick(value);
  }
  return (
    <ul style={props.style}>
      {SUPPORTED_OPERATOR.map(op => {
        return (
          <li key={op}>
            <a data-function={op} onClick={onClick}>
              {op}
            </a>
          </li>
        );
      })}
    </ul>
  );
};
