import React, { useEffect, FC, useCallback } from "react";
import { connect } from "react-redux";
import { ReducerState } from "reducers";
import { Trans } from "react-i18next";

import { Button } from "../button";
import {
  closeGalaxy,
  shouldNotUpdateGalaxy,
  forceCloseDirtyGalaxy,
  cancelClosingDirtyGalaxy
} from "actions/galaxy.action";
import { selectActiveGalaxies } from "selectors";
import { findMap } from "utils/entities.utils";
import { Portal } from "../Portal";

import "./Menu.css";
import { getPositionOverlay } from "utils/component.utils";
import { t } from "utils/i18n";
import { AppGalaxyInfo } from "types/Galaxy";
import { Fa } from "composants/Icon";
import { useDirtyGalaxy } from "layout/Dirty";
import { NavigateFn } from "withNavigate";
import { useNavigate, NavLink, useLocation, useMatch } from "react-router-dom";

const Confirm: FC<{
  sjmoCode: string;
  active: string;
  x: number;
  y: number;
  cancel: (sjmoCode: string) => void;
  forceCloseDirty: (toClose: string, active: string) => void;
}> = ({ sjmoCode, active, x, y, cancel, forceCloseDirty }) => {
  const onCloseEventListener = useCallback(
    (e: MouseEvent) => {
      const el = e.target && (e.target as HTMLElement).closest("#confirmClosingGalaxyBox");
      if (!el) {
        cancel(sjmoCode);
      }
    },
    [cancel, sjmoCode]
  );

  useEffect(() => {
    document.addEventListener("click", onCloseEventListener);
    return function cleanup() {
      document.removeEventListener("click", onCloseEventListener);
    };
  }, [onCloseEventListener]);

  return (
    <Portal>
      <div
        id="confirmClosingGalaxyBox"
        className="box"
        style={{
          position: "absolute",
          left: x - 100,
          top: y + 10,
          zIndex: 40,
          width: 300
        }}
      >
        <p className="is-size-7 pb-7">
          <Trans i18nKey="commun_fermer_galaxy_dirty" />
        </p>
        <nav className="level">
          <div className="level-item">
            <Button
              className="button is-small is-danger"
              onClick={() => {
                forceCloseDirty(sjmoCode, active);
              }}
            >
              {t("commun_fermer")}
            </Button>
          </div>
          <div className="level-item">
            <Button
              className="button is-small"
              onClick={() => {
                cancel(sjmoCode);
              }}
            >
              {t("commun_annuler")}
            </Button>
          </div>
        </nav>
      </div>
    </Portal>
  );
};

interface ActiveTabGalaxyReduxProps {
  activeGalaxies: { sjmoCode: string; link: string }[];
  galaxies: Record<string, AppGalaxyInfo>;
  isClosingDirtyGalaxy: { sjmoCode: string; link: string } | undefined;
}

interface ActiveTabGalaxyFn {
  close(navigate: NavigateFn, toClose: string, active: string, isDirty: boolean): void;
  shouldNotUpdate(code: string): void;
  cancelClosingDirty(code: string): void;
  forceCloseDirty(navigate: NavigateFn, toClose: string, active: string): void;
}

type ActiveTabGalaxyAllProps = ActiveTabGalaxyReduxProps & ActiveTabGalaxyFn;

let dimension: { x: number; y: number } | null = null;

const ActiveTabGalaxy: FC<ActiveTabGalaxyAllProps> = ({
  activeGalaxies,
  galaxies,
  close,
  isClosingDirtyGalaxy,
  shouldNotUpdate,
  cancelClosingDirty,
  forceCloseDirty
}) => {
  const location = useLocation();
  const { getDirty } = useDirtyGalaxy();
  const navigate = useNavigate();
  const match = useMatch("/page/:code/:id");
  const match2 = useMatch("/page/:code");
  return (
    <>
      <div className="galaxy-tab-container">
        <ul style={{ borderColor: "transparent" }}>
          <li className={location.pathname === "/" ? "galaxy-tab is-active" : "galaxy-tab"}>
            <div>
              <NavLink to="/" onClick={() => shouldNotUpdate("HOME")}>
                {t("commun_accueil")}
              </NavLink>
            </div>
          </li>
          {activeGalaxies.map(active => {
            const currentGalaxy = findMap(galaxies, galaxy => galaxy.code === active.sjmoCode);

            if (currentGalaxy === null || !currentGalaxy.link) {
              return null;
            } else {
              const { sjmoCode } = active;

              // Test avec includes car match.url ne renvois pas la partie mainEntityId de l'url courante
              const isActive: boolean =
                sjmoCode === match?.params.code || sjmoCode === match2?.params.code;

              return (
                <li key={sjmoCode} className={isActive ? "galaxy-tab is-active" : "galaxy-tab"}>
                  <div>
                    <NavLink to={active.link} onClick={() => shouldNotUpdate(active.sjmoCode)}>
                      {currentGalaxy ? currentGalaxy.label : undefined}
                    </NavLink>
                    <a
                      id={active.sjmoCode + "_close_button"}
                      className="has-text-danger"
                      onClick={e => {
                        dimension = getPositionOverlay(
                          e.currentTarget.getBoundingClientRect() as DOMRect
                        );
                        close(
                          navigate,
                          sjmoCode,
                          match?.params.code ?? sjmoCode,
                          getDirty(sjmoCode)
                        );
                      }}
                    >
                      <span className="icon is-small">
                        <Fa icon="times" transform="down-2" />
                      </span>
                    </a>
                  </div>
                </li>
              );
            }
          })}
        </ul>
      </div>
      {isClosingDirtyGalaxy && (
        <Confirm
          sjmoCode={isClosingDirtyGalaxy.sjmoCode}
          x={dimension ? dimension.x : 0}
          y={dimension ? dimension.y : 0}
          cancel={cancelClosingDirty}
          forceCloseDirty={(toClose, active) => forceCloseDirty(navigate, toClose, active)}
          active={match ? (match.params.code as string) : isClosingDirtyGalaxy.sjmoCode}
        />
      )}
    </>
  );
};

function mapStateToProps(state: ReducerState) {
  const activeGalaxies = selectActiveGalaxies(state);
  const isClosingDirtyGalaxy = activeGalaxies.find(current => current.closingDirty === true);
  return {
    activeGalaxies,
    galaxies: state.app.galaxies,
    isClosingDirtyGalaxy
  };
}

export default connect<ActiveTabGalaxyReduxProps, ActiveTabGalaxyFn>(mapStateToProps, {
  close: closeGalaxy,
  shouldNotUpdate: shouldNotUpdateGalaxy,
  cancelClosingDirty: cancelClosingDirtyGalaxy,
  forceCloseDirty: forceCloseDirtyGalaxy
})(ActiveTabGalaxy);
