import { AxiosPromise } from "axios";

import { api, apiAdn, getUIContext } from "./common";
import { ProcessusDefinition, ProcessusState } from "types/Processus";

// Fonction qui récupère le menu contextuel à partir d'une colonne
export function getMenuFromColumn(
  sjmoCode: string,
  origineTableName: string,
  columnName: string
): AxiosPromise<ProcessusDefinition[]> {
  const params = getUIContext({ sjmoCode });
  params.append("table", origineTableName);
  params.append("column", columnName);
  return api.get(`/process/menu/column?${params}`);
}

export function showContextInfo(columnName: string, tableName: string): AxiosPromise<string> {
  const params = getUIContext();
  params.append("columnName", columnName);
  params.append("tableName", tableName);
  return apiAdn.get(`/contextMenu/info?${params}`);
}

/**
 * Fonction qui controle si un champ d'une table est une FK.
 * Retourne le nom de table jointe si FK et nul sinon
 * @param tableName
 * @param columnName
 */
export function getFKTableName(
  sjmoCode: string,
  tableName: string,
  columnName: string
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode });
  params.append("tableName", tableName);
  params.append("columnName", columnName);
  return api.get(`/process/getFKTableName?${params}`);
}
