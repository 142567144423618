import { Column, Columns, Stack, Tooltip, ButtonLink } from "@axin-org/comet";
import { tw } from "twind";
import { Dot } from "composants/Dot";
import { Menu } from "composants/DropDown/Menu";
import { Fa } from "composants/Icon";
import {
  JobState,
  JobStatesEnum,
  ProcessusActorContext,
  useRegisterProcessus,
  useInfoProcessus
} from "features/processus/processusManager";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { State } from "xstate";
import { t } from "utils/i18n";
import { Pojo } from "types/Galaxy";

interface RunningProcess {
  id: string;
  label: string;
  status: JobState;
  jobID: string;
  executeAt?: string;
  compositeID: string;
  selected?: Pojo[];
  result?: any;
}

function colorByStatus(status: JobState) {
  switch (status) {
    case JobStatesEnum.CHECK:
    case JobStatesEnum.EXECUTE:
    case JobStatesEnum.STARTED:
    case JobStatesEnum.FETCH_RESULT:
      return "#63b3ed"; // bleu
    case JobStatesEnum.WAIT:
      return "#ecc94b"; // jaune
    case JobStatesEnum.ERROR:
      return "#fc8181"; // rouge
    case JobStatesEnum.DONE:
      return "#68d391"; // vert
    default:
      return "black";
  }
}

function isCancelable(status: JobState) {
  switch (status) {
    case JobStatesEnum.CHECK:
    case JobStatesEnum.EXECUTE:
    case JobStatesEnum.STARTED:
    case JobStatesEnum.FETCH_RESULT:
    case JobStatesEnum.WAIT:
      return true;
    case JobStatesEnum.ERROR:
    case JobStatesEnum.DONE:
    default:
      return false;
  }
}

const ProcessDelayedMenuItem: FC<{ processId: string; cancel: () => void }> = ({
  processId,
  cancel
}) => {
  const [state] = useInfoProcessus(processId);
  return (
    <Menu.Item>
      <Columns>
        <Column width="narrow" className={tw("p-2")}>
          <Dot color={colorByStatus(state.toStrings()[0])} />
        </Column>
        <Column>
          <Stack>
            <Columns>
              <Column className={tw(`font-normal font-semibold`)}>
                {state.context.processusConfig.label}
              </Column>
              <Column width="narrow">
                {state.context.processusConfig.executeAt
                  ? state.context.processusConfig.executeAt
                  : ""}
              </Column>
              <Column width="narrow" className={tw(`pl-1 pt-1`)}>
                {!isCancelable(state.toStrings()[0]) && <Fa icon="ban" />}
                {isCancelable(state.toStrings()[0]) && (
                  <ButtonLink
                    onClick={cancel}
                    aria-disabled={state.toStrings()[0] !== JobStatesEnum.WAIT}
                    className="p-0"
                  >
                    <Fa icon="ban" color={"#f56565"} />
                  </ButtonLink>
                )}
              </Column>
            </Columns>
            <Tooltip
              label={
                <ul>
                  {(state.context.processusConfig.selected as any[]).map(it => (
                    <li key={it.label ?? it.id}>{it.label ?? it.id ?? ""}</li>
                  ))}
                </ul>
              }
            >
              <p className={tw(`text-xs font-extralight`)}>
                {(state.context.processusConfig.selected as any[])
                  .slice(0, 2)
                  .map(it => it.label ?? it.id ?? "")
                  .join(", ") + (state.context.processusConfig.selected.length > 2 ? "..." : "")}
              </p>
            </Tooltip>
          </Stack>
        </Column>
      </Columns>
    </Menu.Item>
  );
};

const ProcessDelayedMenu: FC<{}> = props => {
  const [t] = useTranslation();
  const [managerState, { cancel: cancelProcessus }] = useRegisterProcessus();

  // On ne mémorise pas la liste car le manager state ne relève pas de changement d'état lorsque le jobID revient du back ; par conséquent on a un jobID qui reste à null
  //const processList = useMemo(() => {
  const processList = [];
  for (const key of Object.keys(managerState.context.processus)) {
    if (managerState.context.processus[key]) {
      const oneProcess = managerState.context.processus[key].getSnapshot() as State<
        ProcessusActorContext
      >;

      // oneProcess.toStrings() retourne un tableau de string qui va correspondre au status
      const current: RunningProcess = {
        id: key,
        status: oneProcess.toStrings()[0] as JobState,
        jobID: oneProcess.context.jobID as string,
        executeAt: oneProcess.context.processusConfig?.executeAt,
        label: oneProcess.context.processusConfig?.label as string,
        compositeID: oneProcess.context.processusConfig?.compositeID as string,
        selected: oneProcess.context.processusConfig?.selected,
        result: oneProcess.context.result
      };
      processList.push(current);
    }
  }
  //return list;
  // }, [managerState.context.processus]);

  const counter = useRef<number>();
  counter.current = processList.filter(p => p.result === null || p.result === undefined).length;

  return (
    <Menu className="is-right" autoclose>
      <Menu.Button>
        <span className="fa-layers fa-fw fa-2x" title={t("commun_traitement_en_cours")}>
          <Fa icon={["fal", "cogs"]} className="has-text-white" />
          {counter.current > 0 && (
            <span className="fa-layers-counter" style={{ background: "Tomato" }}>
              {counter.current > 99 ? "99+" : counter.current}
            </span>
          )}
        </span>
      </Menu.Button>
      <Menu.Content style={{ minWidth: "15rem" }}>
        {processList && processList.length > 0 ? (
          processList.map(p => (
            <ProcessDelayedMenuItem
              key={p.jobID}
              processId={p.id}
              cancel={() => cancelProcessus(p.compositeID, p.selected)}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </Menu.Content>
    </Menu>
  );
};

function EmptyState() {
  return (
    <div className={tw(`flex items-center flex-col`)}>
      <div
        className="icon has-background-link"
        style={{ borderRadius: 999999, padding: 5, width: 32, height: 32 }}
      >
        <Fa icon={["fas", "calendar-times"]} className={tw(`text-white`)} />
      </div>

      <p className={tw(`font-semibold text-xs`)}>{t("commun_aucun_processus_en_cours")}</p>
    </div>
  );
}

export default ProcessDelayedMenu;
