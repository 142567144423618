import React, { CSSProperties, useRef } from "react";
import { Trans } from "react-i18next";
import { ProcessusDefinition } from "types/Processus";

import ConfirmationModal from "./ConfirmationModal";

import { Fa } from "composants/Icon";
import { track } from "tracking";
import { useInfoProcessus } from "features/processus/processusManager";
import { ButtonLink, Columns, Column } from "@axin-org/comet";
import DelayProcessModal from "./DelayProcessModal";
import { useProcessus } from "./useProcessus";

function ProcessusLoading(props: { id: string }) {
  const [processusState] = useInfoProcessus(props.id);

  return !processusState.matches("done") &&
    !processusState.matches("wait") &&
    !processusState.matches("error") ? (
    <span className="icon">
      <Fa icon="spinner" spin />
    </span>
  ) : null;
}

export interface ProcessusLinkProps {
  definition: ProcessusDefinition;
  canDelayProcess?: Boolean;
  className?: string;
  style?: CSSProperties;
}

export function ProcessusLink({
  definition,
  children,
  canDelayProcess,
  className,
  ...restProps
}: React.ComponentProps<"a"> & { disabled?: boolean } & ProcessusLinkProps) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const processus = useProcessus(definition);

  return (
    <>
      {processus.modal.confirm.show && processus.modal.position && (
        <ConfirmationModal
          id={definition.id}
          launchProcess={processus.launch}
          close={() => {
            processus.modal.confirm.set(false);
            track("processus::modal::cancel");
          }}
          top={processus.modal.position.x}
          left={processus.modal.position.y}
          buttonRef={containerRef}
        />
      )}

      <div
        ref={containerRef}
        // {...(buttonProps as any)}
        className="processmenu-hover-show-action"
        style={restProps.style}
      >
        <Columns gap={0}>
          <Column width="full">
            <a onClick={processus.button.onClick} className={className} {...restProps}>
              {children ?? definition.label}
              {processus.hasProcessusStateSpawned && (
                <ProcessusLoading id={processus.stableProcessusId} />
              )}
              {definition.type === "edition" && definition.apercu && definition.rapide && (
                <span className="processmenu-action">
                  <div className="field has-addons">
                    <div className="control">
                      <button
                        className="button is-small is-primary"
                        data-edition-type="apercu"
                        disabled={!definition.apercu}
                      >
                        <Trans i18nKey="commun_apercu" />
                      </button>
                    </div>
                    <div className="control">
                      <button
                        className="button is-small is-info"
                        data-edition-type="rapide"
                        disabled={!definition.rapide}
                      >
                        <Trans i18nKey="commun_rapide" />
                      </button>
                    </div>
                  </div>
                </span>
              )}
            </a>
          </Column>
          <Column className="flex justify-end">
            {definition.type !== "navigation" && definition.isDifferable && canDelayProcess && (
              <ButtonLink
                onClick={() => processus.modal.calendar.set(true)}
                className="p-0 right-0"
              >
                <Fa icon="clock" />
              </ButtonLink>
            )}
          </Column>
        </Columns>
      </div>

      {processus.modal.calendar.show && (
        <DelayProcessModal
          sjmoCode={processus.context.sjmoCode}
          compositeID={definition.id}
          type={definition.type}
          editionType={processus.editionDefault}
          selected={processus.context.selected ?? []}
          advanced={
            (definition.type === "traitement" && definition.isAdvanced) ||
            (definition.type === "edition" && definition.isAdvanced)
          }
          label={definition.label}
          needEntity={definition.needEntity}
          onClose={() => processus.modal.calendar.set(false)}
          callback={processus.context.onAfterSaveProcess}
        />
      )}
    </>
  );
}

export default ProcessusLink;
