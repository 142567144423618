import { Dispatch } from "redux";
import Action from "reducers/Action";

import {
  INIT_DATATABLE,
  CHANGE_DATATABLE_FOCUS,
  FETCH_DATATABLE_DATA,
  UPDATE_SEARCH_DATATABLE,
  UPDATE_SELECTED_ROWS_DATATABLE,
  FETCH_DATATABLE_COLUMN_SUCCESS,
  FETCH_DATATABLE_FOCUS_SUCCESS,
  FETCH_DATATABLE_DATA_SUCCESS,
  SAVE_DATATABLE_ENTITY,
  ADD_ROW_DATATABLE,
  ADD_ROW_DATATABLE_SUCCESS,
  CHANGE_ROW_DATATABLE_NEW_ENTITY,
  RESET_NEW_ENTTIES_DATATABLE,
  WVI_DATATABLE,
  ADD_WVI_DATATABLE,
  UPDATE_ROW_DATATABLE,
  REMOVE_ENTITIES_DATATABLE,
  REMOVE_NEW_ENTITIES_DATATABLE,
  SAVE_FOCUS_PERSO_DATATABLE,
  RAZ_FOCUS_PERSO_DATATABLE,
  ADD_MULTIPLE_ROW_DATATABLE_SUCCESS,
  CLEAR_DATATABLE_DATA,
  SHOULD_UPDATE_DATATABLE,
  EXPORT_DT_XLS,
  EXPORT_DT_PDF,
  UPDATE_FILTER_DATATABLE,
  UPDATE_DATATABLE_SORT,
  CLEAR_DATATABLE_REDUX_STATE,
  LOCAL_UPDATE_DATATABLE_FOCUS,
  SET_DATATABLE_PRE_RECORD_CACHE,
  DATATABLE_UPDATE_FILTER_BAR,
  INIT_BASE_DATATABLE,
  DATATABLE_CHANGE_BREAK_COLUMNS,
  CHANGE_DATATABLE_FOCUS_SUCCESS,
  DATATABLE_SATTELLITE_EXIST_SUCCESS,
  DATATABLE_SATTELLITE_EXIST
} from "constant/datatable.constant";

import { ComponentState } from "types/Component";
import { FocusState } from "types/Focus";
import { PagedResource, FilterBar } from "types/Search";
import { Pojo } from "types/Galaxy";
import { InteractionReducerParameter } from "reducers/modules/InteractionReducer";
import { Message } from "types/Message";

export function initDatatable(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string,
  filter: string
) {
  return {
    type: INIT_DATATABLE,
    payload: {
      sjmoCode,
      tableName,
      ctrlKey,
      filter
    }
  };
}

export function initDatatableAdmin(sjmoCode: string, tableName: string, ctrlKey: string) {
  return { type: INIT_BASE_DATATABLE, payload: { sjmoCode, ctrlKey, tableName } };
}

export function changeFocus(sjmoCode: string, ctrlKey: string, selectedFocus: string): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: CHANGE_DATATABLE_FOCUS,
      payload: {
        sjmoCode,
        ctrlKey,
        selectedFocus
      }
    });

    return Promise.resolve();
  };
}

export function changeFocusSuccess(sjmoCode: string, ctrlKey: string, selectedFocus: string) {
  return {
    type: CHANGE_DATATABLE_FOCUS_SUCCESS,
    payload: {
      sjmoCode,
      ctrlKey,
      selectedFocus
    }
  };
}

export function updateSearch(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string,
  filter: string
): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: UPDATE_SEARCH_DATATABLE,
        payload: {
          sjmoCode,
          tableName,
          ctrlKey,
          filter,
          callback: {
            resolve,
            reject
          }
        }
      });
    });
  };
}

export function updateFilter(sjmoCode: string, ctrlKey: string, filter: string) {
  return {
    type: UPDATE_FILTER_DATATABLE,
    payload: {
      sjmoCode,
      ctrlKey,
      filter
    }
  };
}

export interface CallApiProps {
  sjmoCode: string;
  tableName: string;
  ctrlKey: string;
  startIndex: number;
  includeOel: string[];
  size?: number;
  reset?: boolean;
  loading?: boolean;
  includeStyle?: boolean;
  callback?: {
    resolve: () => void;
    reject: () => void;
  };
}

export function callApi({
  sjmoCode,
  tableName,
  ctrlKey,
  startIndex,
  includeOel,
  size = 20,
  reset = false,
  loading = true,
  includeStyle = false,

  callback
}: CallApiProps): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_DATATABLE_DATA,
        payload: {
          sjmoCode,
          tableName,
          ctrlKey,
          startIndex,
          size,
          reset,
          loading,
          includeOel,
          includeStyle,
          callback: {
            resolve: () => {
              resolve();
              callback && callback.resolve();
            },
            reject: () => {
              reject();
              callback && callback.reject();
            }
          }
        }
      });
    });
  };
}

export function updateSelectedRows(sjmoCode: string, ctrlKey: string, rows: number[]): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: UPDATE_SELECTED_ROWS_DATATABLE,
      payload: {
        sjmoCode,
        ctrlKey,
        rows
      }
    });

    return Promise.resolve();
  };
}

export function updateDatatableColumns(
  sjmoCode: string,
  ctrlKey: string,
  columns: ComponentState[]
) {
  return {
    type: FETCH_DATATABLE_COLUMN_SUCCESS,
    payload: {
      sjmoCode,
      ctrlKey,
      columns
    }
  };
}

export function updateDatatableFocus(sjmoCode: string, ctrlKey: string, focus: FocusState[]) {
  return {
    type: FETCH_DATATABLE_FOCUS_SUCCESS,
    payload: {
      sjmoCode,
      ctrlKey,
      focus
    }
  };
}

export function fetchDatatableDataSuccess(
  sjmoCode: string,
  ctrlKey: string,
  startIndex: number,
  pojos: PagedResource<Pojo>,
  reset: boolean = false
) {
  return {
    type: FETCH_DATATABLE_DATA_SUCCESS,
    payload: {
      sjmoCode,
      ctrlKey,
      startIndex,
      pojos,
      reset
    }
  };
}

export function clearDatatableData(sjmoCode: string, ctrlKey: string) {
  return {
    type: CLEAR_DATATABLE_DATA,
    payload: {
      sjmoCode,
      ctrlKey
    }
  };
}

export function saveDatatableEntities(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string,
  entities: Pojo[],
  callback?: () => void
) {
  return {
    type: SAVE_DATATABLE_ENTITY,
    payload: {
      sjmoCode,
      tableName,
      ctrlKey,
      entities,
      callback
    }
  };
}

export function addRowDatatable(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string,
  position: "START" | "END",
  interactions?: InteractionReducerParameter,
  callback?: () => void
) {
  return {
    type: ADD_ROW_DATATABLE,
    payload: {
      sjmoCode,
      tableName,
      ctrlKey,
      position,
      interactions,
      callback
    }
  };
}

export function addRowDatatableSuccess(
  sjmoCode: string,
  ctrlKey: string,
  position: "START" | "END"
) {
  return {
    type: ADD_ROW_DATATABLE_SUCCESS,
    payload: {
      sjmoCode,
      ctrlKey,
      position
    }
  };
}

export function setDatatablePreRecordCache(sjmoCode: string, ctrlKey: string, pojo: Pojo) {
  return {
    type: SET_DATATABLE_PRE_RECORD_CACHE,
    payload: {
      sjmoCode,
      ctrlKey,
      pojo,
      lastFetch: Date.now()
    }
  };
}

export function addMultipleRowDatatableSuccess(sjmoCode: string, ctrlKey: string, pojos: Pojo[]) {
  return {
    type: ADD_MULTIPLE_ROW_DATATABLE_SUCCESS,
    payload: {
      sjmoCode,
      ctrlKey,
      pojos
    }
  };
}

export function updateRowDatatable(sjmoCode: string, ctrlKey: string, pojo: Pojo) {
  return {
    type: UPDATE_ROW_DATATABLE,
    payload: {
      sjmoCode,
      ctrlKey,
      pojo
    }
  };
}

export function onValueChangeNewEntity(
  sjmoCode: string,
  ctrlKey: string,
  entityId: string,
  field: string,
  value: any
): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: CHANGE_ROW_DATATABLE_NEW_ENTITY,
      payload: {
        sjmoCode,
        ctrlKey,
        entityId,
        field,
        value
      }
    });

    return Promise.resolve();
  };
}

export function resetNewEntitiesDatatable(sjmoCode: string, ctrlKey: string) {
  return {
    type: RESET_NEW_ENTTIES_DATATABLE,
    payload: {
      sjmoCode,
      ctrlKey
    }
  };
}

export function datatableWhenValidateItem(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string,
  field: string,
  pojo: Pojo
): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    return new Promise(resolve => {
      dispatch({
        type: WVI_DATATABLE,
        payload: {
          sjmoCode,
          tableName,
          ctrlKey,
          field,
          pojo,
          callback: resolve
        }
      });
    });
  };
}

export function addWviDatatable(
  sjmoCode: string,
  ctrlKey: string,
  id: number | string,
  field: string,
  message: Message
) {
  return {
    type: ADD_WVI_DATATABLE,
    payload: {
      sjmoCode,
      ctrlKey,
      id,
      field,
      message
    }
  };
}

export function datatableDeleteEntities(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string,
  toBeRemove: string[],
  callback?: () => void
): any {
  return (dispatch: Dispatch<any>): Promise<any> => {
    dispatch({
      type: REMOVE_ENTITIES_DATATABLE,
      payload: {
        sjmoCode,
        tableName,
        ctrlKey,
        toBeRemove,
        callback
      }
    });

    return Promise.resolve();
  };
}

export function removeNewEntitiesDatatable(
  sjmoCode: string,
  ctrlKey: string,
  toBeRemove: string[]
) {
  return {
    type: REMOVE_NEW_ENTITIES_DATATABLE,
    payload: {
      sjmoCode,
      ctrlKey,
      toBeRemove
    }
  };
}

export type ActionSaveFocusPersoDatatable = Action<{
  datatableId: string;
  tableName: string;
  ctrlKey: string;
  sjmoCode: string;
  columns: ComponentState[];
}>;

/**
 * Permet de lancer une sauvegarde de focus perso
 *
 * @param datatableId id de datatable
 * @param tableName nom de la table
 * @param ctrlKey clé de controle
 * @param sjmoCode code du module
 * @param columns colonnes
 */
export function saveFocusPersoDatatable(
  datatableId: string,
  tableName: string,
  ctrlKey: string,
  sjmoCode: string,
  columns: ComponentState[]
): ActionSaveFocusPersoDatatable {
  return {
    type: SAVE_FOCUS_PERSO_DATATABLE,
    payload: {
      datatableId,
      tableName,
      ctrlKey,
      sjmoCode,
      columns
    }
  };
}

export type ActionRazFocusPersoDatatable = Action<{
  datatableId: string;
  ctrlKey: string;
  tableName: string;
  sjmoCode: string;
}>;
/**
 * Permet de lancer un demande de suppression de focus perso
 * avec refresh des focus après demande de suppression
 *
 * @param datatableId id de la definition de table
 * @param sjmoCode code du module
 */
export function razFocusPersoDatatable(
  datatableId: string,
  ctrlKey: string,
  tableName: string,
  sjmoCode: string
): ActionRazFocusPersoDatatable {
  return {
    type: RAZ_FOCUS_PERSO_DATATABLE,
    payload: {
      datatableId,
      ctrlKey,
      tableName,
      sjmoCode
    }
  };
}

export function shouldUpdateDatatable(sjmoCode: string, ctrlKey: string) {
  return {
    type: SHOULD_UPDATE_DATATABLE,
    payload: { sjmoCode, ctrlKey }
  };
}

export function onExportExcel(sjmoCode: string, ctrlKey: string) {
  return { type: EXPORT_DT_XLS, payload: { sjmoCode, ctrlKey } };
}

export function onExportPdf(sjmoCode: string, ctrlKey: string) {
  return { type: EXPORT_DT_PDF, payload: { sjmoCode, ctrlKey } };
}

export function updateDatatableSort(
  sjmoCode: string,
  ctrlKey: string,
  focusId: string,
  sort: string
) {
  return { type: UPDATE_DATATABLE_SORT, payload: { sjmoCode, ctrlKey, focusId, sort } };
}

export function clearDatatableReduxState(sjmoCode: string): any {
  return (dispatch: Dispatch<any>): Promise<any> => {
    dispatch({ type: CLEAR_DATATABLE_REDUX_STATE, payload: { sjmoCode } });

    return Promise.resolve();
  };
}

export function updateDatatableFocusInReduxStateOnly(params: {
  sjmoCode: string;
  ctrlKey: string;
  focusId: number | string;
  focusProps: any;
}): any {
  return { type: LOCAL_UPDATE_DATATABLE_FOCUS, payload: params };
}

export function onChangeFilterBar(sjmoCode: string, ctrlKey: string, name: string, value: string) {
  return {
    type: DATATABLE_UPDATE_FILTER_BAR,
    payload: {
      sjmoCode,
      ctrlKey,
      name,
      value
    }
  };
}

export function datatableChangeBreakColumns(
  sjmoCode: string,
  ctrlKey: string,
  breakColumns: string[]
): any {
  return (dispatch: Dispatch) => {
    dispatch({
      type: DATATABLE_CHANGE_BREAK_COLUMNS,
      payload: { sjmoCode, ctrlKey, breakColumns }
    });

    return Promise.resolve();
  };
}

export function datatableSattelliteExist(
  sjmoCode: string,
  ctrlKey: string,
  tableName: string,
  ids: string[]
) {
  return { type: DATATABLE_SATTELLITE_EXIST, payload: { sjmoCode, ctrlKey, tableName, ids } };
}

export function datatableSattelliteExistSuccess(
  sjmoCode: string,
  ctrlKey: string,
  existList: Record<string, boolean>
) {
  return { type: DATATABLE_SATTELLITE_EXIST_SUCCESS, payload: { sjmoCode, ctrlKey, existList } };
}
