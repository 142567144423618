import React, { FC, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import { ReducerState } from "reducers";
import { URL_DOWNLOAD_VIA_UTILS } from "customGlobal";

/**************** ACTIONS *******************/
import { setEditionIndex, hideModalEdition } from "actions/processus";

/***************** CSS **********************/

/*************** COMPOSANTS ***************/

import ModalViewver from "composants/Modal/ModalViewer";
import { Pagination } from "composants/datatable/Pagination";

/************** API **********************/
import { getUIContext } from "api/common";
import { t } from "utils/i18n";
import auth from "auth";

function selectEditionNotification({
  processus: { processInProgress, displayModalEdition, editions, currentEditionIndex },
  email: { mailStruct }
}: ReducerState) {
  return {
    inProgress: processInProgress,
    displayModalEdition,
    editions,
    currentEditionIndex,
    mailStruct
  };
}

const EditionNotification: FC<{ refresh: () => void }> = ({ refresh }) => {
  const reduxValue = useSelector(selectEditionNotification);
  const dispatch = useDispatch();
  const previousInProgressRef = useRef(reduxValue.inProgress);

  useEffect(() => {
    if (
      previousInProgressRef.current === true &&
      reduxValue.inProgress !== previousInProgressRef.current
    ) {
      refresh();
    }
    previousInProgressRef.current = reduxValue.inProgress;
  }, [reduxValue.inProgress, refresh]);

  const mimeType =
    reduxValue.editions?.editionImprDtos?.[reduxValue.currentEditionIndex]?.mimeType ?? "";

  const filePath =
    reduxValue.editions?.editionImprDtos?.[reduxValue.currentEditionIndex]?.filePath ?? "";

  function onClose() {
    dispatch(hideModalEdition());
  }

  function paginationChange(i: number) {
    dispatch(setEditionIndex(i));
  }

  return reduxValue.displayModalEdition && mimeType && filePath ? (
    <ModalViewver onClose={onClose}>
      <Pagination
        totalRecords={reduxValue.editions ? reduxValue.editions.editionImprDtos.length - 1 : 0}
        first={reduxValue.currentEditionIndex}
        pageSize={1}
        isCentered
        isRounded
        paginationChange={paginationChange}
      />
      <Document mimeType={mimeType} filePath={filePath} />
    </ModalViewver>
  ) : null;
};

export default EditionNotification;

const Document: FC<{ mimeType: string; filePath: string }> = props => {
  const params = getUIContext();
  params.append("path", props.filePath);

  return (
    <object
      data={`${URL_DOWNLOAD_VIA_UTILS()}/document?${params}&access_token=${auth.token}`}
      type={props.mimeType}
      style={{ height: "90vh", width: "100%" }}
    >
      {t("commun_impossible_d_afficher_le_document")}
    </object>
  );
};
