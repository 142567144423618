import React, { MouseEvent, PureComponent } from "react";
import get from "lodash-es/get";
import memoize from "memoizee";

import { DashboardFocusState } from "reducers/modules/dashboard/DashboardReducer";
import { Dropdown, DropdownButton, DropdownMenu } from "composants/DropDown/Dropdown";

import { deleteMainEntity } from "actions/galaxy.action";
import {
  fetchDashboardPanelAndChangeFocus,
  addPanelToFocus,
  saveFocusPerso,
  razFocusPerso
} from "actions/dashboard";
import { launchEdition } from "actions/processus";
import { showContextMenu } from "actions/contextMenu";
import { displayEmail, loadAndDisplayEmail } from "actions/email.action";

// octal components

import PanelList from "composants/dashboard/PanelList";
import ExpertMenu from "composants/expert/ExpertMenu";
import Autocomplete from "composants/autocomplete/AutoComplete";
import FocusMenu from "composants/focus/FocusMenu";

import { Button, ButtonNoStyle } from "composants/button";
import { Row, Col } from "composants/Layout";
import ProcessusMenu from "composants/processus/ProcessusMenu";
import { PanelState } from "types/Dashboard";

/***************** TYPES ***********************/
import { GalaxyInformation, Pojo } from "types/Galaxy";

import { t } from "utils/i18n";

/********************** API ***************************/
import "composants/css/component.css";
import { Field } from "composants/form";
import Control from "composants/form/Control";
import { Spring } from "react-spring/renderprops";
import { getTemplatesFromModule } from "api/email";

import Datatable from "../datatable/Datatable";
import { initRsqlCriteria, RSQLFilterExpressionString } from "utils/query.utils";
import { Operators, RSQLFilterExpression, RSQLCriteria } from "rsql-criteria-typescript";
import { connect } from "react-redux";
import { ReducerState } from "reducers";
import { selectDashboardDefinition, selectDashboardSelectedFocus } from "selectors";
import { getDimensionFromEvent } from "utils/component.utils";
import { SuperUserMenu } from "composants/userSettings/SuperUser";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import { Fa } from "composants/Icon";
import { Trans } from "react-i18next";
import { Menu } from "types/Menu";
import { GalaxieListenerCallbackContext } from "./GalaxieContextListener";
import { track } from "tracking";
import { useProcessus } from "composants/processus/useProcessus";
import { NavigateFunction } from "react-router-dom";

const getSpringValue = memoize(
  (opacity: number, height: number) => {
    return {
      opacity,
      height
    };
  },
  { max: 2 }
);

interface GalaxieHeaderProps {
  sjmoCode: string;
  mainEntityId: string | null;
  id: string | null;
  ids: string[];
  query: string | null;
  galaxyInfo?: GalaxyInformation;
  suiviOpen: boolean;
  navigate: NavigateFunction;
  changeMainEntityFromSuivi(id: number | string): void;
  onOpenSuivi(): void;
  onCreatorOpen(): void;
  refresh(): void;
  onOpenExpert(): void;
  changeMainEntity(pojo: Pojo | null): void;
  onAfterSaveProcess(): void;
  clearURLFocus(): void;
}

interface GalaxieHeaderReduxProps {
  expertMenu: Menu[];
  galaxyFocuses: DashboardFocusState[];
  selectedFocus: string;
  entity: Pojo | null;
}

interface GalaxieHeaderReduxFn {
  saveFocusPerso(sjmoCode: string, focusId: string, panels: PanelState[]): void;
  razFocusPerso(sjmoCode: string, focusId: string): void;
  fetchDashboardPanelAndChangeFocus(sjmoCode: string, focusId: string): void;
  launchEdition(
    sjmoCode: string,
    sjipId: string,
    mode: string,
    tableName: string,
    entitiesId: string[],
    navigationUrl?: string,
    callback?: () => void
  ): void;
  addPanelToFocus(sjmoCode: string, sjpaId: string): void;
  showContextMenu(
    xpos: number,
    ypos: number,
    sjmoCode: string,
    origineTableName: string,
    columnName: string,
    contextId: string,
    value: string,
    genericEntity: Pojo | null,
    urlCplt?: string
  ): void;
  displayEmail(tableName: string, id: string, navigate: NavigateFunction): void;
  loadAndDisplayEmail(props: {
    sjmoCode: string;
    tableName: string;
    entityId: string;
    defaultEditionProcessus: string;
    syjMailIdForCustomMails: string;
    navigate: NavigateFunction;
  }): void;
  deleteMainEntity(
    sjmoCode: string,
    tableName: string,
    id: string,
    callback?: (success: boolean) => void
  ): void;
}

type GalaxieHeaderAllProps = GalaxieHeaderProps &
  GalaxieHeaderReduxProps &
  GalaxieHeaderReduxFn & { navigate: NavigateFunction };

type GalaxieHeaderState = {
  isMounted: boolean;
  mailTemplateModules: Pojo[];
  suiviOpenAnimationEnd: boolean;
  isDeletePending: boolean;
};

class GalaxieHeaderInternal extends PureComponent<GalaxieHeaderAllProps, GalaxieHeaderState> {
  static customPanelStyle = {
    background: "#f7f7f7",
    borderRadius: 4,
    border: 0,
    marginBottom: "1em"
    // overflow: "hidden"
  };

  state: GalaxieHeaderState = {
    isMounted: false,
    mailTemplateModules: [],
    suiviOpenAnimationEnd: false,
    isDeletePending: false
  };

  private listeners: Function[] = [];

  static getDerivedStateFromProps(
    props: GalaxieHeaderAllProps,
    state: GalaxieHeaderState
  ): Partial<GalaxieHeaderState> | null {
    const urlsParams = new URLSearchParams(window.location.search);
    const hasSuiviOpen = urlsParams.has("suiviOpen");

    if (!hasSuiviOpen && state.suiviOpenAnimationEnd) {
      return {
        suiviOpenAnimationEnd: false
      };
    }

    return null;
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    if (this.props.galaxyInfo?.sjmoCode) {
      getTemplatesFromModule(this.props.galaxyInfo?.sjmoCode)
        .then((res: { data: any }) => {
          this.setState({ mailTemplateModules: res.data });
        })
        .catch(() => {
          console.error("error during fetch destinataires");
        });
    }
  }

  registerCallback = (callback: () => void) => {
    this.listeners.push(callback);

    return () => {
      this.listeners = this.listeners.filter(it => it !== callback);
    };
  };

  refreshListeners = () => {
    for (let listener of this.listeners) {
      listener();
    }
  };

  contextMenuMGS = (event: MouseEvent<any>) => {
    if (event.ctrlKey) {
      return;
    }
    event.preventDefault();
    const dimension = getDimensionFromEvent(event);

    if (this.props.galaxyInfo && this.props.id) {
      this.props.showContextMenu(
        dimension.x,
        dimension.y,
        this.props.sjmoCode,
        this.props.galaxyInfo.tableName,
        get(this.props, "galaxyInfo.gensearch.column", undefined),
        this.props.id,
        this.props.id,
        null, // genericEntity uniquement pour les listes génériques
        ""
      );
    }
  };

  onPanelAddedToFocus = (sjpaId: string) => {
    this.props.addPanelToFocus(this.props.sjmoCode, sjpaId);
  };

  onLaunchDefaultEdition = () => {
    const { galaxyInfo } = this.props;
    if (galaxyInfo) {
      // on controle qu'un autre processus n'est pas déjà en cours
      this.props.launchEdition(
        this.props.sjmoCode,
        galaxyInfo.defaultEditionProcessus,
        "RAPIDE",
        galaxyInfo.tableName,
        this.props.id ? [this.props.id] : [],
        undefined,
        this.props.onAfterSaveProcess
      );
    }
  };

  onSaveFocusPerso = () => {
    const { galaxyFocuses, selectedFocus } = this.props;
    const indexFocus = galaxyFocuses.findIndex(({ focusId }) => focusId === selectedFocus);

    this.props.saveFocusPerso(
      this.props.sjmoCode,
      this.props.selectedFocus,
      galaxyFocuses[indexFocus].panels.filter(panel => panel.visible)
    );
  };

  onRazFocusPerso = () => {
    this.props.razFocusPerso(this.props.sjmoCode, this.props.selectedFocus);
  };

  onFocusChange = (focusId: string) => {
    this.props.clearURLFocus();
    this.props.fetchDashboardPanelAndChangeFocus(this.props.sjmoCode, focusId);
    // this.props.onFocusChange(this.props.sjmoCode, focusId);
  };

  onDeleteMainEntity = (callback: () => void) => {
    if (this.props.galaxyInfo && this.props.id) {
      this.setState({ isDeletePending: true });
      this.props.deleteMainEntity(
        this.props.sjmoCode,
        this.props.galaxyInfo.tableName,
        this.props.id,
        success => {
          if (success) {
            this.refreshListeners();
            this.setState({ isDeletePending: false }, () => {
              this.props.navigate(`/page/${this.props.sjmoCode}`);
            });
          } else {
            this.setState({ isDeletePending: false });
          }
        }
      );
      callback();
    }
  };

  createLeftButtonBar = () => {
    if (this.props.galaxyInfo && this.props.galaxyInfo.galaxyType === "C") {
      return (
        <div className="inline-flex">
          {/*west header*/}
          <Field className="mr-6" addons>
            <Control>
              <Button
                title={t("commun_ajouter")}
                outlined
                color="link"
                onClick={() => {
                  this.props.onCreatorOpen();
                  track("galaxy::creator::open");
                }}
              >
                <span className="icon">
                  <Fa icon="plus" />
                </span>
              </Button>
            </Control>
            <Control>{this.createExpertMenu()}</Control>
            <Control>
              <Button
                title={t("commun_rafraichir")}
                outlined
                color="link"
                onClick={() => {
                  this.props.refresh();
                  track("galaxy::refresh");
                }}
              >
                <span className="icon">
                  <Fa icon="sync-alt" />
                </span>
              </Button>
            </Control>
            <Control>
              <Dropdown autoclose className="is-right">
                <DropdownButton
                  render={param =>
                    this.state.isDeletePending ? (
                      <Button title={t("commun_suprimer")} outlined color="link">
                        <span className="icon">
                          <Fa icon="spinner-third" spin />
                        </span>
                      </Button>
                    ) : (
                      <Button
                        disabled={this.props.id ? false : true}
                        title={t("commun_supprimer")}
                        ref={param.buttonRef}
                        outlined
                        color="link"
                        onClick={() => {
                          param.onOpen();
                          track("galaxy::delete::mainEntity", "started");
                        }}
                      >
                        <span className="icon">
                          <Fa icon="trash" />
                        </span>
                      </Button>
                    )
                  }
                />
                <DropdownMenu
                  render={param => (
                    <div className="dropdown-item">
                      <p>{t("commun_sur_de_suprimer")}</p>
                      <button
                        className="button is-danger is-fullwidth is-small"
                        onClick={() => {
                          this.onDeleteMainEntity(param.onClose);
                          track("galaxy::delete::mainEntity", "confirmed");
                        }}
                      >
                        {t("commun_oui")}
                      </button>
                    </div>
                  )}
                />
              </Dropdown>
            </Control>
          </Field>
          <Field addons>
            <Control>
              <EditionButton
                galaxyInfo={this.props.galaxyInfo}
                selected={this.props.entity}
                onAfterSaveProcess={this.props.onAfterSaveProcess}
              />
            </Control>
            <Control>
              <Dropdown autoclose>
                <DropdownButton
                  render={params => {
                    return (
                      <Button
                        ref={params.buttonRef}
                        title={t("commun_envoyer_email")}
                        outlined
                        color="link"
                        onClick={() => {
                          this.props.mainEntityId != null && params.onOpen();
                          track("galaxy::mail");
                        }}
                        disabled={this.props.mainEntityId == null}
                      >
                        <span className="icon">
                          <Fa icon="envelope" />
                        </span>
                      </Button>
                    );
                  }}
                />
                <DropdownMenu
                  render={() => {
                    return (
                      <>
                        <a
                          onClick={() => {
                            if (this.props.galaxyInfo && this.props.mainEntityId != null) {
                              this.props.displayEmail(
                                this.props.galaxyInfo.tableName,
                                this.props.mainEntityId,
                                this.props.navigate
                              );
                            }
                            track("galaxy::mail::type", "empty");
                          }}
                          className="dropdown-item"
                        >
                          <span className="pr-7">
                            <Fa icon="envelope-open" />
                          </span>
                          <Trans i18nKey="commun_mail_vierge">Mail vierge</Trans>
                        </a>
                        {this.props.mainEntityId !== null &&
                          this.props.galaxyInfo &&
                          this.props.galaxyInfo.defaultEditionProcessus && (
                            <a
                              onClick={() => {
                                this.props.loadAndDisplayEmail({
                                  sjmoCode: this.props.sjmoCode,
                                  tableName: (this.props.galaxyInfo as GalaxyInformation).tableName,
                                  entityId: this.props.mainEntityId as string,
                                  defaultEditionProcessus: (this.props
                                    .galaxyInfo as GalaxyInformation).defaultEditionProcessus,
                                  // Chargement du syjmail à nul car pas un mail custom
                                  syjMailIdForCustomMails: "" as string,
                                  navigate: this.props.navigate
                                });
                                track("galaxy::mail::type", "filled");
                              }}
                              className="dropdown-item"
                            >
                              <span className="pr-7">
                                <Fa icon="envelope-open-text" />
                              </span>
                              <Trans i18nKey="commun_mail_prerempli">Mail pré-rempli</Trans>
                            </a>
                          )}
                        {this.props.mainEntityId !== null &&
                          this.props.galaxyInfo &&
                          this.state.mailTemplateModules.length > 0 &&
                          this.state.mailTemplateModules.map((mailTemplateModule, index) => (
                            <a
                              key={index}
                              onClick={() => {
                                this.props.loadAndDisplayEmail({
                                  sjmoCode: this.props.sjmoCode,
                                  tableName: (this.props.galaxyInfo as GalaxyInformation).tableName,
                                  entityId: this.props.mainEntityId as string,
                                  defaultEditionProcessus: (this.props
                                    .galaxyInfo as GalaxyInformation).defaultEditionProcessus,
                                  // Chargement du syjmail car mail custom
                                  syjMailIdForCustomMails: mailTemplateModule.syjMailId as string,
                                  navigate: this.props.navigate
                                });
                                track("galaxy::mail::type", "filled");
                              }}
                              className="dropdown-item"
                            >
                              <span className="pr-7">
                                <Fa icon="envelope-open-text" />
                              </span>
                              <a>{t(mailTemplateModule.sjtmLibelle)}</a>
                            </a>
                          ))}
                      </>
                    );
                  }}
                />
              </Dropdown>
            </Control>
            <Control>
              <ProcessusProvider
                sjmoCode={this.props.sjmoCode}
                tableName={this.props.galaxyInfo.tableName}
                selected={this.props.entity ?? undefined}
                onAfterSaveProcess={this.props.onAfterSaveProcess}
              >
                <ProcessusMenu icon="cogs" outlined color="link" />
              </ProcessusProvider>
            </Control>
            {/* <Control>
              <Stargate
                outlined
                color="link"
                sjmoCode={this.props.sjmoCode}
                tableName={this.props.galaxyInfo.tableName}
                entityId={this.props.id}
              />
            </Control> */}
          </Field>
        </div>
      );
    }

    return null;
  };

  createExpertMenu = () => {
    if (this.props.galaxyInfo && this.props.galaxyInfo.galaxyType === "C") {
      return (
        <ExpertMenu
          menu={this.props.expertMenu ? this.props.expertMenu : []}
          sjmoCode={this.props.sjmoCode}
          disabled={this.props.id ? false : true}
          onOpenExpert={this.props.onOpenExpert}
        />
      );
    }
    return null;
  };

  createPanelMenu = () => {
    const { galaxyFocuses, selectedFocus } = this.props;

    let hiddenPanels: PanelState[] = [];

    if (galaxyFocuses) {
      const indexFocus = galaxyFocuses.findIndex(({ focusId }) => focusId === selectedFocus);
      if (galaxyFocuses[indexFocus]) {
        // filtre sur les panels non visibles
        hiddenPanels = galaxyFocuses[indexFocus].panels.filter(panel => panel.visible === false);
      }
    }

    return (
      <PanelList
        className="is-pulled-left"
        panels={hiddenPanels}
        title={t("commun_panels")}
        onPanelAddedToFocus={this.onPanelAddedToFocus}
      />
    );
  };

  render() {
    const { suiviOpen, ids, query } = this.props;

    let suiviAdditionalClause: RSQLCriteria | undefined;
    if (this.state.suiviOpenAnimationEnd && (query != null || ids.length > 0)) {
      suiviAdditionalClause = initRsqlCriteria();
      if (query != null) {
        suiviAdditionalClause.filters.and(new RSQLFilterExpressionString(query));
      } else if (ids.length > 0) {
        suiviAdditionalClause.filters.and(new RSQLFilterExpression("id", Operators.In, ids));
      }
    }

    return (
      <GalaxieListenerCallbackContext.Provider value={this.registerCallback}>
        {this.props.galaxyInfo && this.props.galaxyInfo.galaxyType === "C" && (
          <Spring
            immediate={!this.state.isMounted}
            from={suiviOpen ? getSpringValue(0, 0) : getSpringValue(1, 500)}
            to={suiviOpen ? getSpringValue(1, 500) : getSpringValue(0, 0)}
            onStart={() => {
              // on affiche directement si on est en immediate
              // car cela veut dire que la table de suivi et ouverte via l'url
              // donc soit via un F5, soit via une navigation de tab,
              // soit par une nouvel onglet.
              // dans tous les cas, on ne souhaite pas animer pour éviter des problèmes de
              // performances
              if (!this.state.isMounted) {
                this.setState({
                  suiviOpenAnimationEnd: true
                });
              }
            }}
            onRest={() => {
              // une fois que l'on a fini l'animation
              // on peut afficher la table.
              this.setState({
                suiviOpenAnimationEnd: true
              });
            }}
          >
            {styles => (
              <div style={{ ...GalaxieHeaderInternal.customPanelStyle, ...styles }}>
                <Datatable
                  sjmoCode={this.props.sjmoCode}
                  ctrlkey={this.props.galaxyInfo ? this.props.galaxyInfo.galaxyDtCtrlkeySuivi : ""}
                  tableName={this.props.galaxyInfo ? this.props.galaxyInfo.tableName : ""}
                  onRowClick={this.props.changeMainEntityFromSuivi}
                  additionnalClause={suiviAdditionalClause}
                  onAfterSaveDatatable={this.props.onAfterSaveProcess}
                  hidden={!this.state.suiviOpenAnimationEnd}
                  clearCacheOnClose
                  readOnly
                  toolbarButtonVisibility={{
                    add: false,
                    save: false,
                    delete: false,
                    refresh: true,
                    processus: true,
                    satellite: true,
                    focus: true,
                    exportExcel: true,
                    exportPdf: true
                  }}
                />
              </div>
            )}
          </Spring>
        )}
        <Row style={{ padding: "0 .6em", marginBottom: 0 }}>
          {/*west header*/}
          <Col span={4}>{this.createLeftButtonBar()}</Col>
          <Col span={4}>
            {this.props.galaxyInfo && this.props.galaxyInfo.galaxyType === "C" ? (
              <div className="flex justify-around">
                <Autocomplete
                  id={get(this.props, "galaxyInfo.gensearch.column", undefined)}
                  openOnFocus
                  lefIcon={
                    <ButtonNoStyle
                      title={t("commun_table_suivi")}
                      className="icon is-small is-left cursor-pointer has-text-link"
                      onClick={this.props.onOpenSuivi}
                      style={{ zIndex: 5 }}
                    >
                      <Fa icon={suiviOpen ? "search-minus" : "search-plus"} />
                    </ButtonNoStyle>
                  }
                  sjmoCode={this.props.sjmoCode}
                  joinTableName={get(this.props, "galaxyInfo.gensearch.joinTableName", undefined)}
                  joinListFields={get(this.props, "galaxyInfo.gensearch.joinListFields", undefined)}
                  additionalClause={get(
                    this.props,
                    "galaxyInfo.gensearch.additionalClause",
                    undefined
                  )}
                  sortClause={get(this.props, "galaxyInfo.gensearch.sortClause", undefined)}
                  value={this.props.mainEntityId}
                  controlProps={{ expanded: true }}
                  placeholder={t("commun_recherche")}
                  onItemChange={this.props.changeMainEntity}
                  style={{ flexGrow: 1 }}
                  includeOelStyle={true}
                  onContextMenu={this.contextMenuMGS}
                />
              </div>
            ) : null}
          </Col>
          <Col span={2}>
            {/*east header*/}
            <FocusMenu
              focuses={this.props.galaxyFocuses}
              currentFocus={this.props.selectedFocus}
              onFocusChange={this.onFocusChange}
              onSaveFocusPerso={this.onSaveFocusPerso}
              onRazFocusPerso={this.onRazFocusPerso}
              personnalisable={true}
            />
          </Col>
          <Col span={1}>{this.createPanelMenu()}</Col>
          <Col span={1}>
            <div className="flex justify-around">
              <SuperUserMenu sjmoCode={this.props.sjmoCode} />
            </div>
          </Col>
        </Row>
      </GalaxieListenerCallbackContext.Provider>
    );
  }
}

const mapStateToProps = (
  state: ReducerState,
  { sjmoCode, galaxyInfo, mainEntityId }: GalaxieHeaderAllProps
): GalaxieHeaderReduxProps => {
  const galaxyFocuses = selectDashboardDefinition(state, sjmoCode);
  const selectedFocus = selectDashboardSelectedFocus(state, sjmoCode);
  const expertMenu = state.expert.menu[sjmoCode];
  const entity = get(
    state.entities,
    [sjmoCode, galaxyInfo?.tableName ?? "", mainEntityId ?? ""],
    null
  );

  return {
    galaxyFocuses,
    selectedFocus,
    expertMenu,
    entity
  };
};

export const GalaxieHeader = connect<GalaxieHeaderReduxProps, GalaxieHeaderReduxFn>(
  mapStateToProps,
  {
    saveFocusPerso,
    razFocusPerso,
    fetchDashboardPanelAndChangeFocus,
    launchEdition,
    addPanelToFocus,
    showContextMenu,
    displayEmail,
    deleteMainEntity,
    loadAndDisplayEmail
  }
)(GalaxieHeaderInternal);

function EditionButton({
  selected,
  galaxyInfo,
  onAfterSaveProcess
}: {
  galaxyInfo: GalaxyInformation;
  selected: Pojo | null;
  onAfterSaveProcess(): void;
}) {
  return galaxyInfo.defaultEditionProcessusDefinition ? (
    <ProcessusProvider
      sjmoCode={galaxyInfo.sjmoCode}
      tableName={galaxyInfo.tableName}
      selected={selected ?? undefined}
      onAfterSaveProcess={onAfterSaveProcess}
    >
      <EditionProcessusButton
        definition={galaxyInfo.defaultEditionProcessusDefinition}
        disabled={!selected}
      />
    </ProcessusProvider>
  ) : (
    <Button title={t("commun_aucune_edition_par_defaut")} outlined color="link" disabled>
      <span className="icon">
        <Fa icon="print" />
      </span>
    </Button>
  );
}

function EditionProcessusButton({
  definition,
  disabled
}: {
  definition: NonNullable<GalaxyInformation["defaultEditionProcessusDefinition"]>;
  disabled: boolean;
}) {
  const processus = useProcessus(definition);

  return (
    <Button
      title={t("commun_aucune_edition_par_defaut")}
      outlined
      color="link"
      disabled={disabled}
      onClick={() => processus.launch(processus.editionDefault)}
    >
      <span className="icon">
        <Fa icon="print" />
      </span>
    </Button>
  );
}
