// entities
export const FIND_ONE = "FIND_ONE";
export const FIND_ONE_SUCCESS = "FIND_ONE_SUCCESS";
export const VALUE_CHANGE = "VALUE_CHANGE";
export const ADD_ENTITY = "ADD_ENTITY";
export const ADD_ALL_ENTITIES = "ADD_ALL_ENTITIES";
export const GET_AND_ADD_ENTITY = "GET_AND_ADD_ENTITY";
export const REMOVE_ENTITIES = "REMOVE_ENTITIES";
export const SAVE_OR_UDPATE_ENTITY = "SAVE_OR_UDPATE_ENTITY";
export const SAVE_PICKLIST_RESULT = "SAVE_PICKLIST_RESULT";
export const SAVE_PICKLIST_RESULT_SUCCESS = "SAVE_PICKLIST_RESULT_SUCCESS";
export const CREATE_ONE_ENTITY = "CREATE_ONE_ENTITY";
export const DELETE_ONE_ENTITY = "DELETE_ONE_ENTITY";
export const GET_CREATOR_ASSOCIATIONS = "GET_CREATOR_ASSOCIATIONS";
