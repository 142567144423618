import {
  START_CREATOR_LOADER,
  STOP_CREATOR_LOADER,
  START_GALAXY_LOADER,
  STOP_GALAXY_LOADER,
  START_EXPERT_LOADER,
  STOP_EXPERT_LOADER,
  START_MINI_EXPERT_LOADER,
  STOP_MINI_EXPERT_LOADER
} from "constant/loader";

export function startCreatorLoader() {
  return {
    type: START_CREATOR_LOADER
  };
}

export function stopCreatorLoader() {
  return {
    type: STOP_CREATOR_LOADER
  };
}

export function startExpertLoader() {
  return {
    type: START_EXPERT_LOADER
  };
}

export function stopExpertLoader() {
  return {
    type: STOP_EXPERT_LOADER
  };
}

export function startMiniExpertLoader() {
  return {
    type: START_MINI_EXPERT_LOADER
  };
}

export function stopMiniExpertLoader() {
  return {
    type: STOP_MINI_EXPERT_LOADER
  };
}

export function startGalaxyLoader(sjmoCode: string) {
  return {
    type: START_GALAXY_LOADER,
    payload: { sjmoCode }
  };
}

export function stopGalaxyLoader(sjmoCode: string) {
  return {
    type: STOP_GALAXY_LOADER,
    payload: { sjmoCode }
  };
}
