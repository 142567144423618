import { Action } from "redux";
import produce from "immer";

import { Pojo, WhenValidateItemResult } from "types/Galaxy";
import { DatatableFocusState } from "types/Focus";
import { ComponentState, FilterBarDefinition } from "types/Component";
import { FilterBar, PagedResource } from "types/Search";

import { DatatableSort } from "composants/datatable/Table";
import { ActionTypeData } from "reducers/Action";
import { IS_PRODUCTION } from "customGlobal";
import { uuidv4 } from "utils/uuid.utils";
import { mapToList, listToMap, checkEntityValid } from "utils/entities.utils";

type ExtractAction<Actions, Type> = Extract<Actions, { type: Type }>;

type DatatableStatus =
  | "EMPTY"
  | "IDLE"
  | "DEFINITION_OK"
  | "RELOAD_FROM_CACHE"
  | "REFETCH"
  | "RECOMPUTE"
  | "WVI_WAITING"
  | "WVI_PENDING"
  | "WVI_AND_SAVE_PENDING"
  | "SAVE_PENDING";

export interface DatatableState {
  state: DatatableStatus;
  definitions: {
    focus: DatatableFocusState[];
    columns: ComponentState[];
    filters: FilterBarDefinition[];
    selectedFocus: string | undefined;
  };
  userFilter: {
    filterBar: FilterBar;
    selectedRows: string[];
    open: boolean;
    searchValues: Record<string, any>;
    sortValues: Record<string, DatatableSort>;
  };
  rowExpand: {
    open: Record<string, boolean>;
    data: Record<string, any>;
  };
  data: {
    newEntitiesStart: string[];
    newEntitiesLast: string[];
    entities: { [index: number]: string | "LOADING_POJO" };
    cache: Record<string, Pojo>;
    totalRecords: number;
  };
  breakRows: {
    dialogOpen: boolean;
    columns: string[];
  };
  focusedRow: number | null;
  globalLoading: boolean;
  size: number;
  wvi: {
    state: { [id: number]: Record<string, string> };
    waiting: {
      field: string | null;
      id: string | null;
    };
  };
  tableName: string;
  satellitesExist: Record<string, boolean>;
}

export const initialStateDatatable: DatatableState = {
  state: "EMPTY",
  definitions: {
    focus: [],
    columns: [],
    selectedFocus: undefined,
    filters: []
  },
  userFilter: {
    filterBar: {
      filterBarId: null,
      startDate: null,
      endDate: null
    },
    selectedRows: [],
    open: false,
    searchValues: {},
    sortValues: {}
  },
  data: {
    newEntitiesStart: [],
    newEntitiesLast: [],
    entities: {},
    cache: {},
    totalRecords: 0
  },
  breakRows: {
    dialogOpen: false,
    columns: []
  },
  focusedRow: null,
  rowExpand: {
    open: {},
    data: {}
  },
  globalLoading: false,
  size: 10,
  wvi: {
    state: {},
    waiting: {
      field: null,
      id: null
    }
  },
  tableName: "",
  satellitesExist: {}
};

export type AllDatatableActions =
  | ActionTypeData<"INIT_DATATABLE", { tableName: string; filter: string }>
  | Action<"RESET_DATATABLE">
  | Action<"CLEAR_DATATABLE_DATA">
  | ActionTypeData<"CHANGE_DATATABLE_FOCUS_SUCCESS", { selectedFocus: string }>
  | ActionTypeData<"FETCH_DEFINITIONS", { focus: DatatableFocusState[]; columns: ComponentState[] }>
  | ActionTypeData<
      "CHANGE_FOCUS_SUCCESS",
      {
        columns: ComponentState[];
        isSortChanged: boolean;
        isDirty: boolean;
        newSort: Record<string, DatatableSort> | null;
      }
    >
  | ActionTypeData<"FETCH_DATATABLE_FOCUS_SUCCESS", { focus: DatatableFocusState[] }>
  | ActionTypeData<"DATATABLE_ADD_ONE_FOCUS", { focus: DatatableFocusState }>
  | Action<"REFRESH_DATATABLE">
  | Action<"TOGGLE_SEARCH">
  | ActionTypeData<"DATATABLE_TEMPORARY_LOADING", { startIndex: number; stopIndex: number }>
  | Action<"FETCH_DATATABLE_DATA">
  | Action<"FETCH_DATATABLE_DATA_ERROR">
  | ActionTypeData<
      "FETCH_DATATABLE_DATA_SUCCESS",
      { startIndex: number; pojos: PagedResource<Pojo>; reset: boolean }
    >
  | ActionTypeData<"UPDATE_SELECTED_ROWS_DATATABLE", { rowIndex: string | "ALL" }>
  | Action<"CLEAR_SELECTED_ROWS_DATATABLE">
  | ActionTypeData<"ADD_ROW_DATATABLE", { position: "START" | "END"; pojo: Pojo }>
  | ActionTypeData<"ADD_MULTIPLE_ROW_DATATABLE_SUCCESS", { pojos: Pojo[] }>
  | ActionTypeData<"UPDATE_ROW_DATATABLE", { pojo: Pojo }>
  | ActionTypeData<
      "CHANGE_ROW_DATATABLE",
      { entityId: string; field: string; value: any; hasWVI: boolean }
    >
  | Action<"RESET_NEW_ENTTIES_DATATABLE">
  | ActionTypeData<"REMOVE_NEW_ENTITIES_DATATABLE", { toBeRemove: string[]; unSelect: boolean }>
  | ActionTypeData<"WVI_DATATABLE", { wvi: WhenValidateItemResult<Pojo>; field: string }>
  | ActionTypeData<"UPDATE_DATATABLE_SORT", { focusId: string; sort: string }>
  | ActionTypeData<"TOGGLE_SORT", { name: string; sort: DatatableSort }>
  | ActionTypeData<"DATATABLE_UPDATE_FILTERS", { filter: FilterBarDefinition }>
  | ActionTypeData<"DATATABLE_REMOVE_FILTERS", { id: string }>
  | ActionTypeData<"DATATABLE_REMOVE_FOCUS", { focusId: string }>
  | ActionTypeData<"LOCAL_UPDATE_DATATABLE_FOCUS", { focusId: number; focusProps: any }>
  | ActionTypeData<"DATATABLE_UPDATE_FILTER_BAR", { name: keyof FilterBar; value: any }[]>
  | Action<"CLEAR_FILTERS">
  | Action<"CLEAR_CLOSE_FILTERS">
  | ActionTypeData<"SEARCH_VALUES", { name: string; value: any; needRefresh: boolean }>
  | ActionTypeData<"DATATABLE_CHANGE_BREAK_COLUMNS", { breakColumns: string[] }>
  | ActionTypeData<"RESIZE_COLUMN", { column: string; width: number; deltaX: number }>
  | ActionTypeData<"DATATABLE_SATTELLITE_EXIST_SUCCESS", { existList: Record<string, boolean> }>
  | ActionTypeData<"UPDATE_DATATABLE_STATE", DatatableStatus>
  | Action<"TOGGLE_BREAKS_ROWS_DIALOG">
  | ActionTypeData<"CHANGE_BREAK_ROWS", string[]>
  | ActionTypeData<"TOGGLE_ROW_EXPAND", string>
  | ActionTypeData<"ROW_EXPAND_DATA", { id: string; result: string }>
  | Action<"CHANGE_WVI_PENDING">
  | Action<"CHANGE_SAVE_PENDING">
  | Action<"SAVE_ERROR">
  | ActionTypeData<"UPDATE_COLUMNS", ComponentState[]>
  | ActionTypeData<"FOCUS_ROW", number | null>
  | ActionTypeData<
      "DUPLICATE_ROW",
      { rowIndex: number; entityID: string; direction: "UP" | "DOWN"; duplicated: Pojo }
    >;

export default function reducer(
  state: DatatableState = initialStateDatatable,
  action: AllDatatableActions
): DatatableState {
  switch (action.type) {
    case "INIT_DATATABLE":
      return initDatatable(state, action);

    case "RESET_DATATABLE":
      return initialStateDatatable;

    case "CLEAR_DATATABLE_DATA":
      return clearData(state, action);

    case "CHANGE_DATATABLE_FOCUS_SUCCESS":
      return changeFocus(state, action);

    case "FETCH_DEFINITIONS":
      return initDefinitions(state, action);

    case "CHANGE_FOCUS_SUCCESS":
      return addColumnsAndRefetch(state, action);

    case "FETCH_DATATABLE_FOCUS_SUCCESS":
      return addFocus(state, action);

    case "DATATABLE_ADD_ONE_FOCUS":
      return addOneFocus(state, action);

    case "REFRESH_DATATABLE":
      return updateSearchDatatable(state, action);

    case "TOGGLE_SEARCH":
      return produce(state, draft => {
        draft.userFilter.open = !draft.userFilter.open;
        draft.state = "RECOMPUTE";
      });

    case "DATATABLE_TEMPORARY_LOADING":
      return addLoadingEntities(state, action);

    case "FETCH_DATATABLE_DATA":
      return setGlobalLoadingActive(state, action);

    case "FETCH_DATATABLE_DATA_ERROR":
      return resetGlobalLoading(state, action);

    case "FETCH_DATATABLE_DATA_SUCCESS":
      return fetchDataSuccess(state, action);

    case "UPDATE_SELECTED_ROWS_DATATABLE":
      return updateSelectedRows(state, action);

    case "CLEAR_SELECTED_ROWS_DATATABLE":
      return produce(state, draft => {
        draft.state = "RECOMPUTE";
        draft.userFilter.selectedRows = [];
      });

    case "ADD_ROW_DATATABLE":
      return addRowNewEntities(state, action);

    case "ADD_MULTIPLE_ROW_DATATABLE_SUCCESS":
      return addMultipleRowsNewEntities(state, action);

    case "UPDATE_ROW_DATATABLE":
      return updateNewEntity(state, action);

    case "CHANGE_ROW_DATATABLE":
      return updateFieldNewEntity(state, action);

    case "RESET_NEW_ENTTIES_DATATABLE":
      return produce(state, draft => {
        for (let id of draft.data.newEntitiesStart) {
          delete draft.data.cache[id];
        }

        for (let id of draft.data.newEntitiesLast) {
          delete draft.data.cache[id];
        }

        draft.data.newEntitiesStart = [];
        draft.data.newEntitiesLast = [];
      });

    case "REMOVE_NEW_ENTITIES_DATATABLE":
      return removeNewEntities(state, action);

    case "WVI_DATATABLE":
      return updateWvi(state, action);

    case "UPDATE_DATATABLE_SORT":
      return updateDatatableSort(state, action);

    case "TOGGLE_SORT":
      return produce(state, draft => {
        if (draft.userFilter.sortValues[action.payload.name] === action.payload.sort) {
          delete draft.userFilter.sortValues[action.payload.name];
        } else {
          draft.userFilter.sortValues[action.payload.name] = action.payload.sort;
        }
        draft.state = "REFETCH";
      });

    case "DATATABLE_UPDATE_FILTERS":
      return addOrUpdateFilter(state, action);

    case "DATATABLE_REMOVE_FILTERS":
      return removeFilter(state, action);

    case "DATATABLE_REMOVE_FOCUS":
      return removeFocus(state, action);

    case "LOCAL_UPDATE_DATATABLE_FOCUS":
      return updateFocus(state, action);

    case "DATATABLE_UPDATE_FILTER_BAR":
      return updateFilterBar(state, action);

    case "CLEAR_FILTERS":
      return clearFilters(state, action);

    case "CLEAR_CLOSE_FILTERS":
      return clearAndCloseFilters(state, action);

    case "SEARCH_VALUES":
      return searchValues(state, action);

    case "DATATABLE_CHANGE_BREAK_COLUMNS":
      return changeBreakColumn(state, action);

    case "DATATABLE_SATTELLITE_EXIST_SUCCESS":
      return donneeSatellite(state, action);

    case "RESIZE_COLUMN":
      return resizeColumn(state, action);

    case "UPDATE_DATATABLE_STATE":
      return {
        ...state,
        state: action.payload
      };

    case "TOGGLE_BREAKS_ROWS_DIALOG": {
      const isOpen = state.breakRows.dialogOpen;
      return produce(state, draft => {
        draft.breakRows.dialogOpen = !isOpen;
      });
    }

    case "CHANGE_BREAK_ROWS": {
      return produce(state, draft => {
        draft.breakRows.columns = action.payload;
        draft.breakRows.dialogOpen = false;
        draft.state = "REFETCH";
      });
    }
    case "TOGGLE_ROW_EXPAND": {
      return produce(state, draft => {
        draft.state = "RECOMPUTE";
        draft.rowExpand.open[action.payload] =
          state.rowExpand.open[action.payload] !== undefined
            ? !state.rowExpand.open[action.payload]
            : true;
      });
    }
    case "ROW_EXPAND_DATA": {
      const { id, result } = action.payload;
      return produce(state, draft => {
        draft.rowExpand.data[id] = result;
        draft.rowExpand.open[id] = true;
        draft.state = "RECOMPUTE";
      });
    }

    case "CHANGE_WVI_PENDING":
      return produce(state, draft => {
        if (draft.state === "IDLE") draft.state = "WVI_PENDING";
        if (draft.state === "WVI_WAITING") {
          draft.state = "WVI_PENDING";
          draft.wvi.waiting.id = null;
          draft.wvi.waiting.field = null;
        }
      });

    case "CHANGE_SAVE_PENDING":
      return produce(state, draft => {
        if (draft.state === "IDLE") draft.state = "SAVE_PENDING";
        if (draft.state === "WVI_PENDING") draft.state = "WVI_AND_SAVE_PENDING";
      });

    case "SAVE_ERROR":
      return produce(state, draft => {
        if (draft.state === "SAVE_PENDING") draft.state = "IDLE";
      });

    case "UPDATE_COLUMNS":
      return produce(state, draft => {
        draft.definitions.columns = action.payload;
      });

    case "FOCUS_ROW":
      return produce(state, draft => {
        draft.focusedRow = action.payload;
      });

    case "DUPLICATE_ROW": {
      let entity = state.data.cache[action.payload.entityID];

      if (!entity) {
        return state;
      }

      let insertedIndex = action.payload.rowIndex;
      if (action.payload.direction === "DOWN") {
        insertedIndex++;
      }

      insertedIndex = Math.max(insertedIndex, 0);

      const duplicated = action.payload.duplicated;

      const newState = produce(state, draft => {
        draft.state = "RECOMPUTE";
        draft.data.cache[duplicated.uuid] = duplicated;

        if (entity.id === null) {
          const isEntityStart =
            state.data.newEntitiesStart.findIndex(el => el === entity.uuid) !== -1;
          if (isEntityStart) {
            draft.data.newEntitiesStart.splice(insertedIndex, 0, duplicated.uuid);
          } else {
            draft.data.newEntitiesLast.splice(insertedIndex, 0, duplicated.uuid);
          }
          // gérer l'insertion directement via entitystart ou entityend
        } else {
          // il faut gérer l'insertion directement depuis entities
          const list = mapToList(state.data.entities);
          list.splice(insertedIndex, 0, duplicated.uuid);
          draft.data.entities = listToMap(list);
          // il faudrait unifier listStart; entities & listEnd
          // pour éviter d'avoir besoin de faire ce genre de chose.
          // c'est probablement faisable
          // maintenant que l'on a une propriété "cache" qui contient les pojos
          // par clé d'id.
          draft.data.totalRecords++; // c'est bizarre
        }

        for (let index = insertedIndex; index < draft.userFilter.selectedRows.length; index++) {
          draft.userFilter.selectedRows[index] += 1;
        }
      });

      return newState;
    }

    default:
      return state;
  }
}

/**
 * Initialisation de la table
 * @param state state redux
 * @param action action d'initialisation
 */
function initDatatable(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "INIT_DATATABLE">
): DatatableState {
  const { tableName } = action.payload;
  return produce(state, draft => {
    draft.tableName = tableName;
  });
}

/**
 * Reset des data de la table
 * @param state state redux
 * @param action action de reset des entities
 */
function clearData(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "CLEAR_DATATABLE_DATA">
): DatatableState {
  return produce(state, draft => {
    draft.data.entities = {};
    draft.data.newEntitiesStart = [];
    draft.data.newEntitiesLast = [];
    draft.data.cache = {};
    draft.userFilter.selectedRows = [];
    draft.data.totalRecords = 0;
    draft.wvi.state = {};
  });
}

/**
 * Changement de focus d'une table
 * @param state state redux
 * @param action action de changement de focus
 */
function changeFocus(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "CHANGE_DATATABLE_FOCUS_SUCCESS">
): DatatableState {
  const { selectedFocus } = action.payload;

  return produce(state, draft => {
    draft.definitions.selectedFocus = selectedFocus;

    const currentFocus = draft.definitions.focus.find(f => f.focusId === selectedFocus);

    if (currentFocus) {
      draft.breakRows.columns = currentFocus.breakRows || [];

      const filterBar = currentFocus.filters.find(p => p.privilegie);

      if (filterBar) {
        draft.userFilter.filterBar = {
          filterBarId: filterBar.filterBarId,
          startDate: null,
          endDate: null
        };
      }
    }
  });
}

/**
 * Changement des rows de datatable
 * @param state state redux
 * @param action action d'update des rows sélectionné
 */
function updateSelectedRows(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "UPDATE_SELECTED_ROWS_DATATABLE">
): DatatableState {
  const { rowIndex } = action.payload;
  const {
    data,
    userFilter: { selectedRows }
  } = state;
  return produce(state, draft => {
    const localCount = state.data.totalRecords;
    if (rowIndex === "ALL") {
      if (selectedRows.length === localCount) {
        draft.userFilter.selectedRows = [];
      } else {
        // on sélectionne tous les ids
        let newSelectedRows = [];
        for (let i = 0, index = localCount; i < index; i++) {
          const entity = data.cache[data.entities[i]];
          if (checkEntityValid(entity)) newSelectedRows.push(entity.id);
        }
        draft.userFilter.selectedRows = newSelectedRows;
      }
    } else {
      if (!selectedRows.includes(rowIndex)) {
        draft.userFilter.selectedRows.push(rowIndex);
      } else {
        draft.userFilter.selectedRows = selectedRows.filter(row => row !== rowIndex);
      }
    }
  });
}

function addColumnsAndRefetch(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "CHANGE_FOCUS_SUCCESS">
): DatatableState {
  const { columns, isSortChanged, isDirty, newSort } = action.payload;
  return produce(state, draft => {
    if (isSortChanged && !isDirty) {
      // On lance un refresh pour gérer les cas où le tri change et le cas où les oel changent
      draft.state = "REFETCH";
    } else {
      // On redessine la datatable
      draft.state = "RECOMPUTE";
    }

    // On écrase le sort actuel fait par l'utilisateur avec celui du focus
    if (newSort) {
      draft.userFilter.sortValues = newSort;
    }

    draft.definitions.columns = columns.filter(col => col.compoVisible === true);
  });
}

function initDefinitions(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "FETCH_DEFINITIONS">
) {
  const { focus, columns } = action.payload;
  return produce(state, draft => {
    draft.definitions.focus = focus;
    draft.definitions.columns = columns.filter(col => col.compoVisible === true);
    // le fait de passer a DEFINITION_OK lance un refetch
    // on demande un refetch seulement si la définition était vide de base.
    // si on arrive sur une récupération de cache,
    // on a peut être déjà des data, on évite donc d'écraser
    if (state.state === "EMPTY") {
      draft.state = "DEFINITION_OK";
    }

    const currentFocus = focus[0];

    if (currentFocus) {
      draft.definitions.selectedFocus = currentFocus.focusId;
      draft.breakRows.columns = currentFocus.breakRows || [];
      draft.definitions.filters = currentFocus.filters;

      let filterBar = currentFocus.filters
        ? currentFocus.filters.find(f => f.privilegie === true)
        : null;

      if (filterBar) {
        draft.userFilter.filterBar = {
          filterBarId: filterBar.filterBarId,
          startDate: null,
          endDate: null
        };
      }

      // Affichage du sort dans la colonne
      if (currentFocus.sort) {
        draft.userFilter.sortValues = parseSortStr(currentFocus.sort);
      }
    }
  });
}

function addFocus(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "FETCH_DATATABLE_FOCUS_SUCCESS">
): DatatableState {
  const { focus } = action.payload;
  return produce(state, draft => {
    draft.definitions.focus = focus;

    const currentFocus = focus[0];

    if (currentFocus) {
      draft.definitions.selectedFocus = currentFocus.focusId;
      draft.breakRows.columns = currentFocus.breakRows || [];

      let filterBar =
        currentFocus && currentFocus.filters
          ? currentFocus.filters.find(f => f.privilegie === true)
          : null;

      if (filterBar) {
        draft.userFilter.filterBar = {
          filterBarId: filterBar.filterBarId,
          startDate: null,
          endDate: null
        };
      }
    }
  });
}

function addOneFocus(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "DATATABLE_ADD_ONE_FOCUS">
): DatatableState {
  const { focus } = action.payload;
  return produce(state, draft => {
    draft.definitions.focus.push(focus);
  });
}

function setGlobalLoadingActive(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "FETCH_DATATABLE_DATA">
): DatatableState {
  return produce(state, draft => {
    draft.globalLoading = true;
  });
}

function resetGlobalLoading(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "FETCH_DATATABLE_DATA_ERROR">
): DatatableState {
  return produce(state, draft => {
    draft.globalLoading = false;
  });
}

function fetchDataSuccess(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "FETCH_DATATABLE_DATA_SUCCESS">
) {
  const { startIndex, pojos, reset } = action.payload;

  return produce(state, draft => {
    draft.state = "IDLE";
    draft.size = pojos.meta.size;
    draft.data.totalRecords = pojos.meta.totalRecords;

    let indexPojo = 0;
    for (
      let index = startIndex, maxIndex = startIndex + pojos.data.length;
      index < maxIndex;
      index++
    ) {
      let pojo = pojos.data[indexPojo];
      let id = pojo.id;
      draft.data.entities[index] = id;
      draft.data.cache[id] = pojo;
      indexPojo++;
    }

    if (reset) {
      draft.wvi.state = {};
    }

    draft.globalLoading = false;
  });
}

function addLoadingEntities(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "DATATABLE_TEMPORARY_LOADING">
) {
  const { startIndex, stopIndex } = action.payload;
  return produce(state, draft => {
    for (let index = startIndex; index < stopIndex; index++) {
      if (!draft.data.entities[index]) {
        draft.data.entities[index] = "LOADING_POJO";
      }
    }
  });
}

function updateSearchDatatable(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "REFRESH_DATATABLE">
): DatatableState {
  return produce(state, draft => {
    draft.data.entities = {};
    draft.data.newEntitiesStart = [];
    draft.data.newEntitiesLast = [];
    draft.data.cache = {};
    draft.wvi.state = {};
    draft.userFilter.selectedRows = [];
  });
}

function addRowNewEntities(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "ADD_ROW_DATATABLE">
): DatatableState {
  const { position, pojo } = action.payload;

  // on récupère le preRecordEntity avant d'appeler le produce pour
  // éviter de copier un proxy.

  return produce(state, draft => {
    if (pojo === null) {
      if (IS_PRODUCTION()) {
        console.error("impossible d'ajouter une entité s'il n'y a pas eu de pre-record");
        return;
      } else {
        throw new Error("impossible d'ajouter une entité s'il n'y a pas eu de pre-record");
      }
    }

    const pojoToAdd = { ...pojo, uuid: uuidv4() };
    let id = pojoToAdd.id || pojoToAdd.uuid;
    if (position === "START") {
      draft.state = "RECOMPUTE";
      draft.data.newEntitiesStart.unshift(id);
      draft.data.cache[id] = pojoToAdd;
    } else if (position === "END") {
      draft.state = "RECOMPUTE";
      draft.data.newEntitiesLast.push(id);
      draft.data.cache[id] = pojoToAdd;
    } else {
      if (IS_PRODUCTION()) {
        console.error("impossible d'ajouter une entité en dehors de START et END");
      } else {
        throw new Error("impossible d'ajouter une entité en dehors de START et END");
      }
    }

    for (let index = 0; index < draft.userFilter.selectedRows.length; index++) {
      draft.userFilter.selectedRows[index] += 1;
    }
  });
}

function addMultipleRowsNewEntities(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "ADD_MULTIPLE_ROW_DATATABLE_SUCCESS">
) {
  const { pojos } = action.payload;
  return produce(state, draft => {
    for (const pojo of pojos) {
      const pojoToAdd = { ...pojo, modifie: true };
      let id = pojoToAdd.id || pojoToAdd.uuid;
      draft.data.newEntitiesStart.unshift(id);
      draft.data.cache[id] = pojoToAdd;
    }

    for (let index = 0; index < draft.userFilter.selectedRows.length; index++) {
      draft.userFilter.selectedRows[index] += 1;
    }
  });
}

function updateNewEntity(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "UPDATE_ROW_DATATABLE">
) {
  const { pojo } = action.payload;

  return produce(state, draft => {
    let id = pojo.id || pojo.uuid;
    draft.data.cache[id] = { ...pojo, modifie: true };
  });
}

function updateFieldNewEntity(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "CHANGE_ROW_DATATABLE">
): DatatableState {
  const { entityId, field, value, hasWVI } = action.payload;
  return produce(state, draft => {
    let id = entityId;
    draft.data.cache[id][field] = value;
    draft.data.cache[id].modifie = true;
    if (hasWVI && draft.state === "IDLE") {
      draft.state = "WVI_WAITING";
      draft.wvi.waiting.id = id;
      draft.wvi.waiting.field = field;
    }
  });
}

function updateWvi(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "WVI_DATATABLE">
) {
  const {
    wvi: { message, entity },
    field
  } = action.payload;
  return produce(state, draft => {
    let id = entity.id || entity.uuid;
    draft.data.cache[id] = entity;
    if (draft.state === "WVI_PENDING") draft.state = "IDLE";
    if (draft.state === "WVI_AND_SAVE_PENDING") draft.state = "SAVE_PENDING";

    if (message.type) {
      if (!draft.wvi.state[id]) {
        draft.wvi.state[id] = {};
      }
      draft.wvi.state[id][field] = message.type;
    }
  });
}

function removeNewEntities(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "REMOVE_NEW_ENTITIES_DATATABLE">
): DatatableState {
  function deleteNewEntities(newEntities: string[], id: string) {
    const index = newEntities.findIndex(en => en === id);
    if (index !== -1) {
      newEntities.splice(index, 1);
      return true;
    }
    return false;
  }

  const { toBeRemove } = action.payload;
  return produce(state, draft => {
    for (let id of toBeRemove) {
      // on supprime déjà le cache,
      // puis on supprime de l'index des entities
      delete draft.data.cache[id];

      let isDelete = deleteNewEntities(draft.data.newEntitiesStart, id);
      if (!isDelete) {
        deleteNewEntities(draft.data.newEntitiesLast, id);
      }
    }
    draft.state = "RECOMPUTE";
    if (action.payload.unSelect) {
      draft.userFilter.selectedRows = [];
    }
  });
}

function updateDatatableSort(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "UPDATE_DATATABLE_SORT">
) {
  const { focusId, sort } = action.payload;
  return produce(state, draft => {
    let focuses = draft.definitions.focus;
    if (focuses) {
      for (let focus of focuses) {
        if (focus.focusId === focusId) {
          focus.sort = sort;
        }
      }
    }
  });
}

function addOrUpdateFilter(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "DATATABLE_UPDATE_FILTERS">
) {
  const { filter } = action.payload;
  return produce(state, draft => {
    let focuses = draft.definitions.focus;
    for (let focus of focuses) {
      let existing = focus.filters.findIndex(current => current.filterBarId === filter.filterBarId);
      if (existing > -1) {
        focus.filters.splice(existing, 1, filter);
      } else {
        focus.filters.push(filter);
      }
    }
  });
}

function removeFilter(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "DATATABLE_REMOVE_FILTERS">
) {
  const { id } = action.payload;
  return produce(state, draft => {
    for (let focus of draft.definitions.focus) {
      let existing = focus.filters.findIndex(current => {
        return current.filterBarId.toString() === id.toString();
      });
      if (existing > -1) {
        focus.filters.splice(existing, 1);
      }
    }
  });
}

function removeFocus(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "DATATABLE_REMOVE_FOCUS">
) {
  const { focusId } = action.payload;
  return produce(state, draft => {
    const filtered = state.definitions.focus.filter(focus => focus.focusId !== focusId);
    draft.definitions.focus = filtered;
    if (state.definitions.selectedFocus === focusId) {
      draft.definitions.selectedFocus = filtered.length > 0 ? filtered[0].focusId : undefined;
    }
  });
}

function updateFocus(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "LOCAL_UPDATE_DATATABLE_FOCUS">
) {
  const { focusId, focusProps } = action.payload;
  return produce(state, draft => {
    let focuses = [...state.definitions.focus];
    focuses = focuses.map(f => {
      if (f.focusId.toString() === focusId.toString()) {
        f = { ...f, ...focusProps };
      }
      return f;
    });
    draft.definitions.focus = focuses;
  });
}

function updateFilterBar(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "DATATABLE_UPDATE_FILTER_BAR">
): DatatableState {
  return produce(state, draft => {
    action.payload.forEach(({ name, value }) => {
      draft.userFilter.filterBar[name] = value;
    });
    draft.state = "REFETCH";
  });
}

function clearFilters(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "CLEAR_FILTERS">
) {
  return produce(state, draft => {
    draft.userFilter.searchValues = {};

    // Lorsqu'on annule les tris manuels on revient au tri du focus courant et non au tri par défaut
    const workingFocus = state.definitions.focus.find(
      f => f.focusId === state.definitions.selectedFocus
    );

    if (workingFocus && workingFocus.sort) {
      console.log(workingFocus.sort, parseSortStr(workingFocus.sort));
      draft.userFilter.sortValues = parseSortStr(workingFocus.sort);
    } else {
      draft.userFilter.sortValues = {};
    }

    draft.userFilter.filterBar.filterBarId = null;
    draft.userFilter.filterBar.startDate = null;
    draft.userFilter.filterBar.endDate = null;
    draft.state = "REFETCH";
  });
}

function clearAndCloseFilters(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "CLEAR_CLOSE_FILTERS">
) {
  return produce(state, draft => {
    draft.userFilter.searchValues = {};
    draft.userFilter.sortValues = {};
    draft.userFilter.filterBar.filterBarId = null;
    draft.userFilter.filterBar.startDate = null;
    draft.userFilter.filterBar.endDate = null;
    draft.userFilter.open = false;
    draft.focusedRow = null;
    draft.state = "REFETCH";
  });
}

function searchValues(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "SEARCH_VALUES">
) {
  const { name, value, needRefresh } = action.payload;
  return produce(state, draft => {
    if (needRefresh) draft.state = "REFETCH";
    if (value === null || value === "") {
      delete draft.userFilter.searchValues[name];
    } else {
      draft.userFilter.searchValues[name] = value;
    }
  });
}

function changeBreakColumn(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "DATATABLE_CHANGE_BREAK_COLUMNS">
): DatatableState {
  const { breakColumns } = action.payload;
  return produce(state, draft => {
    draft.breakRows.columns = breakColumns;
  });
}

function donneeSatellite(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "DATATABLE_SATTELLITE_EXIST_SUCCESS">
) {
  const { existList } = action.payload;
  return produce(state, draft => {
    Object.keys(existList).forEach(key => {
      draft.satellitesExist[key] = existList[key];
    });
  });
}

function resizeColumn(
  state: DatatableState,
  action: ExtractAction<AllDatatableActions, "RESIZE_COLUMN">
) {
  const { column, deltaX, width } = action.payload;
  return produce(state, draft => {
    const indexCol = state.definitions.columns.findIndex(col => col.column === column);
    const percentDelta = deltaX / width;

    if (indexCol !== -1) {
      const prevWidth = state.definitions.columns[indexCol].contentSize;
      const widthAdd = percentDelta * width;

      let newWidth = prevWidth + widthAdd;
      if (newWidth < 50) newWidth = 50;

      draft.definitions.columns[indexCol].contentSize = newWidth;
    }
  });
}

/**
 * Parse un order by. Celui ci doit être de la forme suivante :
 * "col1 DatatableSort, col2 DatatableSort, col3 DatatableSort, ...
 * Où DatatableSort est soit "ASC" ou "DESC"
 * @param sortStr
 * @returns
 */
export function parseSortStr(sortStr: string) {
  const sorts: Record<string, DatatableSort> = {};
  sortStr.split(",").forEach(s => {
    const colAndOrder = s.trim().split(" ");
    if (colAndOrder.length === 2) {
      sorts[colAndOrder[0]] =
        colAndOrder[1] === "DESC" || colAndOrder[1] === "desc" ? "DESC" : "ASC";
    } else {
      sorts[colAndOrder[0]] = "ASC";
    }
  });
  return sorts;
}
