import { createStore, applyMiddleware } from "redux";
import { Store } from "redux";
import getPersistMiddleware from "redux-persist-middleware";
import { getConfiguredCache } from "cache";
import { composeWithDevTools } from "redux-devtools-extension";
// import logger from "redux-logger";

// middleware
import ReduxThunk, { ThunkAction } from "redux-thunk";
import createSagaMiddleware from "redux-saga";

import rootReducer, { ReducerState } from "reducers";
import rootSaga from "saga";

export const cacheRedux = getConfiguredCache({
  version: 1,
  maxAge: 8.64e7,
  name: "galaxies-cache"
});

const persistMiddleware = getPersistMiddleware({
  cacheFn: cacheRedux.set,
  logger: console.info,
  actionMap: {
    INIT_GALAXIES_MENU_SUCCESS: ["galaxiesMenu"],
    INIT_INTERACTION_SUCCESS: ["interactions"],
    INIT_APP_STATE: ["app"]
  }
});

/**
 * Schedules actions with { meta: { raf: true } } to be dispatched inside a rAF loop
 * frame.  Makes `dispatch` return a function to remove the action from the queue in
 * this case.
 */
const rafScheduler = (internalStore: any) => (next: any) => {
  let queuedActions: any[] = [];
  let frame: number | null = null;
  function loop() {
    frame = null;
    try {
      if (queuedActions.length > 0) {
        next(queuedActions.shift());
      }
    } finally {
      maybeRaf();
    }
  }
  function maybeRaf() {
    if (queuedActions.length && !frame) {
      frame = requestAnimationFrame(loop);
    }
  }
  return (action: any) => {
    if (!action.meta || !action.meta.raf) {
      return next(action);
    }
    queuedActions.push(action);
    maybeRaf();

    return function cancel() {
      // frame && cancelAnimationFrame(frame);
      queuedActions = queuedActions.filter(a => a !== action);
    };
  };
};

// lors de l'initialisation, on souhaite faire l'update de l'inputValue après l'appel à l'autocomplete
// on utilise setTimeout avec la valeur 0 pour obliger le navigateur a séparer l'éxecution de chaque
// appel dans une "task" de la boucle d'évenement de Javascript séparé.
// En effet, sinon, toutes les GS execute le même code en même temps ce qui ralenti la DT
const nextTaskScheduler = (internalStore: any) => (next: any) => {
  return (action: any) => {
    if (!action.meta || !action.meta.delayed) {
      return next(action);
    }

    let timer = window.requestIdleCallback(() => {
      next(action);
    });
    // let timer = setTimeout(() => {
    //   next(action);
    // }, 0);

    return function cancelNextTask() {
      // clearTimeout(timer);
      if (timer) window.cancelIdleCallback(timer);
    };
  };
};

export type ThunkResult<R> = ThunkAction<R, ReducerState, undefined, any>;

export let store: Store<ReducerState> | null;
// fonction de configuration du store redux
export function configureStore(initial: any) {
  const sagaMiddleware = createSagaMiddleware();

  // création du store avec redux-thunk & redux-saga comme middleware

  const composeEnhancers = composeWithDevTools({
    actionsBlacklist: "PUSH_NOTIFICATION"
  });

  store = createStore(
    rootReducer,
    initial,
    composeEnhancers(
      applyMiddleware(
        persistMiddleware,
        rafScheduler,
        nextTaskScheduler,
        ReduxThunk,
        sagaMiddleware /* logger */
      )
    )
  );

  // initialise les différents générateurs saga disponibles dans l'application
  sagaMiddleware.run(rootSaga);

  return store;
}
