// Fonction qui lance une édition
import { AxiosPromise } from "axios";
import { EmailState, EmailDestinataireState } from "types/Processus";
import { api, getUIContext } from "./common";

export function sendMail(mailStrunct: EmailState): AxiosPromise<string> {
  const params = getUIContext();
  return api.post(`/mail/send?${params}`, mailStrunct);
}

export function getMailTemplate(
  tableName: string,
  id: string,
  mailId: string
): AxiosPromise<EmailState[]> {
  const context = getUIContext();
  context.set("tableName", tableName);
  context.set("id", id);
  return api.get(`/mail/template/${mailId}?${context.toString()}`);
}

/**
 *  Récupère les potentiels destinataires
 *
 */
export function getDestinataires(): AxiosPromise<EmailDestinataireState[]> {
  const params = getUIContext();

  return api.get(`/mail/destinataires?${params}`);
}

/**
 *  Récupère templates en fonction du SJMOCODE du module // Find ModuleID in syjModule (from SJMOCODE) > Find Template in SYJ_MAIL_TEMPLATE_MODULE (from ModuleID)
 *
 */
export function getTemplatesFromModule(sjmoCode: string) {
  const params = getUIContext({ sjmoCode: sjmoCode });
  return api.get(`/mail/templates?${params}`);
}

export function getDefinitionFromScenario(props: {
  sjmoCode: string;
  tableName: string;
  entityId: string;
  defaultEditionProcessus: string;
  syjMailIdForCustomMails: string;
}): AxiosPromise<EmailState[]> {
  const params = getUIContext({ sjmoCode: props.sjmoCode });
  params.append("tableName", props.tableName);
  params.append("entityId", props.entityId);
  params.append("defaultEditionProcessus", props.defaultEditionProcessus);
  params.append("syjMailIdForCustomMails", props.syjMailIdForCustomMails);

  return api.get(`/process/mail/definition?${params}`);
}

export function deleteAdditionnalAttachment(fileKey: string): AxiosPromise<boolean> {
  const params = getUIContext();

  params.append("fileKey", fileKey);

  return api.post(`/mail/deleteAttachment?${params}`);
}
