import { apiAdn, getUIContext } from "api/common";
import { AxiosPromise } from "axios";
import { PagedResource } from "types/Search";
import { Pojo } from "types/Galaxy";

export function synchronizeInteractiveReportColumn(id: string) {
  return apiAdn.post(`/interactive-report/admin/${id}/synchronize-columns`);
}

export function synchronizetradKeys(sjmoCode: string, selectedIR: Pojo, syjTable: Pojo) {
  const params = getUIContext({ sjmoCode });
  selectedIR && params.set("syjIRId", selectedIR.id);
  syjTable && params.set("syjTableId", syjTable.id);
  return apiAdn.post(`/interactive-report/admin/synchronize-trad?${params}`);
}

export function getIrDisplayedColumns(
  sjmoCode: string,
  irId?: string,
  searchedColumn?: string
): AxiosPromise<PagedResource<Pojo>> {
  const params = getUIContext({ sjmoCode });
  irId && params.set("syjIrId", irId.toString());
  searchedColumn && params.set("searchedColumn", searchedColumn.toString());
  return apiAdn.get(`/interactive-report/admin/get-columns?${params}`);
}

export function newIRColumnTrad(sjmoCode: string, newTrad: string, syjIRColumnId: string) {
  const params = getUIContext({ sjmoCode });
  params.set("newTrad", newTrad);
  params.set("syjIRColumnId", syjIRColumnId);
  return apiAdn.post(`/interactive-report/admin/new-column-trad?${params}`);
}

export function updateIRColumnTrad(sjmoCode: string, userLang: string, syjIRColumn: Pojo) {
  const params = getUIContext({ sjmoCode });
  params.set("userLang", userLang);
  return apiAdn.put(`/interactive-report/admin/update-column-trad?${params}`, syjIRColumn);
}

export function getIRByFilters(sjmoCode: string, view?: string, moduleId?: string) {
  const params = getUIContext({ sjmoCode });
  view && params.set("view", view);
  moduleId && params.set("moduleId", moduleId);
  return apiAdn.get(`/interactive-report/admin/get-by-filters?${params}`);
}
