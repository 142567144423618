import React, { Component, SyntheticEvent } from "react";
import { Input } from "composants/input/Input";
import { convertValue } from "utils/entities.utils";
import { t } from "utils/i18n";
import Scrollbars from "react-custom-scrollbars";
import memoize from "memoizee";
import "./filterableList.css";
import { Pojo } from "types/Galaxy";
import { Line } from "types/Component";
import { uuidv4 } from "utils/uuid.utils";

// Différence de taille entre le composant et la liste seule sans le composant de recherche
export const SEARCH_FIELD_SIZE = 50;

interface PojoFilterableListProps {
  pojos: Pojo[];
  fields?: string[];
  listHeight?: number | string;
  buildChildrenList(pojos: Pojo[]): JSX.Element;
}

interface FilterableListState {
  query: string | undefined;
}

function pojoToline(pojo: Pojo, fields: string[]): Line {
  return {
    infos: fields.map(field => {
      return pojo[field];
    })
  };
}

class PojoFilterableList extends Component<PojoFilterableListProps, FilterableListState> {
  state = {
    query: ""
  };

  filter = memoize((query: string, pojos: Pojo[]) => {
    if (query === null || query.length <= 0) {
      return pojos;
    }

    const filteredItems = pojos.filter(pojo => {
      let exist = false;

      const fields = this.props.fields ? this.props.fields : ["label"];
      const line = pojoToline(pojo, fields);

      if (query && line.infos) {
        line.infos.forEach(info => {
          exist =
            exist ||
            (info !== undefined &&
              info !== null &&
              info
                .toString()
                .toUpperCase()
                .includes(query.toUpperCase()));
        });
      }
      return exist;
    });

    return filteredItems;
  });

  render() {
    return (
      <>
        <Input
          id={uuidv4()}
          type="text"
          value={this.state.query}
          onChange={(e: SyntheticEvent<any>) => {
            const val = convertValue(e);
            this.setState({ query: val });
          }}
          className="search_field"
          placeholder={t("commun_recherche_composant")}
          autoComplete="off"
        />
        <Scrollbars
          autoHide
          style={{
            height: this.props.listHeight,
            border: "solid 2px #3298dc",
            borderRadius: "5px"
          }}
        >
          {this.props.buildChildrenList(this.filter(this.state.query, this.props.pojos))}
        </Scrollbars>
      </>
    );
  }
}

export default PojoFilterableList;
