import React, { FC, useEffect, useRef } from "react";
import classNames from "classnames";
import { t } from "utils/i18n";

/************ TYPES **************/
import {
  ProcessusDefinition,
  ProcessusDefinitionNature,
  ProcessusDefinitionNatureValue
} from "types/Processus";

import "composants/processus/ProcessusMenu.css";
import { Pojo } from "types/Galaxy";
import { track } from "tracking";
import ProcessusLink from "composants/processus/ProcessusLink";

interface ContextMenuPropsFn {
  onMiniExpertOpen: Function;
  xpos: number;
  ypos: number;
  definition: ProcessusDefinition[];
  origineTableName: string | null;
  columnName: string | null;
  genericEntity: Pojo | null;
  launchProcessFromRightClick: Function;
  launchEditionFromRightClick: Function;
  launchProcessFromGenericList: Function;
  forceNewTab: boolean;
  onShowInformation(): void;
  hideContextMenu(): void;
  launchNavigation(sjipId: string, newTab: boolean): void;
}

export const ContextMenu: FC<ContextMenuPropsFn> = props => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    document.addEventListener("click", props.hideContextMenu);
    return () => {
      document.removeEventListener("click", props.hideContextMenu);
    };
  }, [props.hideContextMenu]);

  /**
   * Fonction qui permet de créer les différentes parties du menu en fonction du filtre en paramètre
   */
  function createGroupByNature(type: ProcessusDefinitionNature) {
    if (!props.definition) {
      return null;
    }
    const group: ProcessusDefinition[] = props.definition.filter(
      (proc: ProcessusDefinition) => proc.type === type
    );

    if (group.length <= 0) {
      return null;
    }

    let label: string;
    switch (type) {
      case ProcessusDefinitionNatureValue.TRAITEMENT:
        label = t("commun_traitement");
        break;

      case ProcessusDefinitionNatureValue.NAVIGATION:
        label = t("commun_navigation");
        break;

      case ProcessusDefinitionNatureValue.EDITION:
        label = t("commun_editions");
        break;

      default:
        // si l'affectation n'existe pas, on ne render rien
        return null;
    }

    return (
      <div>
        <strong>{label}</strong>
        {createMenu(group)}
      </div>
    );
  }

  function createMenu(processes: ProcessusDefinition[]) {
    return processes.map(process => (
      <ProcessusLink
        key={process.id}
        definition={process}
        className="dropdown-item processmenu-hover-show-action"
        canDelayProcess
      />
    ));
  }

  function createMiniExpertAccess() {
    if (!props.genericEntity) {
      const label = t("commun_miniExpert");
      return (
        <a
          className="dropdown-item"
          onClick={(e: any) => {
            props.onMiniExpertOpen();
            track("contextmenu::miniexpert::open");
          }}
        >
          {label}
        </a>
      );
    }
    return null;
  }

  function createItemInfo() {
    return (
      <a
        className="dropdown-item"
        onClick={() => {
          props.onShowInformation();
          track("contextmenu::info:open");
        }}
      >
        Information
      </a>
    );
  }

  const isActive = classNames("dropdown", "is-active");

  return (
    <div
      className={isActive}
      style={{ position: "absolute", zIndex: 50, top: props.ypos, left: props.xpos }}
      id="contextMenu"
    >
      <div className="dropdown-menu" role="menu">
        <div ref={containerRef} className="dropdown-content">
          <div className="px-4 py-6">
            {createGroupByNature(ProcessusDefinitionNatureValue.TRAITEMENT)}
            {createGroupByNature(ProcessusDefinitionNatureValue.NAVIGATION)}
            {createGroupByNature(ProcessusDefinitionNatureValue.EDITION)}
            <div className="dropdown-divider" />
            {createMiniExpertAccess()}
            {createItemInfo()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContextMenu;
