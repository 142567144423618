import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import get from "lodash-es/get";
import memoize from "memoizee";

import { ReducerState } from "reducers";

/**************** ACTIONS *************/
import { addEntity } from "actions/actionEntities";
import { initGalaxy, setActive, resetGalaxyMainEntity } from "actions/galaxy.action";
import { updateContext, clearContext } from "actions/satellites";
import { initDatatable } from "actions/datatable.action";
import { changeFocusFromURL } from "actions/dashboard";

// octal components
import PanelGrid from "composants/dashboard/PanelGrid";

/***************** TYPES ***********************/
import { GalaxyInformation, Pojo } from "types/Galaxy";

/********************** API ***************************/
import "composants/css/component.css";

import {
  selectGalaxyInformation,
  selectIsGalaxyFetched,
  selectDashboardSelectedFocusCode
} from "selectors";

import EditionNotification from "../GlobalMessage/EditionNotification";
import { selectProcessusInProgress } from "selectors/processus.selectors";
import { selectIsDatatableInit, selectEntitiesFromTable } from "selectors/datatable.selectors";
import LoaderContainer from "composants/LoaderContainer";
import { GalaxieHeader } from "./GalaxieHeader";
import {
  GalaxieListenerContextProps,
  GalaxieListenerComponent,
  GalaxieListenerContext,
  GalaxieDatatableContext,
  GalaxieListenerCallbackContext
} from "./GalaxieContextListener";
import { RSQLFilterExpression, Operators, RSQLCriteria } from "rsql-criteria-typescript";
import { initRsqlCriteria } from "utils/query.utils";
import DragNDropContext from "composants/DragNDropContext";
import { GalaxyStateValue } from "reducers/modules/AppReducer";
import { LoadingGalaxie } from "composants/Loader";
import { findOne } from "api";
import { selectExpertMandatoryComponent } from "selectors/galaxy.selector";
import { ComponentState } from "types/Component";
import { addMessage } from "actions/messages";
import { Message } from "types/Message";
import { track } from "tracking";
import { ON_CLOSE_CREATOR_CALLBACK } from "constant/creator";
import { NavigateFunction, Location } from "react-router-dom";
import { ON_CLOSE_EXPERT_CALLBACK } from "constant/expert";
import { GalaxieContext, GalaxieContextProps } from "./GalaxieContext";

/**
 * On créé une fonction pour mémoriser le context de la galaxie
 * pour éviter de recréer un objet et donc re-render les consumer de notre context de galaxie
 */
const createStateForContext = memoize(
  (
    sjmoCode: string,
    mainEntityTableName: string,
    mainEntityId: string | null,
    mainEntityVersion: number,
    refreshListenerAndCallback: () => void
  ): GalaxieContextProps => {
    return {
      sjmoCode,
      mainEntityTableName,
      mainEntityId,
      mainEntityVersion,
      refreshListenerAndCallback
    };
  },
  { max: 1 }
);

const createStateListenerForContext = memoize(
  (register: Function) => {
    return {
      register
    } as GalaxieListenerContextProps;
  },
  { max: 1 }
);

const createStateListenerForDatatableContext = memoize(
  (register: Function) => {
    return {
      register
    } as GalaxieListenerContextProps;
  },
  { max: 1 }
);

interface GalaxyProps {
  currentState: GalaxyStateValue;
  galaxyInfo: GalaxyInformation;
  isDatatableSuiviInit: boolean;
  isInitialized: boolean;
  loading: boolean;
  inProgress: boolean;
  mainEntityVersion: number;
  userID?: string;
  selectedFocusCode: string | null;
  expertMandatoryComponent: ComponentState[];
  mainEntity: Pojo | null;
}

interface GalaxyFn {
  initGalaxy(sjmoCode: string, focus?: string): void;
  initDatatable(sjmoCode: string, tableName: string, ctrlKey: string, filter: string): void;
  updateContext(context: { tableName: string; id?: string; sjmoCode: string }): void;
  clearContext(sjmoCode: string): void;
  setActive(sjmoCode: string): void;
  resetGalaxyMainEntity(sjmoCode: string, tableName: string, mainEntityId: string): void;
  changeFocusFromURL(sjmoCode: string, fromURL: string | undefined): void;
  addEntity(sjmoCode: string, tableName: string, id: string, value: any, reset: boolean): void;
  addMessage(message: Message): void;
}

type GalaxyAllProps = GalaxyProps &
  GalaxyFn & {
    sjmoCode: string;
    params: Readonly<
      Partial<{
        code: string;
        id: any;
        focus: string;
      }>
    >;
    location: Location;
    searchParams: URLSearchParams;
    navigate: NavigateFunction;
    setSearchParams: (
      nextInit: any,
      navigateOptions?:
        | {
            replace?: boolean | undefined;
            state?: any;
          }
        | undefined
    ) => void;
  };

const URL_FOCUS_KEY = "galaxyFocus";

export const GALAXIE_DECISIONNEL = ["D", "U"];

class Galaxie extends Component<GalaxyAllProps> {
  private callbackInitDatatable: number | null = null;
  private listener: GalaxieListenerComponent[] = [];
  private listenerDatatable: GalaxieListenerComponent[] = [];
  private listenerCallback: (() => void)[] = [];
  private isPendingSave: boolean = false;

  get suiviOpen(): boolean {
    const urlsParams = new URLSearchParams(this.props.location.search);
    return urlsParams.get("suiviOpen") && urlsParams.get("suiviOpen") === "true" ? true : false;
  }

  get ids(): string[] {
    const urlsParams = new URLSearchParams(this.props.location.search);
    const idsString = urlsParams.get("ids");
    return idsString !== null ? idsString.split(",") : [];
  }

  get query(): string | null {
    const urlParams = new URLSearchParams(this.props.location.search);
    return urlParams.get("q");
  }

  get mainEntityId(): string | null {
    if (this.props.galaxyInfo && GALAXIE_DECISIONNEL.includes(this.props.galaxyInfo.galaxyType)) {
      return this.props.userID || null;
    } else {
      const id = this.props.params?.id;
      if (id) return decodeURIComponent(id);
      else return null;
    }
  }

  componentDidMount() {
    this.init();
    const urlParams = new URLSearchParams(this.props.location.search);
    if (
      this.props.isInitialized &&
      urlParams.get(URL_FOCUS_KEY) &&
      urlParams.get(URL_FOCUS_KEY) !== null &&
      urlParams.get(URL_FOCUS_KEY) !== this.props.selectedFocusCode
    ) {
      this.changeFocusFromNavigation();
    }

    if (this.mainEntityId) {
      findOne({
        tableName: this.props.galaxyInfo.tableName,
        id: this.mainEntityId,
        includeStyle: true
      }).then(res => {
        if (this.mainEntityId) {
          this.props.addEntity(
            this.props.sjmoCode,
            this.props.galaxyInfo.tableName,
            this.mainEntityId,
            res.data,
            true
          );
        }
      });

      this.props.updateContext({
        tableName: this.props.galaxyInfo.tableName,
        id: this.mainEntityId,
        sjmoCode: this.props.sjmoCode
      });
    }
    let event = `${this.props.sjmoCode}--galaxie-refresh`;
    window.addEventListener(event, this.refresh);
  }

  componentDidUpdate(prevProps: GalaxyAllProps) {
    if (this.props.sjmoCode !== prevProps.sjmoCode) {
      this.init();
    }

    if (
      this.props.params.id &&
      this.props.galaxyInfo &&
      (prevProps.params.id !== this.props.params.id ||
        this.props.galaxyInfo !== prevProps.galaxyInfo)
    ) {
      if (this.props.galaxyInfo && this.mainEntityId) {
        findOne({
          tableName: this.props.galaxyInfo.tableName,
          id: this.mainEntityId,
          includeStyle: true
        }).then(res => {
          if (this.mainEntityId) {
            this.props.addEntity(
              this.props.sjmoCode,
              this.props.galaxyInfo.tableName,
              this.mainEntityId,
              res.data,
              true
            );
          }
        });
        // Action qui spécifie que la main entity à changée
        // envoi le tableName et l'id de la nouvelle mainEntity pour une galaxie
        // Utilisé pour les satellite et la gestion des galaxie active
        this.props.updateContext({
          tableName: this.props.galaxyInfo.tableName,
          id: this.mainEntityId,
          sjmoCode: this.props.sjmoCode
        });
      }
    } else if (prevProps.params.id !== this.props.params.id) {
      // Action qui spécifie que la main entity de la galaxie courante à été vidée
      // Utilisé pour les satellite et la gestion des galaxie active
      this.props.clearContext(this.props.sjmoCode);
    }

    if (
      this.props.sjmoCode &&
      this.props.galaxyInfo &&
      this.props.galaxyInfo.galaxyType === "C" &&
      !this.props.isDatatableSuiviInit &&
      this.callbackInitDatatable === null
    ) {
      this.callbackInitDatatable = requestAnimationFrame(() => {
        if (this.props.sjmoCode && this.props.galaxyInfo && !this.props.isDatatableSuiviInit) {
          let suiviAdditionalClause: RSQLCriteria | undefined;
          if (this.ids.length > 0) {
            suiviAdditionalClause = initRsqlCriteria();
            suiviAdditionalClause.filters.and(
              new RSQLFilterExpression("id", Operators.In, this.ids)
            );
          }

          this.props.initDatatable(
            this.props.sjmoCode,
            this.props.galaxyInfo.tableName,
            this.props.galaxyInfo.galaxyDtCtrlkeySuivi,
            suiviAdditionalClause ? suiviAdditionalClause.build() : ""
          );
          this.callbackInitDatatable = null;
        }
      });
    }
  }

  componentWillUnmount() {
    let event = `${this.props.params.code}--galaxie-refresh`;
    window.removeEventListener(event, this.refresh);
  }

  changeFocusFromNavigation = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    const galaxyFocus = urlParams.get(URL_FOCUS_KEY);
    this.props.changeFocusFromURL(this.props.sjmoCode, galaxyFocus || undefined);
  };

  register = (node: GalaxieListenerComponent) => {
    this.listener.push(node);
    // on retourne une fonction qui va être appelé par le composant
    // lorsque celui ci va être unmount
    return () => {
      this.listener = this.listener.filter(l => l !== node);
    };
  };

  registerDatatable = (node: GalaxieListenerComponent) => {
    this.listenerDatatable.push(node);
    // on retourne une fonction qui va être appelé par le composant
    // lorsque celui ci va être unmount
    return () => {
      this.listenerDatatable = this.listenerDatatable.filter(l => l !== node);
    };
  };

  registerCallback = (node: () => void) => {
    this.listenerCallback.push(node);
    // on retourne une fonction qui va être appelé par le composant
    // lorsque celui ci va être unmount
    return () => {
      this.listenerCallback = this.listenerCallback.filter(l => l !== node);
    };
  };

  refresh = () => {
    if (this.props.galaxyInfo && this.mainEntityId) {
      this.props.resetGalaxyMainEntity(
        this.props.sjmoCode,
        this.props.galaxyInfo.tableName,
        this.mainEntityId
      );

      this.refetchDatatable();
      this.refreshListenerAndCallback();
    }
  };

  refetchDatatable = () => {
    if (this.listenerDatatable.length > 0) {
      for (let el of this.listenerDatatable) {
        if (el && el.refresh) {
          el.refresh();
        }
      }
    }
  };

  init = () => {
    const { sjmoCode } = this.props;
    if (!this.props.isInitialized) {
      // on récupère le focus privilégié par la navigation / utilisateur
      // c'est ce paramètre qui servira de focus par défaut.
      const urlParams = new URLSearchParams(this.props.location.search);
      this.props.initGalaxy(sjmoCode, urlParams.get(URL_FOCUS_KEY) || undefined);

      // Gère le cas particulier de l'initialisation d'une galaxie de type Univers ou décisionnel
      if (GALAXIE_DECISIONNEL.includes(this.props.galaxyInfo.galaxyType)) {
        // Gère le cas particulier
        this.props.updateContext({
          tableName: "personnel",
          id: this.mainEntityId ?? undefined,
          sjmoCode: this.props.sjmoCode
        });
      }
    } else if (this.mainEntityId) {
      this.props.updateContext({
        tableName: this.props.galaxyInfo.tableName,
        id: this.mainEntityId,
        sjmoCode: this.props.sjmoCode
      });
    }

    if (!this.mainEntityId) {
      this.props.clearContext(this.props.sjmoCode);
    }

    this.props.setActive(this.props.sjmoCode);
  };

  clearURLFocus = () => {
    const urlParams = new URLSearchParams(this.props.location.search);
    if (urlParams.has(URL_FOCUS_KEY)) {
      urlParams.delete(URL_FOCUS_KEY);
      this.props.navigate({ search: urlParams.toString() });
    }
  };

  changeMainEntity = (pojo: Pojo | null) => {
    const url = pojo
      ? `/page/${this.props.sjmoCode}/${encodeURIComponent(pojo.id)}`
      : `/page/${this.props.sjmoCode}`;
    this.props.navigate({ pathname: url, search: this.props.location.search });
  };

  changeMainEntityFromSuivi = (id: number | string | null) => {
    const url = id
      ? `/page/${this.props.sjmoCode}/${encodeURIComponent(id)}`
      : `/page/${this.props.sjmoCode}`;

    this.props.navigate({ pathname: url, search: this.props.location.search });
  };

  openCreator = (
    tableName: string,
    sjmoCode: string,
    contextTable: string,
    contextId: string | null,
    navigateOnClose: boolean,
    creatorSelectedFocusId?: string | null
  ) => {
    const params = new URLSearchParams();
    params.append("creatorTableName", tableName);
    params.append("creatorSjmoCode", sjmoCode);
    params.append("creatorContextTable", contextTable);
    params.append("navigateOnClose", navigateOnClose.toString());
    if (creatorSelectedFocusId) {
      params.append("focusId", creatorSelectedFocusId);
    }

    if (contextId !== null) {
      params.append("creatorContextId", contextId);
    }

    // Si on ouvre un creator depuis un panel (navigateOnClose = false)
    // On ajoute une fonction de refresh de la galaxie
    // Qui sera éxécutée dans le onClose du creator dans appRoute.tsx
    if (!navigateOnClose) {
      window[ON_CLOSE_CREATOR_CALLBACK] = () => this.refresh();
    }
    this.props.setSearchParams(params);
  };

  onCreatorOpen = () => {
    if (this.props.galaxyInfo) {
      this.openCreator(
        this.props.galaxyInfo.tableName,
        this.props.sjmoCode,
        this.props.galaxyInfo.tableName,
        this.mainEntityId,
        true
      );
    }
  };

  onCreatorOpenFromPanel = (creatorTableName: string, creatorSelectedFocus?: string | null) => {
    if (this.props.galaxyInfo) {
      this.openCreator(
        creatorTableName,
        this.props.sjmoCode,
        this.props.galaxyInfo.tableName,
        this.mainEntityId,
        false,
        creatorSelectedFocus
      );
    }
  };

  onOpenExpert = () => {
    window[ON_CLOSE_EXPERT_CALLBACK] = () => this.refresh();
  };

  onCloseCreator = () => {
    this.props.navigate(this.props.location.pathname);
  };

  onNavigate = (url: string) => {
    this.props.navigate(url);
  };

  openTraitementAvanceParams = (id: string) => {
    this.props.navigate(`${this.props.location.pathname}/process/${id}`, {
      state: {
        origin: this.props.location.pathname
      }
    });
  };

  onOpenSuivi = () => {
    let suiviUrl = "";
    if (this.suiviOpen) {
      suiviUrl = `${this.props.location.pathname}`;
    } else {
      suiviUrl = `${this.props.location.pathname}?suiviOpen=true`;
    }

    track("galaxy::open::table-suivi");
    this.props.navigate(suiviUrl);
  };

  onAfterProcessListeGeneric = () => {
    this.refreshListenerAndCallback();
    this.refetchDatatable();
  };

  refreshListenerAndCallback = () => {
    if (this.listener.length > 0) {
      for (let el of this.listener) {
        if (el && el.refresh) {
          el.refresh();
        }
      }
    }
    if (this.listenerCallback.length > 0) {
      for (let el of this.listenerCallback) {
        el && el();
      }
    }
  };

  onAfterDeleteDatatable = () => {
    this.refreshListenerAndCallback();
  };

  renderHeader = () => {
    return (
      <GalaxieHeader
        sjmoCode={this.props.sjmoCode}
        mainEntityId={this.mainEntityId}
        id={this.mainEntityId}
        ids={this.ids}
        query={this.query}
        suiviOpen={this.suiviOpen}
        galaxyInfo={this.props.galaxyInfo}
        changeMainEntity={this.changeMainEntity}
        changeMainEntityFromSuivi={this.changeMainEntityFromSuivi}
        onCreatorOpen={this.onCreatorOpen}
        onOpenSuivi={this.onOpenSuivi}
        onOpenExpert={this.onOpenExpert}
        refresh={this.refresh}
        onAfterSaveProcess={this.refresh}
        clearURLFocus={this.clearURLFocus}
        navigate={this.props.navigate}
      />
    );
  };

  render() {
    const { galaxyInfo } = this.props;

    // on affiche rien au début
    if (this.props.currentState === "INIT") {
      return null;
    }

    if (this.props.currentState === "FETCHING") {
      return (
        <div className="columns is-multiline is-centered" style={{ paddingTop: "2em" }}>
          <div className="column is-3">
            <LoadingGalaxie />
          </div>
          <div className="column is-12 has-text-centered is-size-3">
            <Trans i18nKey="commun_construction_de_galaxie">
              Construction de votre galaxie en cours...
            </Trans>
          </div>
        </div>
      );
    }

    if (this.props.currentState === "ERROR") {
      return (
        <div className="columns is-multiline is-centered" style={{ paddingTop: "2em" }}>
          <div className="column is-3">
            {/* TODO : avoir une icone SVG pour une erreur de galaxie */}
            <LoadingGalaxie />
          </div>
          <div className="column is-12 has-text-centered is-size-3">
            <Trans i18nKey="commun_erreur_init_galaxie">
              Erreur lors de l'initialisation de la galaxie...
            </Trans>
          </div>
        </div>
      );
    }

    return (
      <GalaxieListenerContext.Provider value={createStateListenerForContext(this.register)}>
        <GalaxieDatatableContext.Provider
          value={createStateListenerForDatatableContext(this.registerDatatable)}
        >
          <GalaxieListenerCallbackContext.Provider value={this.registerCallback}>
            <GalaxieContext.Provider
              value={createStateForContext(
                this.props.sjmoCode,
                get(this.props, "galaxyInfo.gensearch.joinTableName", undefined),
                this.mainEntityId,
                this.props.mainEntityVersion,
                this.refresh
              )}
            >
              <LoaderContainer loading={this.props.loading}>
                <EditionNotification refresh={this.refresh} />
                {this.renderHeader()}
                <DragNDropContext>
                  <PanelGrid
                    sjmoCode={this.props.sjmoCode}
                    tableName={galaxyInfo ? galaxyInfo.tableName : ""}
                    currentMainEntityId={this.mainEntityId}
                    onAfterSaveDatatable={this.refreshListenerAndCallback}
                    onAfterDeleteDatatable={this.onAfterDeleteDatatable}
                    onOpenCreator={this.onCreatorOpenFromPanel}
                    onAfterProcessListeGeneric={this.onAfterProcessListeGeneric}
                  />
                </DragNDropContext>
              </LoaderContainer>
            </GalaxieContext.Provider>
          </GalaxieListenerCallbackContext.Provider>
        </GalaxieDatatableContext.Provider>
      </GalaxieListenerContext.Provider>
    );
  }
}

function mapStateToProps(state: ReducerState, { sjmoCode, params }: GalaxyAllProps): GalaxyProps {
  const galaxyInfo = selectGalaxyInformation(state, sjmoCode);
  const isInitialized = selectIsGalaxyFetched(state, sjmoCode);
  const loading = galaxyInfo ? galaxyInfo.loading : false;
  const inProgress = selectProcessusInProgress(state);
  const tableName = galaxyInfo ? galaxyInfo.tableName : undefined;

  const userSettings = state.userSettings;

  const mainEntityVersion: number = get(
    state.entities,
    [sjmoCode, tableName, params.id, "version"],
    0
  );

  const isDatatableSuiviInit = galaxyInfo
    ? selectIsDatatableInit(state, {
        sjmoCode,
        ctrlKey: galaxyInfo.galaxyDtCtrlkeySuivi
      })
    : false;

  const selectedFocusCode = selectDashboardSelectedFocusCode(state, sjmoCode);
  const expertMandatoryComponent: ComponentState[] = selectExpertMandatoryComponent(
    state,
    sjmoCode
  );
  const mainEntity: Pojo | null = galaxyInfo
    ? selectEntitiesFromTable(state, { sjmoCode: sjmoCode, tableName: galaxyInfo.tableName })[
        params.id
      ]
    : null;

  return {
    currentState: state.galaxyState[sjmoCode] || "INIT",
    isInitialized,
    galaxyInfo,
    isDatatableSuiviInit,
    loading,
    inProgress,
    mainEntityVersion,
    userID: userSettings.id,
    selectedFocusCode,
    expertMandatoryComponent,
    mainEntity
  };
}

export default connect<GalaxyProps, GalaxyFn>(mapStateToProps, {
  initGalaxy,
  initDatatable,
  updateContext,
  clearContext,
  setActive,
  resetGalaxyMainEntity,
  changeFocusFromURL,
  addEntity,
  addMessage
})(Galaxie);
