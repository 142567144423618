/**
 * Track the characters generated from keyboard input
 * @param  {Array} history List of (max 3) characters
 * @param  {KeyDownEvent} e Synthetic keyboard event from draftjs' `keyBindingFn`
 * @return {Array} The adjusted history
 */
export default function trackCharacters(
  history: string[] = [],
  e: React.KeyboardEvent<{}>
): string[] {
  // Keep history to <= 3 items because we only need that much to determine
  // whether a list is being indicated
  if (history.length > 2) {
    history = history.slice(1);
  } else {
    history = history.slice();
  }

  // Map the keyCodes to characters we care about
  let character: string | undefined = e.key;
  if (!character) {
    character = String.fromCharCode(e.keyCode);
  }
  history.push(character);
  return history;
}
