import { getUIContext, apiAdn } from "./common";
import { AxiosPromise } from "axios";
import { Pojo } from "types/Galaxy";

export function getCreatorDefinition(sjmoCode: string, focusId: string): AxiosPromise<Pojo[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/creator/definition?${params}&tableId=${focusId}`);
}

export function getPreparedCreatorColumns(
  sjmoCode: string,
  creatorId: string
): AxiosPromise<Pojo[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/creator/syjCreator/${creatorId}?${params}`);
}

export function getPreparedNewCreatorColumns(
  sjmoCode: string,
  tableName: string
): AxiosPromise<Pojo[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/creator/syjCreator?${params}&tableName=${tableName}`);
}

export function getCreatorAssociations(
  sjmoCode: string,
  focusId: string
): AxiosPromise<{ GLOBAL: Pojo[]; ROLE: Pojo[]; USER: Pojo[] }> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/creator/associations?${params}&focusId=${focusId}`);
}
