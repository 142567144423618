import { ReducerState } from "reducers";
import { ComponentState } from "types/Component";
import { mapToUnorderedList } from "utils/entities.utils";

export const selectFavoris = (state: ReducerState) => {
  return state.app.favoris;
};

export const selectExpertMandatoryComponent = (
  state: ReducerState,
  sjmoCode: string
): ComponentState[] => {
  const compos: ComponentState[] = [];
  const definition = mapToUnorderedList(state.expert.expertDefinitions);

  definition.forEach(tab => {
    if (tab.header) {
      compos.push(...tab.header);
    } else {
      tab.groups.forEach(group => {
        compos.push(...group.compos);
      });
    }
  });

  return compos.filter(c => c.mandatory);
};
