import React, { Component, SyntheticEvent, KeyboardEvent, MouseEvent, CSSProperties } from "react";
import { GridCellProps, SectionRenderedParams } from "react-virtualized";
import { arrayMove } from "react-sortable-hoc";
import { DraggableData } from "react-draggable";
import memoize from "memoizee";
import classNames from "classnames";

import get from "lodash-es/get";

import { Pojo, LoadingPojo } from "types/Galaxy";
import { ComponentState, FilterBarDefinition } from "types/Component";
import { DatatableFocusState } from "types/Focus";

import { SimpleComponent, TypeSimpleComponent } from "enum";
import { AutocompleteProps } from "../autocomplete/AutoComplete";

import Toolbar, { ToolbarButtonOverride } from "./Toolbar";
import { DATATABLE_EXPAND, DATATABLE_SELECTION, DATATABLE_ACTION } from "./staticComponent";

import { ComposantAllProps } from "../common";

import { convertValue } from "utils/entities.utils";

import "react-virtualized/styles.css";
import "./GenericDatatable.css";
import { SATELLITES } from "constant/satellite";
import { Button } from "../button";
import { InteractionReducerParameter } from "reducers/modules/InteractionReducer";
import SatelliteMenu from "../satellite/SatelliteMenu";
import { Dropdown, DropdownButton, DropdownMenu } from "../DropDown/Dropdown";
import { getReadonlyValue, getOptionsByType } from "utils/component.utils";

import FilterBarComponent from "./FilterBarComponent";
import { FilterBar } from "types/Search";
import { TextEditorProps, DatatableEditor } from "composants/RichTextEditor/TextEditor";
import { LoadableLoader } from "composants/Loader";
import {
  DatatableState,
  DatatableSort,
  LOCAL_STORAGE_GRID_PADDING,
  GridPaddingType,
  reducerState,
  DatatableActionAll,
  gridPadding,
  gridPaddingComponentSize
} from "./table/datatableBehavior";

import Table from "./Table";
import { TableColumn, ColumnCalculateProperties, CustomRenderHeaderCell } from "./TableColumn";
import {
  OelFilter,
  FilterProps,
  CheckboxFilter,
  SelectFilter,
  AutocompleteFilter,
  InputFilter
} from "./TableFilter";
import { SortableHeaderItem } from "./SortableElements";
import { Fa } from "composants/Icon";
import BreakRowDialog from "./dialog/BreakRowDialog";
import { useDrop } from "react-dnd";

import { Trans } from "react-i18next";
import { Field } from "composants/form";
import { Menu } from "composants/DropDown/Menu";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import { openSatellite } from "utils/navigation.utils";
import { initRsqlCriteria } from "utils/query.utils";
import { Operators, RSQLFilterExpression } from "rsql-criteria-typescript";
import { useNavigate } from "react-router-dom";

type DropAreaProps = {
  dropType: string;
  id: string;
  isOver: boolean;
  onDrop: (pojo: Pojo) => void;
};
function DropArea({ dropType, isOver, children }: React.PropsWithChildren<DropAreaProps>) {
  const [, drop] = useDrop(() => ({
    accept: dropType,
    drop: (item: DropAreaProps, monitor) => {
      if (!monitor) {
        return;
      }
      if (item.onDrop) {
        item.onDrop((monitor.getItem() as any).node);
      }

      return {
        laneId: item.id
      };
    },

    hover: (_props, monitor) => {
      if (!monitor) {
        return;
      }

      return monitor.isOver();
    }
  }));

  return (
    <div
      ref={drop}
      style={{
        width: "100%",
        height: "100%"
      }}
    >
      {children}
      {isOver && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            boxShadow: "hsl(217, 60%, 80%) 0px 0px 6px 1px inset"
          }}
        />
      )}
    </div>
  );
}

const GenericMarkdownDisplay = React.lazy(() =>
  import("composants/genericDisplay/GenericMarkdownDisplay")
);

export type LoadMoreData = (
  params: { startIndex: number; stopIndex: number },
  extra: {
    filters?: Record<string, any>;
    sort?: Record<string, DatatableSort>;
  }
) => Promise<any>;
type OnRowsRendered = (params: { startIndex: number; stopIndex: number }) => void;

export interface GenericDatatableProps {
  sjmoCode: string;
  tableName: string;
  tableCtrlKey: string;
  dirty?: boolean;
  loading?: boolean;
  focus?: DatatableFocusState[];
  selectedFocus?: string;
  originalColumns: ComponentState[];
  entities: { [key: number]: Pojo | LoadingPojo | undefined };
  totalRecords: number;
  selectedRows?: number[];
  selectionActive?: boolean;
  wviState?: { [id: number]: Record<string, string> };
  columnWidthName?: string;
  toolbarButtonVisibility?: ToolbarButtonOverride;
  showActionColumn?: boolean;
  satellites: string[];
  filters: FilterBarDefinition[];
  filterVisible: boolean;
  // faut-il distinguer les entités en new
  flagDirtyEntities: boolean;
  nodeType?: string;
  filterBar: FilterBar;
  breakColumns: string[];
  canDrop?: boolean;
  satellitesExist?: Record<string, boolean>;
  onChangeFilterBar?: (name: string, value: string | null) => void;
  onRowClick?: (entity: Pojo) => void;
  // function
  onChange?: (id: number | string, ctrlKey: string) => (event: SyntheticEvent<any>) => void;
  onValueChange?: (id: number | string, ctrlKey: string) => (value: string) => void;
  onBlur?: (id: number | string, ctrlKey: string) => (event: SyntheticEvent<any>) => void;
  onItemChange?: (id: number | string, ctrlKey: string) => (selectedItem: Pojo) => void;
  getInteractionWhenDatatable?: (
    id: string,
    rowIndex: number
  ) => Promise<InteractionReducerParameter>;
  contextMenu?: (id: number | string, ctrlKey: string) => (event: MouseEvent<any>) => void;
  loadMoreData: LoadMoreData;
  onChangeBreakColumns?(breakColumns: string[]): Promise<void>;
  updateSearch(filters?: Record<string, any>, sort?: Record<string, DatatableSort>): Promise<void>;
  addRow?(): Promise<any>;
  addRowBottom?(): Promise<any>;
  saveRows?(): Promise<any>;
  deleteRow?(index: number): void;
  deleteRows?(): Promise<any>;
  onFocusChange?(selected: string): void;
  onFocusSave?(columns: ComponentState[]): void;
  onRazFocusPerso?(): void;
  onRowSelect?(rowIndex: number | "ALL"): void;
  onRowUnselect?(rowIndex: number | "ALL"): void;
  onRowExpand?(id: number | string): Promise<string>;
  onExportPdf?(): void;
  onExportExcel?(): void;
  onDrop?(pojo: Pojo): void;
}

function buttonVisibility(toolbarValue?: boolean, focusValue?: boolean) {
  if (toolbarValue !== undefined) {
    return toolbarValue;
  }
  return focusValue !== undefined ? focusValue : true;
}

function executeNavigationMove(tableCtrlKey: string, rowIndex: number, colIndex: number) {
  const element = document.querySelector<HTMLInputElement>(
    `[data-navigation-key='${tableCtrlKey}'][data-navigation-row='${rowIndex}'][data-navigation-column='${colIndex}']`
  );

  if (element) {
    element.focus();
  }
}

export class GenericDatatable extends Component<GenericDatatableProps, DatatableState> {
  static defaultProps = {
    filters: [],
    filterVisible: false,
    filterBar: {
      filterBarId: null,
      startDate: null,
      endDate: null
    },
    breakColumns: []
  };

  get defaultColumnName(): string {
    return "contentSize";
  }

  get columnWidthName(): string {
    return this.props.columnWidthName || this.defaultColumnName;
  }

  get columnsWithoutInternal() {
    let startIndex = 0;
    if (this.focus && this.focus.rowExpandable) {
      startIndex++;
    }

    if (this.props.selectionActive) {
      startIndex++;
    }

    if (this.props.showActionColumn) {
      startIndex++;
    }

    return this.state.columns.slice(startIndex);
  }

  get gridPadding(): number {
    return gridPadding(this.state.gridPadding);
  }

  get gridPaddingComponentSize() {
    return gridPaddingComponentSize(this.state.gridPadding);
  }

  get focus() {
    return this.props.focus
      ? this.props.focus.find(f => f.focusId === this.props.selectedFocus)
      : null;
  }

  get focusClassName(): string | undefined {
    return get(this.focus, "cssClass");
  }

  calculateProperties = memoize(
    (
      entity: Pojo | undefined,
      col: ComponentState,
      workingCompo: string,
      rowIndex: number,
      _waitForInitialization: boolean
    ) => {
      const {
        labelSize,
        label,
        typeCompo,
        contentSize,
        mandatory,
        readOnly,
        disabled,
        joinTableName,
        joinListFields,
        joinDisplayedFields,
        additionalClause,
        sortClause,
        compoVisible,
        isNumber,
        wvi,
        defaultValue,
        hasLov,
        ...restProps
      } = col;

      const entityID = entity ? entity.id || entity.uuid : "" + rowIndex;
      const oelStyle = entity && entity._style ? { style: entity._style[col.column] } : ({} as any);

      const propsGS: Partial<AutocompleteProps> =
        workingCompo === "GS" || workingCompo === "GSV" || workingCompo === "GSVL"
          ? {
              joinTableName,
              joinListFields,
              additionalClause,
              sortClause,
              onItemChange: this.onItemChange && this.onItemChange(entityID, col.column),
              controlProps: { expanded: true },
              sjmoCode: this.props.sjmoCode,
              parent: {
                tableName: this.props.tableName,
                ctrlKey: this.props.tableCtrlKey,
                entityId: entityID
              },
              style: { width: "100%" },
              styleInput: oelStyle.style,
              hasLov
            }
          : {};

      const propsSelectComponent =
        workingCompo === "SD"
          ? {
              ...restProps,
              required: mandatory
            }
          : {};

      const propsEditorComponent: Partial<TextEditorProps> =
        workingCompo === "ED"
          ? {
              toolbar: "HOVER",
              onValueChange: this.onValueChange && this.onValueChange(entityID, col.column)
            }
          : {};

      const options = getOptionsByType(col);

      const colIndex = this.state.columns.findIndex(c => c.column === col.column);
      const onKeyDown =
        colIndex !== -1 && rowIndex !== -1
          ? this.onKeyDownSelectedComponent(rowIndex, colIndex)
          : undefined;

      const propsSelected: Partial<ComposantAllProps & AutocompleteProps> = {
        ...oelStyle,
        ...propsGS,
        ...propsSelectComponent,
        ...propsEditorComponent,
        ...options,
        readOnly: getReadonlyValue(readOnly, entity ? entity.version : null),
        disabled: getReadonlyValue(disabled, entity ? entity.version : null),
        size: this.gridPaddingComponentSize,
        onChange: this.onChange && this.onChange(entityID, col.column),
        onBlur: this.onBlur && this.onBlur(entityID, col.column),
        onContextMenu: this.contextMenu && this.contextMenu(entityID, col.column),
        onKeyDown,
        "data-navigation-key": this.props.tableCtrlKey,
        "data-navigation-row": rowIndex,
        "data-navigation-column": colIndex,
        id: `${col.column}_${entityID}`
      };

      return propsSelected;
    }
  );

  calculateSelectedRowStyle = memoize(
    (isApplyLeft: boolean, isApplyRight: boolean, isApplyTop: boolean, isApplyBottom: boolean) => {
      const borderStyleApplied = "1px solid #3273dc";

      return {
        borderLeft: isApplyLeft ? borderStyleApplied : undefined,
        borderRight: isApplyRight ? borderStyleApplied : undefined,
        borderTop: isApplyTop ? borderStyleApplied : undefined,
        borderBottom: isApplyBottom ? borderStyleApplied : undefined
      } as CSSProperties;
    },
    { primitive: true }
  );

  onItemChange = this.props.onItemChange
    ? memoize(this.props.onItemChange, {
        primitive: true
      })
    : undefined;
  onChange = this.props.onChange
    ? memoize(this.props.onChange, {
        primitive: true
      })
    : undefined;

  onValueChange = this.props.onValueChange
    ? memoize(this.props.onValueChange, { primitive: true })
    : undefined;
  onBlur = this.props.onBlur ? memoize(this.props.onBlur, { primitive: true }) : undefined;
  contextMenu = this.props.contextMenu
    ? memoize(this.props.contextMenu, {
        primitive: true
      })
    : undefined;

  onSortChange = memoize(
    (col: string, sort: DatatableSort) => (e: MouseEvent<HTMLAnchorElement>) => {
      this.dispatch(
        {
          type: "SORT_CHANGE",
          payload: { col: col, sort }
        },
        () => {
          this.recomputeHeader();
          this.refresh();
        }
      );
    }
  );

  onResize = memoize(
    (col: string, datatableWidth: number) => (e: any, { deltaX, lastX, x }: DraggableData) => {
      const indexCol = this.state.columns.findIndex(column => column.column === col);
      const percentDelta = deltaX / datatableWidth;

      const prevWidth = get(this.state.columns, [indexCol, this.columnWidthName]);
      const widthAdd = percentDelta * datatableWidth;

      const newWidth = prevWidth + widthAdd;
      if (!isNaN(newWidth)) {
        this.dispatch(
          {
            type: "RESIZE_CHANGE",
            payload: { indexCol, newWidth, columnWidthName: this.columnWidthName }
          },
          this.recomputeGrids
        );
      }
    }
  );

  handleSelection = memoize(
    (rowIndex: number | "ALL") => () => {
      if (
        this.props.onRowSelect &&
        this.props.onRowUnselect &&
        this.props.selectedRows !== undefined
      ) {
        if (rowIndex === "ALL") {
          if (this.props.selectedRows.length === this.props.totalRecords) {
            this.props.onRowUnselect(rowIndex);
          } else {
            this.props.onRowSelect(rowIndex);
          }
          // this.recomputeGrids();
        } else {
          if (this.props.selectedRows.indexOf(rowIndex) === -1) {
            this.props.onRowSelect(rowIndex);
          } else {
            this.props.onRowUnselect(rowIndex);
          }
          // this.recomputeGrids({ rowIndex, columnIndex: 0 });
        }
      }
    },
    { primitive: true }
  );

  onKeyDownSelectedComponent = memoize(
    (rowIndex: number, columnIndex: number) => (event: KeyboardEvent<HTMLInputElement>) => {
      // on ajoute avec ctrl+Insert
      if (event.ctrlKey && event.key === "Insert") {
        this.onAdd();
        return;
      }

      // si jamais on appuie sur ctrl+entrer, on lance une sauvegarde
      if (event.ctrlKey && event.key === "Enter") {
        event.target.dispatchEvent(new Event("blur"));
        this.props.saveRows && this.props.saveRows();
        return;
      }

      // on supprime avec ctrl+Delete/Suppr
      if (event.ctrlKey && event.key === "Delete") {
        this.props.deleteRow && this.props.deleteRow(rowIndex);
        return;
      }

      // on inverse le behavior du standard HTML.
      // Cela veut dire que si ctrlKey est actif en même temps, on laisse le navigateur gérer l'event
      // donc dans le cas d'un input type number, `ctrl+ArrowUp  permet d'incrémenter le nombre.
      if (event.ctrlKey) {
        // si jamais on appuie également sur Control, on souhaite l'ancien behavior
        return;
      }

      let row: number = -1;
      let column: number = -1;

      let authorizeNewRowAtTheEnd = this.props.addRowBottom !== undefined;

      if (event.key === "ArrowDown") {
        let maxRow = authorizeNewRowAtTheEnd
          ? this.props.totalRecords
          : this.props.totalRecords - 1;

        row = Math.min(rowIndex + 1, maxRow);
        column = columnIndex;
        requestAnimationFrame(() => {
          this.onContextualizeWhenArrowUpDown(row);
        });

        // TODO
      } else if (event.key === "ArrowUp") {
        row = Math.max(rowIndex - 1, 0);
        column = columnIndex;
        requestAnimationFrame(() => {
          this.onContextualizeWhenArrowUpDown(row);
        });
      }

      if (row !== -1 && column !== -1) {
        event.preventDefault();
        if (row === this.props.totalRecords && this.props.addRowBottom) {
          this.props.addRowBottom().then(() => {
            requestAnimationFrame(() => {
              const firstColumn = this.state.columns.length - this.props.originalColumns.length;
              executeNavigationMove(this.props.tableCtrlKey, row, firstColumn);
            });
          });
        } else {
          executeNavigationMove(this.props.tableCtrlKey, row, column);
        }
      }
    },
    { primitive: true }
  );

  onRowExpand = memoize(
    (id: string) => () => {
      this.dispatch({ type: "TOGGLE_EXPAND_ROW", payload: { id } }, () => {
        if (
          this.props.onRowExpand &&
          this.state.rowExpand[id] === true &&
          id !== null &&
          id !== undefined
        ) {
          this.props
            .onRowExpand(id)
            .then(result => {
              this.dispatch({ type: "ROW_EXPAND_DATA_SUCCESS", payload: { id, result } });
            })
            .catch(() => {
              this.dispatch({ type: "ROW_EXPAND_DATA_ERROR", payload: { id } });
            })
            .then(() => this.recomputeGrids());
        } else {
          this.recomputeBody();
        }
      });
    },
    { primitive: true }
  );

  private tableRef = React.createRef<Table>();
  private dropArea: any = null;

  constructor(props: GenericDatatableProps) {
    super(props);

    // on initialize avec state = undefined pour utiliser la valeur par défaut de notre state
    this.state = reducerState(undefined, { type: "INIT_TABLE" });
  }

  dispatch = (action: DatatableActionAll, callback?: () => void) => {
    this.setState(currentState => reducerState(currentState, action), callback);
  };

  componentDidMount() {
    const { originalColumns } = this.props;

    if (localStorage.getItem(LOCAL_STORAGE_GRID_PADDING) === null) {
      localStorage.setItem(LOCAL_STORAGE_GRID_PADDING, "small");
    }

    if (originalColumns.length > 0) {
      this.updateInternalColumns();
    }
  }

  componentDidUpdate(prevProps: GenericDatatableProps) {
    if (prevProps.originalColumns !== this.props.originalColumns) {
      this.updateInternalColumns();
      this.updateDefaultFilterVisibility();
    }
  }

  onFilterChange = (col: string, value: any) => {
    this.dispatch({ type: "FILTER_CHANGE", payload: { col, value } }, () => {
      this.recomputeHeader();

      const currentColumn = this.state.columns.find(column => column.column === col);
      if (currentColumn) {
        if (
          currentColumn.typeCompo === TypeSimpleComponent.SYS_DOMAINE ||
          currentColumn.typeCompo === TypeSimpleComponent.CHECKBOX
        ) {
          this.refresh();
        }
      }
    });
  };

  getDefaultSelectedFilter = (filters: FilterBarDefinition[]) => {
    const priv = filters.filter(f => f.privilegie);
    const filterBar = {
      filterBarId: priv.length > 0 ? priv[0].filterBarId : null,
      filterBarStart: null,
      filterBarEnd: null
    };

    return filterBar;
  };

  updateDefaultFilterVisibility = () => {
    this.dispatch({
      type: "SET_VISIBILITY_SEARCH",
      payload: this.props.filterVisible
    });
  };

  recomputeGridPadding = (size: GridPaddingType) => {
    this.dispatch({ type: "CHANGE_GRID_PADDING", payload: size }, () => {
      localStorage.setItem(LOCAL_STORAGE_GRID_PADDING, size);
      this.calculateProperties.clear();
      this.recomputeGrids();
    });
  };

  onClearFilter = () => {
    this.props.onChangeFilterBar && this.props.onChangeFilterBar("filterBarId", null);
    this.dispatch({ type: "CLEAR_SORT_AND_FILTER" }, () => {
      this.recomputeHeader();
      this.refresh();
    });
  };

  onContextualizeWhenArrowUpDown = (row: number) => {
    this.onRowClick(row);
  };

  updateInternalColumns = () => {
    let columns = [];

    if (this.focus && this.focus.rowExpandable) {
      columns.push(DATATABLE_EXPAND.COMPONENT);
    }

    if (this.props.selectionActive) {
      columns.push(DATATABLE_SELECTION.COMPONENT);
    }

    if (this.props.showActionColumn) {
      columns.push(DATATABLE_ACTION.COMPONENT);
    }

    columns = columns.concat(this.props.originalColumns.filter(col => col.compoVisible === true));
    this.dispatch({ type: "UPDATE_COLUMNS", payload: columns });
  };

  public recomputeHeader = (params?: { columnIndex: number; rowIndex: number }) => {
    if (this.tableRef.current) {
      this.tableRef.current.recomputeHeader(params);
    }
  };

  public recomputeBody = (params?: { columnIndex: number; rowIndex: number }) => {
    if (this.tableRef.current) {
      this.tableRef.current.recomputeBody(params);
    }
  };

  public scrollTop = () => {
    if (this.tableRef.current) {
      this.tableRef.current.scrollTop();
    }
  };

  public resetInfiniteLoader = (autoReload?: boolean) => {
    if (this.tableRef.current) {
      this.tableRef.current.resetInfiniteLoader(autoReload);
    }
  };

  public recomputeGrids = (params?: { columnIndex: number; rowIndex: number }) => {
    if (this.tableRef.current) {
      this.tableRef.current.recomputeHeader(params);
      this.tableRef.current.recomputeBody(params);
    }
  };

  public reset = () => {
    if (this.tableRef.current) {
      this.tableRef.current.recomputeGrids();
      this.tableRef.current.resetInfiniteLoader();
    }
  };

  onReorderColumnEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
    this.dispatch(
      { type: "UPDATE_COLUMNS", payload: arrayMove(this.state.columns, oldIndex, newIndex) },
      this.recomputeGrids
    );
  };

  toggleSearch = () => {
    this.dispatch({ type: "TOGGLE_SEARCH" }, this.recomputeGrids);
  };

  saveFocus = () => {
    this.props.onFocusSave && this.props.onFocusSave(this.columnsWithoutInternal);
  };

  onRazFocusPerso = () => {
    this.props.onRazFocusPerso && this.props.onRazFocusPerso();
  };

  refresh = () => {
    this.props
      .updateSearch(
        Object.keys(this.state.filterValues).length > 0 ? this.state.filterValues : undefined,
        Object.keys(this.state.sortValues).length > 0 ? this.state.sortValues : undefined
      )
      .then(() => {
        this.scrollTop();
        this.resetInfiniteLoader(true);
        this.props.onRowUnselect && this.props.onRowUnselect("ALL");
      });
  };

  onKeyDownFilterInput = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      this.refresh();
    }
  };

  onSectionRendered = (
    { rowStartIndex, rowStopIndex }: SectionRenderedParams,
    onRowsRendered: OnRowsRendered
  ) => {
    const startIndex = rowStartIndex;
    const stopIndex = rowStopIndex;

    onRowsRendered({ startIndex, stopIndex });
  };

  onRowClick = (index: number) => {
    const entity = this.props.entities[index];
    if (this.props.onRowClick && entity && entity !== "LOADING_POJO") {
      this.props.onRowClick(entity);
      this.dispatch({
        type: "SET_ROW_CLICK",
        payload: index
      });
    }
  };

  loadMoreData = (indexes: { startIndex: number; stopIndex: number }) => {
    return this.props.loadMoreData(indexes, {
      filters: this.state.filterValues,
      sort: this.state.sortValues
    });
  };

  renderSelection = (rowIndex: number, isRowSelected: boolean) => {
    return (
      <input
        tabIndex={-1}
        style={{
          width: this.gridPaddingComponentSize === "small" ? 15 : 20,
          height: this.gridPaddingComponentSize === "small" ? 15 : 20,
          cursor: "pointer"
        }}
        type="checkbox"
        checked={isRowSelected}
        onChange={this.handleSelection(rowIndex)}
      />
    );
  };

  rowExpandRenderer = (expandedProps: GridCellProps) => {
    const entity = this.props.entities[expandedProps.rowIndex];
    const value =
      entity !== undefined && entity !== "LOADING_POJO"
        ? this.state.rowExpandData[entity.id || entity.uuid] || ""
        : "";

    return (
      <div key={expandedProps.key} style={expandedProps.style}>
        <div style={{ width: "100%", height: "100%", overflow: "scroll", padding: "1em" }}>
          <React.Suspense fallback={<LoadableLoader />}>
            <GenericMarkdownDisplay value={value} />
          </React.Suspense>
        </div>
      </div>
    );
  };

  componentProperties: ColumnCalculateProperties = (
    entity,
    columnProps,
    rowIndex,
    waitForInitialization
  ) => {
    const col = columnProps.column;

    // calcul à mettre en cache

    const properties = this.calculateProperties(
      entity !== "LOADING_POJO" ? entity : undefined,
      col,
      col.typeCompo,
      rowIndex,
      waitForInitialization
    );

    // calcul à ne pas mettre en cache

    const entityId = get(entity, ["id"]) || get(entity, ["uuid"]);
    const wviStateField = get(this.props.wviState, [entityId, col.column]);

    properties.wviState = wviStateField;

    return properties;
  };

  selectedFilterChange = (e: SyntheticEvent) => {
    this.props.onChangeFilterBar && this.props.onChangeFilterBar("filterBarId", convertValue(e));
    // this.dispatch(
    //   {
    //     type: "UPDATE_FILTER_BAR",
    //     payload: { name: "filterBarId", value: convertValue(e) }
    //   },
    //   () => this.refresh()
    // );
  };

  startDateChange = (e: SyntheticEvent) => {
    this.props.onChangeFilterBar && this.props.onChangeFilterBar("startDate", convertValue(e));
    // this.dispatch(
    //   {
    //     type: "UPDATE_FILTER_BAR",
    //     payload: { name: "filterBarStart", value: convertValue(e) }
    //   },
    //   () => this.refresh()
    // );
  };

  endDateChange = (e: SyntheticEvent) => {
    this.props.onChangeFilterBar && this.props.onChangeFilterBar("endDate", convertValue(e));
    // this.dispatch(
    //   {
    //     type: "UPDATE_FILTER_BAR",
    //     payload: { name: "filterBarEnd", value: convertValue(e) }
    //   },
    //   () => this.refresh()
    // );
  };

  toggleBreakRowDialog = () => {
    this.dispatch({ type: "TOGGLE_BREAK_DIALOG" });
  };

  validateChange = (breakRows: string[]) => {
    this.props.onChangeBreakColumns &&
      this.props.onChangeBreakColumns(breakRows).then(() => {
        this.refresh();
      });
    this.dispatch({ type: "TOGGLE_BREAK_DIALOG" });
  };

  onAdd = () => {
    this.props.addRow && this.props.addRow().then(() => this.recomputeBody());
  };

  render() {
    if (this.dropArea === null) {
      this.dropArea = this.props.nodeType ? DropArea : React.Fragment;
    }

    const table = (
      <Table
        ctrlKey={this.props.tableCtrlKey}
        ref={this.tableRef}
        isLoading={this.props.loading}
        entities={this.props.entities}
        selectedRows={this.props.selectedRows}
        totalRecords={this.props.totalRecords}
        loadMoreData={this.loadMoreData}
        toolbar={this.renderToolbar()}
        isFilterVisible={this.state.filterOpen}
        rowExpand={this.state.rowExpand}
        rowExpandData={this.state.rowExpandData}
        rowExpandRenderer={this.rowExpandRenderer}
        gridPadding={this.state.gridPadding}
        onReorderColumnEnd={this.onReorderColumnEnd}
        breakRowColumn={this.props.breakColumns.map(currentBreak => {
          const col = this.props.originalColumns.find(col => col.column === currentBreak);
          return { col: currentBreak, label: col ? col.label : currentBreak };
        })}
        onRowClick={this.onRowClick}
        selectedRow={this.state.currentRowClick}
      >
        {this.state.columns.map(this.renderTableColum)}
      </Table>
    );

    const content =
      this.props.nodeType && this.props.canDrop
        ? React.createElement(
            this.dropArea,
            { id: this.props.tableCtrlKey, onDrop: this.props.onDrop },
            table
          )
        : table;

    return (
      <>
        {content}

        {this.state.breakDialogOpen && (
          <BreakRowDialog
            sjmoCode={this.props.sjmoCode}
            columns={this.props.originalColumns}
            initialBreakRow={this.props.breakColumns}
            onClose={this.toggleBreakRowDialog}
            onValidate={this.validateChange}
          />
        )}
      </>
    );
  }

  renderToolbar = () => {
    const {
      add: isAddButton,
      save: isSaveButton,
      refresh: isRefreshButton = true,
      delete: isDeleteButton,
      focus: isFocusButton = true,
      processus: isProcessusButton = true,
      satellite: isSatelliteButton = true,
      exportExcel: isExportExcell = true,
      exportPdf: isExportPdf = true
    } = this.props.toolbarButtonVisibility || ({} as ToolbarButtonOverride);

    const currentFocus = this.props.focus
      ? this.props.focus.find(f => f.focusId === this.props.selectedFocus)
      : null;

    const { selectedRows } = this.props;
    if (selectedRows && selectedRows.length > 0) {
      let deletable = buttonVisibility(
        isDeleteButton,
        currentFocus ? currentFocus.deletable : undefined
      );

      return (
        <Toolbar
          sjmoCode={this.props.sjmoCode}
          tableName={this.props.tableName}
          tableCtrlKey={this.props.tableCtrlKey}
          className="has-background-blue-alt"
        >
          <Toolbar.Left>
            <Toolbar.Item className="has-text-weight-semibold">
              <Trans
                i18nKey="commun_lignes_selectionnees"
                values={{ nb: selectedRows.length, total: this.props.totalRecords }}
              />
            </Toolbar.Item>

            <Toolbar.Item>
              <Field addons>
                {deletable && <Toolbar.Delete onDelete={this.props.deleteRows} />}
                {isProcessusButton && <Toolbar.ProcessMenu />}
              </Field>
            </Toolbar.Item>
            {isFocusButton && (
              <Toolbar.Item>
                <Toolbar.FocusMenu
                  focus={this.props.focus}
                  selectedFocus={this.props.selectedFocus}
                  onFocusChange={this.props.onFocusChange}
                  onSaveFocusPerso={this.saveFocus}
                  onRazFocusPerso={this.onRazFocusPerso}
                />
              </Toolbar.Item>
            )}
            <Toolbar.Item>
              <button
                className="button is-link is-inverted"
                onClick={() => {
                  this.props.onRowUnselect && this.props.onRowUnselect("ALL");
                }}
              >
                <Trans i18nKey="commun_annuler" />
              </button>
            </Toolbar.Item>
          </Toolbar.Left>
        </Toolbar>
      );
    }

    let insertable = buttonVisibility(
      isAddButton,
      currentFocus ? currentFocus.insertable : undefined
    );
    let updatable = buttonVisibility(
      isSaveButton,
      currentFocus ? currentFocus.updatatable : undefined
    );

    let filterable = buttonVisibility(
      undefined,
      currentFocus ? currentFocus.filterable : undefined
    );

    let nbActive = 0;

    insertable && nbActive++;
    updatable && nbActive++;
    isRefreshButton && nbActive++;
    isProcessusButton && nbActive++;
    isSatelliteButton && nbActive++;

    let isOnlyOneButtonAction = nbActive === 1;

    return (
      <Toolbar
        sjmoCode={this.props.sjmoCode}
        tableName={this.props.tableName}
        tableCtrlKey={this.props.tableCtrlKey}
      >
        <Toolbar.Left>
          {insertable && (
            <Toolbar.Add selectedFocus={this.props.selectedFocus} onAdd={this.onAdd} />
          )}
          <Toolbar.Item>
            <Field addons={true}>
              {updatable && (
                <Toolbar.Save
                  highlightSave={this.props.dirty}
                  onSave={this.props.saveRows}
                  isRounded={isOnlyOneButtonAction}
                />
              )}
              {isRefreshButton && (
                <Toolbar.Refresh onRefresh={this.refresh} isRounded={isOnlyOneButtonAction} />
              )}
              {isSatelliteButton && (
                <Toolbar.Satellite
                  renderSatelliteMenu={this.renderSatelliteMenu}
                  isRounded={isOnlyOneButtonAction}
                />
              )}
              <div className="control">
                <Menu autoclose>
                  <Menu.Button
                    className={classNames(
                      "button is-link is-inverted",
                      isOnlyOneButtonAction && "is-rounded"
                    )}
                  >
                    <span>
                      <Trans i18nKey="commun_plus" />
                    </span>
                    <Fa icon="caret-down" fixedWidth />
                  </Menu.Button>
                  <Menu.Content>
                    <Menu.Item className="flex">
                      <div className="flex justify-between pr-8">
                        <a onClick={() => this.recomputeGridPadding("small")}>
                          <span
                            className={classNames("icon", {
                              "has-text-grey": this.state.gridPadding !== "small"
                            })}
                            title="small"
                          >
                            <Fa icon="th" aria-label="size grid small" />
                          </span>
                        </a>
                        <a onClick={() => this.recomputeGridPadding("large")}>
                          <span
                            className={classNames("icon", {
                              "has-text-grey": this.state.gridPadding !== "large"
                            })}
                            title="large"
                          >
                            <Fa icon="th-large" aria-label="size grid small" />
                          </span>
                        </a>
                      </div>
                      <div>
                        <Trans i18nKey="commun_grille" />
                      </div>
                    </Menu.Item>
                    <Menu.Separator />
                    <Menu.Item
                      as="a"
                      onClick={this.toggleBreakRowDialog}
                      aria-label="add break row"
                    >
                      <Fa icon="outdent" className="has-text-link" fixedWidth />
                      <span>
                        <Trans i18nKey="commun_ajouter_une_rupture" />
                      </span>
                    </Menu.Item>
                    {isExportExcell || isExportPdf ? <Menu.Separator /> : null}
                    {isExportExcell && (
                      <Menu.Item as="a" onClick={this.props.onExportExcel}>
                        <Fa icon="file-excel" className="has-text-link" fixedWidth />
                        <span>
                          <Trans i18nKey="commun_export_excel" />
                        </span>
                      </Menu.Item>
                    )}
                    {isExportPdf && (
                      <Menu.Item as="a" onClick={this.props.onExportPdf}>
                        <Fa icon="file-pdf" className="has-text-link" fixedWidth />
                        <span>
                          <Trans i18nKey="commun_export_pdf" />
                        </span>
                      </Menu.Item>
                    )}
                  </Menu.Content>
                </Menu>
              </div>
            </Field>
          </Toolbar.Item>
          {isFocusButton && (
            <Toolbar.FocusMenu
              focus={this.props.focus}
              selectedFocus={this.props.selectedFocus}
              onFocusChange={this.props.onFocusChange}
              onSaveFocusPerso={this.saveFocus}
              onRazFocusPerso={this.onRazFocusPerso}
            />
          )}
          {filterable && (
            <Toolbar.Search onSearch={this.toggleSearch}>
              <div className="level flex-col items-start p-7">
                <FilterBarComponent
                  // className="is-centered"
                  filters={this.props.filters}
                  selectedFilter={this.props.filterBar.filterBarId}
                  startDate={this.props.filterBar.startDate}
                  endDate={this.props.filterBar.endDate}
                  selectedFilterChange={this.selectedFilterChange}
                  startDateChange={this.startDateChange}
                  endDateChange={this.endDateChange}
                />
              </div>
            </Toolbar.Search>
          )}
          <Toolbar.FilterActive
            isFilterVisible={
              Object.keys(this.state.filterValues).length > 0 ||
              Object.keys(this.state.sortValues).length > 0 ||
              this.props.filterBar.filterBarId !== null
            }
            onClearFilter={this.onClearFilter}
          />
        </Toolbar.Left>
      </Toolbar>
    );
  };

  renderTableColum = (col: ComponentState) => {
    if (col.typeCompo === DATATABLE_SELECTION.TYPE) {
      return (
        <TableColumn
          key={col.column}
          column={col}
          width={col[this.columnWidthName]}
          component={SimpleComponent[col.typeCompo]}
          componentProperties={this.componentProperties}
          renderHeaderCell={({ key, style, className }) => {
            return (
              <div
                key={key}
                className={classNames(className, "cursor-pointer")}
                style={style}
                // onClick={this.handleSelection("ALL")} // TODO : refaire la sélection
              >
                <input
                  type="checkbox"
                  checked={
                    this.props.selectedRows &&
                    this.props.totalRecords > 0 &&
                    this.props.selectedRows.length === this.props.totalRecords
                  }
                  className="cursor-pointer"
                  style={{
                    width: this.gridPaddingComponentSize === "small" ? 15 : 20,
                    height: this.gridPaddingComponentSize === "small" ? 15 : 20
                  }}
                  onChange={this.handleSelection("ALL")}
                />
              </div>
            );
          }}
        >
          {({ className, style, rowIndex, colorRow }) => {
            const isRowSelected =
              this.props.selectedRows && this.props.selectedRows.indexOf(rowIndex) !== -1;

            return (
              <div
                className={className}
                style={{ ...style, cursor: "pointer" }}
                data-state-row={colorRow}
                onClick={this.handleSelection(rowIndex)}
              >
                {this.renderSelection(rowIndex, isRowSelected || false)}
              </div>
            );
          }}
        </TableColumn>
      );
    }

    if (col.typeCompo === DATATABLE_ACTION.TYPE) {
      return (
        <TableColumn
          key={col.column}
          column={col}
          width={col[this.columnWidthName]}
          component={SimpleComponent[col.typeCompo]}
          componentProperties={this.componentProperties}
          renderHeaderCell={({ key, style, className }) => {
            return <div key={key} className={className} style={style} />;
          }}
        >
          {({ className, style, data, colorRow }) => {
            const existSatelliteData = this.props.satellitesExist
              ? this.props.satellitesExist[data.id]
              : false;
            return (
              <div className={className} style={style} data-state-row={colorRow}>
                {data ? (
                  <Dropdown className="is-hoverable" autoclose>
                    <DropdownButton
                      render={params => (
                        <ButtonSatellite
                          buttonRef={params.buttonRef}
                          id={data.id}
                          highlight={existSatelliteData}
                          onOpen={params.onOpen}
                          openSatellite={this.openSatellite}
                        />
                        // <Button
                        //   ref={params.buttonRef}
                        //   className={`is-text ${
                        //     existSatelliteData ? "has-text-link" : "has-text-dark"
                        //   }`}
                        //   onDoubleClick={() => {
                        //     // params.isActive ? params.onClose() : params.onOpen();
                        //     this.openSatellite(Object.keys(SATELLITES)[0], data.id);
                        //   }}
                        //   onClick={params.onOpen}
                        //   aria-haspopup="true"
                        //   aria-controls="dropdown-menu"
                        // >
                        //   <span className="icon">
                        //     <Fa icon={["fal", "satellite"]} fixedWidth />
                        //   </span>
                        // </Button>
                      )}
                    />
                    <DropdownMenu
                      render={param =>
                        this.renderSatelliteMenu(param.isActive, param.onClose, data.id)
                      }
                    />
                  </Dropdown>
                ) : null}
              </div>
            );
          }}
        </TableColumn>
      );
    }

    if (col.typeCompo === DATATABLE_EXPAND.TYPE) {
      return (
        <TableColumn
          key={col.column}
          column={col}
          width={col[this.columnWidthName]}
          component={SimpleComponent[col.typeCompo]}
          componentProperties={this.componentProperties}
          renderHeaderCell={({ key, className, style }) => {
            return <div key={key} className={className} style={style} />;
          }}
        >
          {({ className, style, data, colorRow }) => {
            const id = data.id || data.uuid;
            const iconArrowDirection: IconName = this.state.rowExpand[id]
              ? "arrow-circle-up"
              : "arrow-circle-down";

            return (
              <div className={className} style={style} data-state-row={colorRow}>
                {data ? (
                  <a onClick={this.onRowExpand(id)}>
                    <span className="icon">
                      <Fa icon={iconArrowDirection} />
                    </span>
                  </a>
                ) : null}
              </div>
            );
          }}
        </TableColumn>
      );
    }

    return (
      <TableColumn
        key={col.column}
        column={col}
        width={col[this.columnWidthName]}
        className={col.typeCompo === "ED" ? "cell-editor" : undefined}
        component={col.typeCompo === "ED" ? DatatableEditor : SimpleComponent[col.typeCompo]}
        componentProperties={this.componentProperties}
        renderHeaderCell={this.renderSortableHeaderCell}
      />
    );
  };

  renderSortableHeaderCell = ({ columnIndex, key, style, parent }: CustomRenderHeaderCell) => {
    const col = this.state.columns[columnIndex];

    return (
      <SortableHeaderItem
        key={key}
        index={columnIndex}
        column={col.column}
        typeCompo={col.typeCompo}
        label={col.label}
        tooltip={col.tooltip}
        filterOpen={this.state.filterOpen}
        style={style}
        required={col.mandatory}
        sort={this.state.sortValues[col.column]}
        onSortAsc={this.onSortChange(col.column, "ASC")}
        onSortDesc={this.onSortChange(col.column, "DESC")}
        onResize={this.onResize(col.column, (parent as any).props.width)}
      >
        {this.renderFilter(col)}
      </SortableHeaderItem>
    );
  };

  renderFilter = (col: ComponentState) => {
    const filterProps: FilterProps = {
      name: col.column,
      filterOpen: this.state.filterOpen,
      value: this.state.filterValues[col.column],
      onFilterChange: this.onFilterChange,
      onKeyDown: this.onKeyDownFilterInput
    };

    switch (col.typeCompo) {
      case TypeSimpleComponent.CHECKBOX:
        return <CheckboxFilter {...filterProps} />;
      case TypeSimpleComponent.SYS_DOMAINE:
        return <SelectFilter {...filterProps} options={col.sysDomaineChoices} />;
      case TypeSimpleComponent.OEL:
        return <OelFilter />;
      case TypeSimpleComponent.AUTO_COMPLETE:
        const columnTarget = col.column + ".id";
        return (
          <AutocompleteFilter
            {...filterProps}
            name={columnTarget}
            value={this.state.filterValues[columnTarget]}
          />
        );
      default:
        return <InputFilter {...filterProps} />;
    }
  };

  renderSatelliteMenu = (isActive: boolean, onClose: () => void, id?: string) => {
    return (
      <SatelliteMenu
        tableName={this.props.tableName}
        contextId={id}
        sjmoCode={this.props.sjmoCode}
        isActive={isActive}
        onClick={onClose}
        onMouseLeave={onClose}
        ctrlKey={this.props.tableCtrlKey}
      />
    );
  };

  openSatellite = (satelliteName: string, id?: string) => {
    if (satelliteName) {
      const rsql = initRsqlCriteria();

      const indexes = Object.keys(this.props.entities);

      for (let index of indexes) {
        if (
          this.props.entities[index] &&
          this.props.entities[index] !== "LOADING_POJO" &&
          this.props.entities[index].id
        ) {
          rsql.filters.or(
            new RSQLFilterExpression("id", Operators.Like, this.props.entities[index].id)
          );
        }
      }

      return openSatellite({
        satelliteName: satelliteName,
        sjmoCode: this.props.sjmoCode,
        tableName: this.props.tableName,
        columns: this.props.originalColumns.slice(0, 4).map(col => col.column),
        contextId: id,
        query: rsql.build()
      });
    }

    return undefined;
  };
}

function ButtonSatellite({
  buttonRef,
  id,
  highlight,
  onOpen,
  openSatellite
}: {
  buttonRef: any;
  id: any;
  highlight: boolean;
  onOpen(): void;
  openSatellite(satelliteName: string, id?: string): URLSearchParams | string | undefined;
}) {
  const navigate = useNavigate();
  function onDoubleClick() {
    const params = openSatellite(Object.keys(SATELLITES)[0], id);
    if (params) {
      navigate({
        search: params.toString()
      });
    }
  }

  return (
    <Button
      ref={buttonRef}
      className={`is-text ${highlight ? "has-text-link" : "has-text-dark"}`}
      onDoubleClick={onDoubleClick}
      onClick={onOpen}
      aria-haspopup="true"
      aria-controls="dropdown-menu"
    >
      <span className="icon">
        <Fa icon={["fal", "satellite"]} fixedWidth />
      </span>
    </Button>
  );
}
