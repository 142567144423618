import React, { useEffect, FC, useState, useMemo } from "react";
import { createRoot } from "react-dom/client";

import { Provider } from "react-redux";

import { Helmet, HelmetProvider } from "react-helmet-async";
import { I18nextProvider } from "react-i18next";
import NProgress from "nprogress";

import i18n, { initI18n } from "utils/i18n";
import { configureStore, cacheRedux } from "store";

import * as serviceWorker from "./serviceWorker";
import registerLogger from "registerLogger";

import { setupCookieLang } from "utils/network.utils";

import "./index.css";

import { initGlobalInteraction, initInteractionByTable } from "actions/interactions";
import { LoadableLoader, AuthenticationFailed } from "composants/Loader";
import { fetchUserSettings } from "api";
import { initUserSettings } from "actions/userSettings";
import { isApiDataOK } from "api/apiDataOk";
import { setApiDataOK } from "actions/apiOk";

import "./nprogress.css";

import { fetchApplicationState } from "actions/galaxies.action";
import { startupNotification } from "actions/notifications.action";
import { Store, AnyAction } from "redux";
import { ReducerState } from "reducers";
import { clearDatatableCache } from "containers/datatable/Datatable";
import auth, { initAuth, reconnect } from "auth";
import { QueryClient, QueryClientProvider } from "react-query";
import { setup } from "twind";
import { twindConfig } from "./twind.config";
import { TooltipProvider } from "@axin-org/comet";
import RouterRoot from "router";

import { enableAllPlugins } from "immer";
enableAllPlugins();

setup({ ...twindConfig, hash: string => "tw-" + string });

NProgress.configure({ showSpinner: false });

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10_000,
      retry: false,
      refetchInterval: false,
      refetchOnWindowFocus: false
    }
  }
});

type STATUS_AUTH = "INITIAL" | "LOGGED" | "ERROR";

const AuthLoader: FC<React.PropsWithChildren<{ store: Store<ReducerState, AnyAction> }>> = ({
  store,
  children
}) => {
  const [status, setStatus] = useState<STATUS_AUTH>(auth.authenticated ? "LOGGED" : "INITIAL");

  useEffect(() => {
    if (status === "INITIAL") {
      initAuth()
        .then(authenticated => {
          if (!authenticated) {
            setStatus("ERROR");
            return;
          }

          initI18n();

          const applicationStatesPromise = store.dispatch(fetchApplicationState());
          store.dispatch(initGlobalInteraction());
          store.dispatch(initInteractionByTable());
          store.dispatch(startupNotification());

          return Promise.all([
            applicationStatesPromise,
            fetchUserSettings().then(response => {
              try {
                store.dispatch(initUserSettings(response.data));
                setupCookieLang(response.data.lang);
                i18n.changeLanguage(response.data.lang);
              } catch {
                // on arrive parfois ici, yollo catch pour éviter d'avoir la page blanche.
                setStatus("ERROR");
              }
            }),
            isApiDataOK()
              .then(() => {
                store.dispatch(setApiDataOK(true));
              })
              .catch(() => {
                store.dispatch(setApiDataOK(false));
              })
          ]);
        })
        .then(() => {
          setStatus("LOGGED");
        })
        .catch(error => {
          console.log("This is the problem =>", error);
          setStatus("ERROR");
        });
    }
  }, [status, store]);

  switch (status) {
    case "INITIAL":
      return null;
    case "ERROR":
      return (
        <div style={{ textAlign: "center", marginTop: "1em" }}>
          <div className="title is-3">Authentication failed</div>
          <AuthenticationFailed />
          <div>
            <button className="button is-link" onClick={reconnect}>
              reconnect
            </button>
          </div>
        </div>
      );
    case "LOGGED":
      return <Root store={store} />;
  }
};

const Root: FC<{ store: Store<ReducerState, AnyAction> }> = ({ store }) => {
  return (
    <React.Suspense fallback={<LoadableLoader />}>
      <I18nextProvider i18n={i18n}>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <HelmetProvider>
              <Helmet defaultTitle="Octal" titleTemplate="Octal : %s" />
              <TooltipProvider>
                <RouterRoot />
              </TooltipProvider>
            </HelmetProvider>
          </Provider>
        </QueryClientProvider>
      </I18nextProvider>
    </React.Suspense>
  );
};

// Lors de la fermeture de la page
// on clear le cache lié au TAB_ID courant.
// car, à chaque F5, le cache est perdu parce que le tabID change.
window.addEventListener("beforeunload", () => {
  clearDatatableCache();
});

const rootElement = createRoot(document.getElementById("root") as HTMLElement);

cacheRedux.getAll().then((data: any) => {
  const store = configureStore(data);
  if (!store) {
    return;
  }

  rootElement.render(<AuthLoader store={store} />);
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

registerLogger();
