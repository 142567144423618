import React, { FC, useState } from "react";
import AutoCompleteAndLabel from "composants/autocomplete/AutoCompleteAndLabel";
import { Pojo } from "types/Galaxy";
import { t } from "utils/i18n";
import Modal from "composants/Modal/Modal";
import { Message } from "types/Message";
import { GSBuilder } from "utils/query.utils";
import { createFocusDuplicationOrExtract } from "api/adminDuplication";
import { Col } from "composants/Layout";
import TextAreaAndLabel from "composants/input/TextAreaAndLabel";

interface ModalDuplicationProps {
  sjmoCode: string;
  sqlMode: string;
  selectedFocus: string;
  onClose(): void;
  onCreateOrUpdateGalaxyFocus(params: {
    currentSjmoCode: string;
    title: string;
    id?: string;
    position?: number;
    associationId?: String;
    focusCode?: String;
    groupe?: String;
    privilegie: boolean;
    code: string;
    syjModuleId: string;
    sysMenuGroupeId: string;
    personnelId: string;
    callback: () => void;
  }): void;
  addMessage(message: Message): void;
}

const ModalDuplicationFocus: FC<ModalDuplicationProps> = props => {
  const [selectedGalaxy, setSelectedGalaxy] = useState<Pojo | null>(null);
  const [resultSql, setResultSql] = useState<String | null>("Chargement...");
  const [preRecordPanel, setPreRecordPanel] = useState<Pojo | null>(null);
  const onSave = () => {
    setResultSql("");
    if (selectedGalaxy) {
      createFocusDuplicationOrExtract(
        props.sjmoCode,
        props.selectedFocus,
        selectedGalaxy?.id,
        props.sqlMode // disable sql extraction mode
      ).then(res => {
        setResultSql(res.data);
        if (props.sqlMode == "duplicate") props.onClose();
      });
    } else {
      const message: Message = {
        type: "DANGER",
        message: t("commun_champs_obligatoire_non_rempli"),
        target: "GLOBAL"
      };
      props.addMessage(message);
    }
  };

  return (
    <Modal
      onClose={() => props.onClose()}
      onValidate={onSave}
      title={"Duplication vers une autre galaxie"}
    >
      <Col>
        <AutoCompleteAndLabel
          id="syjModule"
          label={t("commun_module")}
          joinTableName={"syjModule"}
          sjmoCode={"SYJ_MODULE"}
          value={null}
          joinListFields="sjmoCode"
          styleInput={{ width: "30rem" }}
          additionalClause={GSBuilder.Comparison("sjmoCode", "OPER_LIKE_ANYWHERE", "")}
          onItemChange={pojo => {
            setSelectedGalaxy(pojo);
          }}
        />
      </Col>
      {props.sqlMode == "scriptsql" ? (
        <Col>
          <TextAreaAndLabel
            label={"Resultat SQL"}
            readOnly={true}
            style={{ height: 250 }}
            id="syjModule"
            value={resultSql}
          />
        </Col>
      ) : (
        <Col></Col>
      )}
    </Modal>
  );
};

export default ModalDuplicationFocus;
