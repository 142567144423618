import React, { FC, useState, useEffect, SyntheticEvent, useMemo } from "react";
import { Trans } from "react-i18next";

import Modal from "composants/Modal/Modal";
import { Field } from "composants/form/Form";
import { uuidv4 } from "utils/uuid.utils";
import { t } from "utils/i18n";
import { ComponentState } from "types/Component";
import { AggregateProperties } from "types/InteractiveReport";

interface AggregateDialogProps {
  columns: ComponentState[];
  aggregates: AggregateProperties[];
  onClose(): void;
  onValidate(aggr: AggregateProperties): void;
  onDelete(index: number): void;
  onClear(): void;
}

const SUPPORTED_FUNCTION = ["SUM", "AVG", "COUNT", "COUNT_DISTINCT", "MIN", "MAX", "MEDIAN"];

export function useAggrate() {
  const [aggregates, setAggregates] = useState<AggregateProperties[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const breakSubtotal = useMemo(() => {
    return aggregates.map(aggr => {
      return {
        id: aggr.id,
        label: t("commun_aggrate_function_" + aggr.functionType.toLowerCase()) + ` (${aggr.column})`
      };
    });
  }, [aggregates]);

  const toggle = () => setIsOpen(!isOpen);

  return { aggregates, setAggregates, breakSubtotal, isOpen, toggle };
}

function initAggreateProperties(): AggregateProperties {
  return {
    id: uuidv4(),
    functionType: "",
    column: ""
  };
}

const AggregateDialog: FC<AggregateDialogProps> = props => {
  const [selectedAggr, setSelectedAggr] = useState("new");
  const [currentAggr, setCurrentAggr] = useState(initAggreateProperties());

  useEffect(() => {
    if (selectedAggr === "new") {
      setCurrentAggr(initAggreateProperties());
    } else {
      const aggr = props.aggregates.find(ag => ag.id === selectedAggr);
      aggr && setCurrentAggr(aggr);
    }
  }, [selectedAggr]);

  function changeSelectedAggr(e: SyntheticEvent<HTMLSelectElement>) {
    setSelectedAggr(e.currentTarget.value);
  }

  function onChange(e: SyntheticEvent<HTMLSelectElement>) {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;

    setCurrentAggr({
      ...currentAggr,
      [field]: value
    });
  }

  function clear() {
    setSelectedAggr("new");
    props.onClear();
  }

  function onValidate() {
    setCurrentAggr(initAggreateProperties());
    if (currentAggr.column !== "" && currentAggr.functionType !== "") {
      props.onValidate(currentAggr);
    } else {
      props.onClose();
    }
  }

  return (
    <Modal
      title={
        <>
          <Trans i18nKey="commun_agreger">Agréger</Trans>
          <span className="is-pulled-right">
            <button className="button is-text is-small" onClick={clear}>
              <Trans i18nKey="commun_vider" />
            </button>
          </span>
        </>
      }
      width="30rem"
      onClose={props.onClose}
      onValidate={onValidate}
      hideCancel
    >
      <Field label={t("commun_agregat")}>
        <div className="select">
          <select value={selectedAggr} onChange={changeSelectedAggr}>
            <option value="new">{t("commun_nouveau_agregat")}</option>
            {props.aggregates.map(aggr => {
              const label = t("commun_aggrate_function_" + aggr.functionType.toLowerCase());

              return (
                <option key={aggr.id} value={aggr.id}>
                  {`${label} : ${aggr.column}`}
                </option>
              );
            })}
          </select>
        </div>
      </Field>
      <Field label={t("commun_fonction")}>
        <div className="select">
          <select name="functionType" value={currentAggr.functionType} onChange={onChange}>
            <option />
            {SUPPORTED_FUNCTION.map(fn => {
              return (
                <option key={fn} value={fn}>
                  {t("commun_aggrate_function_" + fn.toLowerCase())}
                </option>
              );
            })}
          </select>
        </div>
      </Field>
      <Field label={t("commun_colonne")}>
        <div className="select">
          <select name="column" value={currentAggr.column} onChange={onChange}>
            <option />
            {props.columns.map(col => {
              return (
                <option key={col.column} value={col.column}>
                  {`${col.label} (${col.column})`}
                </option>
              );
            })}
          </select>
        </div>
      </Field>
    </Modal>
  );
};

export default AggregateDialog;
