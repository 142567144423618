import { call, put, takeLatest, takeEvery, putResolve } from "redux-saga/effects";

import Action from "reducers/Action";
import {
  INIT_DRAGGABLE_GROUP_LIST,
  INIT_DGLIST_DEFINITION_SUCCESS,
  GET_FILTERABLE_LIST_DATA,
  GET_FILTERABLE_LIST_DATA_SUCCESS,
  DGLIST_TEMPORARY_LOADING
} from "constant/draggableGroupList";
import { getDGListDefinition, getDGListData } from "api/draggableGroupList";
import { DGLGroupDefinition } from "types/draggableGroupList";
import { AxiosResponse } from "axios";
import { PagedResource } from "types/Search";
import { Pojo } from "types/Galaxy";

function* watchInitDGList(
  action: Action<{
    sjmoCode: string;
    sjpaId: number;
    tableName: string;
  }>
) {
  const { sjmoCode, sjpaId } = action.payload;
  try {
    const responseDefinition: AxiosResponse<{
      id: string;
      multipleDrag: boolean;
      nodeType: string;
      groups: DGLGroupDefinition[];
    }> = yield call(getDGListDefinition, sjmoCode, sjpaId);

    let definition = {
      id: responseDefinition.data.id,
      multipleDrag: responseDefinition.data.multipleDrag,
      nodeType: responseDefinition.data.nodeType,
      groups: {}
    };

    let groups: { [definitionId: number]: DGLGroupDefinition } = {};
    for (let i = 0; i < responseDefinition.data.groups.length; i++) {
      groups[responseDefinition.data.groups[i].id] = responseDefinition.data.groups[i];
    }
    definition.groups = groups;

    yield putResolve({
      type: INIT_DGLIST_DEFINITION_SUCCESS,
      payload: { sjmoCode, panelId: sjpaId, definition }
    });
  } catch (e) {
    console.log(`erreur lors de l'initialisation de la liste groupé ${sjmoCode} ; ${sjpaId}`);
  }
}

function* watchGetData(
  action: Action<{
    sjmoCode: string;
    panelId: number;
    definitionId: number;
    searchTerm?: string;
    displayFields: string;
    first?: number;
    size?: number;
    replace?: boolean;
    rowCount: number;
    interactionFilter?: string;
  }>
) {
  const {
    sjmoCode,
    panelId,
    definitionId,
    searchTerm,
    displayFields,
    first,
    size,
    replace,
    rowCount,
    interactionFilter
  } = action.payload;
  try {
    const responseData: AxiosResponse<PagedResource<Pojo>> = yield call(getDGListData, {
      sjmoCode,
      definitionId,
      displayFields,
      searchTerm,
      first,
      size,
      interactionFilter
    });

    if (replace === false && first && size) {
      let loadingEntities = {};
      for (let i = first; i < rowCount && i < first + size; i++) {
        loadingEntities[i] = "LOADING_POJO";
      }

      yield putResolve({
        type: DGLIST_TEMPORARY_LOADING,
        payload: { sjmoCode, panelId, definitionId, loading: loadingEntities }
      });
    }

    // On ajoute options : {toAdd:true} à toutes les données afin que
    // lors d'un drop, la target puisse distinguer cette donnée des autres données présentent
    const data = responseData.data.data.map(cell => {
      cell.options = { toAdd: true };
      return cell;
    });

    yield put({
      type: GET_FILTERABLE_LIST_DATA_SUCCESS,
      payload: {
        sjmoCode,
        panelId,
        definitionId,
        data,
        rowCount: responseData.data.meta.totalRecords,
        replace: replace === undefined ? true : replace,
        first: first || 0
      }
    });
  } catch (e) {
    console.log(
      `erreur lors de la récupération des données de le liste filtrée ${sjmoCode} ; ${panelId} ; ${definitionId}`
    );
  }
}

export default [
  takeEvery(INIT_DRAGGABLE_GROUP_LIST, watchInitDGList),
  takeEvery(GET_FILTERABLE_LIST_DATA, watchGetData)
];
