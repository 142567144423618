import React, { CSSProperties } from "react";
import { ComposantAllProps } from "../common";

import { LoadableLoader } from "composants/Loader";

const GenericMarkdownDisplay = React.lazy(() =>
  import("composants/genericDisplay/GenericMarkdownDisplay")
);

const oelWrapperStyle: CSSProperties = { width: "100%", height: "100%" };
const oelWrapperStyle2: CSSProperties = { width: "100%" };

const Oel = React.memo<ComposantAllProps>(
  ({ value }) => {
    return (
      <div className="flex items-center" style={oelWrapperStyle}>
        <div style={oelWrapperStyle2}>
          <React.Suspense fallback={<LoadableLoader />}>
            <GenericMarkdownDisplay value={value} />
          </React.Suspense>
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => {
    return prevProps.value === nextProps.value;
  }
);

export default Oel;
