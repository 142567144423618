import immer from "immer";

import {
  LAYOUT_CHANGE,
  FOCUS_CHANGE,
  CLOSE_PANEL,
  ADD_PANEL_TO_FOCUS,
  FETCH_FOCUS_PANELS_SUCCESS,
  FETCH_FOCUS_SUCCESS,
  CHANGE_FOCUS_AND_PANELS,
  ADD_ONE_FOCUS_AND_SELECT,
  GALAXY_FOCUS_DELETED,
  ADD_MULTIPLE_PANEL_TO_FOCUS,
  CLOSE_MULTIPLE_PANEL
} from "constant/dashboard";

import { Layout } from "react-grid-layout";
import Action from "reducers/Action";

import { PanelState } from "types/Dashboard";
import { FocusState } from "types/Focus";

// State d'un focus de galaxie
export interface DashboardFocusState extends FocusState {
  panels: PanelState[];
}

// State redux du dashboard
export interface DashboardState {
  dashboardDefinitions: Record<string, DashboardFocusState[]>;
  selected: Record<string, string>;
}

// type alias pour l'action dans le reducer de dashboard
type DashboardAction = Action<any>;

const initialState = { dashboardDefinitions: {}, selected: {} };

export default function reducer(
  state: DashboardState = initialState,
  action: DashboardAction
): DashboardState {
  switch (action.type) {
    case FOCUS_CHANGE:
      return focusChange(state, action);
    case CLOSE_PANEL:
      return closePanel(state, action);
    case CLOSE_MULTIPLE_PANEL:
      return closeMultiplePanel(state, action);
    case FETCH_FOCUS_SUCCESS:
      return addFocus(state, action);
    case ADD_ONE_FOCUS_AND_SELECT:
      return addOneFocusAndSelect(state, action);
    case FETCH_FOCUS_PANELS_SUCCESS:
      return addPanels(state, action);
    case CHANGE_FOCUS_AND_PANELS:
      return focusAndPanels(state, action);
    case LAYOUT_CHANGE:
      return layoutChange(state, action);
    case ADD_PANEL_TO_FOCUS:
      return addPanelToFocus(state, action);
    case ADD_MULTIPLE_PANEL_TO_FOCUS:
      return addMultiplePanelToFocus(state, action);
    case GALAXY_FOCUS_DELETED:
      return removeOneFocus(state, action);
    default:
      return state;
  }
}

/**
 * Permet de traiter le changement de focus
 * @param state state dashboard
 * @param action action redux
 */
function layoutChange(state: DashboardState, action: DashboardAction) {
  const sjmoCode: string = action.payload.sjmoCode;
  const layouts: Layout[] = action.payload.layouts;

  if (
    state.dashboardDefinitions[sjmoCode] === undefined ||
    state.dashboardDefinitions[sjmoCode] === null
  ) {
    return state;
  }

  return immer(state, draft => {
    const selected: string = draft.selected[sjmoCode];

    const indexFocus = draft.dashboardDefinitions[sjmoCode].findIndex(
      ({ focusId }) => focusId === selected
    );

    // on crée une nouvelle liste de Panel à partir des infos renvoyées par l'action
    // onLayoutchange (Layout[])
    const newPanels = layouts.map((layout: Layout) => {
      // on recherche le panel existant
      const indexPanel = draft.dashboardDefinitions[sjmoCode][indexFocus].panels.findIndex(
        ({ panelId }) => `${selected}_${panelId}` === layout.i
      );

      // on traite un panel
      const currentPanel = draft.dashboardDefinitions[sjmoCode][indexFocus].panels[indexPanel];
      // On construit un nouveau panel en mixant des info du panel originel et
      // des infos du nouveau layout
      const panel = {
        ...currentPanel,
        posX: layout.x,
        posY: layout.y,
        width: layout.w,
        height: layout.h
      };
      draft.dashboardDefinitions[sjmoCode][indexFocus].panels[indexPanel] = panel;
    });
  });
}

/**
 * Permet de traiter le changement de focus
 * @param state state dashboard
 * @param action action redux
 */
function focusChange(state: DashboardState, action: DashboardAction): DashboardState {
  const sjmoCode: string = action.payload.sjmoCode;
  const selected: string = action.payload.selected;
  return immer(state, draft => {
    draft.selected[sjmoCode] = selected;
  });
}

/**
 * Permet de traiter la fermeture de panel
 * @param state state dashboard
 * @param action action redux
 */
export function closePanel(state: DashboardState, action: DashboardAction): DashboardState {
  // on extrait les valeurs du payload
  const sjmoCode: string = action.payload.sjmoCode;
  const sjpaId: string = action.payload.sjpaId;

  // focus sélectionné
  const selected = state.selected[sjmoCode];

  // on recherche l'index du focus courant
  const indexFocus = state.dashboardDefinitions[sjmoCode].findIndex(
    ({ focusId }) => focusId === selected
  );

  // on récupère l'index du panel
  const indexPanel = state.dashboardDefinitions[sjmoCode][indexFocus].panels.findIndex(
    ({ panelId }) => panelId === sjpaId
  );

  // immerjs permet de directement modifier les valeurs du draft
  // il regarde ensuite les différence entre state & draft
  // pour appliquer la différence dans un nouvel object.
  return immer(state, draft => {
    draft.dashboardDefinitions[sjmoCode][indexFocus].panels[indexPanel].visible = false;
  });
}

function closeMultiplePanel(state: DashboardState, action: DashboardAction): DashboardState {
  // on extrait les valeurs du payload
  const sjmoCode: string = action.payload.sjmoCode;
  const panelsId: string[] = action.payload.panelsId;

  // focus sélectionné
  const selected = state.selected[sjmoCode];

  // on recherche l'index du focus courant
  const indexFocus = state.dashboardDefinitions[sjmoCode].findIndex(
    ({ focusId }) => focusId === selected
  );

  // on récupère l'index du panel
  let indexes: number[] = [];
  for (
    let i = 0, max = state.dashboardDefinitions[sjmoCode][indexFocus].panels.length;
    i < max;
    i++
  ) {
    let panel = state.dashboardDefinitions[sjmoCode][indexFocus].panels[i];
    if (panelsId.includes(panel.panelId)) {
      indexes.push(i);
    }
  }

  // immerjs permet de directement modifier les valeurs du draft
  // il regarde ensuite les différence entre state & draft
  // pour appliquer la différence dans un nouvel object.
  return immer(state, draft => {
    for (let index of indexes) {
      draft.dashboardDefinitions[sjmoCode][indexFocus].panels[index].visible = false;
    }
  });
}

function addFocus(state: DashboardState, action: DashboardAction): DashboardState {
  const focuses: FocusState[] = action.payload.focuses;
  const sjmoCode: string = action.payload.sjmoCode;

  return immer(state, draft => {
    draft.dashboardDefinitions[sjmoCode] = focuses.map((focus, index) => {
      const oldFocus = state.dashboardDefinitions[sjmoCode]
        ? state.dashboardDefinitions[sjmoCode][index]
        : undefined;
      return {
        panels: [],
        ...oldFocus,
        ...focus
      };
    });
  });
}

function addPanels(state: DashboardState, action: DashboardAction): DashboardState {
  // on récupère la valeurs des actions
  const sjmoCode: string = action.payload.sjmoCode;
  const focusId: string = action.payload.focusId;
  const panels: PanelState[] = action.payload.panels;

  // on récupère l'index
  const index = state.dashboardDefinitions[sjmoCode].findIndex(def => def.focusId === focusId);

  return immer(state, draft => {
    draft.dashboardDefinitions[sjmoCode][index].panels = panels;
  });
}

function focusAndPanels(
  state: DashboardState,
  action: Action<{ focuses: FocusState[]; sjmoCode: string; focusId: number; panels: PanelState[] }>
): DashboardState {
  const stateAfterNewFocus = addFocus(state, action);
  const stateAfterPanels = addPanels(stateAfterNewFocus, action);
  return stateAfterPanels;
}

/*function insertNewPanel(state: DashboardState, action: DashboardAction): DashboardState {
  // on récupère la valeurs des actions
  const sjmoCode: string = action.payload.sjmoCode;
  const panel: number = action.payload.panel;

  // on récupère l'index
  const index = state.dashboardDefinitions[sjmoCode].findIndex(def => def.focusId === focusId);

  return immer(state, draft => {
    draft.dashboardDefinitions[sjmoCode][index].panels = panels;
  });
}*/

function addPanelToFocus(state: DashboardState, action: DashboardAction): DashboardState {
  // on extrait les valeurs du payload
  const sjmoCode: string = action.payload.sjmoCode;
  const sjpaId: string = action.payload.sjpaId;

  // focus sélectionné
  const selected = state.selected[sjmoCode];

  // on recherche l'index du focus courant
  const indexFocus = state.dashboardDefinitions[sjmoCode].findIndex(
    ({ focusId }) => focusId === selected
  );

  // on récupère l'index du panel
  const indexPanel = state.dashboardDefinitions[sjmoCode][indexFocus].panels.findIndex(
    ({ panelId }) => panelId === sjpaId
  );

  // immerjs permet de directement modifier les valeurs du draft
  // il regarde ensuite les différence entre state & draft
  // pour appliquer la différence dans un nouvel object.
  return immer(state, draft => {
    draft.dashboardDefinitions[sjmoCode][indexFocus].panels[indexPanel].visible = true;
  });
}

function addMultiplePanelToFocus(state: DashboardState, action: DashboardAction): DashboardState {
  // on extrait les valeurs du payload
  const sjmoCode: string = action.payload.sjmoCode;
  const panelsId: string[] = action.payload.panelsId;

  // focus sélectionné
  const selected = state.selected[sjmoCode];

  // on recherche l'index du focus courant
  const indexFocus = state.dashboardDefinitions[sjmoCode].findIndex(
    ({ focusId }) => focusId === selected
  );

  // on récupère l'index du panel
  let indexes: number[] = [];
  for (
    let i = 0, max = state.dashboardDefinitions[sjmoCode][indexFocus].panels.length;
    i < max;
    i++
  ) {
    let panel = state.dashboardDefinitions[sjmoCode][indexFocus].panels[i];
    if (panelsId.includes(panel.panelId)) {
      indexes.push(i);
    }
  }

  // immerjs permet de directement modifier les valeurs du draft
  // il regarde ensuite les différence entre state & draft
  // pour appliquer la différence dans un nouvel object.
  return immer(state, draft => {
    for (let index of indexes) {
      draft.dashboardDefinitions[sjmoCode][indexFocus].panels[index].visible = true;
    }
  });
}

function addOneFocusAndSelect(state: DashboardState, action: DashboardAction): DashboardState {
  const focus: DashboardFocusState = { ...action.payload.focus, panels: [] };
  const sjmoCode: string = action.payload.sjmoCode;
  const MyFocus: string = action.payload.idFocus;
  return immer(state, draft => {
    //Suppression de l'ancien focus pour eviter les doublons
    let focuses = draft.dashboardDefinitions[sjmoCode];
    focuses.splice(
      focuses.findIndex(current => current.focusId === MyFocus),
      1
    );
    draft.dashboardDefinitions[sjmoCode].push(focus);
    draft.selected = { [sjmoCode]: focus.focusId };
  });
}

function removeOneFocus(state: DashboardState, action: DashboardAction): DashboardState {
  const { focusId, sjmoCode } = action.payload;
  return immer(state, draft => {
    let focuses = draft.dashboardDefinitions[sjmoCode];
    focuses.splice(
      focuses.findIndex(current => current.focusId === focusId),
      1
    );

    // S'il n'y a plus de focus dans la liste on est obligé de mettre undefined
    draft.selected = { [sjmoCode]: focuses.length > 0 ? focuses[0].focusId : (undefined as any) };
  });
}
