import React, { FC, useState, CSSProperties, useCallback } from "react";

import throttle from "lodash-es/throttle";

import TextEditor from "./TextEditor";
import { EditorProps } from "./Editor";

const STYLES: CSSProperties = {
  width: "100%",
  height: "calc(100% - 5px)",
  overflowY: "hidden"
};

const STYLES_FOCUSED: CSSProperties = {
  width: "100%",
  minHeight: "10rem",
  zIndex: 50,
  overflowY: "auto"
};

export const DatatableEditor: FC<EditorProps> = props => {
  const [isFocused, setIsFocused] = useState(false);

  const onValueChangeThrolled = useCallback(
    throttle((text: string, name: string | undefined) => {
      props.onValueChange && props.onValueChange(name, text);
    }, 100),
    []
  );

  return (
    <TextEditor
      {...props}
      toolbar="HOVER"
      onValueChange={onValueChangeThrolled}
      onFocus={() => setIsFocused(true)}
      onBlur={e => {
        if (props.onBlur) {
          props.onBlur(e);
        }
        setIsFocused(false);
      }}
      style={isFocused ? STYLES_FOCUSED : STYLES}
    />
  );
};
