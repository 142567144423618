import { call, put, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";

import Action from "reducers/Action";

import {
  INIT_INTERACTION_GLOBAL,
  INIT_INTERACTION_SUCCESS,
  INIT_INTERACTION_BY_TABLE,
  INIT_INTERACTION_BY_TABLE_SUCCESS
} from "constant/context";
import { fetchInteractionsGlobale, fetchInteractionsByTable } from "api/interaction";
import { InteractionDefinitionState } from "reducers/modules/InteractionReducer";

/**
 * Permet de récupérer les groupes de composants associés à un focus d'expert.
 * @param action action redux
 */
function* fetchGlobalInteractions(action: Action<{ type: string }>) {
  try {
    const response: AxiosResponse<InteractionDefinitionState[]> = yield call(
      fetchInteractionsGlobale
    );
    yield put({
      type: INIT_INTERACTION_SUCCESS,
      payload: {
        definition: response.data,
        type: action.payload.type
      }
    });
  } catch {
    console.error("erreur lors de la récupération de la definition des interactions globales");
  }
}

/**
 * Permet de récupérer les interactions spécifique à une table
 * Ex : dans ActionVagueContact le clientContactId n'est pas contextualisé par ClientAdresseId
 * mais par ClientAdresseIdNumero
 * @param action action redux
 */
function* fetchInteractionByTable(action: Action<{ tableName: string }>) {
  try {
    const response: AxiosResponse<InteractionDefinitionState[]> = yield call(
      fetchInteractionsByTable,
      action.payload.tableName
    );

    const interactions: InteractionDefinitionState[] = response.data;
    yield put({
      type: INIT_INTERACTION_BY_TABLE_SUCCESS,
      payload: {
        definition: interactions
      }
    });
  } catch {
    console.error("erreur lors de la récupération de la definition des interactions globales");
  }
}

// export d'une liste qui est utilisée dans l'index des saga
export default [
  takeLatest(INIT_INTERACTION_GLOBAL, fetchGlobalInteractions),
  takeLatest(INIT_INTERACTION_BY_TABLE, fetchInteractionByTable)
];
