import React, { Component, HTMLProps } from "react";
import { Row, Col } from "../Layout";
import { Button } from "../button";
import { Pojo } from "types/Galaxy";
import { DragSourceSpec, DropTargetSpec, DragSource, DropTarget } from "react-dnd";
import PojoFilterableList from "../FilterableList/PojoFilterableList";
import { Fa } from "composants/Icon";
import Scrollbars from "react-custom-scrollbars";

const NODE_TYPE = "pickList&D";
export const AVAILABLE = "AVAILABLE";
export const CHOSEN = "CHOSEN";
export type LaneName = "AVAILABLE" | "CHOSEN";

type CardWrapperAllProps = {
  id: string;
  index: number;
  laneId: LaneName;
  moveCard?(draggedId: string, overId: string, laneId: LaneName): void;
  changeLane?(id: string, laneId: string): void;
  dropOut?(): void;
} & HTMLProps<HTMLDivElement>;

/**
 * Element draggable
 * Appel la méthode changeLane lorsque l'élément survolé et l'élément draggué appartiennent à deux listes différentes
 */
const cardSource: DragSourceSpec<CardWrapperAllProps, any> = {
  beginDrag: props => ({ index: props.index, id: props.id, laneId: props.laneId }),
  endDrag: (props, monitor) => {
    if (!monitor) {
      return;
    }

    const item = monitor.getItem() as CardWrapperAllProps;
    const dropResult = monitor.getDropResult() as any;

    if (props.changeLane && dropResult && dropResult.laneId !== item.laneId) {
      props.changeLane(item.id, dropResult.laneId);
    } else if (props.dropOut) {
      props.dropOut();
    }
  }
};

/**
 * Element droppable
 * Appel la méthode moveCard lorsqu'un élément dragggable le survole.
 */
const cardTarget: DropTargetSpec<CardWrapperAllProps> = {
  hover: (props, monitor) => {
    if (!monitor) {
      return;
    }
    const draggedId = (monitor.getItem() as any).id;

    if (draggedId !== props.id && props.moveCard) {
      props.moveCard(draggedId, props.id, props.laneId);
    }
  }
};

// Connection des composant drag and drop
const dragSource = DragSource(NODE_TYPE, cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging()
}));

const dropTarget = DropTarget(NODE_TYPE, cardTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}));

// Wrapper qui gère le drag and drop pour ses childrens
class CardWrapperInternal extends Component<
  CardWrapperAllProps & {
    connectDragSource: Function;
    connectDropTarget: Function;
    isDragging: boolean;
  }
> {
  render() {
    const {
      children,
      connectDragSource,
      connectDropTarget,
      isDragging,
      moveCard,
      changeLane,
      dropOut,
      laneId,
      ...otherProps
    } = this.props;

    return (
      connectDragSource &&
      connectDropTarget &&
      connectDragSource(
        connectDropTarget(
          <div {...otherProps} style={{ ...otherProps.style, opacity: isDragging ? 0 : 1 }}>
            {children}
          </div>
        )
      )
    );
  }
}

const CardWrapperWithDragAndDrop: any = dropTarget(dragSource(CardWrapperInternal));

// Connection de lane complete en tant que dropable
// afin de géré le cas où l'tilisateur ne déspose pas sur une card existante
const DroppableLane: DropTargetSpec<{ id: LaneName }> = {
  drop: (props, monitor) => {
    if (!monitor) {
      return;
    }

    const { id } = props;
    return {
      laneId: id
    };
  },

  hover: (_props, monitor) => {
    if (!monitor) {
      return;
    }

    return monitor.isOver();
  }
};

class LaneInternal extends Component<
  React.PropsWithChildren<{ id: LaneName; isOver: boolean; connectDropTarget?: any }>
> {
  render() {
    const { id, connectDropTarget, isOver, ...restProps } = this.props;
    return connectDropTarget && connectDropTarget(<div {...restProps} />);
  }
}

export const Lane = DropTarget(NODE_TYPE, DroppableLane, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver()
}))(LaneInternal);

// Gère le fait de cacher les composants choisis dans la liste des composants disponibles
export function getAvailableNotChosen(available: Pojo[], selected: Pojo[]) {
  const filteredPojo = available.filter(
    current => selected.findIndex(s => s.id === current.id) === -1
  );
  return filteredPojo;
}

interface CustomablePicklistProps {
  // liste des élément disponible
  available: Pojo[];
  // Liste des field sur lequel la picklist peut faire une recherche parmi les éléments dispo
  availableSearchFields?: string[];
  // Liste des éléments choisis
  chosen: Pojo[];
  // Liste des field sur lequel la picklist peut faire une recherche parmi les éléments choisi
  chosenSearchField?: string[];
  height: number;
  invertPicklist?: boolean;
  // Fonction appeler dès qu'une carte est déplacée
  moveCard(draggedId: string, overId: string, laneId: LaneName): void;
  // Fonction appeler lors d'un drop sur une lane différente
  changeLane?(id: string, laneId: string): void;
  // Fonctions permettant de définir le rendu de chaque card
  renderChosenCompo(pojo: Pojo): JSX.Element;
  renderAvailableCompo(pojo: Pojo): JSX.Element;
  // Fonction définissant le comportement à adopté lorsqu'une card est cliquée
  // (par défaut ça devrait selectionné l'élément cliquer)
  addSelectedPojo(laneId: string, pojoId: string, replace: boolean): void;
  chooseSelected?(): void;
  unChooseSelected?(): void;
  chooseAll?(): void;
  unChooseAll?(): void;
  putOnTop?(): void;
  putToBottom?(): void;
  up?(): void;
  down?(): void;
  dropOut?(): void;
}

class CustomablePicklist extends Component<CustomablePicklistProps> {
  private pendingUpdate: { draggedId: string; overId: string } | null = null;
  private requestedFrame: any = null;

  buildChildrenList = (laneId: LaneName) => {
    return (pojos: Pojo[]): JSX.Element => {
      return (
        <>
          {pojos.map((pojo, index) => {
            return (
              <div
                key={pojo.id}
                onClick={e => {
                  this.props.addSelectedPojo(laneId, pojo.id, !e.ctrlKey);
                }}
              >
                <CardWrapperWithDragAndDrop
                  key={pojo.id}
                  id={pojo.id}
                  laneId={laneId}
                  index={index}
                  moveCard={this.scheduleUpdate}
                  changeLane={this.props.changeLane}
                  dropOut={this.props.dropOut}
                >
                  {laneId === AVAILABLE && this.props.renderAvailableCompo(pojo)}
                  {laneId === CHOSEN && this.props.renderChosenCompo(pojo)}
                </CardWrapperWithDragAndDrop>
              </div>
            );
          })}
        </>
      );
    };
  };

  buildMoveButton = () => {
    return (
      <Col span={2}>
        <Scrollbars autoHide>
          {!this.props.invertPicklist ? (
            <>
              {this.props.chooseSelected && (
                <Row className="is-centered">
                  <Col span={3}>
                    <Button onClick={this.props.chooseSelected} className="is-info">
                      <Fa icon="angle-right" />
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.unChooseSelected && (
                <Row className="is-centered">
                  <Col span={3}>
                    <Button onClick={this.props.unChooseSelected} className="is-info">
                      <Fa icon="angle-left" />
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.chooseAll && (
                <Row className="is-centered">
                  <Col span={3}>
                    <button className="button is-info" onClick={this.props.chooseAll}>
                      <span className="icon is-small">
                        <Fa icon="angle-double-right" />
                      </span>
                    </button>
                  </Col>
                </Row>
              )}
              {this.props.unChooseAll && (
                <Row className="is-centered">
                  <Col span={3}>
                    <button className="button is-info" onClick={this.props.unChooseAll}>
                      <span className="icon is-small">
                        <Fa icon="angle-double-left" />
                      </span>
                    </button>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <>
              {this.props.unChooseSelected && (
                <Row className="is-centered">
                  <Col span={3}>
                    <Button onClick={this.props.unChooseSelected} className="is-info">
                      <Fa icon="angle-right" />
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.chooseSelected && (
                <Row className="is-centered">
                  <Col span={3}>
                    <Button onClick={this.props.chooseSelected} className="is-info">
                      <Fa icon="angle-left" />
                    </Button>
                  </Col>
                </Row>
              )}
              {this.props.unChooseAll && (
                <Row className="is-centered">
                  <Col span={3}>
                    <button className="button is-info" onClick={this.props.unChooseAll}>
                      <span className="icon is-small">
                        <Fa icon="angle-double-right" />
                      </span>
                    </button>
                  </Col>
                </Row>
              )}
              {this.props.chooseAll && (
                <Row className="is-centered">
                  <Col span={3}>
                    <button className="button is-info" onClick={this.props.chooseAll}>
                      <span className="icon is-small">
                        <Fa icon="angle-double-left" />
                      </span>
                    </button>
                  </Col>
                </Row>
              )}
            </>
          )}
          {this.props.up && (
            <Row className="is-centered">
              <Col span={3}>
                <button className="button is-info" onClick={this.props.up}>
                  <span className="icon is-small">
                    <Fa icon="angle-up" />
                  </span>
                </button>
              </Col>
            </Row>
          )}
          {this.props.down && (
            <Row className="is-centered">
              <Col span={3}>
                <button className="button is-info" onClick={this.props.down}>
                  <span className="icon is-small">
                    <Fa icon="angle-down" />
                  </span>
                </button>
              </Col>
            </Row>
          )}
          {this.props.putOnTop && (
            <Row className="is-centered">
              <Col span={3}>
                <button className="button is-info" onClick={this.props.putOnTop}>
                  <span className="icon is-small">
                    <Fa icon="angle-double-up" />
                  </span>
                </button>
              </Col>
            </Row>
          )}
          {this.props.putToBottom && (
            <Row className="is-centered">
              <Col span={3}>
                <button className="button is-info" onClick={this.props.putToBottom}>
                  <span className="icon is-small">
                    <Fa icon="angle-double-down" />
                  </span>
                </button>
              </Col>
            </Row>
          )}
        </Scrollbars>
      </Col>
    );
  };

  render() {
    return (
      <Row>
        <Col span={5}>
          {!this.props.invertPicklist ? (
            <Lane id={AVAILABLE}>
              <PojoFilterableList
                pojos={getAvailableNotChosen(this.props.available, this.props.chosen)}
                fields={this.props.availableSearchFields}
                listHeight={this.props.height - 40}
                buildChildrenList={this.buildChildrenList(AVAILABLE)}
              />
            </Lane>
          ) : (
            <Lane id={CHOSEN}>
              <PojoFilterableList
                pojos={this.props.chosen}
                fields={this.props.chosenSearchField}
                listHeight={this.props.height - 40}
                buildChildrenList={this.buildChildrenList(CHOSEN)}
              />
            </Lane>
          )}
        </Col>
        {this.buildMoveButton()}
        <Col span={5}>
          {!this.props.invertPicklist ? (
            <Lane id={CHOSEN}>
              <PojoFilterableList
                pojos={this.props.chosen}
                fields={this.props.chosenSearchField}
                listHeight={this.props.height - 40}
                buildChildrenList={this.buildChildrenList(CHOSEN)}
              />
            </Lane>
          ) : (
            <Lane id={AVAILABLE}>
              <PojoFilterableList
                pojos={getAvailableNotChosen(this.props.available, this.props.chosen)}
                fields={this.props.availableSearchFields}
                listHeight={this.props.height - 40}
                buildChildrenList={this.buildChildrenList(AVAILABLE)}
              />
            </Lane>
          )}
        </Col>
      </Row>
    );
  }

  scheduleUpdate = (draggedId: string, overId: string, laneId: LaneName) => {
    this.pendingUpdate = { draggedId, overId };
    if (!this.requestedFrame && this.pendingUpdate) {
      this.requestedFrame = requestAnimationFrame(() => {
        this.props.moveCard(draggedId, overId, laneId);
        this.requestedFrame = null;
        this.pendingUpdate = null;
      });
    }
  };
}

export default CustomablePicklist;
