import React, { FC, useMemo, useTransition } from "react";
import classNames from "classnames";
import { Menu } from "types/Menu";

import { getSizeClasses } from "composants/common";
import "style/expertmenu.css";
import { Fa } from "composants/Icon";
import { t } from "utils/i18n";
import { Menu as DropdownMenu } from "composants/DropDown/Menu";
import {
  useLocation,
  useNavigate,
  useHref,
  useLinkClickHandler,
  LinkProps
} from "react-router-dom";
import { Button } from "composants/button";
import { track } from "tracking";

interface ExpertMenuProps {
  width?: string;
  className?: string;
  menu: Menu[];
  // La taille du menu (small, medium, large) sont les seules valeurs attendues
  size?: string;
  disabled: boolean;
}

type ExpertMenuAllProps = ExpertMenuProps & {
  sjmoCode: string;
  onOpenExpert: () => void;
};

function groupByMenuByLevel(menus: Menu[]): Menu[][] {
  // on transforme la liste des menus en map(level, liste de menu du level)
  // menu[] -> {level: menu[]} -> menu[][]

  let menuByLevel: Record<string, Menu[]> = {};

  for (let menu of menus) {
    if (menu.level == null) continue;

    if (!menuByLevel[menu.level]) {
      menuByLevel[menu.level] = [];
    }

    menuByLevel[menu.level].push(menu);
  }

  // on retourne une liste des listes de menu.
  return Object.keys(menuByLevel).map(key => menuByLevel[key]);
}

const ExpertMenu: FC<ExpertMenuAllProps> = props => {
  const { className } = props;
  const sizeClass = getSizeClasses(props.size);

  const menusList = useMemo(() => {
    return groupByMenuByLevel(props.menu);
  }, [props.menu]);

  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {menusList.length > 0 && (
        <DropdownMenu
          autoclose
          className={classNames(className, sizeClass)}
          disabled={props.disabled}
        >
          <DropdownMenu.Button
            className={classNames("button", "is-outlined", "is-link", sizeClass)}
            title={t("commun_expert")}
            disabled={props.disabled}
          >
            <span className="icon">
              <Fa icon="pencil" />
            </span>
          </DropdownMenu.Button>
          <DropdownMenu.Overlay className="menubox" style={{ minWidth: "50rem" }}>
            <div style={{ padding: "1em" }}>
              {menusList.map((menus, index) => {
                return (
                  <ExpertMenuContainer key={index}>
                    {menus.map(menu => {
                      return (
                        <ExpertMenuTitle
                          key={menu.code}
                          code={menu.code}
                          label={menu.label}
                          onOpenExpert={props.onOpenExpert}
                        >
                          {menu.children.map(subMenu => {
                            return (
                              <ExpertMenuSubtitle
                                key={subMenu.code}
                                code={subMenu.code}
                                label={subMenu.label}
                                onOpenExpert={props.onOpenExpert}
                              />
                            );
                          })}
                        </ExpertMenuTitle>
                      );
                    })}
                  </ExpertMenuContainer>
                );
              })}
            </div>
          </DropdownMenu.Overlay>
        </DropdownMenu>
      )}
      {menusList.length === 0 && (
        <Button
          title={t("commun_expert")}
          outlined
          color="link"
          onClick={() => {
            navigate(location.pathname + "/expert");
            track("galaxy::expert::open");
          }}
          disabled={props.disabled}
        >
          <span className="icon">
            <Fa icon="pencil" />
          </span>
        </Button>
      )}
    </>
  );
};

const ExpertMenuContainer: FC<React.PropsWithChildren<{}>> = ({ children }) => {
  // on parcourt chaque entrée de la map qui correspond à un level afin de crée le level correspondant
  return (
    <div style={{ marginBottom: "1em" }}>
      <div className="columns" style={{ width: "100%" }}>
        {children}
      </div>
    </div>
  );
};

function ExpertLink({ to, relative, replace, state, target, onClick, ...rest }: LinkProps) {
  const href = useHref(to, { relative });
  let handleClick = useLinkClickHandler(to, {
    replace,
    state,
    target
  });
  const [, startTransition] = useTransition();

  function internalOnClick(e: React.MouseEvent<HTMLAnchorElement>) {
    onClick?.(e);
    if (!e.defaultPrevented) {
      startTransition(() => {
        handleClick(e);
      });
    }
  }

  return <a {...rest} href={href} onClick={internalOnClick} target={target} />;
}

const ExpertMenuTitle: FC<React.PropsWithChildren<{
  code: string;
  label: string;
  onOpenExpert: () => void;
}>> = ({ code, label, onOpenExpert, children }) => {
  const location = useLocation();
  return (
    <div className="column" key={code}>
      <h1 className="title is-5">
        <ExpertLink
          className="link"
          to={`${location.pathname}/expert/${code}`}
          onClick={() => {
            onOpenExpert();
          }}
        >
          {label}
        </ExpertLink>
      </h1>
      {children}
    </div>
  );
};

const ExpertMenuSubtitle: FC<{ code: string; label: string; onOpenExpert(): void }> = ({
  code,
  label,
  onOpenExpert
}) => {
  const location = useLocation();

  return (
    <h2 className="subtitle is-6" key={code} style={{ marginTop: "-1em", paddingLeft: "0.5em" }}>
      <ExpertLink
        className="link"
        to={`${location.pathname}/expert/${code}`}
        style={{ color: "#3273dc" }}
        onClick={() => {
          onOpenExpert();
        }}
      >
        {label}
      </ExpertLink>
    </h2>
  );
};

export default ExpertMenu;
