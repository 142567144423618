import React, {
  CSSProperties,
  Component,
  SyntheticEvent,
  KeyboardEvent,
  FC,
  MouseEvent,
  InputHTMLAttributes
} from "react";
import { SortableElement, SortableHandle, SortableContainer } from "react-sortable-hoc";
import Draggable, { DraggableEventHandler } from "react-draggable";
import { GridProps, Grid } from "react-virtualized";
import classNames from "classnames";

import memoize from "memoizee";
import { Item } from "types/Component";
import { Fa } from "composants/Icon";
import { TypeSimpleComponent } from "enum";
import { useTranslation } from "react-i18next";

interface DragHandleProps {
  label: string;
  tooltip?: string;
  required: boolean;
}
export const DragHandle = SortableHandle<DragHandleProps>(
  ({ label, tooltip, required }: DragHandleProps) => (
    <label className="datatable-header-cell--label" data-required={required}>
      {tooltip ? <abbr title={tooltip}>{label}</abbr> : label}
    </label>
  )
);

const computeStyle = memoize((args: object) => ({ ...args }), {
  length: false,
  primitive: true,
  normalizer: function(...args: any[]) {
    return JSON.stringify(args);
  }
});

interface SortableHeaderItemProps {
  column: string;
  typeCompo: string;
  label: string;
  filterOpen: boolean;
  tooltip?: string;
  style: CSSProperties;
  required: boolean;
  children?: React.ReactNode;
  sort?: "ASC" | "DESC" | "NONE";
  onResize?: DraggableEventHandler;
  onSortAsc?(e: MouseEvent<HTMLAnchorElement>): void;
  onSortDesc?(e: MouseEvent<HTMLAnchorElement>): void;
}

export const SortableHeaderItem = SortableElement<SortableHeaderItemProps>(
  ({
    column,
    typeCompo,
    label,
    tooltip,
    style,
    required,
    children,
    sort = "NONE",
    onResize,
    onSortAsc,
    onSortDesc,
    filterOpen,
    ...props
  }: SortableHeaderItemProps) => {
    return (
      <div
        className="datatable-header-cell has-text-weight-semibold"
        data-filter-active={filterOpen}
        style={style}
        {...props}
      >
        <div className="datatable-header-cell--title" style={computeStyle({ width: style.width })}>
          {onResize ? (
            <Draggable axis="x" onDrag={onResize} position={{ x: 0, y: 0 }}>
              <span className="datatable-header-cell--resize" style={{ paddingRight: 5 }}>
                ⋮
              </span>
            </Draggable>
          ) : null}
          <DragHandle label={label} tooltip={tooltip} required={required} />
          {typeCompo !== TypeSimpleComponent.OEL && (
            <div className="sort-container" style={{ marginRight: 5 }}>
              <a
                style={{
                  color: sort === "ASC" ? "red" : undefined
                }}
                onClick={onSortAsc}
              >
                <Fa icon="sort-up" />
              </a>
              <a
                style={{
                  color: sort === "DESC" ? "red" : undefined
                }}
                onClick={onSortDesc}
              >
                <Fa icon="sort-down" />
              </a>
            </div>
          )}
        </div>
        {children}
      </div>
    );
  },
  { withRef: false }
);

interface ComponentFilterProps {
  name: string;
  filterOpen: boolean;
  filterValue: any;
  onFilterChange(e: SyntheticEvent<any>): void;
  onKeyDown(e: KeyboardEvent<HTMLInputElement>): void;
}

export const ComponentFilterInput: FC<ComponentFilterProps &
  InputHTMLAttributes<HTMLInputElement>> = ({
  name,
  filterOpen,
  filterValue = "",
  onFilterChange,
  onKeyDown,
  ...props
}) => {
  return (
    <input
      name={name}
      className="input is-small"
      style={computeStyle({
        width: "100%",
        display: filterOpen ? "block" : "none"
      })}
      value={filterValue}
      onChange={onFilterChange}
      onKeyDown={onKeyDown}
      {...props}
    />
  );
};

export const ComponentFilterSelect: FC<ComponentFilterProps & { options: Item[] }> = ({
  name,
  filterValue = "",
  onFilterChange,
  onKeyDown,
  filterOpen,
  options
}) => {
  return (
    <div
      className="select is-small"
      style={computeStyle({
        display: filterOpen ? "block" : "none"
      })}
    >
      <select name={name} value={filterValue} onChange={onFilterChange}>
        <option />
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export const ComponentFilterCheckbox: FC<ComponentFilterProps> = ({
  name,
  filterOpen,
  filterValue,
  onFilterChange
}) => {
  const [t] = useTranslation();

  return (
    <div
      className="select is-small"
      style={computeStyle({
        display: filterOpen ? "block" : "none"
      })}
    >
      <select name={name} value={filterValue} onChange={onFilterChange}>
        <option />
        <option value="true">{t("commun_oui")}</option>
        <option value="false">{t("commun_non")}</option>
      </select>
    </div>
  );
};

export const SortableHeaderContainer = SortableContainer<
  GridProps & { innerRef?: (ref: Grid | null) => void }
>(
  class InnerSortableHeaderContainer extends Component<GridProps> {
    render() {
      const { innerRef, className, ...restProps } = this.props;
      return (
        <Grid ref={innerRef} className={classNames("datatable-header", className)} {...restProps} />
      );
    }
  },
  {
    withRef: true
  }
);
