import React, { FC, useState } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import { t, formatDate } from "utils/i18n";
import classNames from "classnames";
import Scrollbars from "react-custom-scrollbars";
import { fetchValidationData, validateStep, modifyStep, modifyRStep, refuseStep } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import TabHeader from "./TabHeader";
import { Fa } from "composants/Icon";
import { useCallback } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { Row, Col } from "composants/Layout";
import { useRef } from "react";
import LoaderContainer from "composants/LoaderContainer";
import { useSpring, animated } from "react-spring";

// Type de décision sur une ligne de validation
// Respectivement Validé, à modifier, à modifier (r), refusé
// provient de PL_VACL_DECISION sur sys_domaine_val
type decisionType = "VAL" | "MOC" | "MOI" | "REF" | undefined;

export interface EtapeValidation {
  id: number; // validationCircuitLigne.id
  commentaire: string; // validationCircuitLigne.vacl_commentaire
  commentaireAction: string; // validationCircuitLigne.vacl_commentaire_action
  valideurPrenom?: string; // validationCircuitligne.personnel
  valideurNom?: string; // validationCircuitligne.personnel
  userCourantValideur: boolean;
  role?: string; // validationCircuitligne.sysmenugroupeid
  decision: decisionType; // validationCircuitligne.vacl_decision
  decideurPrenom?: string; // validationCircuitligne.personnelIdDec
  decideurNom?: string; // validationCircuitligne.personnelIdDec
  dateDecision?: Date; // vacl_dt_dec
}

export interface Validation {
  id: number; // validation circuit
  titre?: string; // valcLibelle
  description?: string; //vaclDescription
  responsableNom?: string; // personnelId
  responsablePrenom?: string; // personnelId
  dateCreation?: Date; // vacldtCreation
  dateCloture?: Date; // vacldtcloture
  dateEcheance?: Date; // vaclEcheance
  status: string; // vaclstatut
  etapes: EtapeValidation[]; // position des validationCircuitLigne après order by ligne
  modifiable?: boolean;
  reinitialisationForcee?: boolean;
  sequentiel?: boolean;
  modificationBloquee?: boolean;
}

interface TabValidationState {
  validations: Validation[];
}

/**
 * Construit une étapes de validation.
 *
 * Si une décision est définie on place le is-completed suivi d'un icone en fonction de la décision prise.
 * Sinon on ne met pas d'icone.
 *
 * @param etapes
 */
const Step: FC<EtapeValidation & {
  isSelected: boolean;
  hasOnlyOneStep: boolean;
  onClickStep: (step: EtapeValidation) => void;
}> = currentStep => {
  const statusClassName = useMemo(
    () => [
      currentStep.decision ? "is-completed" : "",
      currentStep.decision === "VAL" ? "is-success" : "",
      currentStep.decision === "REF" ? "is-danger" : "",
      currentStep.decision === "MOI" || currentStep.decision === "MOC" ? "is-warning" : ""
    ],
    [currentStep.decision]
  );

  const traductionTitle = t("commun_decide_par", {
    prenom: currentStep.decideurPrenom,
    nom: currentStep.decideurNom
  });

  const traductionSubtitle =
    currentStep.decision === "VAL"
      ? t("commun_valide_par", {
          prenom: currentStep.valideurPrenom,
          nom: currentStep.valideurNom,
          role: currentStep.role,
          context: !currentStep.valideurPrenom && currentStep.role ? "role" : undefined
        })
      : t("commun_a_valider_par", {
          prenom: currentStep.valideurPrenom,
          nom: currentStep.valideurNom,
          role: currentStep.role,
          context: !currentStep.valideurPrenom && currentStep.role ? "role" : undefined
        });

  return (
    <div
      className={classNames("step-item pb-6 has-border-link", [...statusClassName])}
      style={{
        borderBottom:
          currentStep.isSelected && !currentStep.hasOnlyOneStep ? "solid 2px #3273dc" : "none"
      }}
      title={currentStep.commentaire}
    >
      <div className="step-marker">
        <span className="icon">
          {currentStep.decision === "VAL" && <Fa icon="check" />}
          {currentStep.decision === "MOC" && <Fa icon="pencil-alt" />}
          {currentStep.decision === "MOI" && <Fa icon="pen-fancy" />}
          {currentStep.decision === "REF" && <Fa icon="times-square" />}
        </span>
      </div>
      <div className={classNames("step-details", [...statusClassName])}>
        <p className="step-title">{currentStep.commentaireAction}</p>
        {currentStep.decideurNom && currentStep.decideurPrenom && <p>{traductionTitle}</p>}
        <p>{traductionSubtitle}</p>
        {currentStep.dateDecision && <p>{formatDate(currentStep.dateDecision)}</p>}
        <button
          className="button is-text is-small"
          onClick={() => currentStep.onClickStep(currentStep)}
        >
          {t("commun_commenter")}
        </button>
      </div>
    </div>
  );
};

const Scenario: FC<Validation & {
  validate: (step: EtapeValidation) => void;
  modify: (step: EtapeValidation) => void;
  modifyR: (step: EtapeValidation) => void;
  refuse: (step: EtapeValidation) => void;
}> = validation => {
  const [selected, setSelected] = useState<EtapeValidation | null>(null);

  const disableValidation = useRef<boolean>(true);
  const hasOnlyOneStep = useRef<boolean>(false);
  useEffect(() => {
    hasOnlyOneStep.current = validation.etapes.length === 1;
  }, [validation.etapes.length]);

  const animationProps = useSpring({
    from: { height: 0, opacity: 0 },
    to: { height: selected ? 250 : 0, opacity: selected ? 1 : 0 }
  });

  const onClick = useCallback(
    (step: EtapeValidation) => {
      if (selected === null || step.id !== selected.id) {
        setSelected({ ...step, commentaire: step.commentaire ? step.commentaire : "" });
        disableValidation.current = !step.userCourantValideur || !!step.decision;
      } else {
        setSelected(null);
      }
    },
    [selected]
  );

  return (
    <div className="box donnees-satellite-container" key={validation.id}>
      <article className="media">
        <div className="media-left">
          <h1 className="title">{validation.titre}</h1>
          <div>{validation.description}</div>
          <div>{`${validation.responsablePrenom != null ? validation.responsablePrenom : ""} ${
            validation.responsableNom != null ? validation.responsableNom : ""
          }`}</div>
          <div>{`${t("commun_creer_le")} : ${formatDate(validation.dateCreation)}`}</div>
          <div>{`${t("commun_echance_le")} : ${
            validation.dateEcheance ? formatDate(validation.dateEcheance) : ""
          }`}</div>
          <div>
            {`${t("commun_clos_le")} : ${
              validation.dateCloture ? formatDate(validation.dateCloture) : ""
            }`}
          </div>
          <div>
            {validation.modifiable && (
              <Fa icon="pen" title={t("commun_modifiable")} className="mr-5" />
            )}
            {validation.modificationBloquee && (
              <Fa icon="lock" title={t("commun_modification_bloquee")} className="mr-5" />
            )}
            {validation.reinitialisationForcee && (
              <Fa icon="retweet" title={t("commun_reinitialisation_forcee")} className="mr-5" />
            )}
            {validation.sequentiel && <Fa icon="stream" title={t("commun_sequentiel")} />}
          </div>
        </div>
        <div className="media-content">
          <div className="conf-step steps">
            {validation &&
              validation.etapes.map(step => {
                return (
                  <Step
                    key={step.id}
                    {...step}
                    onClickStep={onClick}
                    isSelected={selected !== null && step.id === selected.id}
                    hasOnlyOneStep={hasOnlyOneStep.current}
                  />
                );
              })}
          </div>
        </div>
      </article>
      <animated.div style={animationProps}>
        {selected != null && (
          <>
            <hr />
            <div className="is-size-4 mt-5">
              {`${
                selected.decision === "VAL" ? t("commun_valide_par") : t("commun_a_valider_par")
              } ${selected.valideurPrenom ? selected.valideurPrenom + " " : ""}${
                selected.valideurNom ? selected.valideurNom : ""
              } ${!selected.valideurPrenom && selected.role ? selected.role : ""}`}
            </div>
            <Row>
              <Col className="is-narrow">
                <textarea
                  rows={8}
                  cols={100}
                  autoFocus
                  className="p-6"
                  disabled={disableValidation.current}
                  value={selected.commentaire}
                  onChange={t => setSelected({ ...selected, commentaire: t.target.value })}
                />
              </Col>
              <Col>
                <div className="mb-7">
                  <button
                    className="button is-success"
                    onClick={() => {
                      validation.validate(selected);
                      setSelected(null);
                    }}
                    disabled={disableValidation.current}
                  >
                    {t("commun_valider")}
                  </button>
                </div>
                <div className="mb-7">
                  <button
                    className="button"
                    onClick={() => {
                      validation.modify(selected);
                      setSelected(null);
                    }}
                    disabled={disableValidation.current}
                  >
                    {t("commun_modifier")}
                  </button>
                </div>
                <div className="mb-7">
                  <button
                    className="button"
                    onClick={() => {
                      validation.modifyR(selected);
                      setSelected(null);
                    }}
                    disabled={disableValidation.current}
                  >
                    {t("commun_modifier_r")}
                  </button>
                </div>
                <div className="mb-7">
                  <button
                    className="button is-danger"
                    onClick={() => {
                      validation.refuse(selected);
                      setSelected(null);
                    }}
                    disabled={disableValidation.current}
                  >
                    {t("commun_refuser")}
                  </button>
                </div>
              </Col>
            </Row>
          </>
        )}
      </animated.div>
    </div>
  );
};

function refresh(
  tableName: string,
  contextId: string,
  countAction: (tableName: string, id?: string) => void,
  addMessage: (message: Message) => void,
  setValidations: (validations: Validation[]) => void,
  setLoading: (loading: boolean) => void
) {
  fetchValidationData(tableName, contextId as string)
    .then(response => {
      setValidations(response.data);
      setLoading(false);
    })
    .catch(e => {
      const er = e as AxiosError<any>;
      if (!er.response) {
        return;
      }

      const message: Message = {
        code: er.response.data.code,
        message: t(er.response.data.message),
        type: er.response.data.type,
        target: er.response.data.target
      };
      addMessage(message);
    });
  countAction(tableName, contextId);
}

const TabValidation: FC<TabSatelliteProps> = props => {
  const { sjmoCode, tableName, contextId, shouldRefreshGalaxie } = props;
  const [validations, setValidations] = useState<Validation[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const refreshData = useCallback(() => {
    if (tableName && contextId) {
      refresh(
        tableName,
        contextId as string,
        props.countAction,
        props.addMessage,
        setValidations,
        setLoading
      );
    }
  }, [contextId, props.addMessage, props.countAction, tableName]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  const validate = useCallback(
    (step: EtapeValidation) => {
      setLoading(true);
      validateStep({ sjmoCode, step })
        .then(() => {
          refreshData();
          shouldRefreshGalaxie(true);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    },
    [refreshData, shouldRefreshGalaxie, sjmoCode]
  );

  const modify = useCallback(
    (step: EtapeValidation) => {
      setLoading(true);
      modifyStep({ sjmoCode, step })
        .then(() => {
          refreshData();
          shouldRefreshGalaxie(true);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    },
    [refreshData, shouldRefreshGalaxie, sjmoCode]
  );

  const modifyR = useCallback(
    (step: EtapeValidation) => {
      setLoading(true);
      modifyRStep({ sjmoCode, step })
        .then(() => {
          refreshData();
          shouldRefreshGalaxie(true);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    },
    [refreshData, shouldRefreshGalaxie, sjmoCode]
  );

  const refuse = useCallback(
    (step: EtapeValidation) => {
      setLoading(true);
      refuseStep({ sjmoCode, step })
        .then(() => {
          refreshData();
          shouldRefreshGalaxie(true);
        })
        .catch(e => {
          console.log(e);
          setLoading(false);
        });
    },
    [refreshData, shouldRefreshGalaxie, sjmoCode]
  );

  return (
    <>
      <LoaderContainer loading={loading}>
        <TabHeader
          i18nKey="commun_validations_liees"
          tableName={props.tableName}
          contextId={props.contextId}
          count={props.count}
          sjmoCode={props.sjmoCode}
        />
        <Scrollbars autoHide style={{ height: props.height }}>
          {validations.map(validation => {
            return (
              <Scenario
                key={validation.id}
                {...validation}
                validate={validate}
                modify={modify}
                modifyR={modifyR}
                refuse={refuse}
              />
            );
          })}
        </Scrollbars>
      </LoaderContainer>
    </>
  );
};

export default TabValidation;
