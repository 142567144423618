import React, { FC, lazy } from "react";
import { ProcessusAvanceCompo } from "AppRoute";
import { Route, useNavigate, useParams } from "react-router-dom";
const AdminGestionDroitsGalaxie = lazy(() =>
  import("./AdminGestionDroitsGalaxie/AdminGestionDroitsGalaxie")
);
const AdminGestionDroitsFocusGalaxie = lazy(() =>
  import("./AdminGestionDroitsFocusGalaxie/AdminGestionDroitsFocusGalaxie")
);
const AdminGestionDroitsPanels = lazy(() =>
  import("./AdminGestionDroitsPanels/AdminGestionDroitsPanels")
);
const AdminGestionDroitsFocusCreator = lazy(() =>
  import("./AdminGestionDroitsFocusCreator/AdminGestionDroitsFocusCreator")
);
const AdminGestionDroitsFocusDT = lazy(() =>
  import("./AdminGestionDroitsFocusDT/AdminGestionDroitsFocusDT")
);
const AdminGestionDroitsProcessus = lazy(() =>
  import("./AdminGestionDroitsProcessus/AdminGestionDroitsProcessus")
);
const AdminDroitsCRUD = lazy(() => import("./AdminDroitsCRUD/AdminDroitsCRUD"));
const AdminDroitsUser = lazy(() => import("./AdminDroitsUser/AdminDroitsUser"));
const AdminMiniExpert = lazy(() => import("../composants/AdminMiniExpert/AdminMiniExpert"));

const AdminMiniExpertCompo: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  return <AdminMiniExpert params={params} navigate={navigate} />;
};

export const AppRouteAdmin: FC = () => {
  return (
    <>
      <Route path="adminGestionDroitsGalaxie" element={<AdminGestionDroitsGalaxie />} />
      <Route
        path="adminGestionDroitsGalaxie/:syjModuleId"
        element={<AdminGestionDroitsGalaxie />}
      />
      <Route path="adminGestionDroitsFocusGalaxie" element={<AdminGestionDroitsFocusGalaxie />} />
      <Route path="adminGestionDroitsPanels" element={<AdminGestionDroitsPanels />} />
      <Route path="adminGestionDroitsPanels/:syjPanelId" element={<AdminGestionDroitsPanels />} />
      <Route path="adminGestionDroitsFocusCreator" element={<AdminGestionDroitsFocusCreator />} />
      <Route
        path="adminGestionDroitsFocusCreator/:syjCreatorFocusId"
        element={<AdminGestionDroitsFocusCreator />}
      />
      <Route path="adminGestionDroitsFocusDT" element={<AdminGestionDroitsFocusDT />} />
      <Route path="adminGestionDroitsProcessus" element={<AdminGestionDroitsProcessus />} />
      <Route path="adminDroitsCRUD" element={<AdminDroitsCRUD />} />
      <Route path="adminDroitsUser" element={<AdminDroitsUser />} />
      <Route path="miniexpert/:tableId/:focusId" element={<AdminMiniExpertCompo />} />
      <Route path="miniexpert/:tableId" element={<AdminMiniExpertCompo />} />
      <Route path="miniexpert" element={<AdminMiniExpertCompo />} />
      <Route element={<ProcessusAvanceCompo />} />
    </>
  );
};
