import React, { Component, HTMLAttributes } from "react";

export class Scroll extends Component<
  { innerRef?(el: HTMLDivElement): void; onScrollEnd?(): void } & HTMLAttributes<HTMLDivElement>
> {
  private container: HTMLDivElement | null;

  setupRef = (el: HTMLDivElement) => {
    this.container = el;
    this.props.innerRef && this.props.innerRef(el);
  };

  handleScroll = (evt: Event) => {
    const node = evt.target as HTMLDivElement;
    if (node == null) {
      return;
    }

    const elemScrollPosition = node.scrollHeight - node.scrollTop - node.clientHeight;

    if (elemScrollPosition <= 0) {
      this.props.onScrollEnd && this.props.onScrollEnd();
    }
  };

  componentDidMount() {
    if (this.container) {
      this.container.addEventListener("scroll", this.handleScroll);
    }
  }

  componentWillUnmount() {
    this.container && this.container.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { innerRef, onScrollEnd, ...otherProps } = this.props;
    return <div ref={this.setupRef} {...otherProps} />;
  }
}
