import React, { FC } from "react";
import { ModalTemplate } from "./template";

export const ValidateFlux: FC<{
  sjmoCode: string;
  template: string;
  kanbanId?: string;
  processId?: string;
  entityId: string;
}> = props => {
  const SelectedComponent = ModalTemplate[props.template];
  return (
    <SelectedComponent
      sjmoCode={props.sjmoCode}
      kanbanDefinitionId={props.kanbanId}
      processId={props.processId}
      id={props.entityId}
    />
  );
};
