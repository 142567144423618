import React, { FC, useEffect, useState, CSSProperties } from "react";
import { Fa } from "composants/Icon";
import { getEnvironment } from "api/userSettings";

const Ribbon: FC<{ style?: CSSProperties }> = props => {
  const [environment, setEnvironment] = useState<string | null>(null);

  useEffect(() => {
    getEnvironment()
      .then(response => setEnvironment(response.data.length > 0 ? response.data : null))
      .catch(e => {
        console.error("error fetch environment", e);
      });
  }, []);

  if (environment != null) {
    return (
      <div className="ribbon" style={props.style}>
        <span className="icon">
          <Fa icon={["fal", "database"]} fixedWidth />
        </span>
        <span>{environment}</span>
      </div>
    );
  } else {
    return <div />;
  }
};
export default Ribbon;
