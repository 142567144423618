import React from "react";

import AutoCompleteImpl from "composants/autocomplete/AutoComplete";
import Checkbox from "composants/checkbox/Checkbox";
import Select from "composants/select/Select";
import Oel from "composants/oel/Oel";
import Input, { InputNumber, InputMask } from "composants/input/Input";
import Calendar from "composants/calendar/Calendar";
import Information from "containers/information/Information";

import AutoCompleteAndLabel from "composants/autocomplete/AutoCompleteAndLabel";
import CheckBoxAndLabel from "composants/checkbox/CheckBoxAndLabel";
import CalendarAndLabel from "composants/calendar/CalendarAndLabel";
import CalendarHoursAndLabel from "composants/calendar/CalendarHoursAndLabel";
import SysDomaineAndLabel from "composants/select/SysDomaineAndLabel";
import InputTextAndLabel, {
  InputNumberAndLabel,
  InputMaskAndLabel
} from "composants/input/InputTextAndLabel";
import CalendarHours from "composants/calendar/CalendarHours";

import { TextEditorAndLabel } from "composants/RichTextEditor/TextEditorAndLabel";
import TextEditor from "composants/RichTextEditor/TextEditor";
import { ProcessusNatureValue } from "types/Processus";
import { IconName } from "@fortawesome/pro-solid-svg-icons";
import autocompleteViewAndLabel from "composants/autocompleteView/autocompleteViewAndLabel";
import autocompleteViewFreeAndLabel from "composants/autocompleteViewFree/autocompleteViewFreeAndLabel";
import AutocompleteView from "composants/autocompleteView/autocompleteView";
import AutocompleteViewFree from "composants/autocompleteViewFree/autocompleteViewFree";

export const SimpleComponent = {
  GS: AutoCompleteImpl,
  GSV: AutocompleteView,
  GSVL: AutocompleteViewFree,
  CH: Checkbox,
  CA: Calendar,
  CAH: CalendarHours,
  SD: Select,
  OEL: Oel,
  I: Input,
  INUM: InputNumber,
  IMASK: InputMask,
  ED: TextEditor
};

export const SimpleComponentAndLabel = {
  GS: AutoCompleteAndLabel,
  CH: CheckBoxAndLabel,
  CA: CalendarAndLabel,
  CAH: CalendarHoursAndLabel,
  SD: SysDomaineAndLabel,
  OEL: Oel,
  I: InputTextAndLabel,
  INUM: InputNumberAndLabel,
  IMASK: InputMaskAndLabel,
  ED: TextEditorAndLabel,
  GSV: autocompleteViewAndLabel,
  GSVL: autocompleteViewFreeAndLabel
};

export const ComplexComponent = {
  DT: React.lazy(() => import("containers/datatable/Datatable")),
  CHART: React.lazy(() => import("containers/chart/Chart")),
  CREATOR: React.lazy(() => import("containers/creator/Creator")),
  INFORMATION: Information,
  MINI_EXPERT: React.lazy(() => import("containers/miniexpert/MiniExpert")),
  ARBO: React.lazy(() => import("containers/tree/GenericTree")),
  EDITOR: React.lazy(() => import("containers/panelEditor/PanelEditor")),
  DGL: React.lazy(() => import("containers/listegenerique/DraggableGroupList")),
  SAS: React.lazy(() => import("composants/sas/Sas")),
  NAV_CARDS: React.lazy(() => import("composants/card/NavigationCardList")),
  INTERACTIVE_REPORT: React.lazy(() => import("containers/datatable/InteractiveReport")),
  INDICATEUR: React.lazy(() => import("composants/indicateur/IndicateurList")),
  PLANIF: React.lazy(() => import("composants/processus/Planification"))

  // à completer au fur et à mesure
};

export const AffectationType = {
  GLOBAL: "GLOBAL",
  ROLE: "ROLE",
  USER: "USER",
  MODULE: "MODULE",
  NON_ASSIGNE: "NONE"
};

export const ProcessusType: Record<string, ProcessusNatureValue> = {
  NAV_INT: "NAINT",
  NAV_EXT: "NAEXT",
  TRAIT: "TRAIT",
  JAVA: "JAVA",
  EDITS: "EDITS",
  EDITA: "EDITA"
};

export const TypeSimpleComponent = {
  AUTO_COMPLETE: "GS",
  CHECKBOX: "CH",
  CALENDAR: "CA",
  CALENDAR_HOUR: "CAH",
  SYS_DOMAINE: "SD",
  OEL: "OEL",
  INPUT: "I",
  INPUT_NUMBER: "INUM",
  INPUT_MASK: "IMASK",
  EDITOR: "ED",
  AUTO_COMPLETE_VIEW: "GSV",
  AUTO_COMPLETE_VIEW_FREE: "GSVL"
};

export const TypeComplexComponent = {
  DATATABLE: "DT",
  CHART: "CHART",
  CREATOR: "CREATOR",
  INFORMATION: "INFORMATION",
  SCHEDULER: "SCHEDULER",
  UDT: "UDT",
  MINI_EXPERT: "MINI_EXPERT",
  SAS: "SAS",
  NAV_CARDS: "NAV_CARDS",
  EDITOR: "EDITOR",
  INDICATEUR: "INDICATEUR",
  INTERACTIVE_REPORT: "INTERACTIVE_REPORT",
  ARBO: "ARBO",
  DGL: "DGL",
  PLANIF: "PLANIF"
};

export function getIconByPanelType(panelType: string): IconName | null {
  switch (panelType) {
    case TypeComplexComponent.DATATABLE:
      return "table";
    case TypeComplexComponent.CHART:
      return "chart-pie";
    case TypeComplexComponent.CREATOR:
      return "external-link-square";
    case TypeComplexComponent.INFORMATION:
      return "info-square";
    case TypeComplexComponent.SCHEDULER:
      return "calendar-alt";
    case TypeComplexComponent.UDT:
      return null;
    case TypeComplexComponent.MINI_EXPERT:
      return "pen-square";
    case TypeComplexComponent.SAS:
      return "file-import";
    case TypeComplexComponent.NAV_CARDS:
      return "route";
    case TypeComplexComponent.EDITOR:
      return "typewriter";
    case TypeComplexComponent.INDICATEUR:
      return "dot-circle";
    case TypeComplexComponent.INTERACTIVE_REPORT:
      return "table";
    case TypeComplexComponent.ARBO:
      return "folder-tree";
    case TypeComplexComponent.DGL:
      return "file-certificate";
    default:
      return "question";
  }
}
