import React, { FC, useCallback, useEffect, useRef } from "react";
import { Portal, PortalProvider } from "composants/Portal";
import { Button } from "composants/button";
import { Trans } from "react-i18next";

interface ConfirmationModalProps {
  id: string;
  top: number;
  left: number;
  buttonRef: React.RefObject<any>;
  launchProcess(): void;
  close(): void;
}

const ConfirmationModal: FC<ConfirmationModalProps> = ({
  id,
  top,
  left,
  buttonRef,
  launchProcess,
  close
}) => {
  const refButton = useRef<HTMLElement | null>(null);
  refButton.current = buttonRef.current;

  const onCloseEventListener = useCallback(
    (e: MouseEvent) => {
      const el =
        e.target &&
        ((e.target as HTMLElement).closest("#confirmProcessBox-" + id) ||
          e.target == refButton.current ||
          refButton.current?.contains(e.target as Node));
      if (!el) {
        close();
      }
    },
    [close, id]
  );

  useEffect(() => {
    document.addEventListener("click", onCloseEventListener);
    return function cleanup() {
      document.removeEventListener("click", onCloseEventListener);
    };
  }, [onCloseEventListener]);

  return (
    <Portal>
      <PortalProvider domRef={id}>
        <div
          id={"confirmProcessBox-" + id}
          className="box"
          style={{
            width: 300,
            height: 130,
            position: "absolute",
            top,
            left: left + 220
          }}
        >
          <p className="is-size-7 mb-7">
            <Trans i18nKey="commun_processus_galaxy_dirty" />
          </p>
          <nav className="level">
            <div className="level-item">
              <Button
                className="button is-small is-danger"
                onClick={() => {
                  launchProcess();
                  close();
                }}
              >
                <Trans key="commun_lancer">Lancer</Trans>
              </Button>
            </div>
            <div className="level-item">
              <Button className="button is-small" onClick={close}>
                <Trans key="commun_annuler">Annuler</Trans>
              </Button>
            </div>
          </nav>
        </div>
      </PortalProvider>
    </Portal>
  );
};

export default ConfirmationModal;
