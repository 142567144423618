import React, { CSSProperties, SyntheticEvent, PureComponent } from "react";
import classNames from "classnames";

import Select from "composants/select/Select";
import Calendar from "composants/calendar/Calendar";

import { FilterBarDefinition } from "types/Component";
import { Trans } from "react-i18next";

interface FilterBarComponentProps {
  filters: FilterBarDefinition[];
  selectedFilter: string | null;
  startDate: string | null;
  endDate: string | null;
  // html
  className?: string;
  style?: CSSProperties;
  // event
  selectedFilterChange(e: SyntheticEvent<any>): void;
  startDateChange(e: SyntheticEvent<any>): void;
  endDateChange(e: SyntheticEvent<any>): void;
}

export default class FilterBarComponent extends PureComponent<FilterBarComponentProps> {
  render() {
    return (
      <>
        <div className="level-item">
          <Trans i18nKey="commun_vos_filtres">Vos filtres :</Trans>
        </div>
        <div className="level-item">
          <Select
            id="filter"
            value={this.props.selectedFilter}
            sysDomaineChoices={this.props.filters.map(filter => ({
              value: filter.filterBarId,
              label: filter.label
            }))}
            onChange={this.props.selectedFilterChange}
            size="small"
          />
        </div>
        <div className="level-item">
          <Trans i18nKey="commun_du">du</Trans>
        </div>
        <div className="level-item">
          <Calendar
            id="start"
            value={this.props.startDate}
            onChange={this.props.startDateChange}
            size="small"
            defaultAppearance
          />
        </div>
        <div className="level-item">
          <Trans i18nKey="commun_au">au</Trans>
        </div>
        <div className="level-item">
          <Calendar
            id="end"
            value={this.props.endDate}
            onChange={this.props.endDateChange}
            size="small"
            defaultAppearance
          />
        </div>
      </>
    );
  }
}
