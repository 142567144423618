import React, { Component, ErrorInfo } from "react";
import { connect } from "react-redux";

import Raven from "raven-js";

import { Trans } from "react-i18next";

import { galaxyMenuFavorisSuccess } from "actions/galaxies.action";

import Main from "composants/main/Main";

import { ReducerState } from "reducers";

import { t } from "utils/i18n";

import { ModeDebugProvider } from "composants/userSettings/ModeDebug";

// charge les icones font-awesome
import "iconLibrary";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./App.css";

import { selectActiveGalaxies } from "selectors";

import { SuperUserProvider } from "composants/userSettings/SuperUser";
import { AppGalaxyInfo } from "types/Galaxy";
import Topbar from "layout/top/Topbar";
import { DirtyProvider } from "./Dirty";
import { Outlet } from "react-router-dom";
import {
  ContextInfoCompo,
  CreatorCompo,
  FluxModalCompo,
  LovCompo,
  MailCompo,
  MiniExpertCompo,
  ProcessusAvanceCompo,
  SatelliteCompo
} from "AppRoute";

interface AppState {
  error: Error | null;
}

interface AppPropsRedux {
  activeGalaxies: { sjmoCode: string; link: string }[];
  favoris: AppGalaxyInfo[];
  galaxies: Record<string, AppGalaxyInfo>;
}

interface AppPropsReduxFn {
  galaxyMenuFavorisSuccess(menus: AppGalaxyInfo[]): void;
}

type AppAllProps = AppPropsRedux & AppPropsReduxFn;

class App extends Component<AppAllProps, AppState> {
  state: AppState = {
    error: null
  };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ error });
    Raven.captureException(error, { extra: errorInfo });
  }

  changeMenuFavoris = (newFavoris: AppGalaxyInfo[]) => {
    this.props.galaxyMenuFavorisSuccess(newFavoris);
  };

  render() {
    if (this.state.error) {
      return (
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-content">
            <p>{t("commun_rien_ne_marche")}</p>
            <p>
              <Trans i18nKey="commun_equipe_notifiee">
                Notre équipe à été avertie, cependant vous pouvez
                <a onClick={() => Raven.lastEventId() && Raven.showReportDialog()}>cliquer ici</a>
                pour nous fournir plus d'informations.
              </Trans>
            </p>
          </div>
        </div>
      );
    }

    return (
      <SuperUserProvider>
        <ModeDebugProvider>
          <ModeDebugProvider>
            <DirtyProvider>
              <Topbar
                favoris={this.props.favoris}
                galaxies={this.props.galaxies}
                changeMenuFavoris={this.changeMenuFavoris}
              />
              <Main style={{ padding: "0 10px" }}>
                <Outlet />
                <CreatorCompo />
                <MiniExpertCompo />
                <ContextInfoCompo />
                <ProcessusAvanceCompo />
                <SatelliteCompo />
                <LovCompo />
                <MailCompo />
                <FluxModalCompo />
              </Main>
            </DirtyProvider>
          </ModeDebugProvider>
        </ModeDebugProvider>
      </SuperUserProvider>
    );
  }
}

const mapStateToProps = (state: ReducerState) => {
  const activeGalaxies = selectActiveGalaxies(state);
  const galaxies = state.app.galaxies;
  return {
    activeGalaxies,
    favoris: state.app.favoris,
    galaxies
  };
};

export default connect<AppPropsRedux, AppPropsReduxFn>(mapStateToProps, {
  galaxyMenuFavorisSuccess
})(App);
