import React, { Component, useEffect, useMemo, useRef, useState } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";

import Scrollbars from "react-custom-scrollbars";
import { t } from "utils/i18n";
import { ButtonLink, Button } from "@axin-org/comet";

import "./satelliteTab.css";
import { createMany, fetchConfigurateurData, preRecord } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";
import { addMessage } from "actions/messages";
import ProcessusMenu from "../processus/ProcessusMenu";
import TabHeader from "./TabHeader";
import { Row, Col } from "../Layout";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import { Fa } from "composants/Icon";
import { useEvent } from "hooks/useEvent";

import snakeCase from "lodash-es/snakeCase";
import toaster from "composants/toaster/toaster";

interface ConfStatus {
  position: number;
  code: string;
  title: string;
  icon: string;
  color: string;
}

export interface Configuration {
  id: number;
  titre: string;
  ovPersoResultatId: string;
  status: string;
  dateGeneration: string;
  articleCode: string;
  articleDesignation: string;
  articleId: string; // utiliser pour la navigation
  clientCode: string;
  clientDesignation: string;
  userName: string;
  userLastName: string;
}

function TabConfigurateur({
  sjmoCode,
  tableName,
  contextId,
  count,
  height,
  countAction
}: TabSatelliteProps) {
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [isCreatingNewConfig, setIsCreatingNewConfig] = useState(false);

  const refresh = useEvent((entityTableName: string, entityId: string) => {
    fetchConfigurateurData(entityTableName, entityId)
      .then(response => {
        setConfigurations(response.data);
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });

    countAction(entityTableName, entityId);
  });

  useEffect(() => {
    refresh(tableName, contextId as string);
  }, [refresh, tableName, contextId]);

  const sideConfigs = useMemo(() => {
    let left = [];
    let right = [];

    for (let i = 0; i < configurations.length; i++) {
      if (i % 2 === 0) {
        left.push(configurations[i]);
      } else {
        right.push(configurations[i]);
      }
    }

    return { left, right };
  }, [configurations]);

  async function createConfiguration() {
    setIsCreatingNewConfig(true);
    try {
      const sysTableId = snakeCase(tableName).toUpperCase();

      const entity = await preRecord({
        sjmoCode,
        tableName: "cfgPersoLien",
        context: { sysTableId: sysTableId, cfplEntityId: contextId as string }
      }).then(
        res => res.data,
        err => {
          console.error("error during prerecord of cfgPersoLien");
          return null;
        }
      );

      if (entity == null) return;

      await createMany("cfgPersoLien", [entity], sjmoCode);

      refresh(tableName, contextId as string);
    } finally {
      setIsCreatingNewConfig(false);
    }
  }

  return (
    <>
      <TabHeader
        i18nKey="commun_configurateurs_liees"
        count={count}
        tableName={tableName}
        contextId={contextId}
        sjmoCode={sjmoCode}
      >
        <Button onClick={createConfiguration}>
          <span className="icon">
            {isCreatingNewConfig ? <Fa icon="spinner" spin /> : <Fa icon="plus" />}
          </span>
          <span>{t("commun_creer")}</span>
        </Button>
      </TabHeader>
      <Scrollbars autoHide style={{ height: height }}>
        <Row style={{ width: "100%" }}>
          <Col span={6}>
            {sideConfigs.left.map(config => (
              <CardConfigurateur key={config.id} sjmoCode={sjmoCode} config={config} />
            ))}
          </Col>
          <Col span={6}>
            {sideConfigs.right.map(config => (
              <CardConfigurateur key={config.id} sjmoCode={sjmoCode} config={config} />
            ))}
          </Col>
        </Row>
      </Scrollbars>
    </>
  );
}

function CardConfigurateur({ sjmoCode, config }: { sjmoCode: string; config: Configuration }) {
  const url = `${import.meta.env.VITE_CONFIGURATEUR_BASE_URL}${config.ovPersoResultatId}`;

  return (
    <div className="box donnees-satellite-container" key={config.id}>
      <article className="media">
        <div className="media-content">
          <nav className="level">
            <div className="level-left">
              <div className="level-item">
                <span className="subtitle is-5">{config.titre}</span>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <span className={`tag ${config.status === "GEN" ? "is-success" : "is-warning"}`}>
                  {config.status}
                </span>
              </div>
              <div className="level-item">
                <ButtonLink className="is-size-4">
                  <a href={url} target="_blank">
                    <span className="icon">
                      <Fa icon="external-link" />
                    </span>
                  </a>
                </ButtonLink>
              </div>
            </div>
          </nav>

          <div>
            <span>
              {`${t("commun_par")} : 
              ${config.userName !== null ? config.userName : ""} 
              ${config.userLastName !== null ? config.userLastName : ""}
             ${config.dateGeneration ? ", " + t("commun_le") + " : " + config.dateGeneration : ""}
             ${
               config.clientDesignation !== null
                 ? `, ${t("commun_pour")} : ${config.clientDesignation} (${config.clientCode})`
                 : ""
             }`}
            </span>
          </div>

          <h2 className="is-size-5">
            {config.articleCode && config.articleCode !== null ? (
              <>
                <div>{`${t("commun_article_genere")} : `}</div>
                <ProcessusProvider
                  sjmoCode={sjmoCode}
                  tableName="article"
                  selected={config.articleId}
                >
                  <ProcessusMenu isAnchor isRight={true} color="link">
                    {`${config.articleDesignation} (${config.articleCode})`}
                  </ProcessusMenu>
                </ProcessusProvider>
              </>
            ) : (
              <div style={{ marginBottom: "1.85rem" }}>{t("commun_aucun_article_genere")}</div>
            )}
          </h2>
        </div>
      </article>
    </div>
  );
}

export default TabConfigurateur;
