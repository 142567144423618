import { createSelector } from "reselect";
import { ReducerState } from "reducers";
import { EmailState } from "types/Processus";

type SelectPropsForEmail = {};

export const selectEmailStruct = (
  state: ReducerState,
  props: SelectPropsForEmail
): EmailState | null => {
  return state.email.mailStruct ? state.email.mailStruct[state.email.currentIndex] : null;
};

export const selectTotalEmail = (state: ReducerState, props: SelectPropsForEmail): number => {
  return state.email.mailStruct ? state.email.mailStruct.length : 0;
};

export const selectEmailTableName = (
  state: ReducerState,
  props: SelectPropsForEmail
): string | null => {
  return state.email.mailStruct && state.email.mailStruct[state.email.currentIndex]
    ? state.email.mailStruct[state.email.currentIndex].tableName
    : null;
};

export const selectEmailEntityId = (
  state: ReducerState,
  props: SelectPropsForEmail
): string | null => {
  return state.email.mailStruct && state.email.mailStruct[state.email.currentIndex]
    ? state.email.mailStruct[state.email.currentIndex].entityId
    : null;
};
