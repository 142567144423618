import {
  ADD_PROCESSUS_DEFINTION,
  CHANGE_PROCESSUS_NEW_ENTITY_VALUE,
  LAUNCH_ADVANCED_PROCESS,
  ADD_NEW_ENTITY,
  LAUNCH_PROCESS,
  ON_APPLY_TO_ALL_CHANGE,
  LAUNCH_NAVIGATION,
  SET_PROCESSUS_ID,
  LAUNCH_PROCESS_FROM_GALAXY_HEADER,
  RESET_PACKAGE,
  START_EDITION_IN_PROGRESS,
  SET_EDITION_SUCCESS,
  SET_EDITION_INDEX,
  DISPLAY_EDITION_DOCUMENT,
  CLEAR_PROCESSUS_REDUCER,
  LAUNCH_EDITION,
  EXECUTE_EDITION,
  SET_MODE_EDITION,
  SET_PROCESS_IN_PROGRESS,
  LAUNCH_PROCESS_FROM_GENERIC_LIST,
  PREPARE_PROCESSUS_CONTEXT,
  HIDE_EDITION_DOCUMENT,
  INIT_ADVANCED_PROCESS,
  EXECUTE_AVANCED_EDITION,
  RESET_PROCESSUS
} from "constant/processus";

import { Pojo } from "types/Galaxy";
import { Dispatch } from "redux";
import { EditionState } from "types/Processus";
import { CLEAR_EMAIL_REDUCER } from "constant/email";
import { ComponentState } from "react";

export function fetchProcessusAvanceDefinition(
  sjmoCode: string,
  tableName: string | null,
  id: string | null
) {
  return {
    type: ADD_PROCESSUS_DEFINTION,
    payload: {
      sjmoCode,
      tableName,
      id
    }
  };
}

export function resetProcessus() {
  return { type: RESET_PROCESSUS };
}

export function initAdvancedProcess(
  entities: Partial<Pojo>[],
  paramsAdd: Partial<Pojo>[],
  definition: ComponentState[],
  forAll: boolean
) {
  return {
    type: INIT_ADVANCED_PROCESS,
    payload: { entities, paramsAdd, definition, forAll }
  };
}

export function onValueChangeProcessAvanceEntity(field: string, value: any): any {
  return (dispatch: Dispatch<any>): Promise<void> => {
    dispatch({
      type: CHANGE_PROCESSUS_NEW_ENTITY_VALUE,
      payload: {
        field,
        value
      }
    });

    return Promise.resolve();
  };
}

export function launchAdvancedProcess(
  sjmoCode: string,
  sjipId: string,
  navigationUrl?: string,
  callback?: () => void,
  onError?: () => void
) {
  return {
    type: LAUNCH_ADVANCED_PROCESS,
    payload: { sjmoCode, sjipId, navigationUrl, callback, onError }
  };
}

export function addNewEntity(avancedParams: Partial<Pojo>) {
  return {
    type: ADD_NEW_ENTITY,
    payload: { avancedParams }
  };
}

/**
 * Action de lancement d'un processus
 *
 * @export
 * @param {string} sjmoCode code module
 * @param {number} sjipId id du processus
 * @param {string} [navigationUrl] ne doit être utilisé que dans le cas d'une navigation vers autre chose qu'une galaxie
 * @returns
 */
export function launchProcess(
  sjmoCode: string,
  sjipId: string,
  navigationUrl?: string,
  callback?: () => void
) {
  return {
    type: LAUNCH_PROCESS,
    payload: { sjmoCode, sjipId, navigationUrl, callback }
  };
}

export function onForAllChange(forAll: boolean) {
  return {
    type: ON_APPLY_TO_ALL_CHANGE,
    payload: { forAll }
  };
}

export function setProcessusId(sjipId: string) {
  return {
    type: SET_PROCESSUS_ID,
    payload: { sjipId }
  };
}

export function launchNavigation(sjipId: string, newTab: boolean) {
  return {
    type: LAUNCH_NAVIGATION,
    payload: { sjipId, newTab }
  };
}

export function prepareProcessus(
  sjipId: string,
  sjmoCode: string,
  tableName: string,
  contextIds: number[] | string[] | null,
  forAll: boolean = false
) {
  return {
    type: PREPARE_PROCESSUS_CONTEXT,
    payload: {
      sjipId,
      sjmoCode,
      tableName,
      contextIds,
      forAll
    }
  };
}

/**
 * Méthode de lancement d'un processus depuis le header d'une galaxie
 *
 * @export
 * @param {string} sjmoCode code module
 * @param {number} sjipId id du processus
 * @param {string} contextTableName table auquel appartient les entités à traiter
 * @param {number[]} contextIds ids des entités à traiter
 * @param {string} [navigationUrl] ne doit être utilisé que dans le cas d'une navigation vers autre chose qu'une galaxie
 * @returns
 */
export function launchProcessFromGalaxyHeader(
  sjmoCode: string,
  sjipId: string,
  contextTableName: string,
  contextIds: string[],
  navigationUrl?: string,
  forAll: boolean = false,
  callback?: () => void
) {
  return {
    type: LAUNCH_PROCESS_FROM_GALAXY_HEADER,
    payload: { sjmoCode, sjipId, contextTableName, contextIds, navigationUrl, forAll, callback }
  };
}

export function launchProcessFromGenericList(
  sjmoCode: string,
  sjipId: string,
  selectGenericEntites: Pojo[],
  columnName: string | null,
  onSuccess?: () => void,
  onError?: () => void
) {
  return {
    type: LAUNCH_PROCESS_FROM_GENERIC_LIST,
    payload: { sjmoCode, sjipId, selectGenericEntites, columnName, onSuccess, onError }
  };
}
export function resetPackage() {
  return {
    type: RESET_PACKAGE
  };
}

export function setEditionInProgress() {
  return {
    type: START_EDITION_IN_PROGRESS
  };
}

export function setEditionSuccess(editions: EditionState[] | null) {
  return {
    type: SET_EDITION_SUCCESS,
    payload: { editions }
  };
}

export function setEditionIndex(index: number) {
  return {
    type: SET_EDITION_INDEX,
    payload: { index }
  };
}

export function displayEditionDocument() {
  return {
    type: DISPLAY_EDITION_DOCUMENT
  };
}

export function hideModalEdition() {
  return {
    type: HIDE_EDITION_DOCUMENT
  };
}

export function clearProcessusReducer() {
  return {
    type: CLEAR_PROCESSUS_REDUCER
  };
}

export function clearEmailReducer() {
  return {
    type: CLEAR_EMAIL_REDUCER
  };
}

export function launchEdition(
  sjmoCode: string,
  sjipId: string,
  mode: string,
  tableName: string,
  entitiesId: string[],
  navigationUrl?: string,
  callback?: () => void
) {
  return {
    type: LAUNCH_EDITION,
    payload: { sjmoCode, sjipId, mode, tableName, entitiesId, navigationUrl, callback }
  };
}

export function executeEdition(
  sjmoCode: string,
  sjipId: string,
  tableName: string,
  entitiesId: string[],
  closeAdditionnalParams: boolean
) {
  return {
    type: EXECUTE_EDITION,
    payload: { sjmoCode, sjipId, tableName, entitiesId, closeAdditionnalParams }
  };
}

export function executeAvancedEdition(
  sjmoCode: string,
  sjipId: string,
  callback?: () => void,
  onError?: () => void
) {
  return {
    type: EXECUTE_AVANCED_EDITION,
    payload: { sjmoCode, sjipId, callback, onError }
  };
}

export function setModeEdition(mode: string) {
  return {
    type: SET_MODE_EDITION,
    payload: { mode }
  };
}

export function setProcessInProgress(running: boolean) {
  return {
    type: SET_PROCESS_IN_PROGRESS,
    payload: running
  };
}
