import React, { FC } from "react";
import classNames from "classnames";

import { DropDownContextProps, Dropdown, DropdownButton, DropdownMenu } from "./Dropdown";
import { SmallButton } from "../button/SmallButton";

export const TagDropdown: FC<{
  className?: string;
  label: string;
  width?: number;
  marginTop?: number;
  renderMenu(params: DropDownContextProps): JSX.Element | JSX.Element[] | null;
}> = ({ className, label, renderMenu, width = 85, marginTop = 3 }) => {
  return (
    <Dropdown autoclose>
      <DropdownButton
        render={params => {
          return (
            <span
              ref={params.buttonRef}
              onClick={params.onOpen}
              className={classNames("tag is-rounded cursor-pointer", className)}
            >
              {label}
              <SmallButton onClick={params.onOpen} />
            </span>
          );
        }}
      />
      <DropdownMenu marginTop={marginTop} render={renderMenu} />
    </Dropdown>
  );
};
