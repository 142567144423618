import React, { useState, useEffect, FC } from "react";

import { Row, Col } from "composants/Layout";
import { Pojo } from "types/Galaxy";
import Modal from "composants/Modal/Modal";
import { t } from "utils/i18n";
import { InteractiveReportConfiguration, ComponentState } from "types/Component";
import Picklist, { SelectedProps, fakeCompo } from "composants/pickList/Picklist";
import { updateInteractiveReportStateAdmin } from "api/interactiveReport";
import { AVAILABLE, CHOSEN } from "composants/pickList/CustomablePickList";
import { Button } from "composants/button";
import ModalUpdateColumnLabel from "./ModalUpdateColumnLabel";
import produce from "immer";

interface ModalAdvanceModeProps {
  sjmoCode: string;
  interactiveReportId: string;
  focus: InteractiveReportConfiguration;
  columns: ComponentState[];
  onClose(): void;
}

const ModalAdvanceMode: FC<ModalAdvanceModeProps> = props => {
  const [allColumns, setAllColumns] = useState<Pojo[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<Pojo[]>([]);
  const [changeColumnLabel, setChangeColumnLabel] = useState<boolean>(false);
  const [selectedColumn, setSelectedColumn] = useState<Pojo | null>(null);
  useEffect(() => {
    const asPojo = props.columns
      .filter(col => col.authorizeVisibility === true)
      .map(col => ({
        id: col.column,
        columnId: (col as any).id,
        label: col.label,
        compoVisible: col.compoVisible,
        uuid: "",
        labelDetails: col.label
      }));

    const visibleColumnsAsPojo = asPojo.filter(el => el.compoVisible === true) as Pojo[];
    setVisibleColumns(visibleColumnsAsPojo);
    setAllColumns(asPojo);
  }, [props.columns]);

  function renderAvailableCompo(pojo: Pojo, selected: SelectedProps) {
    const isSelected =
      selected[AVAILABLE].indexOf(pojo.id) > -1 || selected[CHOSEN].indexOf(pojo.id) > -1;

    if (pojo.__fake !== true) {
      let title = pojo.label;
      let infosCompl = "(" + pojo.id + ")";

      return (
        <div
          className="card"
          key={pojo.id}
          style={{ backgroundColor: isSelected ? "#209cee59" : undefined }}
        >
          <div className="card-content">
            <Row>
              <Col>
                <label>{`${title} ${infosCompl}`}</label>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setSelectedColumn(pojo);
                    setChangeColumnLabel(true);
                  }}
                >
                  {t("commun_modifier")}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return fakeCompo(pojo);
    }
  }

  function renderChosenCompo(pojo: Pojo, selected: SelectedProps) {
    const isSelected =
      selected[AVAILABLE].indexOf(pojo.id) > -1 || selected[CHOSEN].indexOf(pojo.id) > -1;

    if (pojo.__fake !== true) {
      let title = pojo.label;
      let infosCompl = "(" + pojo.id + ")";

      return (
        <div
          className="card"
          key={pojo.id}
          style={{ backgroundColor: isSelected ? "#209cee59" : undefined }}
        >
          <div className="card-content">
            <Row>
              <Col>
                <label>{`${title} ${infosCompl}`}</label>
              </Col>
              <Col>
                <Button
                  onClick={() => {
                    setSelectedColumn(pojo);
                    setChangeColumnLabel(true);
                  }}
                >
                  {t("commun_modifier")}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return fakeCompo(pojo);
    }
  }

  function onCloseModalUpdateTrad() {
    setChangeColumnLabel(false);
  }

  //mise à jour du label de la colonne qui a été modifié
  function updateListWithColumn(obj: Pojo | null) {
    if (obj !== null) {
      //recherche de la colonne dans la liste affiché à gauche
      const indexInAllColumns = allColumns.findIndex(
        col => col.columnId === obj.syjInteractiveReportColumn.id
      );
      if (indexInAllColumns !== -1) {
        const newAllColumns = produce(allColumns, draft => {
          draft[indexInAllColumns]["label"] = obj.trad;
          draft[indexInAllColumns]["labelDetails"] = obj.trad;
        });
        setAllColumns(newAllColumns);
      }

      //recherche de la colonne dans la liste affiché à droite
      const indexInVisibleColumns = visibleColumns.findIndex(
        col => col.columnId === obj.syjInteractiveReportColumn.id
      );
      if (indexInVisibleColumns !== -1) {
        const newVisibleColumns = produce(visibleColumns, draft => {
          draft[indexInVisibleColumns]["label"] = obj.trad;
          draft[indexInVisibleColumns]["labelDetails"] = obj.trad;
        });
        setVisibleColumns(newVisibleColumns);
      }
    }
  }

  function onValidate() {
    let focusUpdated = { ...props.focus };
    let visibleColumnsId: string[] = [];
    visibleColumnsId = visibleColumns.map(col => col.id);
    focusUpdated.includes = visibleColumnsId;
    updateInteractiveReportStateAdmin(props.sjmoCode, props.interactiveReportId, focusUpdated).then(
      () => {
        props.onClose();
      }
    );
  }
  return (
    <>
      <Modal title="Mode avancé" onClose={props.onClose} onValidate={onValidate} height="70vh">
        <Picklist
          available={allColumns}
          chosen={visibleColumns}
          onValueChange={({ AVAILABLE: available, CHOSEN: chosen }) => {
            setAllColumns(() => available);
            setVisibleColumns(() => chosen);
          }}
          renderAvailableCompo={renderAvailableCompo}
          renderChosenCompo={renderChosenCompo}
          displayFields={["label", "id"]}
        />
      </Modal>
      {changeColumnLabel === true && (
        <ModalUpdateColumnLabel
          column={selectedColumn}
          onClose={onCloseModalUpdateTrad}
          updateListWithColumn={updateListWithColumn}
        />
      )}
    </>
  );
};

export default ModalAdvanceMode;
