import produce from "immer";
import Action from "reducers/Action";
import { API_DATA_OK } from "constant/apiData";

export interface ApiOkReducerState {
  isApiDataOk: boolean;
}

const initialState: ApiOkReducerState = { isApiDataOk: false };

export default function apiDataOkReducer(
  state: ApiOkReducerState = initialState,
  action: Action<boolean>
) {
  switch (action.type) {
    case API_DATA_OK:
      return produce(state, draft => {
        draft.isApiDataOk = action.payload;
      });
    default:
      return state;
  }
}
