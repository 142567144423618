import React, {
  FC,
  useState,
  useContext,
  HTMLAttributes,
  MouseEvent,
  useEffect,
  CSSProperties,
  useRef
} from "react";
import classNames from "classnames";

import { Portal, PortalContext } from "composants/Portal";
import { useOverlayPosition } from "hooks/useOverlayPosition";

type DropdownMenuContextType = {
  buttonRef: React.RefObject<HTMLAnchorElement>;
  menuContentRef: React.RefObject<HTMLDivElement>;
  isActive: boolean;
  canMouseLeave: boolean;
  setIsActive(isActive: boolean): void;
  toggleIsActive(): void;
  setCanMouseLeave(canMouseLeave: boolean): void;
};
const DropdownMenuContext = React.createContext<DropdownMenuContextType>({
  buttonRef: { current: null },
  menuContentRef: { current: null },
  isActive: false,
  canMouseLeave: false,
  setIsActive: () => {
    /* empty */
  },
  setCanMouseLeave: () => {
    /* empty */
  },
  toggleIsActive: () => {
    /* empty */
  }
});

type MenuButtonProps = HTMLAttributes<HTMLAnchorElement> & {
  disabled?: boolean;
  activeClassName?: string;
};
const MenuButton: FC<MenuButtonProps> = ({ onClick, onDoubleClick, ...props }) => {
  const context = useContext(DropdownMenuContext);

  function onClickMenuButton(e: MouseEvent<HTMLAnchorElement>) {
    e.stopPropagation();
    if (!props.disabled) {
      context.toggleIsActive();
      onClick && onClick(e);
    }
  }

  function onDoubleClickMenuButton(e: MouseEvent<HTMLAnchorElement>) {
    if (!props.disabled) {
      onDoubleClick && onDoubleClick(e);
    }
  }

  return (
    <div className="dropdown-trigger">
      <a
        ref={context.buttonRef}
        onClick={onClickMenuButton}
        onDoubleClick={onDoubleClickMenuButton}
        {...props}
        className={classNames(props.className, context.isActive && props.activeClassName)}
      />
    </div>
  );
};

type MenuContentProps = HTMLAttributes<HTMLDivElement> & {
  styleContainer?: CSSProperties;
  classNameContainer?: string;
};
const MenuContent: FC<MenuContentProps> = ({
  children,
  classNameContainer,
  styleContainer,
  className,
  ...props
}) => {
  const { setCanMouseLeave, menuContentRef } = useContext(DropdownMenuContext);

  function onMouseEnter() {
    setCanMouseLeave(true);
  }

  return (
    <div className={classNames("dropdown-menu", classNameContainer)} style={styleContainer}>
      <div
        ref={menuContentRef}
        className={classNames("dropdown-content", className)}
        {...props}
        onMouseEnter={onMouseEnter}
      >
        {children}
      </div>
    </div>
  );
};

const MenuContentPortal: FC<MenuContentProps & { styleContainer?: CSSProperties }> = ({
  styleContainer,
  ...props
}) => {
  const { isActive, buttonRef, menuContentRef } = useContext(DropdownMenuContext);
  const modalContext = useContext(PortalContext);
  const isInsideModal =
    modalContext.domRef !== undefined && modalContext.domRef !== null ? true : false;
  const stylesMenu = useOverlayPosition(buttonRef, menuContentRef, isActive, isInsideModal);

  return (
    <Portal>
      <MenuContent
        styleContainer={{
          ...styleContainer,
          display: isActive ? "block" : "none",
          position: "absolute",
          ...stylesMenu
        }}
        {...props}
      />
    </Portal>
  );
};

type MenuContentWhenVisibleType = { children(): React.ReactElement | null };
const MenuContentWhenVisible: FC<MenuContentWhenVisibleType> = props => {
  const { isActive } = useContext(DropdownMenuContext);
  return isActive ? props.children() : null;
};

type MenuContentWithMenuContextType = {
  children(params: DropdownMenuContextType): React.ReactElement | null;
};
const MenuContentWithMenuContext: FC<MenuContentWithMenuContextType> = props => {
  const context = useContext(DropdownMenuContext);
  return props.children(context);
};

type MenuItemProps = { as?: React.ElementType<any>; autoClose?: boolean } & HTMLAttributes<
  HTMLElement
> & { [key: string]: any };

const MenuItem = React.forwardRef<HTMLDivElement, MenuItemProps>(
  ({ as = "div", className, autoClose = false, children, onClick, ...props }, ref) => {
    const { setIsActive } = useContext(DropdownMenuContext);

    function onClickMenuItem(e: MouseEvent<any>) {
      autoClose && setIsActive(false);
      onClick && onClick(e);
    }

    return React.createElement(
      as,
      {
        ...props,
        ref,
        className: classNames("dropdown-item", className),
        onClick: onClickMenuItem
      },
      children
    );
  }
);
MenuItem.displayName = "MenuItem";

type MenuSeparatorProps = HTMLAttributes<HTMLHRElement>;
const MenuSeparator: FC<MenuSeparatorProps> = props => {
  return <hr className="dropdown-divider" {...props} />;
};

interface MenuStaticFunction {
  Button: React.ComponentType<MenuButtonProps>;
  Content: React.ComponentType<MenuContentProps>;
  Overlay: React.ComponentType<MenuContentProps>;
  Item: React.ComponentType<MenuItemProps>;
  Separator: React.ComponentType<MenuSeparatorProps>;
  WhenVisible: React.ComponentType<MenuContentWhenVisibleType>;
  WithContext: React.ComponentType<MenuContentWithMenuContextType>;
}

type MenuProps = HTMLAttributes<HTMLDivElement> & {
  disabled?: boolean;
  autoclose?: boolean;
  onActiveChange?(isActive: boolean): void;
};
export const Menu: FC<MenuProps> & MenuStaticFunction = ({
  disabled,
  autoclose,
  onActiveChange,
  children,
  className,
  ...props
}) => {
  const buttonRef = useRef<HTMLAnchorElement>(null);
  const menuContentRef = useRef<HTMLDivElement>(null);
  const [isActive, setIsActiveInternal] = useState(false);
  const [canMouseLeave, setCanMouseLeave] = useState(false);

  useEffect(() => {
    onActiveChange && onActiveChange(isActive);
  }, [isActive]);

  useEffect(() => {
    function onCloseEventListener(e: any) {
      const el = e.target && e.target.closest(".dropdown-content");
      if (!el || el !== menuContentRef.current) {
        setIsActiveInternal(false);
      }
    }

    if (autoclose && isActive) {
      document.addEventListener("click", onCloseEventListener);
    }

    return function cleanup() {
      if (autoclose) {
        document.removeEventListener("click", onCloseEventListener);
      }
    };
  }, [autoclose, isActive]);

  function onMouseLeave() {
    if (canMouseLeave && !autoclose) {
      setCanMouseLeave(false);
      setIsActiveInternal(false);
    }
  }

  function toggleIsActive() {
    !disabled && setIsActiveInternal(currentIsActive => !currentIsActive);
  }

  function setIsActive(newIsActive: boolean) {
    !disabled && setIsActiveInternal(newIsActive);
  }

  return (
    <DropdownMenuContext.Provider
      value={{
        isActive,
        setIsActive,
        canMouseLeave,
        setCanMouseLeave,
        toggleIsActive,
        menuContentRef,
        buttonRef
      }}
    >
      <div
        className={classNames("dropdown", isActive && "is-active", className)}
        {...props}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>
    </DropdownMenuContext.Provider>
  );
};
Menu.defaultProps = {
  autoclose: false
};

Menu.Button = MenuButton;
Menu.Content = MenuContent;
Menu.Overlay = MenuContentPortal;
Menu.WhenVisible = MenuContentWhenVisible;
Menu.WithContext = MenuContentWithMenuContext;
Menu.Item = MenuItem;
Menu.Separator = MenuSeparator;
