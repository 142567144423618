import * as idb from "./idb";

// pass-through exports
export const { del, clear, keys } = idb;

interface CacheExpiration {
  version: number;
  maxAge: number;
  name?: string;
}

interface Cache extends CacheExpiration {
  lib: typeof idb;
}

const defaultOpts: CacheExpiration = { maxAge: Infinity, version: 0 };
const getOpts = (passedOptions?: CacheExpiration): Cache => ({
  lib: idb,
  ...defaultOpts,
  ...passedOptions
});

export const get = (key: string, opts: CacheExpiration, store: idb.Store) => {
  const { maxAge, version, lib } = getOpts(opts);
  return lib
    .get(key, store)
    .then(JSON.parse)
    .then(parsed => {
      const age = Date.now() - parsed.time;
      if (age > maxAge || version !== parsed.version) {
        lib.del(key, store);
        return null;
      }
      return parsed.data;
    })
    .catch(() => null);
};

export const set = (key: string, data: any, spec: CacheExpiration, store: idb.Store) => {
  const { lib, version } = getOpts(spec);
  return lib
    .set(
      key,
      JSON.stringify({
        version,
        time: Date.now(),
        data
      }),
      store
    )
    .catch(() => {});
};

export const getAll = (spec: CacheExpiration, store: idb.Store) => {
  const opts = getOpts(spec);
  let keys: string[];
  return opts.lib
    .keys(store)
    .then(retrievedKeys => {
      keys = retrievedKeys;
      return Promise.all(keys.map(key => get(key, opts, store)));
    })
    .then(data =>
      data.reduce((acc, bundleData, index) => {
        if (bundleData) {
          acc[keys[index]] = bundleData;
        }
        return acc;
      }, {})
    )
    .catch(() => {});
};

export const getConfiguredCache = (spec: CacheExpiration) => {
  const opts = getOpts(spec);
  let store: idb.Store;
  if (opts.name) {
    store = new idb.Store(opts.name, opts.name);
  }
  return {
    get: (key: string) => get(key, opts, store),
    set: (key: string, val: any) => set(key, val, opts, store),
    getAll: () => getAll(opts, store),
    del: (key: string) => opts.lib.del(key, store),
    clear: () => opts.lib.clear(store),
    keys: () => opts.lib.keys(store)
  };
};
