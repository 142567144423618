import React, { FC } from "react";
import classNames from "classnames";
import { Menu } from "composants/DropDown/Menu";
import { track } from "tracking";
import SatelliteMenu from "./SatelliteMenu";
import { openSatellite } from "utils/navigation.utils";
import { DEFAULT_SATELLITES } from "constant/satellite";
import { useLocation, useNavigate } from "react-router-dom";

function navigateSatellite(
  navigate: ReturnType<typeof useNavigate>,
  location: ReturnType<typeof useLocation>,
  sjmoCode: string,
  satelliteName: string,
  tableName: string,
  id?: string,
  ctrlKey?: string,
  query?: string,
  columns?: string[]
) {
  if (satelliteName) {
    const search = openSatellite({
      sjmoCode,
      ctrlKey,
      query,
      columns,
      tableName,
      satelliteName,
      contextId: id
    });

    navigate(
      {
        search
      },
      {
        state: {
          origin: location.pathname
        }
      }
    );
  }
}

const SatelliteMenuButton: FC<React.PropsWithChildren<{
  sjmoCode: string;
  tableName: string;
  entityId?: string;
  ctrlKey?: string;
  filter?: string;
  columns?: string[];
  isActive: boolean;
  className?: string;
  onClick?: () => void;
}>> = props => {
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <Menu autoclose>
      <Menu.Button
        className={classNames("button", props.className)}
        onDoubleClick={() => {
          track("datatable::satellite::doubleclick");
          navigateSatellite(
            navigate,
            location,
            props.sjmoCode,
            DEFAULT_SATELLITES,
            props.tableName,
            props.entityId,
            props.ctrlKey,
            props.filter,
            props.columns
          );
        }}
        disabled={!props.entityId || props.entityId.split("~").length > 4}
      >
        <span className="h-6 w-6">{props.children}</span>
      </Menu.Button>
      <Menu.Overlay>
        <Menu.WithContext>
          {({ isActive, toggleIsActive }) => {
            return isActive ? (
              <SatelliteMenu
                tableName={props.tableName}
                contextId={props.entityId}
                query={props.filter}
                columns={props.columns}
                sjmoCode={props.sjmoCode}
                isActive={isActive}
                onClick={() => {
                  props.onClick && props.onClick();
                  toggleIsActive();
                  track("datatable::satellite::menuclick");
                }}
                onMouseLeave={toggleIsActive}
                ctrlKey={props.ctrlKey}
              />
            ) : null;
          }}
        </Menu.WithContext>
      </Menu.Overlay>
    </Menu>
  );
};

export default SatelliteMenuButton;
