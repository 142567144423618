import { apiAdn, getUIContext } from "./common";
import { AxiosPromise } from "axios";
import { DGLGroupDefinition } from "types/draggableGroupList";
import { Pojo } from "types/Galaxy";

/*
 * Récupération de la définition des groupes à affaocher
 */
export function getDGListDefinition(
  sjmoCode: string,
  panelId: number
): AxiosPromise<DGLGroupDefinition> {
  const params = getUIContext({ sjmoCode });
  params.append("panelId", panelId.toString());
  return apiAdn.get(`dglist/definition?${params}`);
}
export function getDGListData(args: {
  sjmoCode: string;
  definitionId: number;
  searchTerm?: string;
  displayFields: string;
  first?: number;
  size?: number;
  interactionFilter?: string;
}): AxiosPromise<Pojo> {
  const params = getUIContext({ sjmoCode: args.sjmoCode });
  params.append("groupId", args.definitionId.toString());
  params.append("displayFields", args.displayFields);

  if (args.searchTerm) {
    params.append("searchTerm", args.searchTerm);
  }
  if (args.first) {
    params.append("first", args.first.toString());
  }
  if (args.size) {
    params.append("size", args.size.toString());
  }

  return apiAdn.get(
    `dglist/data?${params}${args.interactionFilter ? "&" + args.interactionFilter : ""}`
  );
}
