import React, { FC, MouseEvent, useMemo } from "react";

import { ComponentState } from "types/Component";
import { Fa } from "composants/Icon";
import { t } from "utils/i18n";
import { Menu } from "composants/DropDown/Menu";

export const DropdownColumn: FC<{
  columns: ComponentState[];
  selectedColumn: ComponentState | null | undefined;
  onClick(e: MouseEvent<HTMLAnchorElement>): void;
}> = ({ columns, selectedColumn, onClick }) => {
  const visibleColumns = useMemo(() => columns.filter(col => col.compoVisible === true), [columns]);
  const hiddenColumns = useMemo(() => columns.filter(col => col.compoVisible === false), [columns]);

  return (
    <Menu autoclose>
      <Menu.Button className="button" title={t("commun_colonne")}>
        <span className="icon">
          <Fa icon={["far", "search"]} className="has-text-link" />
        </span>

        {selectedColumn ? selectedColumn.label : null}
        <span className="icon">
          <Fa icon={["far", "angle-down"]} />
        </span>
      </Menu.Button>
      <Menu.Overlay style={{ maxHeight: 200, overflowY: "auto" }}>
        <Menu.Item as="a" autoClose data-value="ALL" onClick={onClick}>
          {t("commun_toutes_les_colonnes")}
        </Menu.Item>
        {(visibleColumns.length > 0 || hiddenColumns.length > 0) && <Menu.Separator />}
        {visibleColumns.length > 0 && (
          <Menu.Item className="has-text-weight-bold">{t("commun_colonnes_visibles")}</Menu.Item>
        )}
        {visibleColumns.map(col => (
          <Menu.Item
            key={col.column}
            as="a"
            autoClose
            className={
              selectedColumn && selectedColumn.column === col.column ? "is-active" : undefined
            }
            data-value={col.column}
            onClick={onClick}
          >
            {col.label}
          </Menu.Item>
        ))}
        {visibleColumns.length > 0 && <Menu.Separator />}
        {hiddenColumns.length > 0 && (
          <Menu.Item className="has-text-weight-bold">{t("commun_colonnes_masquees")}</Menu.Item>
        )}
        {hiddenColumns.map(col => (
          <Menu.Item
            key={col.column}
            as="a"
            autoClose
            className={
              selectedColumn && selectedColumn.column === col.column ? "is-active" : undefined
            }
            data-value={col.column}
            onClick={onClick}
          >
            {col.label}
          </Menu.Item>
        ))}
      </Menu.Overlay>
    </Menu>
  );
};
