import { useRef, useLayoutEffect, useCallback } from "react";

/**
 * Ce hook est une reproduction du hook de react en cours de validation en RFC :
 *  https://github.com/reactjs/rfcs/pull/220
 *
 * Il permet de mémoriser une fonction pour éviter d'avoir des problèmes de callback pas à jour
 * (avec useCallback) et de garantir que la fonction est toujours à jour.
 *
 * Ce qui permet de ne pas s'inquiéter d'appel supplémentaire parce que la fonction est garanti
 * d'avoir toujours la même instance.
 *
 * @param handler function to be called
 */
export function useEvent<T extends Function>(handler: T) {
  const handlerRef = useRef<T | null>(null);

  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  return useCallback<any>((...args: any[]) => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current;

    if (fn == null) {
      if (import.meta.env.DEV) {
        throw new Error("useEvent cannot be called with a null function");
      } else {
        console.error("useEvent cannot be called with a null function");
      }
    }

    return fn?.(...args);
  }, []) as T;
}
