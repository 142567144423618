import { createSelector } from "reselect";
import { ReducerState } from "reducers";
import { ProcessusState } from "reducers/modules/processus/ProcessusReducer";

export const selectProcessusProperties = (state: ReducerState): ProcessusState => {
  return state.processus;
};

export const selectSjipId = createSelector(selectProcessusProperties, current => {
  return current.sjipId;
});

export const selectContextTableName = createSelector(selectProcessusProperties, current => {
  return current.contextTableName;
});

export const selectProcessusSjmoCode = createSelector(selectProcessusProperties, current => {
  return current.sjmoCode;
});

export const selectProcessusUrlCplt = createSelector(selectProcessusProperties, current => {
  return current.urlCplt;
});

export const selectProcessusInProgress = createSelector(selectProcessusProperties, current => {
  return current.processInProgress;
});

export const selectCurrentContextEntityId = createSelector(selectProcessusProperties, current => {
  return current.contextIds ? current.contextIds[current.indexContextualEntity] : null;
});

export const selectCurrentIndex = createSelector(selectProcessusProperties, current => {
  return current.indexContextualEntity;
});

export const selectNbEntities = createSelector(selectProcessusProperties, current => {
  return current.contextIds ? current.contextIds.length : 0;
});

export const selectNbAllEntities = createSelector(selectProcessusProperties, current => {
  return current.entities.length;
});

export const selectParamsAdd = createSelector(selectProcessusProperties, current => {
  return current.advancedParams[current.indexContextualEntity];
});

export const selectParamsAddToDo = createSelector(selectProcessusProperties, current => {
  return current.advancedParams.filter((pojo, index) => index >= current.indexContextualEntity);
});

export const selectEntitiesToDo = createSelector(selectProcessusProperties, current => {
  return current.entities.filter((pojo, index) => index >= current.indexContextualEntity);
});

export const selectEditionMode = createSelector(selectProcessusProperties, current => {
  return current.mode;
});

export const selectForAll = createSelector(selectProcessusProperties, current => {
  return current.forAll;
});

export const selectModifiedAdvancedParamList = createSelector(
  selectProcessusProperties,
  current => {
    return current.modifiedAdvancedParams;
  }
);

export const selectEntitesRestanteATraiter = createSelector(selectProcessusProperties, current => {
  return current.contextIds ? current.contextIds.slice(current.indexContextualEntity) : null;
});

export const selectContextIds = createSelector(selectProcessusProperties, current => {
  return current.contextIds;
});

export const selectUrlCpl = createSelector(selectProcessusProperties, current => {
  return current.urlCplt;
});
