import { library, dom } from "@fortawesome/fontawesome-svg-core";

import { fas } from "@fortawesome/pro-solid-svg-icons";
import { far } from "@fortawesome/pro-regular-svg-icons";
import { fal } from "@fortawesome/pro-light-svg-icons";
import { fad } from "@fortawesome/pro-duotone-svg-icons";

library.add(fad);
library.add(fas);
library.add(far);
library.add(fal);

dom.watch();

export function getListIcons() {
  return Object.keys(fal);
}
