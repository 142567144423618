import React from "react";

/**
 * Context passé par la galaxie
 */
export interface GalaxieContextProps {
  sjmoCode?: string;
  mainEntityTableName?: string;
  mainEntityId?: string | null;
  mainEntityVersion?: number;
  refreshListenerAndCallback?: () => void;
}

/**
 * On créé un context avec React pour permettre de passer des informations
 * aux composants enfants de la galaxie.
 *
 * Contient deux composants:
 * - provider : prend le context à passer.
 * - consumer : récupère le context passé par le Provider parent.
 */
export const GalaxieContext = React.createContext<GalaxieContextProps>({});
