import React, { FC, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { getRefreshedEntityLabels } from "api";
import { AxiosError } from "axios";
import { t } from "utils/i18n";
import { useEffect } from "react";

interface TabHeaderProps {
  tableName: string;
  contextId?: string;
  count: number;
  i18nKey: string;
  sjmoCode: string;
}

const TabHeader: FC<React.PropsWithChildren<TabHeaderProps>> = props => {
  const [labels, setLabels] = useState<null | { table: String; entity: string }>(null);

  const refresh = useCallback(() => {
    if (!props.contextId) {
      return;
    }

    getRefreshedEntityLabels(props.sjmoCode, props.tableName, props.contextId as string)
      .then(response => {
        setLabels(response.data);
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }
        console.log(t(er.response.data.message));
      });
  }, [props.sjmoCode, props.tableName, props.contextId]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const { t } = useTranslation();

  return (
    <nav className="level">
      <div className="level-left">
        <div className="level-item">
          <p className="subtitle is-5" style={{ maxWidth: "60vw" }}>
            {t(props.i18nKey, {
              count: props.count,
              tableName: labels?.table,
              oldPks: [labels?.entity]
            })}
          </p>
        </div>
      </div>
      <div className="level-right">
        <div className="level-item">{props.children}</div>
      </div>
    </nav>
  );
};

export default TabHeader;
