import { SyntheticEvent } from "react";
import { format, parse, min as minDate, max as maxDate } from "date-fns";
import { AxiosError, AxiosResponse } from "axios";

import { whenValidateItem, createMany, deleteMany } from "api";

import { Pojo, WhenValidateItemResult } from "types/Galaxy";
import { Message } from "types/Message";
import { ComponentGroupState } from "types/ComponentGroup";
import toaster from "composants/toaster/toaster";
import { uuidv4 } from "./uuid.utils";

/**
 * Permet de convertir les valeurs d'un element HTML vers la bonne valeur
 * @param event event HTML
 */
export function convertValue(event: SyntheticEvent<any>, useLocalDate: boolean = false) {
  // on évite d'avoir des string vide pour les boolean, number ou date
  if (event.currentTarget.value === undefined || event.currentTarget.value.length === 0) {
    return null;
  }

  if (
    event.currentTarget instanceof HTMLButtonElement &&
    event.currentTarget.getAttribute("role") === "switch"
  ) {
    return event.currentTarget["ariaChecked"] === "true";
  }

  switch (event.currentTarget.type) {
    case "checkbox":
      return event.currentTarget.checked;

    case "number": {
      let valueNumber: number = event.currentTarget.valueAsNumber;
      let min = event.currentTarget.min ? Number.parseFloat(event.currentTarget.min) : null;
      let max = event.currentTarget.max ? Number.parseFloat(event.currentTarget.max) : null;

      if (min !== null) {
        valueNumber = Math.max(min, valueNumber);
      }

      if (max !== null) {
        valueNumber = Math.min(max, valueNumber);
      }

      // si jamais on a pas le droit de mettre de nombre à virgule, on faire un round sur le nombre
      let maxValDB: string = event.currentTarget.dataset.maxDb;
      let shouldParseToInt = maxValDB ? maxValDB.indexOf(".") === -1 : false;
      return shouldParseToInt && valueNumber ? Math.round(valueNumber) : valueNumber;
    }

    // peut-être que c'est quelque chose que l'on
    // souhaitera faire plus tard
    case "date":
    case "datetime-local": {
      let dateValue = parse(event.currentTarget.value);
      let min = event.currentTarget.min ? parse(event.currentTarget.min) : null;
      let max = event.currentTarget.max ? parse(event.currentTarget.max) : null;

      if (min !== null) {
        dateValue = maxDate(min, dateValue);
      }

      if (max !== null) {
        dateValue = minDate(max, dateValue);
      }

      const convertedValue = useLocalDate ? format(dateValue, "YYYY-MM-DD") : format(dateValue);
      return convertedValue;
    }

    default:
      // if (event.currentTarget.dataset.dataCustomDecimalLimit) {
      //   return event.currentTarget.valueAsNumber;
      // } else {
      return event.currentTarget.value;
    // }
  }
}

/**
 * Méthode qui permet de valider une entité (WVI) en base.
 * Elle appelle l'api et s'occupe de filtrer le message d'erreur si jamais
 * l'appel plante.
 *
 * @param sjmoCode code module
 * @param tableName nom de la table
 * @param ctrlKey colonne d'origine de la modif
 * @param entity entité à valider
 * @param onSuccess fonction lorsque l'appel est correct
 * @param onError fonction lorsque la validation retourne une erreur
 */
export function validatePojo(
  sjmoCode: string,
  tableName: string,
  ctrlKey: string,
  entity: Pojo,
  onSuccess: (success: AxiosResponse<WhenValidateItemResult<Pojo>>) => void,
  onError: (error: WhenValidateItemResult<Pojo>) => void
) {
  whenValidateItem(sjmoCode, tableName, ctrlKey, entity)
    .then(res => {
      onSuccess(res);
    })
    .catch((res: AxiosError<WhenValidateItemResult<Pojo>>) => {
      if (res.response) {
        const data = res.response.data;

        if (data && typeof data === "object" && data.message.target === "FIELD") {
          onError(data);
        } else {
          const isExistingEntity = entity.id !== null || entity.id !== undefined;
          console.error(
            `uncatched exception with validatePojo on ${sjmoCode} with ${tableName} on the key ${ctrlKey}. ${
              isExistingEntity ? `Entity ID = ${entity.id}` : ""
            }`
          );
        }
      }
    });
}

export function createOneEntity(
  tableName: string,
  entity: Pojo,
  sjmoCode: string,
  onSuccess: (success: Pojo) => void
) {
  createMany(tableName, [entity], sjmoCode)
    .then(res => {
      onSuccess(res.data[0]);
    })
    .catch((res: AxiosError<any>) => {
      throw res;
    });
}

export function createManyEntity(
  tableName: string,
  entities: Pojo[],
  sjmoCode: string,
  onSuccess: (success: AxiosResponse<Pojo[]>) => void,
  onError: (error: Message) => void
) {
  createMany(tableName, entities, sjmoCode)
    .then(res => {
      onSuccess(res);
    })
    .catch((res: AxiosError<Message>) => {
      if (res.response) {
        const data = res.response.data;
        if (data && data.target === "FIELD") {
          onError(data);
        } else {
          throw res;
        }
      }
    });
}

export function deleteManyEntity(
  tableName: string,
  entities: (string | number)[],
  sjmoCode: string,
  onSuccess: (success: AxiosResponse<void>) => void,
  onError: (error: Message) => void
) {
  deleteMany(tableName, entities, sjmoCode)
    .then(res => {
      onSuccess(res);
    })
    .catch((res: AxiosError<Message>) => {
      if (res.response) {
        const data = res.response.data;
        if (data && data.target === "FIELD") {
          onError(data);
        } else {
          throw res;
        }
      }
    });
}

/**
 * Retourne la liste des composants depuis une liste de groupes.
 * @param groups groupes de composant
 */
export function getCompos(groups: ComponentGroupState[]) {
  return groups.flatMap(group => group.compos);
}

export function findIndexMap<T>(map: Record<string, T>, getter: (el: T) => boolean): number {
  let keys = Object.keys(map);
  for (let index = 0, maxIndex = keys.length; index < maxIndex; index++) {
    if (getter(map[keys[index]])) {
      return index;
    }
  }
  return -1;
}

export function findMap<T>(map: Record<string, T>, getter: (el: T) => boolean): T | null {
  let keys = Object.keys(map);
  for (let key of keys) {
    if (getter(map[key])) {
      return map[key];
    }
  }

  return null;
}

export function listToMap<T>(
  list: T[] = [],
  startAt: number = 0,
  getter: (el: T) => any = (el: T) => el
): Record<string, T> {
  let map = {};

  for (let index = 0; index < list.length; index++) {
    map[index + startAt] = getter(list[index]);
  }

  return map;
}

export function mapToList<T>(map: Record<string, T>): T[] {
  const keys = Object.keys(map);
  let array: T[] = new Array(keys.length);

  for (let key of keys) {
    const index = parseInt(key, 10);
    array[index] = map[key];
  }

  return array;
}

export function mapToUnorderedList<T>(map: Record<string, T>): T[] {
  const keys = Object.keys(map);
  let array: T[] = new Array();

  for (let key of keys) {
    array.push(map[key]);
  }

  return array;
}

export function convertToMap<T, X = T | keyof T>(
  list: T[],
  getKey: (el: T) => string,
  getValue: (el: T) => X = el => el as any
): Record<string, X> {
  let map: Record<string, X> = {};
  for (let el of list) {
    map[getKey(el)] = getValue(el);
  }

  return map;
}

/**
 * Retourne une map de pojo indexé par index
 * @param pojos liste de pojo
 */
export function listAsMapOfIndex<T>(pojos: T[], startAt: number = 0) {
  return listToMap<T>(pojos, startAt);
}

export function pojoListToMapOfIndex(pojos: Pojo[], startAt: number = 0) {
  return listToMap(pojos, startAt, el => el.id);
}

/**
 * Permet de séparer les jointures de l'entité passé en paramètre.
 * Le retour de la fonction est une map par tableName des différentes entités présentes
 *
 * @param pojo pojo à transformer
 */
export function preparePojo(pojo: Pojo): Record<string, Record<string, Pojo>> {
  let preparedPojos: Record<string, Record<string, Pojo>> = {};

  // on initialise le tableName de notre entité
  if (!preparedPojos[pojo.tableName]) {
    preparedPojos[pojo.tableName] = {} as any;
    preparedPojos[pojo.tableName][pojo.id] = pojo;
  }

  // on boucle à travers les différentes propriétés de notre objet
  const keys = Object.keys(pojo);
  for (let key of keys) {
    if (typeof pojo[key] === "object" && key !== "_style") {
      // on récupère le pojo interne
      let innerPojo: Pojo = pojo[key];

      if (innerPojo) {
        // on associe seulement la clé du parent
        preparedPojos[pojo.tableName][pojo.id][key] = innerPojo.id;

        // on initialise le table name de la jointure
        if (!preparedPojos[innerPojo.tableName]) {
          preparedPojos[innerPojo.tableName] = {} as any;
        }
        preparedPojos[innerPojo.tableName][innerPojo.id] = innerPojo;
      }
    }
  }
  return preparedPojos;
}

export function checkEntityValid(e: "LOADING_POJO" | undefined | Pojo): e is Pojo {
  return e !== "LOADING_POJO" && e !== undefined;
}
