import { call, put, takeLatest } from "redux-saga/effects";

import { AxiosResponse } from "axios";

import Action from "reducers/Action";

import {
  FETCH_EXPERT_GROUP,
  FETCH_EXPERT_GROUP_SUCCESS,
  FETCH_EXPERT_HEADER,
  FETCH_EXPERT_HEADER_SUCCESS
} from "constant/expert";

import { getExpertGroups, getExpertHeader } from "api/expert";
import { ComponentGroupState } from "types/ComponentGroup";

/**
 * Permet de récupérer les groupes de composants associés à un focus d'expert.
 * @param action action redux
 */
function* fetchExpertGroups(action: Action<{ sjmoCode: string; sjxdId: string }>) {
  try {
    const response: AxiosResponse<ComponentGroupState[]> = yield call(
      getExpertGroups,
      action.payload.sjmoCode,
      action.payload.sjxdId
    );

    yield put({
      type: FETCH_EXPERT_GROUP_SUCCESS,
      payload: {
        groups: response.data,
        sjxdId: action.payload.sjxdId
      }
    });
  } catch {
    console.error(
      "erreur lors de la récupération de la definition d'expert :saga.fetchExpertGroups' "
    );
  }
}

/**
 * Permet de récupérer le header de composants associés à un expert
 * @param action action redux
 */
function* fetchExpertHeader(action: Action<{ sjmoCode: string }>) {
  try {
    const response: AxiosResponse<ComponentGroupState[]> = yield call(
      getExpertHeader,
      action.payload.sjmoCode
    );

    yield put({
      type: FETCH_EXPERT_HEADER_SUCCESS,
      payload: {
        header: response.data,
        sjmoCode: action.payload.sjmoCode
      }
    });
  } catch {
    console.error(
      "erreur lors de la récupération de la definition d'expert :saga.fetchExpertHeader "
    );
  }
}

// export d'une liste qui est utilisée dans l'index des saga
export default [
  takeLatest(FETCH_EXPERT_GROUP, fetchExpertGroups),
  takeLatest(FETCH_EXPERT_HEADER, fetchExpertHeader)
];
