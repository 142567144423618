import { AxiosPromise } from "axios";
import { apiAdn, getUIContext } from "./common";
import { FlatTreeNode, RoughTreeData } from "types/Tree";
import { Message } from "types/Message";

export function getTreeFocuses(sjmoCode: string, panelId: string) {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/tree/focuses?${params}&panelId=${panelId}`);
}

export interface TreeDataDto {
  message: Message;
  treeData: FlatTreeNode[];
  treeId: string;
}
export function getTreeDataApi(treeId: string, rootId: string | null, sjmoCode: string) {
  const params = getUIContext({ sjmoCode });
  params.append("treeId", treeId);
  rootId && params.append("rootId", rootId);
  return apiAdn.get<TreeDataDto>(`/tree/data?${params}`);
}

export function getNodeInfos(sjmoCode: string, treeId: string, node: RoughTreeData) {
  const params = getUIContext({ sjmoCode });

  let url = `/tree/infos?${params}&treeId=${treeId}`;

  Object.keys(node).forEach(key => {
    if (key !== "children" && key !== "title" && key !== "infosCompl" && key !== "options") {
      url += `&${key}=${node[key]}`;
    }
  });

  Object.keys(node.options).forEach(key => {
    url += `&${key}=${node[key]}`;
  });

  return apiAdn.get(url);
}

export function onDeleteNode(args: {
  sjmoCode: string;
  treeId: string;
  mainEntityId: string | null;
  current: RoughTreeData;
}) {
  const params = getUIContext({ sjmoCode: args.sjmoCode });
  const idToDelete = args.current.options.deleteId
    ? args.current.options.deleteId
    : args.current.id;
  return apiAdn.delete<TreeDataDto>(
    `tree/deleteNode?treeId=${args.treeId}&rootId=${args.mainEntityId}&id=${idToDelete}&nature=${args.current.nature}?${params}`
  );
}

export function onMoveNode(args: {
  sjmoCode: string;
  treeId: string;
  mainEntityId: string | null;
  current: RoughTreeData;
  nextParent: RoughTreeData;
  nextIndex: number;
}) {
  const params = getUIContext({ sjmoCode: args.sjmoCode });
  const body = {
    id: args.current.id,
    parentCible: args.nextParent.id,
    position: args.nextIndex,
    nature: args.current.nature,
    parentNature: args.nextParent.nature,
    treeId: args.treeId,
    rootId: args.mainEntityId,
    ...args.current.options
  };

  return apiAdn.post<TreeDataDto>(`/tree/moveNode?${params}`, body);
}

/* ***************************************** EXPERT ************************************************* */

export function getExpertTreeFocus(sjmoCode: string): AxiosPromise<FlatTreeNode[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/expert/tree?${params}`);
}

export function onMoveNodeExpert(args: {
  current: RoughTreeData;
  nextParent: RoughTreeData;
  nextIndex: number;
  sjmoCode: string;
}): AxiosPromise<FlatTreeNode[]> {
  const params = getUIContext({ sjmoCode: args.sjmoCode });

  const body = {
    id: args.current.id,
    parentCible: args.nextParent ? args.nextParent.id : null,
    position: args.nextIndex,
    nature: args.current.nature,
    parentNature: args.nextParent ? args.nextParent.nature : null
  };

  return apiAdn.post(`/admin/expert/tree/onMoveNode?${params}`, body);
}

export function editComponentName(ComponentId: string, title: string, sjmoCode: string) {
  const params = getUIContext({ sjmoCode: sjmoCode });
  const body = {
    ComponentId: ComponentId,
    title: title
  };
  return apiAdn.post(`/admin/expert/tree/editComponentName?${params}`, body);
}

export function onInsertNodeExpert(args: {
  current: RoughTreeData;
  nextParent: RoughTreeData;
  nextIndex: number;
  sjmoCode: string;
}): AxiosPromise<FlatTreeNode[]> {
  const params = getUIContext({ sjmoCode: args.sjmoCode });

  const body = {
    id: args.current.id,
    parentCible: args.nextParent ? args.nextParent.id : null,
    position: args.nextIndex,
    nature: args.current.nature,
    parentNature: args.nextParent ? args.nextParent.nature : null,
    title: args.current.title
  };

  return apiAdn.put(`/admin/expert/tree/onInsertNode?${params}`, body);
}

export function onDeleteNodeExpert(args: {
  current: RoughTreeData;
  sjmoCode: string;
}): AxiosPromise<FlatTreeNode[]> {
  const params = getUIContext({ sjmoCode: args.sjmoCode });

  return apiAdn.delete(
    `/admin/expert/tree/onDeleteNode?${params}&id=${args.current.id}&nature=${args.current.nature}`
  );
}
