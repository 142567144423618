import { apiAdn, apiUtils, getUIContext } from "./common";
import { AxiosPromise, CancelTokenSource } from "axios";
import { InteractiveReport, InteractiveReportConfiguration } from "types/Component";
import {
  FindViewComplexParams,
  configureUrlParamsForSearch,
  FindViewComplexBody
} from "./entities";
import { URL_DOWNLOAD } from "customGlobal";
import { Pojo } from "types/Galaxy";
import auth from "auth";

export function getInteractiveReportDefinition(id: string): AxiosPromise<InteractiveReport> {
  return apiAdn.get(`/interactive-report/definition/${id}`);
}

export function saveCustomInteractiveReport(
  id: string,
  configuration: InteractiveReportConfiguration
): AxiosPromise<InteractiveReport> {
  return apiAdn.post(`/interactive-report/definition/${id}`, configuration);
}

export function deleteCustomInteractiveReport(
  id: string,
  configurationId: number | string
): AxiosPromise<InteractiveReport> {
  return apiAdn.delete(`/interactive-report/definition/${id}/${configurationId}`);
}

export function updateInteractiveReportStateAdmin(
  sjmoCode: string,
  interactiveReportId: string,
  configuration: InteractiveReportConfiguration
): AxiosPromise<InteractiveReport> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.put(
    `/interactive-report/admin/global?${params}&interactiveReportId=${interactiveReportId}`,
    configuration
  );
}

export function createInteractiveReportStateAdmin(
  sjmoCode: string,
  interactiveReportId: string,
  configuration: InteractiveReportConfiguration
): AxiosPromise<InteractiveReport> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.post(
    `/interactive-report/admin/global?${params}&interactiveReportId=${interactiveReportId}`,
    configuration
  );
}

export function createInteractiveReportAdmin(sjmoCode: string, entity: Pojo): AxiosPromise<Pojo> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.post(`/interactive-report/admin?${params}`, entity);
}

export function duplicateInteractiveReportAdmin(
  sjmoCode: string,
  syjIR: Pojo,
  syjPanel: Pojo,
  sjinCode: string
) {
  const params = getUIContext({ sjmoCode });
  return apiAdn.post(`/interactive-report/admin/duplicate?${params}`, {
    syjPanel: syjPanel,
    syjInteractiveReport: syjIR,
    sjinCode: { sjinCode }
  });
}

export function createPanelByAdmin(sjmoCode: string, entity: Pojo): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.post(`/interactive-report/admin/panel?${params}`, entity);
}

export function deleteInteractiveReportStateAdmin(
  id: string,
  configurationId: number | string
): AxiosPromise<InteractiveReport> {
  return apiAdn.delete(`/interactive-report/admin/${id}/${configurationId}`);
}

export function createInteractiveReportState(sjmoCode: string): AxiosPromise<InteractiveReport> {
  return apiAdn.post(`/interactive-report/state`);
}

export function downloadInteractiveReportExport(
  params: FindViewComplexParams & { labels: string[] },
  typeExport: string,
  cancelToken?: CancelTokenSource
) {
  const allParamsStr = configureUrlParamsForSearch(params);

  const body: FindViewComplexBody & { labels: string[] } = {
    includes: params.includes,
    groupBy: {
      groupBy: params.groupBy.columns || [],
      functions: params.groupBy.functionList || []
    },
    aggregates: (params.aggregates || []).map(aggr => {
      return { ...aggr, overColumns: params.breakRows || [] };
    }),
    labels: params.labels
  };

  return apiUtils
    .post(`/view/${params.tableName}/${typeExport}?${allParamsStr}`, body, {
      cancelToken: cancelToken && cancelToken.token
    })
    .then(res => {
      let fakeLink = document.createElement("a");
      fakeLink.href = `${URL_DOWNLOAD()}${encodeURI(res.data)}&access_token=${auth.token}`;
      fakeLink.click();
    });
}
