import React, {
  FC,
  useState,
  MouseEvent,
  useMemo,
  useRef,
  SyntheticEvent,
  useCallback
} from "react";
import classNames from "classnames";
import Fuse from "fuse.js";

import { ComponentState } from "types/Component";
import { Fa } from "composants/Icon";
import { t } from "utils/i18n";

import { getUserLang } from "utils/network.utils";
import { useIsSuperUserActive } from "composants/userSettings/SuperUser";
import { Popover } from "@axin-org/comet";

const DropdownFilterItem: FC<{
  column: string;
  isVisible: boolean;
  label: string;
  resourceKey: string;
  isAdmin?: boolean;
  onClick(e: MouseEvent<HTMLAnchorElement>): void;
}> = ({ column, isVisible, label, resourceKey, isAdmin, onClick }) => {
  const isSuperUserActive = useIsSuperUserActive();
  return (
    <a className="dropdown-item" data-value={column} onClick={onClick}>
      <span className="icon">
        <Fa icon="circle" className={classNames(isVisible && "has-text-success")} />
      </span>
      <span>{label}</span>
      {isAdmin === true ? ` (${resourceKey})` : null}
      {isSuperUserActive ? ` (${column})` : null}
    </a>
  );
};

export const DropdownFilterColumn: FC<{
  columns: ComponentState[];
  disabled?: boolean;
  isAdmin?: boolean;
  onClick(e: MouseEvent<HTMLAnchorElement>): void;
}> = ({ columns, disabled, isAdmin, onClick }) => {
  const [term, setTerm] = useState("");
  const fuseInstance = useRef<Fuse<ComponentState> | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  function onActiveChange(isActive: boolean) {
    if (isActive && inputRef.current) {
      inputRef.current.focus();
    }
  }

  const isAllColumnVisible = useMemo(() => {
    return columns.filter(col => col.compoVisible).length === columns.length;
  }, [columns]);

  const visibleColumns = useMemo(() => {
    return columns.filter(col => col.compoVisible);
  }, [columns]);

  const hiddenColumns = useMemo(() => {
    const hidden = columns.filter(col => !col.compoVisible && col.authorizeVisibility !== false);

    const collator = Intl.Collator(getUserLang(), {
      sensitivity: "base",
      ignorePunctuation: true,
      numeric: true
    });

    hidden.sort((a, b) => collator.compare(a.label, b.label));

    return hidden;
  }, [columns]);

  function onChangeTerm(e: SyntheticEvent<HTMLInputElement>) {
    const value = e.currentTarget.value;
    setTerm(value);
  }

  const getFuse = useCallback(function getFuse(filter: ComponentState[]) {
    if (fuseInstance.current === null) {
      fuseInstance.current = new Fuse<ComponentState>(filter, {
        keys: ["label", "column"],
        threshold: 0.3
      });
    }

    fuseInstance.current.setCollection(filter);
    return fuseInstance.current;
  }, []);

  const computedVisibleColumns = useMemo(() => {
    if (term === "") {
      return visibleColumns;
    } else {
      const fuse = getFuse(visibleColumns);
      return fuse.search(term).map(r => r.item);
    }
  }, [getFuse, term, visibleColumns]);

  const computedHiddenColumns = useMemo(() => {
    if (term === "") {
      return hiddenColumns;
    } else {
      const fuse = getFuse(hiddenColumns);
      return fuse.search(term).map(r => r.item);
    }
  }, [getFuse, hiddenColumns, term]);

  return (
    <Popover
      trigger={
        <button
          className="button is-link is-inverted"
          disabled={disabled}
          title={t("commun_colonnes")}
        >
          <span className="icon">
            <Fa icon="columns" className="has-has-text-link" />
          </span>
          <span>{t("commun_colonnes")}</span>
          <span className="icon">
            <Fa icon="angle-down" />
          </span>
        </button>
      }
      usePortal
    >
      <div className="" style={{ maxHeight: 400, overflowY: "auto" }}>
        <div className="dropdown-item">
          <div className="control has-icons-left">
            <input
              ref={inputRef}
              onDoubleClick={e => e.currentTarget.select()}
              className="input is-small"
              placeholder={t("commun_recherche")}
              value={term}
              onChange={onChangeTerm}
            />
            <span className="icon is-left">
              <Fa icon="search" />
            </span>
          </div>
        </div>
        <a
          className="dropdown-item"
          onClick={onClick}
          data-value={isAllColumnVisible ? "DESELECT_ALL" : "SELECT_ALL"}
        >
          {t(isAllColumnVisible ? "commun_deselectionner_tout" : "commun_selectionner_tout")}
        </a>
        <hr className="dropdown-divider" />
        <section>
          {computedVisibleColumns.map(col => (
            <DropdownFilterItem
              key={col.column}
              column={col.column}
              isVisible={col.compoVisible}
              label={col.label}
              resourceKey={col.resourceKey ? col.resourceKey : ""}
              isAdmin={isAdmin}
              onClick={onClick}
            />
          ))}
        </section>
        <hr className="dropdown-divider" />
        <section>
          {computedHiddenColumns.map(col => (
            <DropdownFilterItem
              key={col.column}
              column={col.column}
              isVisible={col.compoVisible}
              label={col.label}
              resourceKey={col.resourceKey ? col.resourceKey : ""}
              isAdmin={isAdmin}
              onClick={onClick}
            />
          ))}
        </section>
      </div>
    </Popover>
  );
};
