import React, { FC, HTMLAttributes } from "react";
import classNames from "classnames";

import "./Dot.css";

export const Dot: FC<{
  color: string;
  size?: number | string;
  hasShadow?: boolean;
  innerClassName?: string;
} & HTMLAttributes<HTMLDivElement>> = ({
  className,
  style,
  color,
  title,
  size = 16,
  hasShadow = true,
  innerClassName,
  ...props
}) => {
  return (
    <div
      className={classNames("dot-container", className)}
      style={{
        ...style,
        width: size,
        height: size
      }}
      {...props}
    >
      <div
        title={title}
        aria-hidden="true"
        className={classNames("dot", innerClassName)}
        style={{
          backgroundColor: color,
          boxShadow: hasShadow ? color + " 0 0 6px 1px" : undefined
        }}
      />
    </div>
  );
};
