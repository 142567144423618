import { FC, useState, useEffect } from "react";
import AutoCompleteAndLabel from "composants/autocomplete/AutoCompleteAndLabel";
import { Pojo } from "types/Galaxy";
import { t } from "utils/i18n";
import Modal from "composants/Modal/Modal";
import { Message } from "types/Message";
import { preRecord } from "api";
import { GSBuilder } from "utils/query.utils";
import { createPanelsDuplicationOrExtract, createPanelsExtract } from "api/adminDuplication";
import { PanelState } from "types/Dashboard";
import { Col } from "composants/Layout";
import TextAreaAndLabel from "composants/input/TextAreaAndLabel";

interface ModalDuplicationProps {
  sjmoCode: string;
  sqlMode: string;
  panel: PanelState | null;
  createAction: "USER" | "ROLE" | "GLOBAL";
  galaxyFocuses: any;
  syjModuleId?: string;
  personnelId?: string;
  selectedFocus: string;
  onClose(): void;
  onCreateOrUpdateGalaxyFocus(params: {
    currentSjmoCode: string;
    title: string;
    id?: string;
    position?: number;
    associationId?: String;
    focusCode?: String;
    groupe?: String;
    privilegie: boolean;
    code: string;
    syjModuleId: string;
    sysMenuGroupeId: string;
    personnelId: string;
    callback: () => void;
  }): void;
  addMessage(message: Message): void;
}

const ModalDuplication: FC<ModalDuplicationProps> = props => {
  const [selectedGalaxy, setSelectedGalaxy] = useState<Pojo | null>(null);
  const [resultSql, setResultSql] = useState<String | null>("Chargement...");
  const [preRecordPanel, setPreRecordPanel] = useState<Pojo | null>(null);

  function createPanelsExtractionfunction() {
    if (props.sjmoCode && props.panel) {
      createPanelsExtract(props.sjmoCode, props.panel.panelId).then(res => {
        setResultSql(res.data);
      });
    }
  }

  useEffect(() => {
    preRecord({ sjmoCode: "SYJ_PANEL", tableName: "syjPanel" })
      .then(res => {
        if (props.sqlMode === "scriptsql") {
          createPanelsExtractionfunction();
        }
        let panel = res.data;
        panel.sjpaType = props.panel?.panelType;
        setPreRecordPanel(panel);
      })
      .catch(error => {
        console.log("Prerecord failed ", error);
      });
  }, []);

  const onSave = () => {
    setResultSql("");
    if (props.syjModuleId && props.panel && selectedGalaxy && preRecordPanel) {
      let panel = { ...preRecordPanel };
      panel.syjModuleId = selectedGalaxy?.id;
      panel.sjpaSjrbResourceKey = props.panel.panelLabel;
      panel.sjpaCode = props.panel.panelCode + "_DUPLI";
      console.log("Panel send prerecord => ", panel);
      if (props.sqlMode == "duplicate") {
        createPanelsDuplicationOrExtract(
          props.sjmoCode,
          props.panel.panelId,
          selectedGalaxy?.id,
          panel,
          props.sqlMode // disable sql extraction mode
        ).then(res => {
          props.onClose();
        });
      } else {
        props.onClose();
      }
    } else {
      const message: Message = {
        type: "DANGER",
        message: t("commun_champs_obligatoire_non_rempli"),
        target: "GLOBAL"
      };
      props.addMessage(message);
    }
  };

  return (
    <Modal
      onClose={() => props.onClose()}
      onValidate={onSave}
      title={"Duplication vers une autre galaxie"}
    >
      {props.sqlMode !== "scriptsql" && (
        <Col>
          <AutoCompleteAndLabel
            id="syjModule"
            label={t("commun_module")}
            joinTableName={"syjModule"}
            sjmoCode={"SYJ_MODULE"}
            value={selectedGalaxy?.id}
            joinListFields="sjmoCode"
            styleInput={{ width: "30rem" }}
            additionalClause={GSBuilder.Comparison("sjmoCode", "OPER_LIKE_ANYWHERE", "")}
            onItemChange={pojo => {
              setSelectedGalaxy(pojo);
            }}
          />
        </Col>
      )}
      {props.sqlMode === "scriptsql" && (
        <Col>
          <TextAreaAndLabel
            label={"Resultat SQL"}
            readOnly={true}
            style={{ height: 250 }}
            id="syjModule"
            value={resultSql}
          />
        </Col>
      )}
    </Modal>
  );
};

export default ModalDuplication;
