import React, { FC } from "react";
import ModalViewver from "composants/Modal/ModalViewer";
import { getUIContext } from "api/common";
import { URL_DOWNLOAD_VIA_UTILS, URL_DOWNLOAD_VIA_GED } from "customGlobal";
import { t } from "utils/i18n";
import RafComponent from "composants/RafComponent";
import auth from "auth";

export interface DocumentLightDto {
  name?: string;
  path?: string;
  id?: string;
  mimeType: string;
}

interface DocumentViewerProps {
  document: DocumentLightDto;
  onClose(): void;
}

const DocumentViewer: FC<DocumentViewerProps> = props => {
  const params = getUIContext();
  if (props.document.path) {
    params.append("path", props.document.path ? props.document.path : "");
  }

  const renderBody = () => {
    let path: string;
    // si on a un ID, c'est pas utils qu'il faut appeler, c'est le rest
    if (props.document.id) {
      path = [URL_DOWNLOAD_VIA_GED(), "document", props.document.id].join("/");
    } else {
      // si on arrive ici, il nous faut absolument un path du parent
      path = [URL_DOWNLOAD_VIA_UTILS(), "document"].join("/");
    }

    const url = `${path}?${params}&access_token=${auth.token}`;

    if (props.document.mimeType.includes("image")) {
      return (
        <img src={url} alt={props.document.name} style={{ height: "90vh", maxWidth: "100%" }} />
      );
    } else {
      return (
        <object data={url} type={props.document.mimeType} style={{ height: "90vh", width: "100%" }}>
          {t("commun_impossible_d_afficher_le_document")}
        </object>
      );
    }
  };

  return (
    <ModalViewver onClose={props.onClose}>
      <RafComponent>{renderBody}</RafComponent>
    </ModalViewver>
  );
};

export default DocumentViewer;
