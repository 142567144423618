import React, { FC, SyntheticEvent } from "react";

import "./NumPad.css";

export const NumPad: FC<{ onClick(value: string): void }> = props => {
  function onClick(e: SyntheticEvent<HTMLAnchorElement>) {
    const value = e.currentTarget.dataset.value;
    value && props.onClick(value);
  }

  return (
    <div className="numpad">
      <a data-value="(" onClick={onClick}>
        (
      </a>
      <a data-value=")" onClick={onClick}>
        )
      </a>
      <a data-value="'" onClick={onClick}>
        '
      </a>
      <a data-value="||" onClick={onClick}>
        ||
      </a>

      <a data-value="7" onClick={onClick}>
        7
      </a>
      <a data-value="8" onClick={onClick}>
        8
      </a>
      <a data-value="9" onClick={onClick}>
        9
      </a>
      <a data-value="-" onClick={onClick}>
        -
      </a>

      <a data-value="4" onClick={onClick}>
        4
      </a>
      <a data-value="5" onClick={onClick}>
        5
      </a>
      <a data-value="6" onClick={onClick}>
        6
      </a>
      <a data-value="+" onClick={onClick}>
        +
      </a>

      <a data-value="1" onClick={onClick}>
        1
      </a>
      <a data-value="2" onClick={onClick}>
        2
      </a>
      <a data-value="3" onClick={onClick}>
        3
      </a>
      <a data-value="*" onClick={onClick}>
        *
      </a>

      <a data-value="0" onClick={onClick}>
        0
      </a>
      <a data-value="." onClick={onClick} className="numpad-dot">
        .
      </a>
      <a data-value="/" onClick={onClick}>
        /
      </a>

      <a data-value=" " onClick={onClick} className="numpad-space">
        espace
      </a>
      <a data-value="," onClick={onClick} className="numpad-comma">
        ,
      </a>
    </div>
  );
};
