import React, { FC, FormEvent, useState, SyntheticEvent, useMemo } from "react";
import Modal from "composants/Modal/Modal";

import { FilterProperties } from "types/InteractiveReport";
import { Trans, useTranslation } from "react-i18next";
import produce from "immer";
import { Field } from "composants/form";
import { Fa } from "composants/Icon";
import { ComponentState } from "types/Component";
import { convertValue } from "utils/entities.utils";
import { getCollator } from "utils/network.utils";
import { format } from "date-fns";
import { RSQLOperator } from "utils/query.utils";

interface FilterDialogProps {
  isAdmin?: boolean;
  columns: ComponentState[];
  filters: FilterProperties[];
  onClose(): void;
  onValidate(filters: FilterProperties[]): void;
}

export const FilterDialog: FC<FilterDialogProps> = props => {
  const [t] = useTranslation(["commun", "operator"]);
  const [filters, setFilters] = useState(props.filters);

  function getFirstUnusedColumn() {
    for (let col of props.columns) {
      if (filters.findIndex(filter => filter.column === col.column) === -1) {
        return col.column;
      }
    }

    return "";
  }

  const visibleColumns = useMemo(() => {
    const collator = getCollator();
    const visible = props.columns.filter(col => col.compoVisible);
    visible.sort((a, b) => collator.compare(a.label ?? a.column, b.label ?? b.column));
    return visible;
  }, [props.columns]);

  const invisibleColumns = useMemo(() => {
    const collator = getCollator();

    const invisible = props.columns.filter(col => !col.compoVisible);
    invisible.sort((a, b) => collator.compare(a.label ?? a.column, b.label ?? b.column));
    return invisible;
  }, [props.columns]);

  const filtersDeps = filters.map(filter => `${filter.column}:${filter.value}`).join("&");
  const hasDuplicateColumns = useMemo(() => {
    const columnsUsage: Record<string, number> = {};
    for (let filter of filters) {
      if (columnsUsage[filter.column] === undefined) {
        columnsUsage[filter.column] = 1;
      } else {
        columnsUsage[filter.column]++;
      }
    }

    const values = Object.values(columnsUsage);

    for (let value of values) {
      if (value > 1) {
        return true;
      }
    }

    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersDeps]);

  function addNewFilters() {
    setFilters([
      ...filters,
      { type: "normal", column: getFirstUnusedColumn(), operator: "OPER_EQ", value: "" }
    ]);
  }

  function deleteFilter(index: number) {
    setFilters([...filters.slice(0, index), ...filters.slice(index + 1)]);
  }

  function onValidate(e: FormEvent) {
    e.preventDefault();
    if (!hasDuplicateColumns) {
      props.onValidate(filters);
    }
  }

  function onChange(index: number, name: keyof FilterProperties, value: string) {
    const newFilters = produce(filters, draft => {
      draft[index][name] = value;
    });
    setFilters(newFilters);
  }

  return (
    <Modal title={t("commun_filtrer")} onClose={props.onClose} hideFooter>
      {hasDuplicateColumns && (
        <div className="message is-danger">
          <div className="message-body">
            <Trans i18nKey="commun_filter_duplicate_column_error" />
          </div>
        </div>
      )}
      <form onSubmit={onValidate}>
        <Field label={t("commun_vos_filtres")}>
          {filters.map((filter, index) => {
            const col = props.columns.find(col => col.column === filter.column);

            return (
              <div
                key={index}
                data-key={index}
                className="flex justify-between items-center mb-6"
                style={{ width: "100%" }}
              >
                {props.isAdmin === true && (
                  <div className="mr-6">
                    <div className="select">
                      <select
                        name="type"
                        value={filter.type}
                        onChange={e => onChange(index, "type", e.target.value)}
                      >
                        <option value="normal">normal</option>
                        <option value="static">static</option>
                      </select>
                    </div>
                  </div>
                )}
                <div className="mr-6">
                  <div className="select">
                    <select
                      name="column"
                      value={filter.column}
                      onChange={e => onChange(index, "column", e.target.value)}
                    >
                      <optgroup label={t("commun_visible")}>
                        {visibleColumns.map(col => {
                          return (
                            <option key={col.column} value={col.column}>
                              {col.label || col.column}
                            </option>
                          );
                        })}
                      </optgroup>
                      <optgroup label={t("commun_cache")}>
                        {invisibleColumns.map(col => {
                          return (
                            <option key={col.column} value={col.column}>
                              {col.label || col.column}
                            </option>
                          );
                        })}
                      </optgroup>
                    </select>
                  </div>
                </div>
                <div className="mr-6">
                  <div className="select">
                    <select
                      name="operator"
                      value={filter.operator}
                      onChange={e => onChange(index, "operator", e.target.value)}
                    >
                      <option value="OPER_NULL">{t("operator:operator_oper_null")}</option>
                      <option value="OPER_NOT_NULL">{t("operator:operator_oper_not_null")}</option>
                      <option value="OPER_EQ">{t("operator:operator_oper_eq")}</option>
                      <option value="OPER_NE">{t("operator:operator_oper_ne")}</option>
                      <option value="OPER_GT">{t("operator:operator_oper_gt")}</option>
                      <option value="OPER_LT">{t("operator:operator_oper_lt")}</option>
                      <option value="OPER_GE">{t("operator:operator_oper_ge")}</option>
                      <option value="OPER_LE">{t("operator:operator_oper_le")}</option>
                      <option value="OPER_LIKE_ANYWHERE">
                        {t("operator:operator_oper_like_anywhere")}
                      </option>
                      <option value="OPER_LIKE_END">{t("operator:operator_oper_like_end")}</option>
                      <option value="OPER_LIKE_START">
                        {t("operator:operator_oper_like_start")}
                      </option>
                      <option value="OPER_IN">{t("operator:operator_oper_in")}</option>
                      <option value="OPER_NOT_IN">{t("operator:operator_oper_not_in")}</option>
                    </select>
                  </div>
                </div>
                <div className="mr-6">
                  <input
                    className="input"
                    name="value"
                    type={inferTypeOfHtml(col, filter.operator)}
                    value={filter.value ?? ""}
                    onChange={e => onChange(index, "value", convertValue(e, true))}
                    disabled={
                      filter.operator === "OPER_NULL" || filter.operator === "OPER_NOT_NULL"
                    }
                    style={{ minWidth: "30rem" }}
                  />
                </div>
                <div className="mr-6">
                  <button className="delete" type="button" onClick={() => deleteFilter(index)} />
                </div>
              </div>
            );
          })}
        </Field>

        <div className="buttons">
          <input
            type="submit"
            className="button is-primary"
            value={t("commun_valider") as string}
            disabled={hasDuplicateColumns}
          />

          <button className="button" type="button" onClick={addNewFilters}>
            <Fa icon="plus" fixedWidth />
            <span>
              <Trans i18nKey="commun_ajouter" />
            </span>
          </button>
        </div>
      </form>
    </Modal>
  );
};

function inferTypeOfHtml(col: ComponentState | undefined, operator: RSQLOperator): string {
  if (!col) {
    return "text";
  }

  if (operator === "OPER_IN" || operator === "OPER_NOT_IN") {
    return "text";
  }

  switch (col.typeCompo) {
    case "NUMBER":
      return "number";
    case "DATE":
      return "date";
    case "TEXT":
    default:
      return col.isNumber ? "number" : "text";
  }
}
