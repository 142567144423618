import React, { FC, useState, SyntheticEvent, useCallback } from "react";
import Modal from "composants/Modal/Modal";
import { ComponentState } from "types/Component";
import produce from "immer";
import { Trans } from "react-i18next";
import { t } from "utils/i18n";
import { GroupByFunctionParameter } from "types/InteractiveReport";

interface GroupByDialogProps {
  columns: ComponentState[];
  initialGroupBy: string[];
  initialFunctionList: GroupByFunctionParameter[];
  onClose(): void;
  onValidate(groupBy: string[], functionList: GroupByFunctionParameter[]): void;
}

const SUPPORTED_FUNCTION = [
  "SUM",
  "AVG",
  "MAX",
  "MIN",
  "MEDIAN",
  "COUNT",
  "COUNT_DISTINCT",
  "RATIO_TO_REPORT_SUM",
  "RATIO_TO_REPORT_COUNT"
];

export function useGroupByColumns() {
  const [groupBy, setGroupByColumns] = useState<string[]>([]);
  const [functionList, setFunctionList] = useState<GroupByFunctionParameter[]>([]);

  const [isGroupByDialogOpen, setGroupByDialogOpen] = useState(false);

  const toggle = useCallback(() => setGroupByDialogOpen(val => !val), [isGroupByDialogOpen]);

  const setColumns = useCallback((col: string[]) => setGroupByColumns(col), [groupBy]);

  return {
    columns: groupBy,
    functionList,
    setColumns,
    setFunctionList,
    isOpen: isGroupByDialogOpen,
    toggle
  };
}

const initialFunctionParamater: GroupByFunctionParameter = {
  functionType: SUPPORTED_FUNCTION[0],
  column: "",
  label: ""
};

const GroupByFunctionList: FC<{
  functionList: GroupByFunctionParameter[];
  columns: ComponentState[];
  onChange(e: SyntheticEvent<HTMLSelectElement | HTMLInputElement>): void;
  addNewLine(): void;
  deleteLine(index: number): void;
}> = ({ functionList, columns, onChange, addNewLine, deleteLine }) => {
  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th>fonction</th>
            <th>colonne</th>
            <th>libellé</th>
          </tr>
        </thead>

        <tbody>
          {functionList.map((fn, index) => {
            return (
              <tr key={index}>
                <td>
                  <div className="select is-small">
                    <select
                      name="functionType"
                      value={fn.functionType}
                      onChange={onChange}
                      data-index={index}
                    >
                      <option value="" />
                      {SUPPORTED_FUNCTION.map(fnSupported => {
                        return (
                          <option key={fnSupported} value={fnSupported}>
                            {t(`commun_aggrate_function_${fnSupported.toLowerCase()}`)}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </td>
                <td>
                  <div className="select is-small">
                    <select name="column" value={fn.column} onChange={onChange} data-index={index}>
                      <option value="" />
                      {columns
                        .filter(col => col.typeCompo === "NUMBER")
                        .map(col => {
                          return (
                            <option key={col.column} value={col.column}>
                              {col.label}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </td>
                <td>
                  <input
                    className="input is-small"
                    type="text"
                    name="label"
                    value={fn.label}
                    onChange={onChange}
                    data-index={index}
                  />
                </td>
                <td>
                  <button
                    className="delete"
                    onClick={() => deleteLine(index)}
                    title={t("commun_supprimer")}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <button className="button is-text is-small" onClick={addNewLine}>
        Ajouter une autre fonction
      </button>
    </>
  );
};

const GroupByDialog: FC<GroupByDialogProps> = props => {
  const [groupBy, setBreakRow] = useState<string[]>(props.initialGroupBy || []);

  const [fnList, setFnList] = useState(
    props.initialFunctionList.length <= 0 ? [initialFunctionParamater] : props.initialFunctionList
  );

  function onChangeFnList(e: SyntheticEvent<HTMLSelectElement | HTMLInputElement>) {
    setFnList(
      produce(fnList, draft => {
        const currentIndex = e.currentTarget.dataset.index;
        const field = e.currentTarget.name;

        if (currentIndex && field) {
          draft[parseInt(currentIndex, 10)][field] = e.currentTarget.value;
        }
      })
    );
  }

  function addNewLineFnList() {
    setFnList(
      produce(fnList, draft => {
        draft.push(initialFunctionParamater);
      })
    );
  }

  function deleteLineFnList(index: number) {
    setFnList(
      produce(fnList, draft => {
        if (fnList.length === 1) {
          draft.splice(index, 1, initialFunctionParamater);
        } else {
          draft.splice(index, 1);
        }
      })
    );
  }

  function onChange(e: SyntheticEvent<HTMLSelectElement>) {
    const newState = produce(groupBy, draft => {
      draft.push(e.currentTarget.value);
    });

    setBreakRow(newState);
  }

  function onChangeExisting(e: SyntheticEvent<HTMLSelectElement>) {
    const oldValue = e.currentTarget.name;
    const value = e.currentTarget.value;

    const index = groupBy.indexOf(oldValue);
    if (index !== -1) {
      if (value === "") {
        onDelete(oldValue);
      } else {
        const newState = produce(groupBy, draft => {
          draft[index] = value;
        });
        setBreakRow(newState);
      }
    }
  }

  function onDelete(col: string) {
    const newState = produce(groupBy, draft => {
      const index = draft.indexOf(col);
      if (index !== -1) {
        draft.splice(index, 1);
      }
    });
    setBreakRow(newState);
  }

  function onValidate() {
    let finalFnList = fnList.filter(fn => fn.column !== "");
    props.onValidate(groupBy, finalFnList);
  }

  function clear() {
    setBreakRow([]);
    setFnList([initialFunctionParamater]);
  }

  const optionsExisting = props.columns
    .filter(column => column.typeCompo !== "OEL")
    .map(column => {
      return (
        <option key={column.column} value={column.column}>
          {column.label}
        </option>
      );
    });

  const optionsNew = props.columns
    .filter(column => column.typeCompo !== "OEL")
    .filter(column => groupBy.indexOf(column.column) === -1)
    .map(column => {
      return (
        <option key={column.column} value={column.column}>
          {column.label}
        </option>
      );
    });

  return (
    <Modal
      onClose={props.onClose}
      onValidate={onValidate}
      title={
        <>
          <Trans i18nKey="commun_ajouter_un_regroupement">Ajouter un regroupement</Trans>
          <span className="is-pulled-right">
            <button className="button is-text is-small" onClick={clear}>
              <Trans i18nKey="commun_vider" />
            </button>
          </span>
        </>
      }
      hideCancel
    >
      {groupBy.map(col => {
        return (
          <div key={col}>
            <div className="select mb-5">
              <select name={col} value={col} onChange={onChangeExisting}>
                <option value="" />
                {optionsExisting}
              </select>
            </div>
          </div>
        );
      })}
      <hr style={{ backgroundColor: "hsl(0, 0%, 94%)", width: "40%", margin: "1.25rem auto" }} />
      <div className="select">
        <select value="" onChange={onChange}>
          <option value="" />
          {optionsNew}
        </select>
      </div>

      <hr style={{ backgroundColor: "hsl(0, 0%, 90%)", width: "40%", margin: "1.25rem auto" }} />

      <GroupByFunctionList
        columns={props.columns}
        functionList={fnList}
        onChange={onChangeFnList}
        addNewLine={addNewLineFnList}
        deleteLine={deleteLineFnList}
      />
    </Modal>
  );
};

export default GroupByDialog;
