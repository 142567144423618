import { call, put, takeLatest } from "redux-saga/effects";
import { AxiosResponse } from "axios";
import { t } from "utils/i18n";

import Action from "reducers/Action";

/***************** TYPES *********************/

import { Pojo } from "types/Galaxy";
import { Message } from "types/Message";
import { ProcessusDefinition } from "types/Processus";

/*************** CONSTANT ******************/
import { SET_PROCESSUS_CONTEXT, RESET_PACKAGE } from "constant/processus";

import { SHOW_CONTEXT_MENU, FETCH_CONTEXT_MENU_SUCCESS } from "constant/contextMenu";

/*************** ACTIONS **************/
import { addMessage } from "actions/messages";

/********************* API *****************/
import { resetPackage, getProcessusDefinitionColumn, getProcessusDefinition } from "api/processus";

import { getFKTableName } from "api/contextMenu";

import { track } from "tracking";

/**
 * Permet de récupérer la définition d'un menu contextuel (clic droit)
 * @param action action redux
 */
function* showMenu(
  action: Action<{
    xpos: number;
    ypos: number;
    sjmoCode: string;
    columnName: string;
    contextTableName: string;
    contextId: number;
    value: number;
    urlCplt?: string;
    genericEntity: Pojo | null;
    forceNewTab: boolean;
  }>
) {
  track("contextmenu::open", {
    module: action.payload.sjmoCode,
    table: action.payload.contextTableName,
    column: action.payload.columnName
  });
  try {
    let contextTableName = action.payload.contextTableName;
    let contextId = action.payload.contextId;
    let response: AxiosResponse<ProcessusDefinition[]>;

    if (action.payload.columnName === null) {
      response = yield call(
        getProcessusDefinition,
        action.payload.sjmoCode,
        action.payload.contextTableName
      );
    } else {
      response = yield call(
        getProcessusDefinitionColumn,
        action.payload.sjmoCode,
        action.payload.contextTableName,
        action.payload.columnName
      );
      // si la column à l'origine du clic droit est une FK alors
      // la contextTableName et le contextId devienne ceux de la FK
      const responseFK: AxiosResponse<string> = yield call(
        getFKTableName,
        action.payload.sjmoCode,
        action.payload.contextTableName,
        action.payload.columnName
      );
      // si la colonne est une FK alors la table de contextualisation est la table de la FK
      if (responseFK.data && responseFK.data !== null) {
        contextTableName = responseFK.data;
        contextId = action.payload.value;
      }
    }

    yield put({
      type: FETCH_CONTEXT_MENU_SUCCESS,
      payload: {
        xpos: action.payload.xpos,
        ypos: action.payload.ypos,
        sjmoCode: action.payload.sjmoCode,
        columnName: action.payload.columnName,
        definition: response.data,
        origineTableName: action.payload.contextTableName,
        origineTableId: action.payload.contextId,
        genericEntity: action.payload.genericEntity,
        forceNewTab: action.payload.forceNewTab
      }
    });

    yield put({
      type: SET_PROCESSUS_CONTEXT,
      payload: {
        contextTableName: contextTableName,
        sjipId: -1, // on ne connait pas encore le processus choisit
        contextIds: contextId ? [contextId] : null,
        sjmoCode: action.payload.sjmoCode,
        urlCplt: action.payload.urlCplt ? action.payload.urlCplt : ""
      }
    });
  } catch {
    console.error("erreur lors du calcul du menu contextuel :saga.showMenu' ");
    const message: Message = {
      code: "",
      message: t("commun_erreur_processus"),
      type: "DANGER",
      target: "GLOBAL"
    };
    yield put(addMessage(message));
  }
}

/**
 * Permet de lancer un reset des packages en base
 * @param action action redux
 */
function* resetDbPackage(action: Action<{}>) {
  try {
    yield call(resetPackage);
    const message: Message = {
      code: "",
      message: t("commun_packages_reset"),
      type: "SUCCESS",
      target: "GLOBAL"
    };
    yield put(addMessage(message));
  } catch {
    console.error("erreur lors du lancement du reset des packages ");
    const message: Message = {
      code: "",
      message: t("commun_packages_reset_error"),
      type: "DANGER",
      target: "GLOBAL"
    };
    yield put(addMessage(message));
  }
}

// export d'une liste qui est utilisée dans l'index des saga
const sagas: any[] = [
  takeLatest(SHOW_CONTEXT_MENU, showMenu),
  takeLatest(RESET_PACKAGE, resetDbPackage)
];

export default sagas;
