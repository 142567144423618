import { Notification } from "types/Notification";
import {
  FETCH_LIST_NOTIFICATION_SUCCESS,
  PUSH_NOTIFICATION,
  STARTUP_NOTIFICATION
} from "constant/notification.constant";

export function startupNotification() {
  return {
    type: STARTUP_NOTIFICATION
  };
}

export function fetchListNotificationSuccess(notifications: Notification[]) {
  return {
    type: FETCH_LIST_NOTIFICATION_SUCCESS,
    payload: notifications
  };
}

export function pushNotification(notification: Notification) {
  return {
    type: PUSH_NOTIFICATION,
    payload: notification
  };
}
