// dashboard
export const LAYOUT_CHANGE = "LAYOUT_CHANGE";
export const FOCUS_CHANGE = "FOCUS_CHANGE";
export const CLOSE_PANEL = "CLOSE_PANEL";
export const CLOSE_MULTIPLE_PANEL = "CLOSE_MULTIPLE_PANEL";
export const ADD_PANEL_TO_FOCUS = "ADD_PANEL_TO_FOCUS";
export const ADD_MULTIPLE_PANEL_TO_FOCUS = "ADD_MULTIPLE_PANEL_TO_FOCUS";

export const FETCH_FOCUS = "FETCH_FOCUS";
export const FETCH_FOCUS_SUCCESS = "FETCH_FOCUS_SUCCESS";

export const FETCH_FOCUS_PANELS = "FETCH_FOCUS_PANELS";
export const FETCH_FOCUS_PANELS_SUCCESS = "FETCH_FOCUS_PANELS_SUCCESS";

export const CHANGE_FOCUS_AND_PANELS = "CHANGE_FOCUS_AND_PANELS";

export const FETCH_FOCUS_PANELS_AND_CHANGE_FOCUS = "FETCH_FOCUS_PANELS_AND_CHANGE_FOCUS";
export const CHANGE_FOCUS_FROM_URL = "CHANGE_FOCUS_FROM_URL";

export const SAVE_FOCUS_PERSO = "SAVE_FOCUS_PERSO";

export const RAZ_FOCUS_PERSO = "RAZ_FOCUS_PERSO";
export const RAZ_FOCUS_PERSO_SUCCESS = "RAZ_FOCUS_PERSO_SUCCESS";
export const ADD_ONE_FOCUS_AND_SELECT = "ADD_ONE_FOCUS_ANS_SELECT";
export const GALAXY_FOCUS_DELETED = "GALAXY_FOCUS_DELETED";
