import { PluginObject } from "./pluginTypes";
import { RichUtils, getDefaultKeyBinding, KeyBindingUtil } from "draft-js";

export default function draftDefaultPlugin(): PluginObject {
  return {
    handleKeyCommand(command, editorState, { setEditorState }) {
      const newState = RichUtils.handleKeyCommand(editorState, command);
      if (newState) {
        setEditorState(newState);
        return "handled";
      }

      return "not-handled";
    },
    keyBindingFn(e) {
      return getDefaultKeyBinding(e);
    }
  };
}
