/**
 * Permet de récupérer le sigle associé à un code de devise (dvseCode ou deviseId)
 *
 * @param {(string | null | undefined)} devise code / id de la devise
 * @returns retourne soit le sigle, soit le code devise, soit vide si jamais null ou undefined
 */
export function getSigle(devise: string | null | undefined) {
  if (devise === null || devise === undefined) {
    return "";
  }

  switch (devise) {
    case "EUR":
      return "€";
    case "USD":
      return "$";
    default:
      return devise;
  }
}
