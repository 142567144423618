import { SyntheticEvent, MouseEvent, CSSProperties } from "react";

import classNames from "classnames";
import { IntentType } from "@axin-org/comet";

// Liste exhaustive des classes relativent aux couleurs utiliser dans les composant standard de l'application
const colors = {
  black: "is-black",
  white: "is-white",
  light: "is-light",
  dark: "is-dark",
  primary: "is-primary",
  link: "is-link",
  info: "is-info",
  success: "is-success",
  warning: "is-warning",
  danger: "is-danger"
};

// Liste exhaustive des classes relativent aux tailles dans les composants standard de l'aplication
const sizes = {
  small: "is-small",
  medium: "is-medium",
  large: "is-large"
};

export interface ColorProps {
  color?: string;
}

export interface SizeProps {
  size?: string;
}

/**
 * Type générique pour les événements HMTL avec React
 */
export type GenericEvent = (<T>(event: SyntheticEvent<T>) => void) | undefined;
export type GenericKeyboardEvent = (<T>(event: SyntheticEvent<T>) => void) | undefined;
export type ContextMenuEvent = (<T>(event: MouseEvent<T>) => void) | undefined;

interface FormComponentsProps {
  id?: string;
  name?: string;
  value: any;
  type?: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  style?: CSSProperties;
  wviState?: string;
  placeholder?: string;
  // Tooltip
  tooltip?: string;
  mandatory?: boolean;

  onChange?<T = any>(e: React.SyntheticEvent<T>): void;
  onBlur?<T = HTMLElement>(e: React.FocusEvent<T>): void;
  onKeyDown?<T = HTMLElement>(e: React.KeyboardEvent<T>): void;
  onContextMenu?<T = HTMLElement>(e: React.MouseEvent<T>): void;
}

/**
 * Ensemble des propriétés commune à tous les composants de base
 * inputtext,
 * calendar,
 * checkbox
 * editor,
 * search,
 * select
 */
export type ComposantAllProps = SizeProps & FormComponentsProps;

export interface ComposantAndLabelProps extends ComposantAllProps {
  label: string;
  labelSize?: number;
  wviMessage?: string;
  required?: boolean;
  styleLabel?: CSSProperties;
}

export interface ParentComponentProps {
  tableName: string;
  ctrlKey: string;
  entityId?: string;
}

export function getIntent(wviState: string | undefined): IntentType | undefined {
  if (!wviState) return undefined;

  switch (wviState) {
    case "success":
      return "success";
    case "warning":
      return "warning";
    case "danger":
      return "danger";

    default:
      return undefined;
  }
}

export function getColorsClasses(props: string | null = ""): string | undefined {
  if (!props) {
    return undefined;
  }

  return colors[props.toLowerCase()];
}

export function getSizeClasses(props: string | null = ""): string | undefined {
  if (!props) {
    return undefined;
  }
  return sizes[props];
}

/**
 * Permet d'exclure les propriétés à ne pas passer au composant html standard
 *
 * TODO : trouver un meilleur moyen d'exclure les dites propriétés
 * @param props props
 */
export function excludeFromProps(props: any) {
  const newProps = { ...props };

  delete newProps.primary;
  delete newProps.info;
  delete newProps.link;
  delete newProps.success;
  delete newProps.warning;
  delete newProps.danger;
  delete newProps.small;
  delete newProps.medium;
  delete newProps.large;

  return newProps;
}
