import { AxiosPromise } from "axios";

import { apiAdn, getUIContext } from "./common";
import { FocusState } from "types/Focus";
import { ComponentGroupState } from "types/ComponentGroup";

// Fonction qui récupère la liste des focus d'un mini expert de galaxie
export function getMiniExpertFocus(
  sjmoCode: string,
  tableName: string
): AxiosPromise<FocusState[]> {
  const urlParam = getUIContext({ sjmoCode });
  urlParam.append("tableName", tableName);

  return apiAdn.get(`/miniexpert/focus?${urlParam}`);
}

/**
 *  Fonction qui récupère la liste des focus d'un mini expert de galaxie
 * dans le cadre de l'administration
 */
export function getAdminMiniExpertFocus(sjtaId: number): AxiosPromise<FocusState[]> {
  const urlParam = getUIContext();
  urlParam.append("sjtaId", sjtaId.toString());

  return apiAdn.get(`/miniexpert/admin/focus?${urlParam}`);
}

// Fonction qui récupère les groupes de composants d'un focus de mini-expert
export function getMiniExpertGroups(
  sjmoCode: string,
  id: string
): AxiosPromise<ComponentGroupState[]> {
  const urlParam = getUIContext({ sjmoCode });
  return apiAdn.get(`/miniexpert/${id}/groups?${urlParam}`);
}
