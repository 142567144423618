import { AppRoute } from "AppRoute";
import { AccessDenied } from "composants/Loader";
import { APP_FONT_SIZE } from "composants/userSettings/SelectSize";
import OctalContextMenu from "containers/contextMenu/OctalContextMenu";
import { ProcessusManager } from "features/processus/processusManager";
import { useEffect, useMemo, lazy } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Link,
  Outlet,
  Route,
  RouterProvider
} from "react-router-dom";

import { ReducerState } from "reducers";

import { AppRouteAdmin as AppRouteAdminClient } from "admin/client/AppRouteAdmin";
import { AppRouteAdmin as AppRouteAdminIntegrateur } from "admin/integrateur/AppRouteAdmin";
import App from "layout/App";
import { PUBLIC_URL } from "customGlobal";
import { ToastProvider } from "composants/toaster/toaster";

const AdminAppClient = lazy(() => import("admin/client"));
const AdminAppIntegrateur = lazy(() => import("admin/integrateur"));

function AccessDeniedSuperUser() {
  const [t] = useTranslation();

  return (
    <div style={{ textAlign: "center", marginTop: "1em" }}>
      <div className="title is-3">{t("commun_accessRefuse")}</div>
      <AccessDenied />
      <div className="subtitle is-2">
        <Link to="/" className="underline-link">
          {t("commun_retourAccueil")}
        </Link>
      </div>
    </div>
  );
}

const basename = PUBLIC_URL() != "/" ? PUBLIC_URL().replace(/\/+$/, "") : PUBLIC_URL();

function createRouter(galaxies: GalaxiesType, adminLevel: number) {
  const appRoutes = AppRoute({ galaxies });
  const appRouteAdminClient = AppRouteAdminClient({});
  const appRouteAdminIntegrateur = AppRouteAdminIntegrateur({});

  return createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<IndexRoutes />}>
        <Route path="admin">
          {adminLevel >= 1 && (
            <Route path="client" element={<AdminAppClient />}>
              {appRouteAdminClient}
            </Route>
          )}
          {adminLevel === 3 && (
            <Route path="integrateur" element={<AdminAppIntegrateur />}>
              {appRouteAdminIntegrateur}
            </Route>
          )}
          <Route element={<AccessDeniedSuperUser />} />
        </Route>
        <Route element={<App />}>{appRoutes}</Route>
      </Route>
    ),
    {
      basename: basename
    }
  );
}

function IndexRoutes() {
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--axin-size", `${localStorage.getItem(APP_FONT_SIZE)}px`);
  }, []);

  return (
    <ToastProvider>
      <ProcessusManager>
        <Outlet />
        <OctalContextMenu />
      </ProcessusManager>
    </ToastProvider>
  );
}

const selectGetAdminLevel = (state: ReducerState) => state.userSettings.adminLevel;
const selectGetGalaxies = (state: ReducerState) => state.app.galaxies;
type GalaxiesType = ReturnType<typeof selectGetGalaxies>;

export default function RouterRoot() {
  const galaxies = useSelector(selectGetGalaxies);
  const adminLevel = useSelector(selectGetAdminLevel);
  const router = useMemo(() => createRouter(galaxies, adminLevel), [adminLevel, galaxies]);

  return <RouterProvider router={router} />;
}
