import React, { FC, useState, useCallback, useEffect, useRef, useMemo } from "react";
import CustomablePicklist, {
  CHOSEN,
  LaneName,
  AVAILABLE,
  getAvailableNotChosen
} from "./CustomablePickList";
import { arrayMoveImmutable } from "array-move";
import { Pojo } from "types/Galaxy";
import { Fa } from "composants/Icon";
import { AutoSizer } from "react-virtualized";

import "./picklist.css";

export interface SelectedProps {
  AVAILABLE: string[];
  CHOSEN: string[];
}

interface PickListProps {
  // Liste de tout elementes disponibles y comprt ceux déjà selectionné
  available: Pojo[];
  // Liste des éléments déjà selectionnés
  chosen: Pojo[];
  // Liste des fields à affiché et utiliser pour la recherche, si undefined on affiche uniquement le label
  displayFields?: string[];
  height?: number;
  invertPicklist?: boolean;
  onValueChange(props: { AVAILABLE: Pojo[]; CHOSEN: Pojo[] }): void;
  renderAvailableCompo?(pojo: Pojo, selected: SelectedProps): JSX.Element;
  renderChosenCompo?(pojo: Pojo, selected: SelectedProps): JSX.Element;
}

/**
 * Composant "fantome" utilisé pour visualisé l'ajout d'une card dans une lane
 *
 * @param {Pojo} pojo
 * @returns {JSX.Element}
 */
export function fakeCompo(pojo: Pojo): JSX.Element {
  return (
    <div className="card" key={pojo.id}>
      <div className="card-content">
        <div className="content">
          <Fa icon="plus" />
        </div>
      </div>
    </div>
  );
}

const Picklist: FC<PickListProps> = props => {
  const pickListRef = useRef<HTMLDivElement | null>(null);
  const [selected, setSelected] = useState<SelectedProps>({
    AVAILABLE: [],
    CHOSEN: []
  });

  const chosenItems = useMemo((): Pojo[] => [...props.chosen], [props.chosen]);
  const availableItems = useMemo((): Pojo[] => [...props.available], [props.available]);

  /**
   * Création du composant de rendu à partir d'un pojo.
   * pojo.__fake signifie qu'il s'agit du composant du faux composant pour l'ajout
   * selected est une liste d'id de pojo selectionné pour une opération ultérieure
   *
   * CONSEIL : Utiliser cette base pour faire des compos custom.
   *
   * @memberof Picklist
   */
  const renderDefaultCompo = useCallback(
    (pojo: Pojo): JSX.Element => {
      // Chaque pojo n'est visible (et donc selectionnable) que dans une seule liste
      const isSelected =
        selected[AVAILABLE].indexOf(pojo.id) > -1 || selected[CHOSEN].indexOf(pojo.id) > -1;

      if (pojo.__fake !== true) {
        let title = "";
        let infosCompl = "";

        if (props.displayFields && props.displayFields.length > 1) {
          infosCompl = "(";
          const fields = props.displayFields;
          fields.forEach(field => {
            if (fields.indexOf(field) === 0) {
              title = pojo[field];
            } else {
              infosCompl += pojo[field] + ", ";
            }
          });
          infosCompl = infosCompl.substring(0, infosCompl.lastIndexOf(",")) + ")";
        } else {
          title = props.displayFields ? pojo[props.displayFields[0]] : pojo.label;
        }

        return (
          <div
            className="picklistCard"
            key={pojo.id}
            style={{ backgroundColor: isSelected ? "#209cee59" : undefined }}
          >
            <div className="card-content">{`${title} ${infosCompl}`}</div>
          </div>
        );
      } else {
        return fakeCompo(pojo);
      }
    },
    [props.displayFields, selected]
  );

  const renderAvailableCompo = useCallback(
    (pojo: Pojo): JSX.Element => {
      if (props.renderAvailableCompo) {
        return props.renderAvailableCompo(pojo, selected);
      } else {
        return renderDefaultCompo(pojo);
      }
    },
    [props, renderDefaultCompo, selected]
  );

  const renderChosenCompo = (pojo: Pojo): JSX.Element => {
    if (props.renderChosenCompo) {
      return props.renderChosenCompo(pojo, selected);
    } else {
      return renderDefaultCompo(pojo);
    }
  };

  /**
   * Gère la liste des éléments sélectionnés.
   * On ne peut pas avoir des éléments sélectionnés dans les deux listes en même temps.
   * Si replace === true alors il faut éliminé les éléments précédemment sélectionné
   */
  const addSelectedPojo = useCallback(
    (laneId: string, pojoId: string, replace: boolean) => {
      let currentSelectedLane = selected[laneId];

      if (replace) {
        if (currentSelectedLane.indexOf(pojoId) > -1 && currentSelectedLane.length === 1) {
          currentSelectedLane = [];
        } else {
          currentSelectedLane = [pojoId];
        }
      } else {
        if (currentSelectedLane.indexOf(pojoId) > -1) {
          currentSelectedLane.splice(currentSelectedLane.indexOf(pojoId), 1);
        } else {
          currentSelectedLane.push(pojoId);
        }
      }

      if (laneId === CHOSEN) {
        setSelected({ AVAILABLE: [], CHOSEN: [...currentSelectedLane] });
      } else {
        setSelected({ CHOSEN: [], AVAILABLE: [...currentSelectedLane] });
      }
    },
    [selected]
  );

  const unselectAll = useCallback(() => {
    setSelected({ AVAILABLE: [], CHOSEN: [] });
  }, []);

  useEffect(() => {
    function onUnselectListener(e: any) {
      const el = e.target && e.target.closest(".picklist-container-ref");
      if (!el || el !== pickListRef.current) {
        unselectAll();
      }
    }

    document.addEventListener("click", onUnselectListener);
    return () => {
      document.removeEventListener("click", onUnselectListener);
    };
  }, [unselectAll]);

  const chooseSelected = useCallback(() => {
    let newSelected = availableItems
      .filter(pojo => selected.AVAILABLE.indexOf(pojo.id) > -1)
      .map(pojo => {
        return { ...pojo };
      });
    props.onValueChange({
      AVAILABLE: availableItems,
      CHOSEN: chosenItems.concat(newSelected)
    });
    setSelected({ CHOSEN: selected.AVAILABLE, AVAILABLE: [] });
  }, [availableItems, chosenItems, props, selected.AVAILABLE]);

  const unChooseSelected = useCallback(() => {
    let newAvailable = availableItems;
    const selectedIds = selected.CHOSEN;
    let newSelected = chosenItems
      .filter(pojo => selected.CHOSEN.indexOf(pojo.id) > -1)
      .map(pojo => {
        return { ...pojo };
      });
    selectedIds.forEach(id => {
      const index = newAvailable.findIndex(pojo => pojo.id === id);
      newAvailable = arrayMoveImmutable(newAvailable, index, newAvailable.length - 1) as Pojo[];
    });

    const newChosen = chosenItems.filter(pojo => selectedIds.indexOf(pojo.id) === -1);

    let addElementAvailable = availableItems.concat(newSelected);
    let ids = addElementAvailable.map(o => o.id);
    const filteredDeduplicatedAvailable = addElementAvailable.filter(
      ({ id }, index) => !ids.includes(id, index + 1)
    );

    props.onValueChange({
      AVAILABLE: filteredDeduplicatedAvailable,
      CHOSEN: newChosen
    });

    setSelected({ AVAILABLE: selected.CHOSEN, CHOSEN: [] });
  }, [availableItems, chosenItems, props, selected.CHOSEN]);

  const chooseAll = useCallback(() => {
    let toAdd = availableItems
      .filter(
        availablePojo =>
          chosenItems.findIndex(chosenPojo => chosenPojo.id === availablePojo.id) === -1
      )
      .map(pojo => {
        return { ...pojo };
      });
    const selectedIds = toAdd.map(pojo => pojo.id);

    props.onValueChange({
      AVAILABLE: availableItems,
      CHOSEN: chosenItems.concat(toAdd)
    });
    setSelected({ AVAILABLE: [], CHOSEN: selectedIds });
  }, [availableItems, chosenItems, props]);

  const unChooseAll = useCallback(() => {
    let newAvailable = availableItems;
    const selectedIds = chosenItems.map(pojo => pojo.id);

    selectedIds.forEach(id => {
      const index = newAvailable.findIndex(pojo => pojo.id === id);
      newAvailable = arrayMoveImmutable(newAvailable, index, newAvailable.length - 1) as Pojo[];
    });

    props.onValueChange({
      AVAILABLE: newAvailable,
      CHOSEN: []
    });
    setSelected({ AVAILABLE: selectedIds, CHOSEN: [] });
  }, [availableItems, chosenItems, props]);

  const move = useCallback(
    (toDo: (pojos: Pojo[], selected: string[]) => Pojo[]) => {
      let newChosen = chosenItems;
      let newAvailable = availableItems;

      const selectedChosen = selected.CHOSEN;
      const selectedAvailable = selected.AVAILABLE;

      if (selectedChosen.length > 0) {
        newChosen = toDo(newChosen, selectedChosen);
      }

      if (selectedAvailable.length > 0) {
        newAvailable = toDo(newAvailable, selectedAvailable);
      }

      props.onValueChange({ AVAILABLE: newAvailable, CHOSEN: newChosen });
    },
    [availableItems, chosenItems, props, selected.AVAILABLE, selected.CHOSEN]
  );

  const putOnTop = useCallback(() => {
    move((pojos, selected) => {
      selected.forEach((id, index) => {
        const oldIndex = pojos.findIndex(pojo => pojo.id === id);
        pojos = arrayMoveImmutable(pojos, oldIndex, index);
      });
      return pojos;
    });
  }, [move]);

  const putToBottom = useCallback(() => {
    move((pojos, selected) => {
      selected.forEach(id => {
        const oldIndex = pojos.findIndex(pojo => pojo.id === id);
        pojos = arrayMoveImmutable(pojos, oldIndex, pojos.length - 1);
      });
      return pojos;
    });
  }, [move]);

  const up = useCallback(() => {
    move((pojos, selected) => {
      const indexToMove: number[] = selected.map(id => pojos.findIndex(pojo => pojo.id === id));
      indexToMove
        .sort((a, b) => a - b)
        .forEach((oldIndex, i) => {
          if (oldIndex > i) {
            pojos = arrayMoveImmutable(pojos, oldIndex, oldIndex - 1);
          }
        });
      return pojos;
    });
  }, [move]);

  const down = useCallback(() => {
    move((pojos, selected) => {
      const indexToMove: number[] = selected.map(id => pojos.findIndex(pojo => pojo.id === id));
      indexToMove
        .sort((a, b) => b - a)
        .forEach((oldIndex, i) => {
          if (oldIndex < pojos.length - (i + 1)) {
            pojos = arrayMoveImmutable(pojos, oldIndex, oldIndex + 1);
          }
        });
      return pojos;
    });
  }, [move]);

  /**
   * Fonction appelé dès qu'une card est drag.
   * Si un élément est déplacé au sein d'une même lane on exécute un réorder
   * Si un élément est déplacé dans une autre lane, on place un composant fantome à l'emplacement du curseur
   *
   * @memberof Picklist
   */
  const moveCard = useCallback(
    (draggedId: string, overId: string, laneId: LaneName) => {
      let laneState =
        laneId === CHOSEN ? chosenItems : getAvailableNotChosen(availableItems, chosenItems);
      let otherLane =
        laneId === AVAILABLE ? chosenItems : getAvailableNotChosen(availableItems, chosenItems);

      const dragIndex = laneState.findIndex(pojo => pojo.id === draggedId);
      const overIndex = laneState.findIndex(pojo => pojo.id === overId);
      if (overIndex > -1) {
        if (dragIndex > -1) {
          // Cas d'un reorder au sein d'une même liste
          laneState = arrayMoveImmutable(laneState, dragIndex, overIndex) as Pojo[];

          // Gestion du cas d'un changelement de lane annulé
          const fakeIndex = otherLane.findIndex((pojo: Pojo) => pojo.__fake === true);
          if (fakeIndex > -1) {
            otherLane.splice(fakeIndex, 1);
          }
        } else {
          // Cas d'un changement de lane pas encore posé
          const fakeIndex = laneState.findIndex((pojo: Pojo) => pojo.__fake === true);
          if (fakeIndex > -1) {
            laneState.splice(fakeIndex, 1);
          }
          laneState.splice(overIndex, 0, { id: "-1", __fake: true } as any);
        }

        if (laneId === CHOSEN) {
          props.onValueChange({
            AVAILABLE: otherLane,
            CHOSEN: laneState
          });
        } else {
          props.onValueChange({
            AVAILABLE: laneState,
            CHOSEN: otherLane
          });
        }
      }
    },
    [availableItems, chosenItems, props]
  );

  const changeLane = useCallback(
    (draggedId: string, laneId: LaneName) => {
      let newChosen: Pojo[] = chosenItems;
      let newAvailable: Pojo[] = availableItems;

      const fakeChosenIndex = chosenItems.findIndex((pojo: Pojo) => pojo.__fake === true);
      const fakeAvailableIndex = availableItems.findIndex((pojo: Pojo) => pojo.__fake === true);

      if (laneId === CHOSEN) {
        // un pojo est mit danns la liste des choisis
        // Le pojo est ajouté dans le state des choisis et on envoi la nouvelle liste au parent
        const dragged: Pojo | undefined = availableItems.find(
          (pojo: Pojo) => pojo.id === draggedId
        ) as any;

        if (dragged) {
          if (fakeChosenIndex === -1) {
            newChosen.push({ ...dragged });
          } else {
            newChosen.splice(fakeChosenIndex, 1, { ...dragged });
          }

          // Une fois ajouté dans chosen, on peut le supprimer de available
          // pour eviter un doublon (available et chosen)
          const draggedIndex: number = availableItems.findIndex(
            (pojo: Pojo) => pojo.id === draggedId
          );
          if (draggedIndex > -1) {
            newAvailable.splice(draggedIndex, 1);
          }

          props.onValueChange({ AVAILABLE: newAvailable, CHOSEN: newChosen });
        }
      } else {
        // Si un pojo est déplacé de la liste des choisi vers les dispo
        const dragged: Pojo | undefined = chosenItems.find(
          (pojo: Pojo) => pojo.id === draggedId
        ) as any;

        if (dragged) {
          if (fakeAvailableIndex === -1) {
            newAvailable.push({ ...dragged });
          } else {
            newAvailable.splice(fakeAvailableIndex, 1, { ...dragged });
          }

          // On enlève le pojo de la liste des choisis
          // Par conséquent, le disponible correspondant ne sera plus filtré
          const draggedIndex: number = chosenItems.findIndex((pojo: Pojo) => pojo.id === draggedId);
          if (draggedIndex > -1) {
            newChosen.splice(draggedIndex, 1);
          }
          props.onValueChange({ AVAILABLE: newAvailable, CHOSEN: newChosen });
        }
      }
    },
    [availableItems, chosenItems, props]
  );

  const dropOut = useCallback(() => {
    let newAvailable: Pojo[] = availableItems;
    let newChosen: Pojo[] = chosenItems;

    const fakeAvailableIndex = availableItems.findIndex((pojo: Pojo) => pojo.__fake === true);
    const fakeChosenIndex = chosenItems.findIndex((pojo: Pojo) => pojo.__fake === true);

    if (fakeAvailableIndex > -1) {
      newAvailable.splice(fakeAvailableIndex, 1);
    }
    if (fakeChosenIndex > -1) {
      newChosen.splice(fakeChosenIndex, 1);
    }

    props.onValueChange({ AVAILABLE: newAvailable, CHOSEN: newChosen });
  }, [availableItems, chosenItems, props]);

  return (
    <>
      <AutoSizer disableWidth>
        {({ width, height }) => (
          <div ref={pickListRef} className="picklist-container-ref">
            <CustomablePicklist
              available={availableItems}
              availableSearchFields={props.displayFields}
              renderAvailableCompo={renderAvailableCompo}
              chosen={chosenItems as any[]}
              chosenSearchField={props.displayFields}
              renderChosenCompo={renderChosenCompo}
              height={props.height ? props.height : height}
              moveCard={moveCard}
              changeLane={changeLane}
              addSelectedPojo={addSelectedPojo}
              chooseSelected={chooseSelected}
              unChooseSelected={unChooseSelected}
              chooseAll={chooseAll}
              unChooseAll={unChooseAll}
              putOnTop={putOnTop}
              putToBottom={putToBottom}
              up={up}
              down={down}
              dropOut={dropOut}
              invertPicklist={props.invertPicklist}
            />
          </div>
        )}
      </AutoSizer>
    </>
  );
};

export default Picklist;
