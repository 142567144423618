export const START_CREATOR_LOADER = "START_CREATOR_LOADER";
export const STOP_CREATOR_LOADER = "STOP_CREATOR_LOADER";

export const START_GALAXY_LOADER = "START_GALAXY_LOADER";
export const STOP_GALAXY_LOADER = "STOP_GALAXY_LOADER";

export const START_EXPERT_LOADER = "START_EXPERT_LOADER";
export const STOP_EXPERT_LOADER = "STOP_EXPERT_LOADER";

export const START_MINI_EXPERT_LOADER = "START_MINI_EXPERT_LOADER";
export const STOP_MINI_EXPERT_LOADER = "STOP_MINI_EXPERT_LOADER";
