import React, { Component, FC, useState, useEffect, SyntheticEvent, useContext } from "react";
import { t } from "utils/i18n";

/**************** TYPES ***********************/
import { Pojo } from "types/Galaxy";

/**************** COMPOSANTS ******************/
import Modal from "composants/Modal/Modal";
import { Row, Col } from "composants/Layout";

/**************** ACTIONS *********************/
import { createOneEntity, deleteOneEntity } from "actions/actionEntities";
import { getAllRoles, getAllUsers } from "actions/adminCreator";
import { getAssociationsFocus } from "actions/adminGalaxy.action";
import { addMessage, HandleApiMessageAction } from "actions/messages";

/***************** API *********************/
import Card from "composants/card/Card";
import PojoFilterableList from "composants/FilterableList/PojoFilterableList";
import { Association } from "types/admin";
import Checkbox from "composants/checkbox/Checkbox";
import { ReducerState } from "reducers";
import { connect } from "react-redux";
import { ADMIN_GALAXIE } from "./AdminGalaxy";
import {
  selectGlobalAssociation,
  selectRoleAssociation,
  selectUserAssociation
} from "selectors/focusList.selector";
import { convertValue, mapToUnorderedList } from "utils/entities.utils";
import { preRecord } from "api";
import { Message } from "types/Message";
import { AffectationType } from "enum";
import { get } from "lodash-es";

interface ModalFocusAssociationProps {
  focusId: string;
  sjmoCode: string;
  onClose(): void;
}

type ContextValue =
  | {
      syjModuleId: string;
      focusId: string;
      create: (sjmoCode: string, tableName: string, pojo: any) => void;
      delete: (sjmoCode: string, tableName: string, id: string | number) => void;
      addMessage(message: Message, autoclose?: boolean): HandleApiMessageAction;
    }
  | undefined;
const FocusAssociationContext = React.createContext<ContextValue>(undefined);

const AssCard: FC<{ ass: Association }> = ({ ass }) => {
  const context = useContext(FocusAssociationContext);

  function onAssociationChanged(ass: Association, selected: any) {
    if (!context) return;

    let entity: any = { syjCreatorFocusId: context.focusId };
    let tableName = "";
    switch (ass.type) {
      case AffectationType.GLOBAL:
        tableName = "syjFocusGlobal";
        entity.sjfgPrivilegie = false;
        break;
      case AffectationType.ROLE:
        tableName = "syjFocusRole";
        entity.sjfrPrivilegie = false;
        entity.sysMenuGroupeId = ass.contextId;
        break;
      case AffectationType.USER:
        tableName = "syjFocusUser";
        entity.sjfuPrivilegie = false;
        entity.personnelId = ass.contextId;
        break;
      default:
        break;
    }

    entity.syjModuleId = context.syjModuleId;
    entity.syjFocusId = context.focusId;

    if (selected) {
      preRecord({ sjmoCode: ADMIN_GALAXIE, tableName, context: entity }).then(response => {
        context.create(ADMIN_GALAXIE, tableName, response.data);
      });
    } else {
      context.delete(ADMIN_GALAXIE, tableName, ass.id);
    }
  }

  function onPrivilegeChanged(ass: Association, selected: any) {
    if (!context) return;

    if (ass.creatorFocusId && ass.id) {
      let entity: any = ass;
      let tableName = "";
      switch (ass.type) {
        case AffectationType.GLOBAL:
          tableName = "syjFocusGlobal";
          entity.sjfgPrivilegie = selected;
          break;
        case AffectationType.ROLE:
          tableName = "syjFocusRole";
          entity.sjfrPrivilegie = selected;
          break;
        case AffectationType.USER:
          tableName = "syjFocusUser";
          entity.sjfuPrivilegie = selected;
          break;
        default:
          break;
      }

      context.create(ADMIN_GALAXIE, tableName, entity);
    } else {
      context.addMessage({
        type: "DANGER",
        message: t("commun_selectionner_avant_privilege"),
        target: "GLOBAL"
      });
    }
  }

  return (
    <div
      className="card"
      key={ass.id}
      style={{ backgroundColor: ass.privilege ? "#209cee59" : undefined }}
    >
      <div className="card-content">
        <Row>
          <Col span={3}>
            <Checkbox
              id={`selected_${ass.contextId}`}
              label="select"
              value={ass.creatorFocusId ? true : false}
              onValueChange={(_, val) => onAssociationChanged(ass, val)}
            />
          </Col>
          <Col span={6}>{ass.label}</Col>
          <Col span={3}>
            <Checkbox
              id={`privilege_${ass.contextId}`}
              label="privilege"
              value={ass.privilegie}
              onValueChange={(_, val) => onPrivilegeChanged(ass, val)}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

function renderAssociationChildren(pojos: Pojo[]): JSX.Element {
  const children = pojos.map((pojo, index) => <AssCard key={index} ass={pojo as Association} />);
  return <div>{children}</div>;
}

interface FocusAssociationReduxProps {
  syjModuleId?: string;
  globalAssociation: Association;
  roleAssociations: Association[];
  userAssociations: Association[];
}

interface FocusAssociationReduxFn {
  getAllRoles(sjmoCode: string): void;
  getAllUsers(sjmoCode: string): void;
  getAssociationsFocus(sjmoCode: string, focusId: string): void;
  createOneEntity(sjmoCode: string, tableName: string, pojo: any): void;
  deleteOneEntity(sjmoCode: string, tableName: string, id: string | number): void;
  addMessage(message: Message, autoclose?: boolean): HandleApiMessageAction;
}

const ModalAssociationFocus: FC<ModalFocusAssociationProps &
  FocusAssociationReduxProps &
  FocusAssociationReduxFn> = props => {
  useEffect(() => {
    props.getAllRoles(ADMIN_GALAXIE);
    props.getAllUsers(ADMIN_GALAXIE);
    props.getAssociationsFocus(ADMIN_GALAXIE, props.focusId);
  }, []);

  return (
    <Modal
      title={t("commun_edition_pptes")}
      onClose={e => props.onClose()}
      show={true}
      onValidate={() => props.onClose()}
    >
      <FocusAssociationContext.Provider
        value={{
          syjModuleId: props.syjModuleId as string,
          focusId: props.focusId,
          create: props.createOneEntity,
          delete: props.deleteOneEntity,
          addMessage: props.addMessage
        }}
      >
        <Card footer={<div />} title={t("commun_assignations")}>
          <Row>
            <Col span={4}>{t("commun_selection")}</Col>
            <Col span={4}>{t("commun_titre")}</Col>
            <Col span={4}>{t("commun_privilege")}</Col>
          </Row>
          <Row>
            <Col>
              <AssCard ass={props.globalAssociation} />
            </Col>
          </Row>
          <Row>
            <Col>
              <div>{t("commun_role")}</div>
              <PojoFilterableList
                pojos={props.roleAssociations}
                buildChildrenList={renderAssociationChildren}
                listHeight={200}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <div>{t("commun_utilisateur")}</div>
              <PojoFilterableList
                pojos={props.userAssociations}
                buildChildrenList={renderAssociationChildren}
                listHeight={200}
              />
            </Col>
          </Row>
        </Card>
      </FocusAssociationContext.Provider>
    </Modal>
  );
};

function mapStateToProps(state: ReducerState, ownProps: { sjmoCode: string; focusId: number }) {
  // syjModuleId
  const syjModules: Record<number, Pojo> = get(state, ["entities", ADMIN_GALAXIE, "syjModule"]);
  const syjModule = mapToUnorderedList(syjModules).find(
    module => module.sjmoCode === ownProps.sjmoCode
  );
  const syjModuleId = syjModule ? syjModule.id : undefined;

  // Association global
  const globalAssociation = selectGlobalAssociation(
    state,
    ADMIN_GALAXIE,
    ownProps.focusId.toString(),
    "syjFocusGlobal",
    "syjFocusId",
    "sjfgPrivilegie"
  );

  // Association role
  const roleAssociations = selectRoleAssociation(
    state,
    ADMIN_GALAXIE,
    ownProps.focusId.toString(),
    "syjFocusRole",
    "syjFocusId",
    "sjfrPrivilegie"
  );

  // Association user
  const userAssociations = selectUserAssociation(
    state,
    ADMIN_GALAXIE,
    ownProps.focusId.toString(),
    "syjFocusUser",
    "syjFocusId",
    "sjfuPrivilegie"
  );

  return {
    syjModuleId,
    globalAssociation,
    roleAssociations,
    userAssociations
  };
}

export default connect<FocusAssociationReduxProps, FocusAssociationReduxFn>(mapStateToProps, {
  createOneEntity,
  deleteOneEntity,
  getAllRoles,
  getAllUsers,
  getAssociationsFocus,
  addMessage
})(ModalAssociationFocus);
