import React, { FC, lazy } from "react";
import {
  Route,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
  Params,
  Outlet
} from "react-router-dom";

import Expert from "containers/expert/Expert";

const AdminExpert = lazy(() => import("./AdminExpert"));
const AdminUDT = lazy(() => import("./AdminUDT"));
const AdminGalaxy = lazy(() => import("./AdminGalaxy"));
const AdminCreator = lazy(() => import("./AdminCreator/AdminCreator"));
const AdminMiniExpert = lazy(() => import("../composants/AdminMiniExpert/AdminMiniExpert"));
const AdminDatatable = lazy(() => import("./AdminDatatable/AdminDatatable"));
const AdminDevDatatable = lazy(() => import("./AdminDevDatatable/AdminDatatable"));
const AdminSearch = lazy(() => import("./AdminSearch"));
const AdminGestionDroit = lazy(() => import("./AdminGestionDroit/AdminGestionDroit"));
const AdminInteractiveReport = lazy(() =>
  import("./AdminInteractiveReport/AdminInteractiveReport")
);
const AdminNewInteractiveReport = lazy(() =>
  import("./AdminInteractiveReport/AdminNewInteractiveReport")
);
const AdminDGL = lazy(() => import("./AdminSuggestion/AdminSuggestion"));
const AdminCartesNavigation = lazy(() => import("./AdminCartesNavigation/AdminCartesNavigation"));
const AdminNavProcessus = lazy(() => import("./AdminNavProcessus/AdminNavProcessus"));
const AdminSAS = lazy(() => import("./AdminSAS/AdminSAS"));
const AdminIndicateur = lazy(() => import("./AdminIndicateur/AdminIndicateur"));
const AdminArborescence = lazy(() => import("./AdminArborescence/AdminArborescence"));
const AdminColonnes = lazy(() => import("./AdminColonnes/AdminColonnes"));
const Accueil = lazy(() => import("./AdminAccueil/Accueil"));
const AdminGestionDroitLecture = lazy(() => import("./AdminDroitLecture/AdminGestionDroitLecture"));
const AdminGestionDroitCrud = lazy(() => import("./AdminDroitCRUD/AdminGestionDroitCrud"));
const AdminGestionDroitPanel = lazy(() => import("./AdminDroitPanel/AdminGestionDroitPanel"));
const AdminOEL = lazy(() => import("./AdminOEL/AdminOEL"));
const AdminInteraction = React.lazy(() => import("./AdminInteraction"));

const AcceuilCompo: FC = () => {
  const params: Params = useParams();
  const navigate = useNavigate();
  return <Accueil navigate={navigate} params={params} />;
};

const AdminExpertCompo: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const sjmoCode = location.pathname.split("/")[4];
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <>
      <AdminExpert
        sjmoCode={sjmoCode}
        location={location}
        navigate={navigate}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
      />
      <Outlet />
    </>
  );
};

const ExpertCompo: FC = () => {
  // const navigate = useNavigate();
  const location = useLocation();

  return (
    <Expert
      sjmoCode={location.pathname.split("/")[4]}
      height="calc(70vh - 40px)"
      // onClose={() => navigate(location.pathname.substring(0, location.pathname.lastIndexOf("/")))}
    />
  );
};

const AdminGalaxyCompo: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  return <AdminGalaxy navigate={navigate} params={params} />;
};

const AdminCreatorCompo: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  return <AdminCreator navigate={navigate} params={params} />;
};

const AdminMiniExpertCompo: FC = () => {
  const params = useParams();
  const navigate = useNavigate();
  return <AdminMiniExpert params={params} navigate={navigate} />;
};

const AdminDatatableCompo: FC<{}> = () => {
  const params = useParams();
  const navigate = useNavigate();
  return <AdminDatatable params={params} navigate={navigate} />;
};

const AdminDevDatatableCompo: FC<{}> = () => {
  const params = useParams();
  const navigate = useNavigate();
  return <AdminDevDatatable params={params} navigate={navigate} />;
};

const AdminInteractionCompo: FC<{}> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return <AdminInteraction navigate={navigate} params={params} location={location} />;
};

const AdminSearchCompo: FC<{}> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return <AdminSearch navigate={navigate} params={params} location={location} />;
};

const AdminGestionDroitCompo: FC<{}> = () => {
  return <AdminGestionDroit />;
};

const AdminInteractiveReportCompo: FC<{}> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <AdminInteractiveReport navigate={navigate} params={params} location={location} />
    </>
  );
};

const AdminNewInteractiveReportCompo: FC<{}> = () => {
  return <AdminNewInteractiveReport />;
};

const AdminDGLCompo: FC<{}> = () => {
  const params = useParams();
  const navigate = useNavigate();
  return <AdminDGL navigate={navigate} params={params} />;
};

export const AppRouteAdmin: FC = () => {
  return (
    <>
      <Route path="accueil/:id" element={<AcceuilCompo />} />
      <Route path="accueil" element={<AcceuilCompo />} />
      <Route path="expert/:sjmoCode" element={<AdminExpertCompo />}>
        <Route path="expert/:sjmoCode/show" element={<ExpertCompo />} />
      </Route>
      <Route path="expert" element={<AdminExpertCompo />} />
      <Route path="udt" element={<AdminUDT />} />
      <Route path="galaxie/:id" element={<AdminGalaxyCompo />} />
      <Route path="galaxie" element={<AdminGalaxyCompo />} />
      <Route path="creator/:tableName/:focusId" element={<AdminCreatorCompo />} />
      <Route path="creator/:tableName" element={<AdminCreatorCompo />} />
      <Route path="creator" element={<AdminCreatorCompo />} />
      <Route path="miniexpert/:tableId/:focusId" element={<AdminMiniExpertCompo />} />
      <Route path="miniexpert/:tableId" element={<AdminMiniExpertCompo />} />
      <Route path="miniexpert" element={<AdminMiniExpertCompo />} />
      <Route
        path="datatable/:tableName/:ctrlKey/:selectedFocusId"
        element={<AdminDatatableCompo />}
      />
      <Route path="datatable/:tableName/:ctrlKey" element={<AdminDatatableCompo />} />
      <Route path="datatable/:tableName" element={<AdminDatatableCompo />} />
      <Route path="datatable" element={<AdminDatatableCompo />} />
      <Route
        path="devdatatable/:tableName/:ctrlKey/:selectedFocusId"
        element={<AdminDevDatatableCompo />}
      />
      <Route path="devdatatable/:tableName/:ctrlKey" element={<AdminDevDatatableCompo />} />
      <Route path="devdatatable/:tableName" element={<AdminDevDatatableCompo />} />
      <Route path="devdatatable" element={<AdminDevDatatableCompo />} />
      <Route path="interaction/:sjmoCode" element={<AdminInteractionCompo />} />
      <Route path="interaction" element={<AdminInteractionCompo />} />
      <Route path="search/:tableName" element={<AdminSearchCompo />} />
      <Route path="search" element={<AdminSearchCompo />} />
      <Route path="gestionDroitGalaxie" element={<AdminGestionDroitCompo />} />
      <Route
        path="interactivereport/:InteractiveReportId/new/:syjModuleId/:syjPanelId"
        element={<AdminNewInteractiveReportCompo />}
      />
      <Route
        path="interactivereport/:InteractiveReportId/new/:syjModuleId"
        element={<AdminNewInteractiveReportCompo />}
      />
      <Route
        path="interactivereport/:InteractiveReportId/new"
        element={<AdminNewInteractiveReportCompo />}
      />
      <Route
        path="interactivereport/:InteractiveReportId"
        element={<AdminInteractiveReportCompo />}
      />
      <Route
        path="interactivereport/new/:syjModuleId/:syjPanelId"
        element={<AdminNewInteractiveReportCompo />}
      />
      <Route
        path="interactivereport//new/:syjModuleId"
        element={<AdminNewInteractiveReportCompo />}
      />
      <Route path="interactivereport/new" element={<AdminNewInteractiveReportCompo />} />
      <Route path="interactivereport" element={<AdminInteractiveReportCompo />} />
      <Route path="dgl/:module/:panel/:dglId" element={<AdminDGLCompo />} />
      <Route path="dgl/:module/:panel" element={<AdminDGLCompo />} />
      <Route path="dgl/:module" element={<AdminDGLCompo />} />
      <Route path="dgl" element={<AdminDGLCompo />} />
      <Route path="navcard/:panelId" element={<AdminCartesNavigation />} />
      <Route path="navcard" element={<AdminCartesNavigation />} />
      <Route path="processus" element={<AdminNavProcessus />} />
      <Route path="sas/:panelId" element={<AdminSAS />} />
      <Route path="sas" element={<AdminSAS />} />
      <Route path="gestionDroitLecture" element={<AdminGestionDroitLecture />} />
      <Route path="gestionDroitCrud" element={<AdminGestionDroitCrud />} />
      <Route path="gestionDroitPanel/:panelId" element={<AdminGestionDroitPanel />} />
      <Route path="gestionDroitPanel/" element={<AdminGestionDroitPanel />} />
      <Route path="indicateur/:panelId" element={<AdminIndicateur />} />
      <Route path="indicateur" element={<AdminIndicateur />} />
      <Route path="arborescence/:panelId" element={<AdminArborescence />} />
      <Route path="arborescence" element={<AdminArborescence />} />
      <Route path="colonnes/:colonneId" element={<AdminColonnes />} />
      <Route path="colonnes" element={<AdminColonnes />} />
      <Route path="oel/:tableName/:columnName" element={<AdminOEL />} />
      <Route path="oel/:tableName" element={<AdminOEL />} />
      <Route path="oel" element={<AdminOEL />} />
    </>
  );
};
