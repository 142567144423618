import React, { FC, useState, SyntheticEvent, FormEvent, useEffect } from "react";
import Modal from "composants/Modal/Modal";

import { t } from "utils/i18n";
import { uuidv4 } from "utils/uuid.utils";
import { Field } from "composants/form";
import { SaveProperties } from "types/InteractiveReport";
import { InteractiveReportConfiguration } from "types/Component";

interface AdminCreateDialogProps {
  currentFocus: InteractiveReportConfiguration | null;
  onClose(): void;
  onValidate(saveProperties: SaveProperties, typeFocus?: "user" | "global"): void;
  onDelete(id: number | string): void;
}

function initialSaveProperties(
  id: string | number | undefined | null,
  label: string | undefined | null,
  isGlobal?: boolean | undefined | null,
  position?: number | undefined | null
): SaveProperties {
  return {
    id: id || uuidv4(),
    label: label || "",
    isGlobal: isGlobal || false,
    position: position || 0
  };
}

export const AdminUpdateDialog: FC<AdminCreateDialogProps> = props => {
  const isAnExistingFocus = props.currentFocus && props.currentFocus.id !== undefined;

  const [currentProperties, setCurrentProperties] = useState<SaveProperties>(
    initialSaveProperties(
      props.currentFocus && props.currentFocus.id,
      props.currentFocus && props.currentFocus.label,
      props.currentFocus && props.currentFocus.isGlobal,
      props.currentFocus && props.currentFocus.position
    )
  );

  function onChange(e: SyntheticEvent<any>) {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;

    setCurrentProperties({
      ...currentProperties,
      [field]: value
    });
  }

  function onValidate(e: FormEvent) {
    e.preventDefault();
    // // on autorise la sauvegarde sur des nouveaux focus **et** si le focus appartient à l'utilisateur
    let currentPropertiesNumberPosition = currentProperties;
    currentPropertiesNumberPosition.position = Number(currentProperties.position);
    props.onValidate(currentPropertiesNumberPosition, props.currentFocus?.typeFocus);
    props.onClose();
  }

  return (
    <Modal title="Modifier un état" onClose={props.onClose} hideFooter>
      <form onSubmit={onValidate}>
        <Field label={t("commun_titre")} isHorizontal>
          <input
            className="input"
            required
            name="label"
            value={currentProperties.label}
            onChange={onChange}
          />
        </Field>
        <Field label={t("commun_position")} isHorizontal>
          <input
            className="input"
            style={{ textAlign: "left" }}
            type="number"
            name="position"
            value={currentProperties.position ? currentProperties.position : 0}
            onChange={onChange}
          />
        </Field>
        <div className="field is-grouped">
          <div className="control">
            <input type="submit" className="button is-primary" value={t("commun_valider")} />
          </div>
        </div>
      </form>
    </Modal>
  );
};
