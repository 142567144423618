import { NavigationDefinition, NavigationSubContext } from "constant/navigation";

interface LovNavigationParams {
  sjmoCode: string;
  lovTargetTableName: string;
  lovTargetCtrlKey: string; //
  lovTargetId?: string;
  column: string;
  tableName: string;
  syjLovId: string | null;
  type: "DT" | "GS";
  contextTableName?: string;
  contextId?: string | null;
}

interface InitLovNavigationParams extends LovNavigationParams {
  search: string;
}

export const LOV_URL_PARAMS = {
  LOV_SJMO_CODE: "lovSjmoCode",
  LOV_TARGET_TABLE_NAME: "lovTargetTableName",
  LOV_TARGET_CTRL_KEY: "lovTargetCtrlKey",
  LOV_TARGET_ID: "lovTargetId",
  LOV_COLUMN: "lovColumn",
  LOV_TABLE_NAME: "lovTableName",
  LOV_ID: "lovId",
  LOV_TYPE: "lovType",
  LOV_CONTEXT_TABLE_NAME: "contextTableName",
  LOV_CONTEXT_ID: "contextId"
};

/**
 * Permet d'initialiser l'url params pour l'ouverture de la LOV.
 * @param param0 paramètre de l'ouverture de la lov
 */
export function initLovNavigation({
  search,
  sjmoCode,
  lovTargetTableName,
  lovTargetCtrlKey,
  lovTargetId,
  column,
  tableName,
  syjLovId,
  type,
  contextTableName,
  contextId
}: InitLovNavigationParams) {
  const urlParams = new URLSearchParams(search);

  urlParams.set(LOV_URL_PARAMS.LOV_SJMO_CODE, sjmoCode);
  urlParams.set(LOV_URL_PARAMS.LOV_TARGET_TABLE_NAME, lovTargetTableName);
  urlParams.set(LOV_URL_PARAMS.LOV_TARGET_CTRL_KEY, lovTargetCtrlKey.toString());
  urlParams.set(LOV_URL_PARAMS.LOV_COLUMN, column);
  urlParams.set(LOV_URL_PARAMS.LOV_TABLE_NAME, tableName);

  if (syjLovId) {
    urlParams.set(LOV_URL_PARAMS.LOV_ID, syjLovId);
  }

  urlParams.set(LOV_URL_PARAMS.LOV_TYPE, type);

  lovTargetId && urlParams.set(LOV_URL_PARAMS.LOV_TARGET_ID, lovTargetId.toString());
  contextTableName && urlParams.set(LOV_URL_PARAMS.LOV_CONTEXT_TABLE_NAME, contextTableName);
  contextId && urlParams.set(LOV_URL_PARAMS.LOV_CONTEXT_ID, contextId);

  return urlParams;
}

export function getLovParamsFromURL(search: string): LovNavigationParams | null {
  const urlParams = new URLSearchParams(search);

  const sjmoCode = urlParams.get(LOV_URL_PARAMS.LOV_SJMO_CODE);
  const lovTargetTableName = urlParams.get(LOV_URL_PARAMS.LOV_TARGET_TABLE_NAME);
  const lovTargetCtrlKey = urlParams.get(LOV_URL_PARAMS.LOV_TARGET_CTRL_KEY); //
  const lovTargetId = urlParams.get(LOV_URL_PARAMS.LOV_TARGET_ID);
  const column = urlParams.get(LOV_URL_PARAMS.LOV_COLUMN);
  const tableName = urlParams.get(LOV_URL_PARAMS.LOV_TABLE_NAME);
  const type = urlParams.get("lovType") as "DT" | "GS";
  const contextTableName = urlParams.get(LOV_URL_PARAMS.LOV_CONTEXT_TABLE_NAME) || undefined;
  const contextId = urlParams.get(LOV_URL_PARAMS.LOV_CONTEXT_ID) || undefined;
  const syjLovId = urlParams.get(LOV_URL_PARAMS.LOV_ID);

  if (
    sjmoCode &&
    lovTargetTableName &&
    lovTargetCtrlKey &&
    column !== null &&
    tableName !== null &&
    type
  ) {
    return {
      sjmoCode,
      lovTargetTableName,
      lovTargetCtrlKey,
      lovTargetId: lovTargetId || undefined,
      contextTableName,
      contextId,
      column,
      tableName,
      syjLovId: syjLovId || null,
      type
    };
  } else {
    return null;
  }
}

export function removeLovParamsFromURL(search: string) {
  const urlParams = new URLSearchParams(search);
  urlParams.delete(LOV_URL_PARAMS.LOV_SJMO_CODE);
  urlParams.delete(LOV_URL_PARAMS.LOV_TARGET_TABLE_NAME);
  urlParams.delete(LOV_URL_PARAMS.LOV_TARGET_CTRL_KEY);
  urlParams.delete(LOV_URL_PARAMS.LOV_TARGET_ID);
  urlParams.delete(LOV_URL_PARAMS.LOV_COLUMN);
  urlParams.delete(LOV_URL_PARAMS.LOV_TABLE_NAME);
  urlParams.delete(LOV_URL_PARAMS.LOV_TYPE);
  urlParams.delete(LOV_URL_PARAMS.LOV_CONTEXT_TABLE_NAME);
  urlParams.delete(LOV_URL_PARAMS.LOV_CONTEXT_ID);
  urlParams.delete(LOV_URL_PARAMS.LOV_ID);
  return urlParams;
}

type OpenSatelliteProps = {
  sjmoCode: string;
  tableName: string;
  satelliteName: string;
  ctrlKey?: string;
  query?: string;
  columns?: string[];
  contextId?: string;
};
export function openSatellite(props: OpenSatelliteProps): string {
  const { tableName, sjmoCode, satelliteName, ctrlKey, query, columns, contextId } = props;
  let params = undefined;
  if (satelliteName) {
    params = new URLSearchParams(window.location.search);
    params.set("satelliteTableName", tableName);
    params.set("satelliteSjmoCode", sjmoCode);
    if (contextId) {
      params.set("satelliteContextId", contextId);
    }
    params.set("currentTab", satelliteName);
    ctrlKey && params.set("ctrlKey", ctrlKey);
    query && params.set("query", query);
    columns && params.set("columns", columns.join(","));

    return params.toString();
  } else {
    return "";
  }
}

export function closeSatellite() {
  const params = new URLSearchParams(window.location.search);
  params.delete("satelliteTableName");
  params.delete("satelliteSjmoCode");
  params.delete("satelliteContextId");
  params.delete("currentTab");
  params.delete("query");
  params.delete("ctrlKey");
  params.delete("columns");
  return params;
}

export function getNavigationUrlFromMap(navigationMap: NavigationDefinition) {
  let urlPath = navigationMap.module ? ["page", navigationMap.module] : null;
  if (navigationMap.id && urlPath) {
    urlPath.push(encodeURIComponent(navigationMap.id));
  }

  const urlSearchParams = new URLSearchParams();
  if (navigationMap.subContext) {
    const sc: NavigationSubContext = navigationMap.subContext;
    switch (sc.type) {
      case "suiviOpen":
        urlSearchParams.set("suiviOpen", "true");
        if (sc.query) {
          // exemple ?suiviOpen=true&q=clientId.id=='ACEMO'
          urlSearchParams.set("q", sc.query);
        } else if (sc.ids) {
          // exemple ?suiviOpen=true&ids=D19-0085,D19-0058
          urlSearchParams.set("ids", sc.ids.join(","));
        }
        break;

      case "expert":
        // exemple /expert/287
        if (urlPath) {
          urlPath.push("expert");
          urlPath.push(sc.contextId || "");
        }

        break;

      case "creator":
        // exemple : ?creatorTableName=devisClient&creatorSjmoCode=OGCO020&creatorContextTable=devisClient&navigateOnClose=true&creatorContextId=D19-00085
        urlSearchParams.set("creatorTableName", sc.tableName || "");
        urlSearchParams.set("creatorSjmoCode", sc.module || "");
        urlSearchParams.set("creatorContextTable", sc.contextTable || "");
        urlSearchParams.set("creatorContextId", sc.contextId || "");
        urlSearchParams.set("navigateOnClose", sc.navigateOnClose || "");
        sc.focusId && urlSearchParams.set("focusId", sc.focusId);

        if (sc.contextualValues && sc.contextualValues != null) {
          const keys = Object.keys(sc.contextualValues);
          for (let key of keys) {
            urlSearchParams.set(key, sc.contextualValues[key]);
          }
        }
        break;

      case "envoiMail":
        urlSearchParams.set("mail", "true");
        break;

      case "process":
        urlSearchParams.set("process", sc.process || "");
        urlSearchParams.set("navigationUrl", sc.navigationUrl || "");
        urlSearchParams.set("nature", sc.nature || "");
        urlSearchParams.set("sjmoCode", sc.sjmoCode || "");
        break;

      case "commentaires":
      case "extensions":
      case "documents":
      case "messages":
      case "configurateurs":
      case "articulations":
      case "validations":
      case "mails":
        // exemple ?satelliteTableName=devisClient&satelliteSjmoCode=OGCO020&satelliteContextId=D19-00085&currentTab=commentaires&ctrlKey=
        urlSearchParams.set("satelliteTableName", sc.tableName || "");
        urlSearchParams.set("satelliteSjmoCode", sc.module || "");
        urlSearchParams.set("satelliteContextId", sc.contextId || "");
        urlSearchParams.set("currentTab", sc.type || "");
        urlSearchParams.set("ctrlKey", sc.ctrlKey || "");
        urlSearchParams.set("query", sc.query || "");
        urlSearchParams.set("columns", sc.columns || "");
        break;

      default:
        break;
    }

    // Gestion de la navigation vers un focus en particulier
    if (navigationMap.focus) {
      urlSearchParams.set("galaxyFocus", navigationMap.focus);
    }
  } else if (navigationMap.focus) {
    urlSearchParams.set("galaxyFocus", navigationMap.focus);
  }

  const search = urlSearchParams.toString();
  const baseUrl = urlPath ? urlPath.join("/") : "";

  let url = baseUrl.length > 0 ? "/" + baseUrl : "";
  if (search.length > 0) url += "?" + search;

  return url;
}
