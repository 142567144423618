import { AxiosPromise } from "axios";

import { apiAdn, getUIContext } from "./common";
import { FocusState } from "types/Focus";
import { Menu } from "types/Menu";
import { ComponentGroupState } from "types/ComponentGroup";

// Fonction qui récupère la liste des focus d'un expert de galaxie
export function getExpertFocus(sjmoCode: string): AxiosPromise<FocusState[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/expert/focus?${params}`);
}

// Fonction qui récupère les groupes de composants d'un focus de mini-expert
export function getExpertMenu(sjmoCode: string): AxiosPromise<Menu[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/expert/menu?${params}`);
}

// Fonction qui récupère les groupes de composants d'un focus de mini-expert
export function getExpertGroups(sjmoCode: string, id: string): AxiosPromise<ComponentGroupState[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/expert/${id}/groups?${params}`);
}

// Fonction qui récupère les groupes de composants d'un focus de mini-expert
export function getExpertHeader(sjmoCode: string): AxiosPromise<ComponentGroupState[]> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/expert/header?${params}`);
}
