import produce from "immer";

import { DatatableFocusState, FocusState } from "types/Focus";
import { ComponentState, FilterBarDefinition } from "types/Component";
import { Pojo } from "types/Galaxy";
import Action from "reducers/Action";
import {
  INIT_DATATABLE,
  CHANGE_DATATABLE_FOCUS_SUCCESS,
  UPDATE_SEARCH_DATATABLE,
  UPDATE_SELECTED_ROWS_DATATABLE,
  FETCH_DATATABLE_DATA_SUCCESS,
  FETCH_DATATABLE_COLUMN_SUCCESS,
  FETCH_DATATABLE_FOCUS_SUCCESS,
  DATATABLE_TEMPORARY_LOADING,
  ADD_ROW_DATATABLE_SUCCESS,
  CHANGE_ROW_DATATABLE_NEW_ENTITY,
  RESET_NEW_ENTTIES_DATATABLE,
  ADD_WVI_DATATABLE,
  UPDATE_ROW_DATATABLE,
  REMOVE_NEW_ENTITIES_DATATABLE,
  ADD_MULTIPLE_ROW_DATATABLE_SUCCESS,
  CLEAR_DATATABLE_DATA,
  SHOULD_UPDATE_DATATABLE,
  UPDATE_FILTER_DATATABLE,
  UPDATE_DATATABLE_SORT,
  CLEAR_DATATABLE_REDUX_STATE,
  INIT_BASE_DATATABLE,
  DATATABLE_ADD_ONE_FOCUS,
  DATATABLE_UPDATE_FILTERS,
  DATATABLE_REMOVE_FILTERS,
  DATATABLE_REMOVE_FOCUS,
  FETCH_DATATABLE_DATA,
  FETCH_DATATABLE_DATA_ERROR,
  LOCAL_UPDATE_DATATABLE_FOCUS,
  SET_DATATABLE_PRE_RECORD_CACHE,
  DATATABLE_UPDATE_FILTER_BAR,
  DATATABLE_CHANGE_BREAK_COLUMNS,
  DATATABLE_SATTELLITE_EXIST_SUCCESS
} from "constant/datatable.constant";
import { PagedResource, FilterBar } from "types/Search";
import { Message } from "types/Message";
import { SHOULD_NOT_UPDATE_GALAXY } from "constant/galaxy";
import { get } from "lodash-es";
import { uuidv4 } from "utils/uuid.utils";
import { IS_PRODUCTION } from "customGlobal";

export interface DatatableState {
  preRecordEntity: Pojo | null;
  preRecordEntityLastFetch: number | null;
  newEntitiesStart: Pojo[];
  newEntitiesLast: Pojo[];
  focus: DatatableFocusState[];
  columns: ComponentState[];
  selectedFocus: string | undefined;
  filterBar: FilterBar;
  globalLoading: boolean;
  entities: { [index: number]: string | "LOADING_POJO" };
  totalRecords: number;
  size: number;
  selectedRows: number[];
  filter: string;
  wviState: { [id: number]: Record<string, string> };
  shouldUpdate?: boolean;
  tableName: string;
  filters: FilterBarDefinition[];
  breakColumns: string[];
  satellitesExist: Record<string, boolean>;
}

export const initialStateDatatable: DatatableState = {
  preRecordEntity: null,
  preRecordEntityLastFetch: null,
  newEntitiesStart: [],
  newEntitiesLast: [],
  focus: [],
  columns: [],
  globalLoading: false,
  entities: {},
  totalRecords: 0,
  size: 10,
  selectedFocus: undefined,
  selectedRows: [],
  filterBar: {
    filterBarId: null,
    startDate: null,
    endDate: null
  },
  filter: "",
  wviState: {},
  tableName: "",
  filters: [],
  breakColumns: [],
  satellitesExist: {}
};

export type ReducerDatatableState = {
  [sjmoCode: string]: { [ctrlKey: string]: DatatableState };
};

export default function reducer(
  state: ReducerDatatableState = {},
  action: Action<{ sjmoCode: string; ctrlKey: string }>
): ReducerDatatableState {
  switch (action.type) {
    case INIT_DATATABLE:
      return initDatatable(state, action as any);
    case CLEAR_DATATABLE_DATA:
      return clearData(state, action as any);
    case CHANGE_DATATABLE_FOCUS_SUCCESS:
      return changeFocus(state, action as any);
    case FETCH_DATATABLE_COLUMN_SUCCESS:
      return addColumns(state, action as any);
    case FETCH_DATATABLE_FOCUS_SUCCESS:
      return addFocus(state, action as any);
    case DATATABLE_ADD_ONE_FOCUS:
      return addOneFocus(state, action as any);
    case UPDATE_FILTER_DATATABLE:
      return updateFilter(state, action as any);
    case UPDATE_SEARCH_DATATABLE:
      return updateSearchDatatable(state, action as any);
    case DATATABLE_TEMPORARY_LOADING:
      return addLoadingEntities(state, action as any);
    case FETCH_DATATABLE_DATA:
      return setGlobalLoadingActive(state, action as any);
    case FETCH_DATATABLE_DATA_ERROR:
      return resetGlobalLoading(state, action as any);
    case FETCH_DATATABLE_DATA_SUCCESS:
      return fetchDataSuccess(state, action as any);
    case UPDATE_SELECTED_ROWS_DATATABLE:
      return updateSelectedRows(state, action as any);

    case ADD_ROW_DATATABLE_SUCCESS:
      return addRowNewEntities(state, action as any);

    case SET_DATATABLE_PRE_RECORD_CACHE:
      return setPreRecordEntity(state, action as any);

    case ADD_MULTIPLE_ROW_DATATABLE_SUCCESS:
      return addMultipleRowsNewEntities(state, action as any);

    case UPDATE_ROW_DATATABLE:
      return updateNewEntity(state, action as any);

    case CHANGE_ROW_DATATABLE_NEW_ENTITY:
      return updateFieldNewEntity(state, action as any);

    case RESET_NEW_ENTTIES_DATATABLE:
      return produce(state, draft => {
        const { sjmoCode, ctrlKey } = action.payload;
        draft[sjmoCode][ctrlKey].newEntitiesStart = [];
        draft[sjmoCode][ctrlKey].newEntitiesLast = [];
      });

    case REMOVE_NEW_ENTITIES_DATATABLE:
      return removeNewEntities(state, action as any);

    case ADD_WVI_DATATABLE:
      return updateWvi(state, action as any);

    case SHOULD_UPDATE_DATATABLE:
      return shouldUpdate(state, true, action.payload.sjmoCode, action.payload.ctrlKey);
    case SHOULD_NOT_UPDATE_GALAXY:
      return shouldUpdate(state, false, action.payload.sjmoCode);
    case UPDATE_DATATABLE_SORT:
      return updateDatatableSort(state, action.payload as any);
    case CLEAR_DATATABLE_REDUX_STATE:
      return clearSjmoCode(state, action.payload.sjmoCode);
    case INIT_BASE_DATATABLE:
      return initDatatable(state, action as any);
    case DATATABLE_UPDATE_FILTERS:
      return addOrUpdateFilter(state, action.payload as any);
    case DATATABLE_REMOVE_FILTERS:
      return removeFilter(state, action.payload as any);
    case DATATABLE_REMOVE_FOCUS:
      return removeFocus(state, action.payload as any);
    case LOCAL_UPDATE_DATATABLE_FOCUS:
      return updateFocus(state, action.payload as any);
    case DATATABLE_UPDATE_FILTER_BAR:
      return updateFilterBar(state, action as any);
    case DATATABLE_CHANGE_BREAK_COLUMNS:
      return changeBreakColumn(state, action as any);
    case DATATABLE_SATTELLITE_EXIST_SUCCESS:
      return donneeSatellite(state, action as any);
    default:
      return state;
  }
}

/**
 * Initialisation de la table
 * @param state state redux
 * @param action action d'initialisation
 */
function initDatatable(
  state: ReducerDatatableState,
  action: Action<{ tableName: string; sjmoCode: string; ctrlKey: string; filter: string }>
): ReducerDatatableState {
  const { ctrlKey, sjmoCode, filter } = action.payload;
  return produce(state, draft => {
    if (!draft[sjmoCode]) {
      draft[sjmoCode] = {};
    }

    if (!draft[sjmoCode][ctrlKey]) {
      draft[sjmoCode][ctrlKey] = {
        ...initialStateDatatable,
        tableName: action.payload.tableName,
        filter: filter
      };
    }
  });
}

/**
 * Reset des data de la table
 * @param state state redux
 * @param action action de reset des entities
 */
function clearData(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey } = action.payload;
  return produce(state, draft => {
    const definition = draft[sjmoCode] && draft[sjmoCode][ctrlKey];

    if (definition) {
      definition.entities = {};
      definition.newEntitiesStart = [];
      definition.newEntitiesLast = [];
      definition.selectedRows = [];
      definition.totalRecords = 0;
      definition.wviState = {};
    }
  });
}

/**
 * Changement de focus d'une table
 * @param state state redux
 * @param action action de changement de focus
 */
function changeFocus(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; selectedFocus: string }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, selectedFocus } = action.payload;
  return produce(state, draft => {
    if (!draft[sjmoCode][ctrlKey]) {
      return;
    }

    draft[sjmoCode][ctrlKey].selectedFocus = selectedFocus;

    const currentFocus = draft[sjmoCode][ctrlKey].focus.find(f => f.focusId === selectedFocus);

    if (currentFocus) {
      draft[sjmoCode][ctrlKey].breakColumns = currentFocus.breakRows || [];

      const filterBar = currentFocus.filters.find(p => p.privilegie);

      if (filterBar) {
        draft[sjmoCode][ctrlKey].filterBar = {
          filterBarId: filterBar.filterBarId,
          startDate: null,
          endDate: null
        };
      }
    }
  });
}

/**
 * Changement des rows de datatable
 * @param state state redux
 * @param action action d'update des rows sélectionné
 */
function updateSelectedRows(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; rows: number[] }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, rows } = action.payload;
  return produce(state, draft => {
    draft[sjmoCode][ctrlKey].selectedRows = rows;
  });
}

function addColumns(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; columns: ComponentState[] }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, columns } = action.payload;
  return produce(state, draft => {
    draft[sjmoCode][ctrlKey].columns = columns;
  });
}

function addFocus(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; focus: DatatableFocusState[] }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, focus } = action.payload;
  return produce(state, draft => {
    draft[sjmoCode][ctrlKey].focus = focus;

    const currentFocus = focus[0];

    draft[sjmoCode][ctrlKey].selectedFocus = currentFocus.focusId;
    draft[sjmoCode][ctrlKey].breakColumns = currentFocus.breakRows || [];

    if (currentFocus) {
      let filterBar =
        currentFocus && currentFocus.filters
          ? currentFocus.filters.find(f => f.privilegie === true)
          : null;

      if (filterBar) {
        draft[sjmoCode][ctrlKey].filterBar = {
          filterBarId: filterBar.filterBarId,
          startDate: null,
          endDate: null
        };
      }
    }
  });
}

function addOneFocus(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; focus: DatatableFocusState }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, focus } = action.payload;
  return produce(state, draft => {
    draft[sjmoCode][ctrlKey].focus.push(focus);
  });
}

function setGlobalLoadingActive(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
  }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey } = action.payload;
  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];
    current.globalLoading = true;
  });
}

function resetGlobalLoading(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
  }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey } = action.payload;
  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];
    current.globalLoading = false;
  });
}

function fetchDataSuccess(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    startIndex: number;
    pojos: PagedResource<Pojo>;
    reset: boolean;
  }>
) {
  const { sjmoCode, ctrlKey, startIndex, pojos, reset } = action.payload;

  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];
    current.size = pojos.meta.size;
    current.totalRecords = pojos.meta.totalRecords;

    let indexPojo = 0;
    for (
      let index = startIndex, maxIndex = startIndex + pojos.data.length;
      index < maxIndex;
      index++
    ) {
      const workingKey = pojos.data[indexPojo].id;
      current.entities[index] = workingKey;
      indexPojo++;
    }

    if (reset) {
      current.wviState = {};
    }

    current.globalLoading = false;
  });
}

function addLoadingEntities(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    loading: Record<string, number | "LOADING_POJO">;
  }>
) {
  const { sjmoCode, ctrlKey, loading } = action.payload;
  return produce(state, draft => {
    const indexes = Object.keys(loading);
    for (let index of indexes) {
      const entity = draft[sjmoCode][ctrlKey].entities[index];

      if (!entity) {
        draft[sjmoCode][ctrlKey].entities[index] = "LOADING_POJO";
      }
    }
  });
}

function updateFilter(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    filter: string;
  }>
) {
  const { sjmoCode, ctrlKey, filter } = action.payload;
  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];
    current.filter = filter;
  });
}

function updateSearchDatatable(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    filter: string;
  }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, filter } = action.payload;
  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];
    current.entities = {};
    current.filter = filter;
    current.newEntitiesStart = [];
  });
}

function setPreRecordEntity(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; pojo: Pojo; lastFetch: number }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, pojo, lastFetch } = action.payload;
  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];

    current.preRecordEntity = { ...pojo, modifie: true };
    current.preRecordEntityLastFetch = lastFetch;
  });
}

function addRowNewEntities(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    position: "START" | "END";
  }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, position } = action.payload;

  // on récupère le preRecordEntity avant d'appeler le produce pour
  // éviter de copier un proxy.
  const preRecordEntity = state[sjmoCode][ctrlKey].preRecordEntity;

  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];

    if (preRecordEntity === null) {
      if (IS_PRODUCTION()) {
        console.error("impossible d'ajouter une entité s'il n'y a pas eu de pre-record");
        return;
      } else {
        throw new Error("impossible d'ajouter une entité s'il n'y a pas eu de pre-record");
      }
    }

    const pojoToAdd = { ...preRecordEntity, uuid: uuidv4() };
    if (position === "START") {
      current.newEntitiesStart.unshift(pojoToAdd);
    } else if (position === "END") {
      current.newEntitiesLast.push(pojoToAdd);
    } else {
      if (IS_PRODUCTION()) {
        console.error("impossible d'ajouter une entité en dehors de START et END");
      } else {
        throw new Error("impossible d'ajouter une entité en dehors de START et END");
      }
    }

    for (let index = 0; index < current.selectedRows.length; index++) {
      current.selectedRows[index] += 1;
    }
  });
}

function addMultipleRowsNewEntities(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    pojos: Pojo[];
  }>
) {
  const { sjmoCode, ctrlKey, pojos } = action.payload;
  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];

    for (const pojo of pojos) {
      current.newEntitiesStart.unshift({ ...pojo, modifie: true });
    }

    for (let index = 0; index < current.selectedRows.length; index++) {
      current.selectedRows[index] += 1;
    }
  });
}

function updateNewEntity(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; pojo: Pojo }>
) {
  const { sjmoCode, ctrlKey, pojo } = action.payload;

  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];

    let index = current.newEntitiesStart.findIndex(entity => entity.uuid === pojo.uuid);
    if (index !== -1) {
      current.newEntitiesStart[index] = { ...pojo, modifie: true };
    } else {
      index = current.newEntitiesLast.findIndex(entity => entity.uuid === pojo.uuid);
      if (index !== -1) {
        current.newEntitiesLast[index] = { ...pojo, modifie: true };
      }
    }
  });
}

function updateFieldNewEntity(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    entityId: string | number;
    field: string;
    value: any;
  }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, entityId, field, value } = action.payload;
  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];
    let index = current.newEntitiesStart.findIndex(entity => entity.uuid === entityId);

    if (index !== -1) {
      current.newEntitiesStart[index][field] = value;
      current.newEntitiesStart[index].modifie = true;
    } else {
      index = current.newEntitiesLast.findIndex(entity => entity.uuid === entityId);

      if (index !== -1) {
        current.newEntitiesLast[index][field] = value;
        current.newEntitiesLast[index].modifie = true;
      }
    }
  });
}

function updateWvi(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    id: number;
    field: string;
    message: Message;
  }>
) {
  const { sjmoCode, ctrlKey, id, field, message } = action.payload;
  return produce(state, draft => {
    const current = draft[sjmoCode][ctrlKey];

    if (message.type) {
      if (!current.wviState[id]) {
        current.wviState[id] = {};
      }
      current.wviState[id][field] = message.type;
    }
  });
}

function removeNewEntities(
  state: ReducerDatatableState,
  action: Action<{
    sjmoCode: string;
    ctrlKey: string;
    toBeRemove: string[];
  }>
): ReducerDatatableState {
  function deleteNewEntities(newEntities: Pojo[], id: string, selectedRows: number[]) {
    const index = newEntities.findIndex(en => en.uuid === id);
    if (index !== -1) {
      newEntities.splice(index, 1);
      const selectedIndex = selectedRows.findIndex(realIndex => realIndex === index);
      if (selectedIndex !== -1) {
        selectedRows.splice(selectedIndex, 1);
      }
      return true;
    }
    return false;
  }

  const { sjmoCode, ctrlKey, toBeRemove } = action.payload;
  return produce(state, draft => {
    const currentDefinition = draft[sjmoCode][ctrlKey];

    for (let id of toBeRemove) {
      let isDelete = deleteNewEntities(
        currentDefinition.newEntitiesStart,
        id,
        currentDefinition.selectedRows
      );
      if (!isDelete) {
        deleteNewEntities(currentDefinition.newEntitiesLast, id, currentDefinition.selectedRows);
      }
    }
  });
}

function shouldUpdate(
  state: ReducerDatatableState,
  update: boolean,
  sjmoCode: string,
  ctrlKey?: string
): ReducerDatatableState {
  return produce(state, draft => {
    if (ctrlKey) {
      draft[sjmoCode][ctrlKey] = { ...state[sjmoCode][ctrlKey], shouldUpdate: update };
    } else if (state[sjmoCode]) {
      Object.keys(state[sjmoCode]).forEach(dtCtrlKey => {
        draft[sjmoCode][dtCtrlKey] = { ...state[sjmoCode][dtCtrlKey], shouldUpdate: update };
      });
    }
  });
}

function updateDatatableSort(
  state: ReducerDatatableState,
  params: {
    sjmoCode: string;
    ctrlKey: string;
    focusId: string;
    sort: string;
  }
) {
  return produce(state, draft => {
    let focuses = get(draft, [params.sjmoCode, params.ctrlKey, "focus"], undefined);
    if (focuses) {
      focuses.forEach(focus => {
        if (focus.focusId === params.focusId) {
          focus.sort = params.sort;
        }
      });
    }
  });
}

function clearSjmoCode(state: ReducerDatatableState, sjmoCode: string) {
  return produce(state, draft => {
    // On force la suppression
    draft[sjmoCode] = undefined as any;
  });
}

function addOrUpdateFilter(
  state: ReducerDatatableState,
  payload: { sjmoCode: string; ctrlKey: string; filter: FilterBarDefinition }
) {
  const { sjmoCode, ctrlKey, filter } = payload;
  return produce(state, draft => {
    let focuses = draft[sjmoCode][ctrlKey].focus;
    focuses.forEach(focus => {
      let existing = focus.filters.findIndex(current => current.filterBarId === filter.filterBarId);
      if (existing > -1) {
        focus.filters.splice(existing, 1, filter);
      } else {
        focus.filters.push(filter);
      }
    });
  });
}

function removeFilter(
  state: ReducerDatatableState,
  payload: { sjmoCode: string; ctrlKey: string; id: string }
) {
  const { sjmoCode, ctrlKey, id } = payload;
  return produce(state, draft => {
    let focuses = draft[sjmoCode][ctrlKey].focus;
    focuses.forEach(focus => {
      let existing = focus.filters.findIndex(current => {
        return current.filterBarId.toString() === id.toString();
      });
      if (existing > -1) {
        focus.filters.splice(existing, 1);
      }
    });
  });
}

function removeFocus(
  state: ReducerDatatableState,
  payload: { sjmoCode: string; ctrlKey: string; focusId: string }
) {
  const { sjmoCode, ctrlKey, focusId } = payload;
  return produce(state, draft => {
    let focuses = state[sjmoCode][ctrlKey].focus;
    const filtered = focuses.filter(f => f.focusId.toString() !== focusId.toString());
    draft[sjmoCode][ctrlKey].focus = filtered;
    if (state[sjmoCode][ctrlKey].selectedFocus === focusId) {
      draft[sjmoCode][ctrlKey].selectedFocus =
        filtered.length > 0 ? filtered[0].focusId : undefined;
    }
  });
}

function updateFocus(
  state: ReducerDatatableState,
  payload: {
    sjmoCode: string;
    ctrlKey: string;
    focusId: number;
    focusProps: any;
  }
) {
  const { sjmoCode, ctrlKey, focusId, focusProps } = payload;
  return produce(state, draft => {
    let focuses = [...state[sjmoCode][ctrlKey].focus];
    focuses = focuses.map(f => {
      if (f.focusId.toString() === focusId.toString()) {
        f = { ...f, ...focusProps };
      }
      return f;
    });
    draft[sjmoCode][ctrlKey].focus = focuses;
  });
}

function updateFilterBar(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; name: string; value: any }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, name, value } = action.payload;
  return produce(state, draft => {
    draft[sjmoCode][ctrlKey].filterBar[name] = value;
  });
}

function changeBreakColumn(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; breakColumns: string[] }>
): ReducerDatatableState {
  const { sjmoCode, ctrlKey, breakColumns } = action.payload;
  return produce(state, draft => {
    draft[sjmoCode][ctrlKey].breakColumns = breakColumns;
  });
}

function donneeSatellite(
  state: ReducerDatatableState,
  action: Action<{ sjmoCode: string; ctrlKey: string; existList: Record<string, boolean> }>
) {
  const { sjmoCode, ctrlKey, existList } = action.payload;
  return produce(state, draft => {
    Object.keys(existList).forEach(key => {
      draft[sjmoCode][ctrlKey].satellitesExist[key] = existList[key];
    });
  });
}
