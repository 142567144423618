import {
  UPDATE_CONTEXT,
  CLEAR_CONTEXT,
  FETCH_SATELLITES_ALL_COUNT,
  CLEAR_SATELLITES_ALL_COUNT
} from "constant/satellite";

export function updateContext(context: { tableName: string; id?: string; sjmoCode: string }) {
  return { type: UPDATE_CONTEXT, payload: { ...context } };
}

/**
 * Vide le context pour remettre les compteurs à zéro
 * @export
 * @returns
 */
export function clearContext(sjmoCode: string) {
  return { type: CLEAR_CONTEXT, payload: { sjmoCode } };
}

/**
 * Vide les counts des données satellites sans modifier le context.
 * Utiliser pour les galaxies de type décisionnel et univers
 */
export function clearSatelliteCount() {
  return { type: CLEAR_SATELLITES_ALL_COUNT, payload: undefined };
}

/**
 * Action qui récupère le count pour toutes les données satellites à afficher.
 *
 * @export
 * @param {Object} entity
 * @returns
 */
export function fetchAllSatelliteCount(tableName: string, id: string) {
  return { type: FETCH_SATELLITES_ALL_COUNT, payload: { tableName, id } };
}

/**
 * Factory qui créer une action de fetch pour une donnée satellite à partir d'un type d'action
 * @param actionName
 */
const createAction = (actionName: string) => (tableName: string, id: string) => {
  return {
    type: actionName,
    payload: {
      tableName,
      id
    }
  };
};

export const fetchExtensionCount = createAction("FETCH_EXTENSIONS_COUNT");
export const fetchCommentaireCount = createAction("FETCH_COMMENTAIRES_COUNT");
export const fetchDocumentCount = createAction("FETCH_DOCUMENTS_COUNT");
export const fetchConfigurateurCount = createAction("FETCH_CONFIGURATEURS_COUNT");
export const fetchArticulationCount = createAction("FETCH_ARTICULATIONS_COUNT");
export const fetchValidationCount = createAction("FETCH_VALIDATIONS_COUNT");
export const fetchMailCount = createAction("FETCH_MAILS_COUNT");

export const fetchExtensionData = createAction("FETCH_EXTENSIONS");
export const fetchCommentaireData = createAction("FETCH_COMMENTAIRES");
export const fetchDocumentData = createAction("FETCH_DOCUMENTS");
export const fetchConfigurateurData = createAction("FETCH_CONFIGURATEURS");
export const fetchArticulationData = createAction("FETCH_ARTICULATIONS");
export const fetchValidationData = createAction("FETCH_VALIDATIONS");
export const fetchMailData = createAction("FETCH_MAILS");
