import { apiAdn, getUIContext, api } from "./common";
import { AxiosPromise } from "axios";
import { KanbanDefinition } from "types/Component";
import { Pojo } from "types/Galaxy";
import { FindViewParams, configureUrlParamsForSearch } from "./entities";
import { PagedResource } from "types/Search";

export function getKanbanDefinition(sjmoCode: string): AxiosPromise<KanbanDefinition> {
  const urlParams = getUIContext({ sjmoCode });
  return apiAdn.get(`/kanban/definition?${urlParams}`);
}

/**
 * Permet de lancer les différentes actions du kanban
 *
 * @param {string} sjmoCode code du module
 * @param {string} action action à lancer
 * @param {number} id id de la définition du kanban
 * @param {Record<string, any>} body body à lancer au traitement
 * @returns retourne une promesse
 */
function executeKanbanAction<T extends Pojo[]>(
  sjmoCode: string,
  action: "move" | "reorder" | "launch",
  id: string,
  body: Record<string, any> | Record<string, any>[],
  params: Record<string, any> | undefined,
  laneCode?: string
): AxiosPromise<T> {
  const urlParams = getUIContext({ sjmoCode });

  if (laneCode) {
    urlParams.append("laneCode", laneCode);
  }

  return api.post(`/kanban-process/${action}/${id}?${urlParams}`, {
    body: Array.isArray(body) ? body : [body],
    params
  });
}

export function executeKanbanLaunch(
  sjmoCode: string,
  id: string,
  body: Record<string, any> | Record<string, any>[],
  params?: Record<string, any>
) {
  return executeKanbanAction(sjmoCode, "launch", id, Array.isArray(body) ? body : [body], params);
}

export function executeProcessLaunch(
  sjmoCode: string,
  processId: string,
  body: Record<string, any> | Record<string, any>[],
  params?: Record<string, any>
) {
  const urlParams = getUIContext({ sjmoCode });
  urlParams.append("processId", processId);

  return api.post(`/kanban-process/launch?${urlParams}`, {
    body: Array.isArray(body) ? body : [body],
    params
  });
}

export function executeKanbanMove(
  sjmoCode: string,
  id: string,
  laneCode: string,
  body: Record<string, any> | Record<string, any>[],
  params?: Record<string, any>
) {
  return executeKanbanAction(
    sjmoCode,
    "move",
    id,
    Array.isArray(body) ? body : [body],
    params,
    laneCode
  );
}

export function executeKanbanReorder(
  sjmoCode: string,
  id: string,
  laneCode: string,
  body: Record<string, any> | Record<string, any>[],
  params?: Record<string, any>
) {
  return executeKanbanAction(
    sjmoCode,
    "reorder",
    id,
    Array.isArray(body) ? body : [body],
    params,
    laneCode
  );
}

export function executeKanbanValidationLane<T extends Pojo>(
  sjmoCode: string,
  id: string,
  laneCode: string
): AxiosPromise<T> {
  const urlParams = getUIContext({ sjmoCode });

  if (laneCode) {
    urlParams.append("laneCode", laneCode);
  }

  return api.post(`/kanban-process/validate-lane/${id}?${urlParams}`);
}

export function executeKanbanValidationGlobal<T extends Pojo>(
  sjmoCode: string,
  id: string
): AxiosPromise<T> {
  const urlParams = getUIContext({ sjmoCode });
  return api.post(`/kanban-process/validate-global/${id}?${urlParams}`);
}

export type FindDataKanbanParams = { kanbanId: string };
export function findDataKanban(
  params: FindViewParams & FindDataKanbanParams
): AxiosPromise<PagedResource<Pojo>> {
  const search = configureUrlParamsForSearch(params);

  return apiAdn.get(`/kanban/data/${params.kanbanId}/${params.tableName}?${search}`);
}
