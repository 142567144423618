import { call, put, takeLatest, select, fork, delay, putResolve } from "redux-saga/effects";
import { Task } from "redux-saga";

import { AxiosResponse } from "axios";

import { ReducerState } from "reducers";
import { DashboardFocusState } from "reducers/modules/dashboard/DashboardReducer";

import { FocusState } from "types/Focus";
import { DashboardLayout, PanelState } from "types/Dashboard";

import Action from "reducers/Action";

import { getDashboardPanels } from "api/dashboard";

import { changeFocus } from "actions/dashboard";
import toaster from "composants/toaster/toaster";
import { uuidv4 } from "utils/uuid.utils";
import { NotificationGroup } from "types/Notification";

import {
  SAVE_ALL_ADMIN,
  SET_DIRTY,
  CREATE_NEW_PANEL,
  DELETE_FOCUS_GALAXY,
  DELETE_PANEL,
  GET_ASSOCIATION_FOCUS,
  REFRESH_GALAXY_FOCUS_LIST,
  CREATE_OR_UPDATE_FOCUS_GALAXY
} from "constant/adminGalaxy";

import { initGalaxieLoading } from "actions/galaxy.action";
import { selectIsGalaxyFetched } from "selectors";
import { LOADER_TIME_TRIGGER } from "customGlobal";
import { INIT_ADMIN_GALAXY, CREATE_NEW_GALAXY } from "constant/adminGalaxy";
import {
  saveAdminCurrentFocus,
  createAdminGalaxyPanel,
  createNewGalaxy,
  createOrUpdateGalaxieFocus,
  deleteFocusGalaxyApi,
  getAdminDashboardFocus,
  getGalaxyFocusAssociations
} from "api/adminGalaxy";
import {
  FETCH_FOCUS_SUCCESS,
  FETCH_FOCUS_PANELS_SUCCESS,
  ADD_ONE_FOCUS_AND_SELECT,
  GALAXY_FOCUS_DELETED
} from "constant/dashboard";
import { findAll, deleteMany } from "api";
import { addEntity, addAllEntities } from "actions";
import { ADMIN_GALAXIE } from "admin/integrateur/AdminGalaxy/AdminGalaxy";
import { setCurrentGalaxyPropertiesId } from "actions/adminGalaxy.action";
import { Pojo } from "types/Galaxy";
import { PagedResource } from "types/Search";
import { t } from "utils/i18n";

/**
 * Permet de récupérer les focus de galaxies
 * @param action action redux
 */
function* fetchDashboardFocus(action: any) {
  try {
    const response: AxiosResponse<FocusState[]> = yield call(
      getAdminDashboardFocus,
      action.payload
    );

    yield put({
      type: FETCH_FOCUS_SUCCESS,
      payload: { focuses: response.data, sjmoCode: action.payload }
    });
  } catch (e) {
    console.log("error during fetchDashboardFocus", e);
  }
}

/**
 * Permet de récupérer les panels associés à un focus de galaxie.
 * @param action action redux
 */
function* fetchDashboardPanel(action: Action<{ sjmoCode: string; focusId: string }>) {
  try {
    const response: AxiosResponse<DashboardLayout> = yield call(
      getDashboardPanels,
      action.payload.focusId,
      action.payload.sjmoCode
    );

    yield put({
      type: FETCH_FOCUS_PANELS_SUCCESS,
      payload: {
        panels: response.data,
        focusId: action.payload.focusId,
        sjmoCode: action.payload.sjmoCode
      }
    });
  } catch {
    console.error("Erreur admin galaxie:fetchDashboardPanel");
  }
}

function* showLoadingGalaxie(sjmoCode: string) {
  yield delay(LOADER_TIME_TRIGGER());
  yield put(initGalaxieLoading(sjmoCode));
  return performance.now();
}

/**
 * Permet d'initialiser une galaxie en fonction de l'action INIT_GALAXIE
 * @param action action redux
 */
function* initGalaxyAdmin(action: Action<string>): any {
  try {
    const sjmoCode: string = action.payload;
    const isGalaxyInitialized: any = yield select(selectIsGalaxyFetched, sjmoCode);

    // si jamais la galaxie a déjà été initialisé,
    // pas besoin de refaire un appel
    if (isGalaxyInitialized) {
      return;
    }

    yield call(fetchAdminGalaxyProps, action);
    const task: Task = yield fork(showLoadingGalaxie, sjmoCode);
    // on lance la récupération des focus de la galaxie
    yield call(fetchDashboardFocus, action);

    // on récupére le focus privilégié
    const focus: DashboardFocusState = yield select((state: ReducerState) => {
      const def = state.dashboard.dashboardDefinitions[sjmoCode];
      return def && def.filter(el => el.privilegie)[0];
    });

    // si on a un focus
    if (focus) {
      // on lance la récupération des panels associés
      yield call(fetchDashboardPanel, {
        payload: { sjmoCode, focusId: focus.focusId }
      } as any);

      // on sélectionne le focus
      yield put(changeFocus(sjmoCode, focus.focusId));
    }

    if (task.isRunning()) {
      task.cancel();
    } else {
      // si le démarrage du loading est inférieur à 1s, on laisse le loader encore jusqu'à
      // 1s pour éviter de faire un flash à l'utilisateur
      const timeToDelay = 500 - (performance.now() - task.result());
      if (timeToDelay > 0) {
        yield delay(timeToDelay);
      }
    }
  } catch {
    console.error("Erreur lors de l'initialisation de la galaxie admin");
  }
}

/**
 * Permet de faire une sauvegarde total du paramétrage de la galaxie
 * @param action action redux
 */
function* saveAllAdmin(action: Action<{ sjmoCode: string }>) {
  try {
    const { sjmoCode } = action.payload;
    // on récupére le focus sélectionné
    const currentFocusId: string = yield select((state: ReducerState) => {
      return state.dashboard.selected[sjmoCode];
    });
    const panels: PanelState[] = yield select((state: ReducerState) => {
      const indexFocus = state.dashboard.dashboardDefinitions[sjmoCode].findIndex(
        ({ focusId }) => focusId === currentFocusId
      );
      return state.dashboard.dashboardDefinitions[sjmoCode][indexFocus].panels.filter(
        panel => panel.visible
      );
    });
    // si on a un focus
    if (currentFocusId) {
      // on sauvegarde la définition courante pour le focus courant
      // on demande une sauvegarde
      const response: AxiosResponse<DashboardLayout> = yield call(
        saveAdminCurrentFocus,
        sjmoCode,
        currentFocusId,
        panels
      );
    }
    toaster.notify({
      id: uuidv4(),
      group: NotificationGroup.DEFAULT,
      title: t("commun_sauvegarde_success"),
      priority: "NORMAL",
      intent: "SUCCESS",
      createdAt: new Date().toISOString()
    });
    yield put({
      type: SET_DIRTY,
      payload: {
        isDirty: false
      }
    });
  } catch {
    toaster.notify({
      id: uuidv4(),
      group: NotificationGroup.DEFAULT,
      title: t("commun_sauvegarde_error"),
      priority: "NORMAL",
      intent: "DANGER",
      createdAt: new Date().toISOString()
    });
    console.error("erreur de sauvegarde dans l'ADMIN ");
  }
}

function* fetchAdminGalaxyProps(action: Action<string>): any {
  try {
    if (action.payload && action.payload !== undefined) {
      const response = yield call(findAll, {
        sjmoCode: action.payload,
        filter: "q=syjModuleId.sjmoCode==" + action.payload,
        tableName: "syjGalaxyProperties",
        includeJoinParent: true,
        size: 1000
      });
      // on ajoute l'entité dans le reducer entities pour le module de l'admin galaxies
      yield put(
        addEntity(
          ADMIN_GALAXIE,
          "syjGalaxyProperties",
          response.data.data[0].id,
          response.data.data[0],
          true
        )
      );

      // on stocke l'id de syjGalaxyProperties dans le reducer de l'admin
      yield put(setCurrentGalaxyPropertiesId(response.data.data[0].id));
    }
  } catch {
    // todo : log to sentry
  }
}

/**
 * Permet de créer un nouveau SYJ_PANEL pour la galaxie courante
 * @param action action redux
 */
function* createNewGalaxyPanel(
  action: Action<{ sjmoCode: string; title: string; code: string; type: string; focusId: string }>
) {
  try {
    const { sjmoCode, title, code, type, focusId } = action.payload;
    const response: AxiosResponse<DashboardLayout> = yield call(
      createAdminGalaxyPanel,
      sjmoCode,
      title,
      code,
      type
    );

    if (response && response.data) {
      // on ajoute le nouveau panel au dashboard
      yield call(fetchDashboardPanel, {
        payload: { sjmoCode, focusId: focusId }
      } as any);
    }
  } catch {
    console.error("erreur durant l'ajout du panel");
  }
}

/**
 * Permet de créer un nouveau SYJ_PANEL pour la galaxie courante
 * @param action action redux
 */
function* onCreateNewGalaxy(
  action: Action<{
    galaxyTitle: string;
    newSjmoCode: string;
    newSjmoIcon: string;
    sjgaType: string;
    syjTablesId: string | null;
    callback: () => void;
  }>
) {
  try {
    const { galaxyTitle, newSjmoCode, newSjmoIcon, sjgaType, syjTablesId } = action.payload;
    const response: AxiosResponse<boolean> = yield call(
      createNewGalaxy,
      galaxyTitle,
      newSjmoCode,
      newSjmoIcon,
      sjgaType,
      syjTablesId
    );

    if (response) {
      // close and contextualize
      action.payload.callback();
    }
  } catch {
    console.error("erreur lors de la creation de la galaxie");
  }
}

function* onCreateOrUpdateFocusGalaxy(
  action: Action<{
    currentSjmoCode: string;
    id: string;
    title: string;
    position: number;
    associationId: String;
    privilegie: boolean;
    code: string;
    resourceKey: string;
    syjModuleId: string;
    sysMenuGroupeId: string;
    personnelId: string;
    callback: () => void;
  }>
) {
  try {
    const response: AxiosResponse<FocusState> = yield call(
      createOrUpdateGalaxieFocus,
      action.payload
    );

    // On set le focus dans le redux state
    yield putResolve({
      type: ADD_ONE_FOCUS_AND_SELECT,
      payload: {
        focus: response.data,
        sjmoCode: action.payload.currentSjmoCode,
        idFocus: action.payload.id
      }
    });

    // on lance la récupération des panels associés
    yield call(fetchDashboardPanel, {
      payload: { sjmoCode: action.payload.currentSjmoCode, focusId: response.data.focusId }
    } as any);
    action.payload.callback();
  } catch {
    console.error("erreur lors de la creation du focus de dashboard");
  }
}

function* onDeleteFocusGalaxy(action: Action<{ focusId: string; sjmoCode: string }>) {
  try {
    const { sjmoCode, focusId } = action.payload;
    yield call(deleteFocusGalaxyApi, focusId);
    yield put({ type: GALAXY_FOCUS_DELETED, payload: { sjmoCode, focusId } });
    const currentFocusId: number = yield select((state: ReducerState) => {
      return state.dashboard.selected[sjmoCode];
    });

    // on lance la récupération des panels associés
    yield call(fetchDashboardPanel, {
      payload: { sjmoCode: action.payload.sjmoCode, focusId: currentFocusId }
    } as any);
    toaster.notify({
      id: uuidv4(),
      group: NotificationGroup.DEFAULT,
      title: t("commun_suppression_success"),
      priority: "NORMAL",
      intent: "SUCCESS",
      createdAt: new Date().toISOString()
    });
  } catch {
    toaster.notify({
      id: uuidv4(),
      group: NotificationGroup.DEFAULT,
      title: t("commun_suppression_error"),
      priority: "NORMAL",
      intent: "DANGER",
      createdAt: new Date().toISOString()
    });
    console.error("erreur lors de la suppression du focus de dashboard");
  }
}

export function* watchDeletePanel(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    panel: PanelState;
    callback?: () => void;
  }>
) {
  try {
    const { sjmoCode, tableName, panel, callback } = action.payload;

    yield call(deleteMany, tableName, [panel.panelId], sjmoCode);

    if (callback) {
      toaster.notify({
        id: uuidv4(),
        group: NotificationGroup.DEFAULT,
        title: t("commun_suppression_success"),
        priority: "NORMAL",
        intent: "SUCCESS",
        createdAt: new Date().toISOString()
      });
      callback();
    }
  } catch {
    toaster.notify({
      id: uuidv4(),
      group: NotificationGroup.DEFAULT,
      title: t("commun_suppression_error"),
      priority: "NORMAL",
      intent: "DANGER",
      createdAt: new Date().toISOString()
    });
    console.error("erreur lors de la suppression du panel");
  }
}

function* watchGetAssociations(action: Action<{ sjmoCode: string; focusId: string }>): any {
  try {
    const { sjmoCode, focusId } = action.payload;
    const response = yield call(getGalaxyFocusAssociations, sjmoCode, focusId);
    if (response.data && response.data.GLOBAL.length > 0) {
      yield put(addAllEntities(sjmoCode, "syjFocusGlobal", response.data.GLOBAL, false));
    }
    if (response.data && response.data.ROLE.length > 0) {
      yield put(addAllEntities(sjmoCode, "syjFocusRole", response.data.ROLE, false));
    }
    if (response.data && response.data.USER.length > 0) {
      yield put(addAllEntities(sjmoCode, "syjFocusUser", response.data.USER, false));
    }
  } catch (e) {
    console.log(e);
  }
}

function* watchRefreshFocusList(action: Action<string>) {
  yield call(fetchDashboardFocus, action);
}

// export d'une liste qui est utilisé dans l'index des saga
export default [
  takeLatest(INIT_ADMIN_GALAXY, initGalaxyAdmin),
  takeLatest(SAVE_ALL_ADMIN, saveAllAdmin),
  takeLatest(CREATE_NEW_PANEL, createNewGalaxyPanel),
  takeLatest(CREATE_NEW_GALAXY, onCreateNewGalaxy),
  takeLatest(CREATE_OR_UPDATE_FOCUS_GALAXY, onCreateOrUpdateFocusGalaxy),
  takeLatest(DELETE_FOCUS_GALAXY, onDeleteFocusGalaxy),
  takeLatest(DELETE_PANEL, watchDeletePanel),
  takeLatest(GET_ASSOCIATION_FOCUS, watchGetAssociations),
  takeLatest(REFRESH_GALAXY_FOCUS_LIST, watchRefreshFocusList)
];
