import produce from "immer";
import { SyntheticEvent } from "react";

import { ComponentReadOnly, ComponentState, Item } from "types/Component";
import { Pojo } from "types/Galaxy";
import { uuidv4 } from "./uuid.utils";

/**
 * Permet d'exclure des éléments d'un object javascript
 * @param props object javasript à traiter
 * @param toExclude liste des éléments à exclure
 */
export function excludeFromProps<T>(props: T, ...toExclude: Array<keyof T>): Partial<T> {
  return produce(props, draft => {
    for (let key of toExclude) {
      delete draft[key as string];
    }
  });
}

/**
 * Retourne un boolean true / false dans le cas où il faut appliquer le readonly
 * en fonction du type de readonly à appliquer et de la version de l'entité
 * @param type type de readonly à appliquer
 * @param versionEntity n° de version de l'entité (si null on est en insert)
 */
export function getReadonlyValue(
  type: ComponentReadOnly | undefined | null,
  versionEntity: number | null
) {
  // si null, on ne bloque pas
  if (type === undefined || type === null) {
    return false;
  }

  switch (type) {
    case ComponentReadOnly.READ_ONLY:
      return true;
    case ComponentReadOnly.READ_ONLY_INSERT:
      return versionEntity === null;
    case ComponentReadOnly.READ_ONLY_UPDATE:
      return versionEntity !== null;
    case ComponentReadOnly.DEFAULT:
    default:
      return false;
  }
}

/**
 * Renvoi un objet dimension contenant les positions opitmal de placement
 * pour un élément à afficher lié à un composant.
 * Exemple le contextMenu.
 *
 * Cete fonction prend en compte la taille de l'écran et le scroll.
 *
 * @export
 * @param {SyntheticEvent<any>} event
 * @returns
 */
export function getDimensionFromEvent(event: SyntheticEvent<any>, isInsideDialog: boolean = false) {
  const dimension = event.currentTarget.getBoundingClientRect();
  return getPositionOverlay(dimension, isInsideDialog);
}

export function getPositionOverlay(dimension: DOMRect, isInsideDialog: boolean = false) {
  const scrollOffset = isInsideDialog
    ? 0
    : window.scrollY || (document.documentElement ? document.documentElement.scrollTop : 0);
  return {
    x: dimension.left,
    y: dimension.bottom + scrollOffset,
    width: dimension.width,
    height: dimension.height
  };
}

/**
 * Permet de récupérer les options liée au composants que l'on récupère
 * (les options diffèrent en fonction du compo : "I", "C")
 *
 * @export
 * @param {ComponentState} compo définition du composant
 * @returns options du composant
 */
export function getOptionsByType(compo: ComponentState) {
  return compo.options || {};
}

export function createCustomComponentState(
  column: string,
  typeCompo: string,
  label: string,
  items: Item[],
  mandatory: boolean = false,
  options?: Record<string, any>
): ComponentState {
  return {
    id: uuidv4(),
    column,
    typeCompo,
    label,
    labelSize: 100,
    position: 3,
    readOnly: ComponentReadOnly.DEFAULT,
    disabled: ComponentReadOnly.DEFAULT,
    mandatory,
    mask: "",
    joinTableName: "",
    joinListFields: "",
    joinListFiltre: "",
    joinDisplayedFields: "",
    wvi: false,
    additionalClause: undefined as any,
    compoVisible: true,
    contentSize: 100,
    sysDomaineChoices: items,
    options: options
  };
}

/**
 * Permet de vérifier si tout les champs obligatoires d'un formulaire ont été saisis
 *
 * @export
 * @template S
 * @template F
 * @param {(Pojo | Partial<Pojo>)} entity
 * @param {ComponentState[]} fields
 * @param {() => S} onSuccess
 * @param {(label: string) => F} onFailure
 * @returns {(S | F)}
 */
export function validateForm<S, F>(
  entity: Pojo | Partial<Pojo>,
  fields: ComponentState[],
  onSuccess: () => S,
  onFailure: (label: string) => F
): S | F {
  return onSuccess();
  /*
  Méthode annulé en attendant validation en réunion

  const unfilledMandatoryFields: string[] = [];
  for (const field of fields.filter(f => f.mandatory)) {
    if (entity[field.column] === null || entity[field.column] === undefined) {
      unfilledMandatoryFields.push(field.label);
    }
  }

  if (unfilledMandatoryFields.length === 0) {
    return onSuccess();
  } else {
    let label = "";

    for (let i = 0; i < unfilledMandatoryFields.length; i++) {
      if (i === 0) {
        label = unfilledMandatoryFields[i];
      } else {
        label += `, ${unfilledMandatoryFields[i]}`;
      }
    }

    return onFailure(label);
  }
  */
}
