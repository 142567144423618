import produce from "immer";
import {
  HANDLE_API_MESSAGE,
  REMOVE_NOTIFICATION,
  REMOVE_ALL_NOTIFICATIONS,
  REMOVE_NOTIFICATION_BY_ID
} from "constant/common";
import Action from "reducers/Action";
import { Message } from "types/Message";

export type MessageReducerState = Array<Message>;

/**
 * Action lancé lorsque l'on a un ajout de(s) message(s) depuis l'api
 */
interface ActionHandleApiMessage {
  type: "HANDLE_API_MESSAGE";
  payload: Message;
}

/**
 * Action lancé lorsque l'utilisateur clique sur fermer le message
 */
interface ActionRemoveNotification {
  type: "REMOVE_NOTIFICATION";
  payload: number;
}

interface ActionRemoveNotificationById {
  type: "REMOVE_NOTIFICATION_BY_ID";
  payload: string;
}

interface ActionRemoveAllNotifications {
  type: "REMOVE_ALL_NOTIFICATIONS";
}

type ActionAll =
  | ActionHandleApiMessage
  | ActionRemoveNotification
  | ActionRemoveNotificationById
  | ActionRemoveAllNotifications;

export default function reducer(state: MessageReducerState = [], action: ActionAll) {
  switch (action.type) {
    // case HANDLE_API_MESSAGE:
    //   return [...state, action.payload];
    case REMOVE_NOTIFICATION:
      return produce(state, draft => {
        draft.splice(action.payload, 1);
      });
    case REMOVE_NOTIFICATION_BY_ID:
      return produce(state, draft => {
        const index = draft.findIndex(el => el.id === action.payload);
        if (index !== -1) {
          draft.splice(index, 1);
        }
      });
    case REMOVE_ALL_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
}
