import { NavigationDefinition } from "constant/navigation";
import { ProcessusNatureValue } from "./Processus";

export type NotificationPriority = "TEMPORARY" | "LOW" | "NORMAL" | "HIGH" | "CRITICAL";
export type NotificationIntent = "DEFAULT" | "DANGER" | "WARNING" | "SUCCESS" | "INFO";

export interface NotificationActionLink {
  type: "LINK";
  label: string;
  params: NavigationDefinition;
}

export interface NotificationActionDirectLink {
  type: "DIRECT_LINK";
  label: string;
  url: string;
}

export interface NotificationActionProcess {
  type: "PROCESSUS";
  params: NotificationProcess;
}

interface NotificationProcess {
  sjmoCode: string;
  tableName: string;
  processId: string;
  entities: string[];
  label: string;
  nature: ProcessusNatureValue;
  apercu?: boolean;
  rapide?: boolean;
}

export interface NotificationActionDispatch {
  type: "DISPATCH";
  label: string;
  params: NotificationDispatchParam;
}

interface NotificationDispatchParam {
  type: string;
  payload: Record<string, any>;
}

export type AllNotificationAction =
  | NotificationActionLink
  | NotificationActionDirectLink
  | NotificationActionProcess
  | NotificationActionDispatch;

export interface Notification {
  id: string;
  group: NotificationGroup;
  priority: NotificationPriority;
  intent: NotificationIntent;
  title: string;
  message?: string | React.ReactNode;
  actions?: AllNotificationAction[];
  createdAt: string;
  validatedAt?: string;
  validatedBy?: string;
  copies?: string[];
}

export enum NotificationGroup {
  DEFAULT = "DEFAULT",
  EDITION = "EDITION",
  METIER = "METIER",
  INFO = "INFO"
}
