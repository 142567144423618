import { call, put, takeLatest } from "redux-saga/effects";

import { AxiosResponse, AxiosError } from "axios";

import { FocusState } from "types/Focus";

import Action from "reducers/Action";

import { getAdminMiniExpertFocus } from "api/miniexpert";

import {
  FETCH_ADMIN_MINI_EXPERT_FOCUS_SUCCESS,
  INIT_ADMIN_MINI_EXPERT,
  INIT_ADMIN_MINI_EXPERT_TREE,
  GET_MINI_EXPERT_TREE_FOCUS_SUCCESS,
  TREE_ON_INSERT_NODE_MINI_EXPERT,
  TREE_ON_MOVE_NODE_MINI_EXPERT,
  TREE_ON_DELETE_NODE_MINI_EXPERT,
  ADMIN_MINI_EXPERT_TREE_MODULE,
  ADMIN_MINI_EXPERT_TREE_PANEL_ID,
  ADMIN_MINI_EXPERT_SAVE_SUCESS,
  ADMIN_MINI_EXPERT_SAVE_ERROR,
  ADMIN_MINI_EXPERT_SAVE_PICKLIST,
  ADMIN_EXPERT_MINI_DELETE_FOCUS
} from "constant/adminMiniExpert";
import {
  getMiniExpertTreeData,
  callAdminMiniExpertOnInsertNode,
  callAdminMiniExpertOnMoveNode,
  callAdminMiniExpertOnDeleteNode,
  deleteMiniExpertFocusApi
} from "api/adminMiniExpert";
import { FlatTreeNode, RoughTreeData } from "types/Tree";
import { Message } from "types/Message";
import { t } from "utils/i18n";
import { addMessage } from "actions/messages";
import { TREE_ON_CRUD_NODE_EXPERT_ERROR, TREE_ON_CRUD_NODE_EXPERT_SUCESS } from "constant/tree";
import { savePickListResult } from "actions";
import { Pojo } from "types/Galaxy";

/**
 * Permet de récupérer les focus de mini expert dans le cadre de l'admin
 * @param action action redux
 */
function* initAdminMiniExpert(
  action: Action<{
    sjtaId: number;
  }>
) {
  try {
    const response: AxiosResponse<FocusState[]> = yield call(
      getAdminMiniExpertFocus,
      action.payload.sjtaId
    );

    yield put({
      type: FETCH_ADMIN_MINI_EXPERT_FOCUS_SUCCESS,
      payload: { focuses: response.data }
    });
  } catch {
    console.error("fetch focus mini expert admin saga failed");
  }
}

function* initAdminMiniExpertTree(
  action: Action<{
    sjmoCode: string;
    focusId: string;
  }>
) {
  const { sjmoCode, focusId } = action.payload;
  try {
    const focus: AxiosResponse<{ treeData: FlatTreeNode[]; treeId: number }> = yield call(
      getMiniExpertTreeData,
      sjmoCode,
      focusId
    );

    yield put({ type: GET_MINI_EXPERT_TREE_FOCUS_SUCCESS, payload: focus.data });
  } catch {
    console.error("fetch tree data mini expert admin saga failed");
  }
}

function* doOnMoveNodeMiniExpert(
  action: Action<{
    current: RoughTreeData;
    nextParent: RoughTreeData;
    nextIndex: number;
    sjmoCode: string;
    focusId: string;
  }>
) {
  yield call(onExpertActionApi, action, callAdminMiniExpertOnMoveNode);
}

function* doOnInsertNodeMiniExpert(
  action: Action<{
    current: RoughTreeData;
    nextParent: RoughTreeData;
    nextIndex: number;
    sjmoCode: string;
    focusId: string;
  }>
) {
  yield call(onExpertActionApi, action, callAdminMiniExpertOnInsertNode);
}

function* doOnDeleteNodeMiniExpert(
  action: Action<{
    current: RoughTreeData;
    sjmoCode: string;
    focusId: string;
  }>
) {
  yield call(onExpertActionApi, action as any, callAdminMiniExpertOnDeleteNode);
}

/**
 * Fonction généric pour gérer les action CRUD sur l'arborescence
 * des Experts
 *
 * A voir s'il est possible de gérer les arborescence classiques ici aussi lors de leur développement
 *
 * @param {Action<{
 *     current: RoughTreeData;
 *     nextParent: RoughTreeData;
 *     nextIndex: number;
 *     sjmoCode: string;
 *   }>} action
 * @param {string} success
 * @param {string} error
 * @param {*} apiFunction
 * @returns
 */
function* onExpertActionApi(
  action: Action<{
    current: RoughTreeData;
    nextParent: RoughTreeData;
    nextIndex: number;
    sjmoCode: string;
    focusId: string;
  }>,
  apiFunction: any
): any {
  try {
    const result: any = yield call(apiFunction, action.payload);
    // Message OK
    const message: Message = {
      code: result.data.message.code,
      message: t(result.data.message.message),
      type: result.data.message.type,
      target: result.data.message.target
    };

    yield put(addMessage(message));

    // Dans le cas de admin expert sjmoCode est toujours EXPERT sur le reducer state
    // Et panelId est toujours 0 sur le reducer state
    yield put({
      type: TREE_ON_CRUD_NODE_EXPERT_SUCESS,
      payload: {
        flatTreeData: result.data.treeData,
        sjmoCode: ADMIN_MINI_EXPERT_TREE_MODULE,
        panelId: ADMIN_MINI_EXPERT_TREE_PANEL_ID
      }
    });
  } catch (e) {
    const er = e as AxiosError<any>;
    if (!er.response) {
      return;
    }

    if (er.response.data.message.message) {
      const message: Message = {
        code: er.response.data.message.code,
        message: er.response.data.message.message,
        type: er.response.data.message.type,
        target: er.response.data.message.target
      };
      yield put(addMessage(message));

      yield put({
        type: TREE_ON_CRUD_NODE_EXPERT_ERROR,
        payload: {
          flatTreeData: er.response.data.treeData,
          sjmoCode: ADMIN_MINI_EXPERT_TREE_MODULE,
          panelId: ADMIN_MINI_EXPERT_TREE_PANEL_ID
        }
      });
    }
  }
}

function* watchSaveMiniExpertPickList(
  action: Action<{
    sjmoCode: string;
    tableName: string;
    chosen: Pojo[];
    oldIdToDelete: string[];
  }>
) {
  const { sjmoCode, tableName, chosen, oldIdToDelete } = action.payload;
  try {
    yield put(savePickListResult(sjmoCode, tableName, chosen, oldIdToDelete));
    yield put({ type: ADMIN_MINI_EXPERT_SAVE_SUCESS, payload: undefined });
  } catch (e) {
    console.log(e);
    yield put({ type: ADMIN_MINI_EXPERT_SAVE_ERROR, payload: undefined });
  }
}

function* watchDeleteFocus(action: Action<{ id: number; callback: () => void }>) {
  try {
    yield call(deleteMiniExpertFocusApi, action.payload.id);
    if (action.payload.callback) {
      yield call(action.payload.callback);
    }
  } catch (e) {
    yield put({ type: ADMIN_MINI_EXPERT_SAVE_ERROR, payload: undefined });
  }
}

// export d'une liste qui est utilisé dans l'index des saga
export default [
  takeLatest(INIT_ADMIN_MINI_EXPERT, initAdminMiniExpert),
  takeLatest(INIT_ADMIN_MINI_EXPERT_TREE, initAdminMiniExpertTree),
  takeLatest(TREE_ON_INSERT_NODE_MINI_EXPERT, doOnInsertNodeMiniExpert),
  takeLatest(TREE_ON_MOVE_NODE_MINI_EXPERT, doOnMoveNodeMiniExpert),
  takeLatest(TREE_ON_DELETE_NODE_MINI_EXPERT, doOnDeleteNodeMiniExpert),
  takeLatest(ADMIN_MINI_EXPERT_SAVE_PICKLIST, watchSaveMiniExpertPickList),
  takeLatest(ADMIN_EXPERT_MINI_DELETE_FOCUS, watchDeleteFocus)
];
