import { AxiosPromise, AxiosError, AxiosResponse } from "axios";

import { api, getUIContext, apiUtils } from "./common";
import {
  EditionState,
  ProcessusReturnStruct,
  ProcessusDefinition,
  ProcessusJobStatus,
  ProcessusAdvancedFormInit,
  ProcessusAdvancedFormInitData,
  ProcessusResult
} from "types/Processus";
import { ComponentGroupState } from "types/ComponentGroup";

import { Pojo, WhenValidateItemResult } from "types/Galaxy";
import { SyjProcessusCompositePlanification } from "composants/processus/Planification";

// Fonction qui récupère la liste des focus d'un expert de galaxie
export function getProcessusDefinition(
  sjmoCode: string,
  tableName?: string
): AxiosPromise<ProcessusDefinition[]> {
  const params = getUIContext({ sjmoCode });

  if (tableName) {
    params.append("table", tableName);
  }
  return api.get(`/process/menu/table?${params}`);
}

export function getProcessusDefinitionColumn(
  sjmoCode: string,
  tableName?: string,
  column?: string
) {
  const params = getUIContext({ sjmoCode });

  if (tableName) {
    params.append("table", tableName);
  }

  if (column) {
    params.append("column", column);
  }

  return api.get(`/process/menu/column?${params}`);
}

export function getProcessusDefinitionByCompositeId(
  sjmoCode: string,
  compositeId: string
): AxiosPromise<ProcessusDefinition | null> {
  const params = getUIContext({ sjmoCode });

  return api.get(`/process/menu/composite/${compositeId}?${params}`);
}

export function executeProcessus(
  module: string,
  compositeID: string,
  selected: Pojo[],
  optional: { executeAt?: string; modeEdition?: string } = {}
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode: module });
  if (optional.executeAt) params.append("executeAt", optional.executeAt);
  if (optional.modeEdition) params.append("modeEdition", optional.modeEdition);

  return api.post(`/process/${compositeID}/execute?${params}`, selected);
}

export function executeProcessusAdvanced(
  module: string,
  compositeID: string,
  formData: ProcessusAdvancedFormInitData,
  optional: { executeAt?: string; modeEdition?: string } = {}
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode: module });
  if (optional.executeAt) params.append("executeAt", optional.executeAt);
  if (optional.modeEdition) params.append("modeEdition", optional.modeEdition);

  return api.post(`/process/${compositeID}/execute-advanced?${params}`, formData);
}

export function initProcessusAdvanced(
  module: string,
  compositeID: string,
  selected: Pojo[]
): AxiosPromise<ProcessusAdvancedFormInit> {
  const params = getUIContext({ sjmoCode: module });

  return api.post(`/process/${compositeID}/init-advanced?${params}`, selected);
}

export function checkProcessusExecution(jobID: string): AxiosPromise<ProcessusJobStatus> {
  return api.get(`/process/${jobID}/status`);
}

export function fetchProcessusResult(jobID: string): AxiosPromise<ProcessusResult> {
  return api.get(`/process/${jobID}/result`);
}

export function cancelProcessusExecution(jobID: string): AxiosPromise<ProcessusJobStatus> {
  return api.post(`/process/${jobID}/cancel`);
}

// Fonction qui lance un traitement
export function launchProcess(
  sjmoCode: string,
  sjipId: string,
  tableName: string,
  entityIds: number[],
  urlCplt: string,
  navigationUrl?: string
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode });
  params.append("sjipId", sjipId.toString());
  params.append("tableName", tableName);

  if (urlCplt) {
    params.append("urlOrigine", urlCplt);
  }
  const parametersStr = entityIds.filter(p => p).join(",");
  params.append("entityIds", parametersStr);

  if (navigationUrl) {
    params.append("navigationUrl", navigationUrl);
  }

  return api.get(`/process/traitement?${params}`);
}

// Fonction qui calcul si un processus d'édition a besoin de paramètres additionnels
export function hasAdditionnalParam(
  sjmoCode: string,
  sjipId: string,
  tableName: string,
  entityIds: string[],
  urlCplt: string,
  mode: string,
  navigationUrl?: string
): AxiosPromise<string> {
  const params = getUIContext({ sjmoCode });
  params.append("sjipId", sjipId.toString());
  params.append("tableName", tableName);

  if (urlCplt) {
    params.append("urlOrigine", urlCplt);
  }
  const parametersStr = entityIds.filter(p => p).join(",");
  params.append("entityIds", parametersStr);

  if (navigationUrl) {
    params.append("navigationUrl", navigationUrl);
  }

  params.append("modeEdition", mode);

  return api.get(`/process/paramadd?${params}`);
}

// Fonction qui lance un traitement avancé
export function launchAdvancedProcess(
  sjmoCode: string,
  sjipId: string,
  tableName: string,
  id: number,
  paramsAdd: Partial<Pojo>,
  navigationUrl?: string
): AxiosPromise<ProcessusReturnStruct[]> {
  const params = getUIContext({ sjmoCode });
  params.append("sjipId", sjipId.toString());

  if (tableName) {
    params.append("tableName", tableName);
  }

  if (id) {
    params.append("entityId", id.toString());
  }
  if (navigationUrl) {
    params.append("navigationUrl", navigationUrl);
  }
  return api.post(`/process/traitementAvance?${params}`, paramsAdd);
}

/**
 * Fonction qui lance un traitement depuis l'entete d'une liste générique
 */
export function launchProcessFromGenericList(
  sjmoCode: string,
  sjipId: string,
  selectGenericEntites: Pojo[],
  columnName: string | null
): AxiosPromise<string> {
  const urlParams = getUIContext({ sjmoCode });
  if (sjipId) {
    urlParams.append("sjipId", sjipId.toString());
  }

  if (columnName) {
    urlParams.append("columnName", columnName);
  }
  return api.post(`/process/traitementListGen?${urlParams}`, selectGenericEntites);
}

// Fonction qui lance un traitement avancé sur une liste d'entités contextuelles
// Sert exclusivement lorsque la case appliquer à tous est cochée dans la dialogue des paramètres avancés
// NavigationUrl n'est à utilisé que dans le cas d'une navigation qui n'est pas vers une galaxie
export function launchAdvancedProcessMulti(props: {
  sjmoCode: string;
  sjipId: string;
  entities: Partial<Pojo>[];
  paramsAdd: Partial<Pojo>[];
  navigationUrl?: string;
}): AxiosPromise<ProcessusReturnStruct[]> {
  const { sjmoCode, sjipId, entities, paramsAdd, navigationUrl } = props;
  const params = getUIContext({ sjmoCode });
  params.append("sjipId", sjipId.toString());

  if (navigationUrl) {
    params.append("navigationUrl", navigationUrl);
  }
  return api.post(`/process/traitementAvanceMulti?${params}`, {
    paramsAdd: paramsAdd,
    entities: entities
  });
}

// Fonction qui lance un traitement
// NavigationUrl n'est à utilisé que dans le cas d'une navigation qui n'est pas vers une galaxie
export function getParamsAvanceDefinition(
  sjmoCode: string,
  sjipId: string,
  tableName: string,
  ids: string
): AxiosPromise<ComponentGroupState[]> {
  const params = getUIContext({ sjmoCode });
  params.append("sjipId", sjipId.toString());
  params.append("tableName", tableName);

  if (ids) {
    params.append("id", ids.toString());
  }
  return api.get(`/process/avance/definition?${params}`);
}

/**
 * Méthode qui permet de valider les paramètres additionnels du traitement (wvi spécifique au traitement).
 * Elle appelle l'api et s'occupe de filtrer le message d'erreur si jamais
 * l'appel échoue.
 *
 * @param  paramsAdd: liste des paramètres additionnels avec leurs valeurs actuelles
 * @param tableName nom de la table
 * @param ctrlKey colonne d'origine de la modif
 * @param entity entité à valider
 * @param onSuccess fonction lorsque l'appel est correct
 * @param onError fonction lorsque la validation retourne une erreur
 */
export function validateParamAvance(
  paramsAdd: Partial<Pojo>,
  sjipId: string,
  ctrlValid: string,
  ctrlKey: string,
  onSuccess: (success: AxiosResponse<Partial<Pojo>>) => void,
  onError: (error: WhenValidateItemResult<Pojo>) => void
) {
  const params = getUIContext({ column: ctrlKey });
  params.append("compositeId", sjipId.toString());
  params.append("ctrlValid", ctrlValid);
  return api
    .post(`/process/traitement-advanced/wvi?${params}`, paramsAdd)
    .then(res => {
      onSuccess(res);
    })
    .catch((res: AxiosError<WhenValidateItemResult<Pojo>>) => {
      const data = res.response && res.response.data;
      if (data && data.message.target === "FIELD") {
        onError(data);
      } else {
        throw res;
      }
    });
}

// Fonction qui lance un traitement
export function resetPackage(): AxiosPromise<string> {
  const params = getUIContext();
  return api.post(`/process/resetPackage?${params}`);
}

// Fonction qui lance une édition
export function launchEdition(
  sjmoCode: string,
  sjipId: string,
  modeEdition: string,
  tableName: string,
  entities: Partial<Pojo>,
  paramsAdd: Partial<Pojo>
): AxiosPromise<EditionState> {
  const params = getUIContext({ sjmoCode });
  params.append("sjipId", sjipId.toString());
  params.append("modeEdition", modeEdition);
  params.append("tableName", tableName);

  return api.post(`/process/edition?${params}`, {
    paramAdd: paramsAdd,
    entities: entities
  });
}

// Récupère une édition
export function getEditionDocument(
  sjmoCode: string,
  path: string
): AxiosPromise<ComponentGroupState[]> {
  const params = getUIContext({ sjmoCode });
  params.append("path", path);

  return apiUtils.get(`/document?${params}`);
}

export function saveAndSchedule(
  sjmoCode: string,
  sjih: SyjProcessusCompositePlanification
): AxiosPromise<SyjProcessusCompositePlanification> {
  const params = getUIContext({ sjmoCode });
  return api.post(`/schedule/process?${params}`, sjih);
}

export function deleteSchedule(
  sjmoCode: string,
  sjih: SyjProcessusCompositePlanification
): AxiosPromise<String> {
  const params = getUIContext({ sjmoCode });
  params.append("planificationId", sjih.id as string);
  return api.delete(`/schedule/process?${params}`);
}
