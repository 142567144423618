import React, { useContext, useMemo, useEffect, useState } from "react";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { makeSelectContextAsDetailList } from "selectors/context.selector";
import { ReducerState } from "reducers";
import isEqual from "lodash-es/isEqual";
import { Pojo } from "types/Galaxy";
import { getColumnDefinitionByTableAndColumn } from "api/column";
import { getCacheKey, getAutocompleteCache } from "composants/autocomplete/autocomplete-cache";
import { findOneView } from "api";
import { InteractionValuesByField } from "reducers/modules/InteractionReducer";

export const DataInteractionContext = React.createContext<Pojo | null>(null);

type InteractionType = "default" | "dataPreferred";

interface InteractionProps {
  sjmoCode: string;
  ctrlKey: string;
  type?: InteractionType;
  tableName?: string;
}

interface ChooseInteraction {
  isEligible(data: Pojo | null, interaction: InteractionValuesByField): boolean;
  get(data: Pojo | null, interaction: InteractionValuesByField): Promise<any>;
}

class ValueParent implements ChooseInteraction {
  isEligible(_: Pojo | null, interaction: InteractionValuesByField): boolean {
    return interaction.valueParent != null;
  }
  async get(_: Pojo | null, interaction: InteractionValuesByField): Promise<any> {
    return interaction.valueParent;
  }
}

class DataParent implements ChooseInteraction {
  constructor(private tableName?: string) {}

  isEligible(data: Pojo | null, interaction: InteractionValuesByField): boolean {
    return data != null && interaction.fieldMaster !== null;
  }

  async get(data: Pojo | null, interaction: InteractionValuesByField): Promise<any> {
    if (data == null || interaction.fieldMaster == null) return null;

    const fieldData = data[interaction.master];

    // si c'est nul, on s'en fou, on peut directement mettre l'interaction à null.
    // on ne ne trouvera de toute façon pas de jointure
    if (fieldData === null) {
      return null;
    }
    // si c'est l'id, on peut directement positionner la valeur depuis l'entité principale.
    else if (interaction.fieldMaster === "id") {
      return fieldData;
    } else if (this.tableName) {
      // on récupère le tableName de la jointure
      const joinTableName = await getColumnDefinitionByTableAndColumn(
        this.tableName,
        interaction.master
      ).then(res => res.data);

      if (!joinTableName) {
        return fieldData;
      }

      // si on a en cache, on utilise le cache
      // sinon on fait un find one pour récupérer la valeur
      let cache = getAutocompleteCache(getCacheKey(joinTableName, fieldData));

      if (cache == null && data[interaction.fieldMaster] !== undefined) {
        return fieldData;
      } else if (cache === null) {
        try {
          const resJoinData = await findOneView({ tableName: joinTableName, id: fieldData });
          cache = resJoinData.data;
        } catch {
          console.warn(
            `ignored interaction values for unmatching entity ${joinTableName}=${fieldData} foreign key of ${this.tableName}=${data.id}`
          );
        }
      }
      if (cache != null) {
        return cache[interaction.fieldMaster];
      }
    } else {
      return fieldData;
    }
  }
}

export default function useInteractions({
  sjmoCode,
  ctrlKey,
  tableName,
  type = "default"
}: InteractionProps) {
  const contextAsDetailSelector = useMemo(() => makeSelectContextAsDetailList(), []);
  const selectorContext = useCallback(
    (redux: ReducerState) => {
      return contextAsDetailSelector(redux, {
        sjmoCode: sjmoCode,
        ctrlKey: ctrlKey.split("--")[0], // on est obligé de split à cause des datatables qui ont des ID avec l'ID de l'entité en params
        tableName: tableName
      });
    },
    [contextAsDetailSelector, ctrlKey, sjmoCode, tableName]
  );

  const interactions = useSelector(selectorContext, (a, b) => isEqual(a, b));
  const data = useContext(DataInteractionContext);

  const [calculated, setInteractionsMap] = useState<Record<string, any>>({});

  useEffect(() => {
    async function calculateInteraction() {
      let interactionMap: Record<string, any> = {};

      let generateInteractions: ChooseInteraction[] = [
        new ValueParent(),
        new DataParent(tableName ?? "")
      ];
      if (type === "dataPreferred") {
        // dans le cas du dataPreferred, on ne souhaite pas prendre en compte ValueParent
        // parce que la valeur associé et globale et n'est pas contextualisé pour l'entité courante
        // de cette intéraction. Ce qui fait qu'on pourrait par exemple
        // contextualiser une ligne différente de celle en cours à cause des contexualize lancé dans
        // les composants.
        generateInteractions = [new DataParent(tableName ?? "")];
      }

      for (let interaction of interactions) {
        interactionMap[interaction.fieldDetails] = null;

        for (let choose of generateInteractions) {
          if (choose.isEligible(data, interaction)) {
            const result = await choose.get(data, interaction);
            interactionMap[interaction.fieldDetails] = result;
            if (result != null) break;
          }
        }
      }

      return interactionMap;
    }

    calculateInteraction().then(map => setInteractionsMap(map));
  }, [data, interactions, tableName, type]);

  return calculated;
}
