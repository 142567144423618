import React, {
  FC,
  useRef,
  useMemo,
  CSSProperties,
  HTMLAttributes,
  useContext,
  SyntheticEvent,
  useEffect,
  ChangeEvent,
  useState
} from "react";
import classNames from "classnames";

import { TableColumn } from "composants/datatable/TableColumn";
import { ComponentState, InteractiveReportConfiguration } from "types/Component";
import Table, { BreakRow } from "composants/datatable/Table";
import BreakRowDialog from "composants/datatable/dialog/BreakRowDialog";
import Toolbar from "composants/datatable/Toolbar";
import { Fa } from "composants/Icon";
import GroupByDialog from "composants/datatable/dialog/GroupByDialog";
import { formatDate, formatNumber, t } from "utils/i18n";
import { LinkProps } from "react-router-dom";
import ComputedColumnDialog from "composants/datatable/dialog/ComputedColumnDialog";
import AggregateDialog from "composants/datatable/dialog/AggregateDialog";
import HighlightDialog, { isHighlighted } from "composants/datatable/dialog/HighlightDialog";
import Control from "composants/form/Control";
import { Field } from "composants/form";
import { DropdownColumn } from "./custom-component/DropdownColumn";
import { DropdownFilterColumn } from "./custom-component/DropdownFilterColumn";
import { Menu } from "composants/DropDown/Menu";
import { SaveDialog } from "composants/datatable/dialog/SaveDialog";
import { createCustomComponentState } from "utils/component.utils";
import { LineChart, Line, ResponsiveContainer } from "recharts";
import { ReducerState } from "reducers";
import { HeaderDynamicTable, DistinctListSuggestion } from "./custom-component/HeaderDynamicTable";
import {
  useInteractiveReports,
  getInteractiveReportCacheKey,
  deleteInteractiveReportCache,
  IR_MINIMUM_BATCH_SIZE
} from "./hook/useInteractiveReport";
import { convertValue, checkEntityValid } from "utils/entities.utils";
import { format } from "date-fns";
import ProcessusLink from "composants/processus/ProcessusLink";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import { Pojo } from "types/Galaxy";
import { Trans, useTranslation } from "react-i18next";
import { FilterDialog } from "composants/datatable/dialog/FilterDialog";
import { GalaxieContext } from "containers/galaxy/GalaxieContext";
import { uuidv4 } from "utils/uuid.utils";
import { AdminCreateDialog } from "composants/datatable/dialog/AdminCreateDialog";
import { AdminUpdateDialog } from "composants/datatable/dialog/AdminUpdateDialog";
import { track } from "tracking";
import { Button } from "composants/button";
import ModalAdvanceMode from "admin/integrateur/AdminInteractiveReport/ModalAdvanceMode";
import { useCallback } from "react";
import { contextualize } from "actions/contextTreeModule";
import { useDispatch, useSelector } from "react-redux";
import {
  ProcessusDefinitionNature,
  ProcessusDefinition,
  ProcessusAffectation
} from "types/Processus";
import { useNavigate, Link } from "react-router-dom";
import { tw } from "twind";
import { Notice } from "@axin-org/comet";
import { GalaxieListenerCallbackContext } from "containers/galaxy/GalaxieContextListener";
import Axios from "axios";
import { useEvent } from "hooks/useEvent";

interface GlobalCompoProps {
  value: any;
  index: number;
  name: string;
  rowIndex: number;
  colIndex: number;
}

const TextCompo: FC<GlobalCompoProps & HTMLAttributes<HTMLSpanElement>> = ({
  value,
  rowIndex,
  colIndex,
  ...props
}) => {
  return <span {...props}>{value}</span>;
};

const NumberCompo: FC<GlobalCompoProps &
  HTMLAttributes<HTMLSpanElement> & { formatOptions: Intl.NumberFormatOptions }> = ({
  value,
  rowIndex,
  colIndex,
  formatOptions,
  ...props
}) => {
  const result = useMemo(() => {
    return formatNumber(value, formatOptions);
  }, [value, formatOptions]);

  if (value === null || value === undefined) {
    return null;
  } else {
    return <span {...props}>{result}</span>;
  }
};

const DateCompo: FC<GlobalCompoProps & HTMLAttributes<HTMLSpanElement>> = ({
  value,
  rowIndex,
  colIndex,
  ...props
}) => {
  const result = useMemo(() => {
    return formatDate(value);
  }, [value]);
  return <span {...props}>{result}</span>;
};

const LinkCompo: FC<GlobalCompoProps &
  LinkProps & { label?: string; customValue?: any; navType?: "int" | "ext" }> = ({
  value,
  customValue,
  label,
  rowIndex,
  colIndex,
  className = "link",
  navType = "int",
  ...props
}) => {
  const selectionEntityId = customValue !== undefined ? customValue : value;

  if (navType === "int") {
    return (
      <Link
        {...props}
        className={className}
        to={props.to + "/" + encodeURIComponent(selectionEntityId)}
      >
        {label || value}
      </Link>
    );
  } else if (navType === "ext") {
    return (
      <a
        {...props}
        className={className}
        href={props.to + "/" + encodeURIComponent(selectionEntityId)}
        target="_blank"
        rel="noreferrer"
      >
        {label || value}
      </a>
    );
  } else {
    return (
      <Notice intent="danger">
        erreur configuration navType = {navType}, "int" et "ext" uniquement supporté
      </Notice>
    );
  }
};

const LineChartCompo: FC<GlobalCompoProps> = ({ value }) => {
  const data = JSON.parse(value);

  return (
    <ResponsiveContainer>
      <LineChart data={data}>
        <Line type="monotone" dataKey="value" stroke="#8884d8" strokeWidth={2} dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

const StaticHTMLCompo: FC<GlobalCompoProps> = ({ value }) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  useEffect(() => {
    const container = divRef.current;
    function onClickElement(e: MouseEvent) {
      if (e.target && e.target instanceof HTMLAnchorElement) {
        let href = e.target.href;
        const origin = `${window.location.protocol}//${window.location.host}`;

        // si c'est un navigation locale, on bloque le behavior par défaut
        // puis on substring le host pour ne push que le pathname à react-router.
        if (href.includes(origin)) {
          e.preventDefault();
          href = href.substring(origin.length);
        }
        navigate(href);
      }
    }

    if (container) {
      container.addEventListener("click", onClickElement);
    }

    return () => {
      container?.removeEventListener("click", onClickElement);
    };
  });

  return (
    <div
      ref={divRef}
      dangerouslySetInnerHTML={{ __html: value }}
      className="content"
      style={{ width: "100%", height: "100%" }}
    />
  );
};

const InputChangeContext = React.createContext<(index: number, name: string, value: any) => void>(
  () => {
    console.warn("provider for the input change is not set");
  }
);

const InputCompo: FC<HTMLAttributes<HTMLInputElement> & GlobalCompoProps & { type?: string }> = ({
  index,
  name,
  value,
  className,
  type = "text",
  rowIndex,
  colIndex,
  ...props
}) => {
  const context = useContext(InteractiveReportNavigationContext);

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (context == null) return;

    function executeNavigationMove(k: string, r: number, c: number) {
      const element = document.querySelector<HTMLInputElement>(
        `[data-navigation-key='${k}'][data-navigation-row='${r}'][data-navigation-column='${c}']`
      );

      if (element) {
        element.focus();
      }
    }
    // on inverse le behavior du standard HTML.
    // Cela veut dire que si ctrlKey est actif en même temps, on laisse le navigateur gérer l'event
    // donc dans le cas d'un input type number, `ctrl+ArrowUp  permet d'incrémenter le nombre.
    if (event.ctrlKey) {
      // si jamais on appuie également sur Control, on souhaite l'ancien behavior
      return;
    }

    let row: number = -1;
    let column: number = -1;

    if (event.key === "ArrowDown") {
      let maxRow = context.totalRecords - 1;

      row = Math.min(rowIndex + 1, maxRow);
      column = colIndex;

      // TODO
    } else if (event.key === "ArrowUp") {
      row = Math.max(rowIndex - 1, 0);
      column = colIndex;
    }

    if (row !== -1 && column !== -1) {
      event.preventDefault();
      requestAnimationFrame(() => {
        executeNavigationMove(context.navigationKey, row, column);
      });
    }
  }

  const onChange = useContext(InputChangeContext);

  function onChangeValue(e: SyntheticEvent<HTMLInputElement>) {
    onChange(index, e.currentTarget.name, convertValue(e));
  }

  let valueToUse: any = value;

  if (type === "date") {
    valueToUse = format(value, "YYYY-MM-DD");
  }

  return (
    <input
      {...props}
      className={classNames("input", className)}
      name={name}
      value={valueToUse || ""}
      type={type}
      onChange={onChangeValue}
      onKeyDown={onKeyDown}
      data-navigation-key={context ? context.navigationKey : uuidv4()}
      data-navigation-row={rowIndex}
      data-navigation-column={colIndex}
    />
  );
};

const TagCompo: FC<GlobalCompoProps & HTMLAttributes<HTMLDivElement>> = ({
  rowIndex,
  colIndex,

  ...props
}) => {
  return (
    <span
      {...props}
      className={classNames("tag", props.className)}
      style={{ minWidth: "3em", ...props.style }}
    >
      {props.value}
    </span>
  );
};

const BooleanCompo: FC<GlobalCompoProps &
  HTMLAttributes<HTMLDivElement> & {
    activeClassName?: string;
    inactiveClassName?: string;
    undefinedClassName: string;
  }> = ({
  activeClassName = "is-light is-success",
  inactiveClassName = "is-light is-info",
  undefinedClassName = "is-light",
  ...props
}) => {
  const [t] = useTranslation();

  let booleanValue: boolean | null = null;
  const value = props.value;
  let traductionKey = "";
  if (typeof value == "string") {
    if (value === "O") {
      traductionKey = "commun_oui";
      booleanValue = true;
    } else if (value === "N") {
      traductionKey = "commun_non";
      booleanValue = false;
    }
  } else if (typeof value == "boolean") {
    if (value === true) {
      traductionKey = "commun_oui";
      booleanValue = true;
    } else if (value === false) {
      traductionKey = "commun_non";
      booleanValue = false;
    }
  }

  let className = classNames(
    {
      [undefinedClassName]: booleanValue === null,
      [activeClassName]: booleanValue === true,
      [inactiveClassName]: booleanValue === false
    },
    props.className
  );

  const translatedValue = t(traductionKey, { defaultValue: value });

  return <TagCompo {...props} className={className} value={translatedValue} />;
};

type InteractiveReportProcessusLinkProps = {
  sjmoCode: string;
  target: string;
};

const ProcessusInlineRefreshContext = React.createContext<Function | undefined>(undefined);

const ProcessusLinkCompo: FC<GlobalCompoProps & {
  id: string;
  type: ProcessusDefinitionNature;
  label: string;
  rapide?: boolean;
  apercu?: boolean;
  isAdvanced?: boolean;
} & InteractiveReportProcessusLinkProps> = ({ rowIndex, colIndex, ...props }) => {
  const providerOptions: InteractiveReportProcessusLinkProps & {
    selectedEntities?: string[];
  } = JSON.parse(props.value);

  const galaxyProps = useContext(GalaxieContext);

  const callback = useContext(ProcessusInlineRefreshContext);

  const defition = useMemo(() => {
    return {
      id: props.id,
      type: props.type,
      label: props.label,
      affectation: ProcessusAffectation.GLOBAL,
      needEntity: true,
      apercu: props.type === "edition" ? props.apercu : undefined,
      rapide: props.type === "edition" ? props.rapide : undefined,
      forAll: props.type === "traitement" ? false : undefined,
      isAdvanced: props.isAdvanced ? props.isAdvanced : false,
      isOneByOne: false
    } as ProcessusDefinition;
  }, [props.apercu, props.id, props.label, props.rapide, props.type]);

  return (
    <ProcessusProvider
      sjmoCode={galaxyProps.sjmoCode || providerOptions.sjmoCode || props.sjmoCode || "DEFAULT"}
      tableName={providerOptions.target || props.target || "default"}
      selected={providerOptions.selectedEntities}
      onAfterSaveProcess={() => callback?.()}
    >
      <ProcessusLink {...props} definition={defition} canDelayProcess />
    </ProcessusProvider>
  );
};

const SysDomaineCompo: FC<HTMLAttributes<HTMLSpanElement> &
  GlobalCompoProps & { labelColumn: string }> = ({
  colIndex,
  index,
  name,
  rowIndex,
  value,
  children,
  labelColumn,
  ...props
}) => {
  const { t } = useTranslation("sd");
  return <span {...props}>{t(labelColumn) ?? value}</span>;
};

const VALID_COMPO = {
  TEXT: TextCompo,
  NUMBER: NumberCompo,
  DATE: DateCompo,
  LINK: LinkCompo,
  LINE_CHART: LineChartCompo,
  STATIC_HTML: StaticHTMLCompo,
  INPUT: InputCompo,
  TAG: TagCompo,
  PROCESSUS_LINK: ProcessusLinkCompo,
  BOOLEAN: BooleanCompo,
  SYS_DOMAINE: SysDomaineCompo
};

interface InteractiveReportProps {
  sjmoCode: string;
  definitionId: string;
  isAdmin?: boolean;
  isProcessusActive?: boolean;
  onAfterSaveDatatable?(): void;
  getActiveFocus?(childData: any): void;
  onChangeSelectedEntities?(e: Pojo[]): void;
}

const SELECTION_TABLE = createCustomComponentState(
  "ROW_SELECT",
  "CUSTOM_CHECKBOX",
  "SELECTIONS",
  []
);

const InteractiveReportContext = React.createContext<ComponentState[]>([]);

const InteractiveReportNavigationContext = React.createContext<{
  navigationKey: string;
  totalRecords: number;
} | null>(null);

const InteractiveReport: FC<InteractiveReportProps> = ({
  sjmoCode,
  definitionId,
  isAdmin,
  isProcessusActive,
  onAfterSaveDatatable,
  getActiveFocus,
  onChangeSelectedEntities
}) => {
  const [advanceMode, setAdvanceMode] = useState<Boolean>(false);
  const uniqueIdRef = useRef(uuidv4());
  const tableCtrlKey = "interactiveReport1DevisClient";

  const [selectedRow, setSelectedRow] = useState<number | null>(null);
  const tableRef = useRef<Table>(null);
  const inputSearchRef = useRef<HTMLInputElement | null>(null);
  const {
    state,
    interactions,
    computedState,
    dialog,
    columnsAction,
    dataAction,
    searchAction,
    aggregatesAction,
    breakRowsAction,
    computedColumnAction,
    groupByAction,
    highlightsAction,
    sortAction,
    focusAction,
    selectionsAction,
    exportData,
    admin
  } = useInteractiveReports(sjmoCode, definitionId, inputSearchRef, tableRef);

  const groupByFnColumn = useMemo(() => {
    return state.groupBy.functionList.map(fnList => {
      return createCustomComponentState(fnList.label.toLowerCase(), "NUMBER", fnList.label, []);
    });
  }, [state.groupBy.functionList]);

  const columns = useMemo(() => {
    return [
      ...state.columns.filter(
        col =>
          col.compoVisible === true &&
          // si on a pas de group-by, les colonnes actives sont toutes les visibles
          // sinon, il faut que la colonne soit présente dans la liste du group-by
          (state.groupBy.columns.length <= 0 || state.groupBy.columns.indexOf(col.column) !== -1)
      ),
      ...groupByFnColumn
    ];
  }, [state.columns, state.groupBy.columns, groupByFnColumn]);

  const oldColumn = useRef<ComponentState[]>(columns);
  useEffect(() => {
    if (columns.length !== oldColumn.current.length && tableRef.current) {
      tableRef.current.recomputeGrids();
      oldColumn.current = columns;
    }
  }, [columns]);

  const columnsWithNumber = useMemo(() => {
    return state.columns.filter(col => col.isNumber);
  }, [state.columns]);

  const targetColumnComponentState = useMemo(() => {
    return columns.find(col => col.column === state.search.targetColumn);
  }, [state.search.targetColumn, columns]);

  const aggregatesSubTotal = useMemo(() => {
    return state.aggregates.map(aggr => {
      const colAggr = state.columns.find(col => col.column === aggr.column);

      return {
        id: aggr.id,
        label:
          t("commun_aggrate_function_" + aggr.functionType.toLowerCase()) +
          (colAggr ? " " + colAggr.label : ` (${aggr.column})`)
      };
    });
  }, [state.aggregates, state.columns]);

  const hasStaticHTML = useMemo(
    () => columns.filter(col => col.typeCompo === "STATIC_HTML").length > 0,
    [columns]
  );

  const breakRowsWithLabels = useMemo(() => {
    let breakForTable: BreakRow[] = [];
    for (let currentBreak of state.breakRows) {
      const col = state.columns.find(col => col.column === currentBreak);
      breakForTable.push({ col: currentBreak, label: col ? col.label : currentBreak });
    }
    return breakForTable;
  }, [state.breakRows, state.columns]);

  const navigationContext = useMemo(() => {
    return {
      navigationKey: uniqueIdRef.current,
      totalRecords: state.totalRecords
    };
  }, [state.totalRecords]);

  const hasCustomFilter = useMemo(() => {
    return state.search.filters.filter(it => it.type === "normal").length > 0;
  }, [state.search.filters]);

  function toggleVisibilityColumn(e: React.MouseEvent<HTMLAnchorElement>) {
    const targetColumn = e.currentTarget.dataset.value;

    if (targetColumn === "DESELECT_ALL") {
      columnsAction.hideAllColumns();
      track("ir::columns::visibility", { type: "hidden", columns: "ALL" });
    } else if (targetColumn === "SELECT_ALL") {
      columnsAction.showAllColumns();
      track("ir::columns::visibility", { type: "visible", columns: "ALL" });
    } else {
      const index = state.columns.findIndex(col => col.column === targetColumn);
      columnsAction.toggleVisibility(index);
      track("ir::columns::visibility", { type: "toggle", columns: targetColumn });
    }
  }

  const callback = useContext(GalaxieListenerCallbackContext);
  const fetchDataInterval = useEvent(dataAction.fetchDataInternal);
  useEffect(() => {
    const source = Axios.CancelToken.source();

    function refreshCallback() {
      fetchDataInterval(undefined, source);
    }

    const unregister = callback(refreshCallback);
    return () => {
      unregister();
      source.cancel();
    };
  }, [callback, fetchDataInterval]);

  const hasLaunchAProcessRef = useRef(false);
  useEffect(() => {
    // le useEffect suivant sert à invalider le cache de l'interactive report
    // suite a un processus.
    // le code ci-dessous est **toujours** exécuté avant le onAfterProcess.
    // donc, à chaque re-render, on dit que l'on a pas eu d'appel a un processus.
    hasLaunchAProcessRef.current = false;

    // le cleanup est exécuté **avant** le prochain re-render qui va exécuter le code ci-dessus
    // Le code du dessus n'est exécuté que si l'on a un re-render. Si le composant est unmount,
    // seulement le code du dessous va être exécuter.
    return () => {
      // si on a exécuté un processus avant le cleanup.
      // on va supprimer le cache de l'IR. De toute façon le code est invalide.
      // l'astuce vient du fait que, dans useInteractiveReport, on set le cache
      // à chaque changement de l'état de notre composant.
      // donc si on passe ici sans unmount le composant,
      // le refetch des données va de toute façon remettre en place un cache à jour.
      //
      // Voir le dessin ci-dessous :
      //  +----------------------------------------------------------------------------------------------------------------------->>
      //  |                                                 durée de vie du composant
      //  +----------------------------------------------------------------------------------------------------------------------->>
      //              render                        render                        unmount                       render
      //  +-----------------------------+-----------------------------+-----------------------------+-----------------------------+
      //  |          get gache          |  si launchproc =true clear  |  si launchproc =true clear  |          get gache          |
      //  +-----------------------------+-----------------------------+-----------------------------+-----------------------------+
      //  |     launchproc = false      |     launchproc = false      |                             |     launchproc = false      |
      //  +-----------------------------+-----------------------------+-----------------------------+-----------------------------+
      //  |       update cache          |       update cache          |                             |       update cache          |
      //  +-----------------------------+-----------------------------+-----------------------------+-----------------------------+
      //
      // Comme on peut le voir sur le dessin,
      // l'ordre de render permet de garantir que le cache est à jour.
      // et après un unmount, si le launchproc était true, le prochain render n'aura pas de cache.
      // on peut donc garantir que les données seront correctement à jour.
      if (hasLaunchAProcessRef.current) {
        const key = getInteractiveReportCacheKey(sjmoCode, definitionId);
        deleteInteractiveReportCache(key);
      }
    };
  });

  function onAfterProcess() {
    hasLaunchAProcessRef.current = true;
    dataAction.fetchDataInternal().then(() => {
      // suite a un process, le nombre de valeurs / type de donnée / break peuvent changer.
      // il faut recalculer la grille
      tableRef.current && tableRef.current.recomputeBody();
      onAfterSaveDatatable && onAfterSaveDatatable();
    });
  }

  function deleteEtat() {
    if (focusAction.highlightDelete) {
      if (state.focus[state.activeFocus]) {
        const focusId = state.focus[state.activeFocus].id;
        if (focusId && focusId !== undefined) {
          track("ir::etat::delete", { status: "CONFIRMED" });
          focusAction.onFocusDelete(definitionId, focusId);
          focusAction.changeHighlightDelete(false);
        }
      }
    } else {
      track("ir::etat::delete", { status: "HIGHLIGHT" });
      focusAction.changeHighlightDelete(true);
    }
  }

  useEffect(() => {
    if (state.focus !== undefined && state.activeFocus !== undefined && getActiveFocus) {
      getActiveFocus(state.focus[state.activeFocus]);
    }
  }, [state.activeFocus, state.focus]);

  const fakeTableName = state.code || state.tableName || "default";
  const reduxDispatch = useDispatch();
  const onRowClick = useCallback(
    (rowIndex: number) => {
      const entity = state.entities[rowIndex];
      if (entity !== "LOADING_POJO") {
        reduxDispatch(
          contextualize(sjmoCode, fakeTableName, `interactiveReport:${definitionId}`, entity)
        );
      }
      setSelectedRow(rowIndex);
    },
    [definitionId, fakeTableName, reduxDispatch, sjmoCode, state.entities]
  );

  const { selectedIndexes, selectedEntities } = useMemo(() => {
    let selectedEntities: Pojo[] = [];

    for (let index of state.selectedRows) {
      if (state.selectedRows.includes(index)) {
        const entity = state.entities[index];
        checkEntityValid(entity) && selectedEntities.push(entity);
      }
    }

    return { selectedIndexes: state.selectedRows, selectedEntities };
  }, [state.entities, state.selectedRows]);

  useEffect(() => {
    if (onChangeSelectedEntities !== undefined) {
      onChangeSelectedEntities(selectedEntities);
    }
  }, [selectedEntities]);

  function selectLangAndSuperUser({ userSettings }: ReducerState) {
    return { lang: userSettings.lang, adminLevel: userSettings.adminLevel };
  }

  const { adminLevel } = useSelector(selectLangAndSuperUser);

  return (
    <ProcessusProvider
      sjmoCode={sjmoCode || "DEFAULT"}
      tableName={fakeTableName}
      selected={selectedEntities}
      onAfterSaveProcess={onAfterProcess}
    >
      <InputChangeContext.Provider value={dataAction.onChangeValue}>
        <InteractiveReportContext.Provider value={columns}>
          <InteractiveReportNavigationContext.Provider value={navigationContext}>
            <Table
              minimumBatchSize={Math.round(IR_MINIMUM_BATCH_SIZE / 4)}
              threshold={Math.round(IR_MINIMUM_BATCH_SIZE / 3)}
              ref={tableRef}
              showDirty={false}
              entities={state.entities}
              totalRecords={state.totalRecords}
              loadMoreData={dataAction.loadMoreData}
              breakRowColumn={breakRowsWithLabels}
              breakSubtotals={aggregatesSubTotal}
              onReorderColumnEnd={columnsAction.onReorderColumn}
              selectedRows={selectedIndexes}
              selectedRow={selectedRow}
              autoHeight
              toolbar={
                state.selectedRows.length > 0 ? (
                  <Toolbar
                    sjmoCode={sjmoCode}
                    tableName={state.tableName}
                    tableCtrlKey={tableCtrlKey}
                  >
                    <Toolbar.Left>
                      <Toolbar.Item className="has-text-weight-semibold">
                        <Trans
                          i18nKey="commun_lignes_selectionnees"
                          values={{ nb: state.selectedRows.length, total: state.totalRecords }}
                        />
                      </Toolbar.Item>
                      {isProcessusActive === false ? (
                        <></>
                      ) : (
                        <Toolbar.Item>
                          {state.groupBy.columns.length <= 0 &&
                            state.groupBy.functionList.length <= 0 && <Toolbar.ProcessMenu />}
                        </Toolbar.Item>
                      )}
                      <Toolbar.Item>
                        <button
                          className="button is-link is-inverted"
                          onClick={() => {
                            track("ir::unselect::all");
                            selectionsAction.onRowUnselect("ALL");
                          }}
                        >
                          <Trans i18nKey="commun_annuler" />
                        </button>
                      </Toolbar.Item>
                    </Toolbar.Left>
                  </Toolbar>
                ) : (
                  <Toolbar
                    sjmoCode={sjmoCode}
                    tableName={state.tableName}
                    tableCtrlKey={tableCtrlKey}
                  >
                    <Toolbar.Left>
                      <Toolbar.Item>
                        <Field addons>
                          <Control>
                            <DropdownColumn
                              columns={state.columns}
                              selectedColumn={targetColumnComponentState}
                              onClick={searchAction.changeTargetColumn}
                            />
                          </Control>
                          <Control>
                            <input
                              ref={inputSearchRef}
                              className="input"
                              value={state.search.term}
                              onChange={searchAction.onChangeSearchTerm}
                              onKeyDown={searchAction.onKeyDownSearchTerm}
                            />
                          </Control>
                          <Control>
                            <button className="button" onClick={searchAction.executeSearch}>
                              <span>OK</span>
                              <span className="icon is-small">
                                <Fa icon="sync" fixedWidth size="sm"></Fa>
                              </span>
                            </button>
                          </Control>
                        </Field>
                      </Toolbar.Item>
                      <Toolbar.Item>
                        <Field addons>
                          <Control>
                            <DropdownFilterColumn
                              columns={state.columns}
                              isAdmin={isAdmin}
                              onClick={toggleVisibilityColumn}
                              disabled={state.groupBy.columns.length > 0}
                            />
                          </Control>
                          <Control>
                            <Menu autoclose>
                              <Menu.Button className="button is-link is-inverted">
                                <span className="icon">
                                  <Fa icon="rocket" className="has-text-link" />
                                </span>
                                <span>{t("commun_actions")}</span>
                                <span className="icon">
                                  <Fa icon="angle-down" />
                                </span>
                              </Menu.Button>
                              <Menu.Overlay>
                                <Menu.Item as="a" onClick={dialog.breakRows.toggle}>
                                  <span className="icon">
                                    <Fa icon="outdent" className="has-text-link" />
                                  </span>
                                  <span>{t("commun_ajouter_une_rupture")}</span>
                                </Menu.Item>
                                <Menu.Item as="a" onClick={dialog.groupBy.toggle}>
                                  <span className="icon">
                                    <Fa icon={["fas", "object-group"]} className="has-text-link" />
                                  </span>
                                  <span>{t("commun_ajouter_un_regroupement")}</span>
                                </Menu.Item>
                                <Menu.Item
                                  as="a"
                                  className="has-text-grey" /*onClick={dialog.computedColumn.toggle}*/
                                >
                                  <span className="icon">
                                    <Fa icon="calculator" className="has-text-grey" />
                                  </span>
                                  <span>{t("commun_calculer")}</span>
                                </Menu.Item>
                                <Menu.Item as="a" onClick={dialog.aggregate.toggle}>
                                  <span className="icon">
                                    <Fa icon="sigma" className="has-text-link" />
                                  </span>
                                  <span>{t("commun_agreger")}</span>
                                </Menu.Item>
                                <Menu.Item as="a" onClick={dialog.highlights.toggle}>
                                  <span className="icon">
                                    <Fa icon="highlighter" className="has-text-link" />
                                  </span>
                                  <span>{t("commun_highlight")}</span>
                                </Menu.Item>
                                <Menu.Item as="a" onClick={dialog.filter.toggle}>
                                  <span className="icon">
                                    <Fa icon="filter" className="has-text-link" />
                                  </span>
                                  <span>{t("commun_filtrer")}</span>
                                </Menu.Item>
                                <Menu.Separator />
                                <Menu.Item as="a" onClick={() => exportData("excel")}>
                                  <span className="icon">
                                    <Fa icon="file-excel" className="has-text-link" />
                                  </span>
                                  <span>
                                    <Trans i18nKey="commun_export_excel" />
                                  </span>
                                </Menu.Item>
                                <Menu.Item as="a" onClick={() => exportData("pdf")}>
                                  <span className="icon">
                                    <Fa icon="file-pdf" className="has-text-link" />
                                  </span>
                                  <span>
                                    <Trans i18nKey="commun_export_pdf" />
                                  </span>
                                </Menu.Item>
                                <Menu.Separator />
                                {sjmoCode !== "ADMIN_I_R" && (
                                  <Menu.Item as="a" onClick={dialog.save.toggle}>
                                    <span className="icon">
                                      <Fa icon="cloud-upload" className="has-text-link" />
                                    </span>
                                    <span>{t("commun_enregistrer_etat")}</span>
                                  </Menu.Item>
                                )}
                                {sjmoCode === "ADMIN_I_R" && adminLevel > 0 ? (
                                  <>
                                    <Menu.Item
                                      as="a"
                                      onClick={
                                        state.activeFocus !== -1
                                          ? dialog.AdminSave.toggle
                                          : undefined
                                      }
                                    >
                                      <span className="icon">
                                        <Fa
                                          icon="cloud-upload"
                                          className={
                                            state.activeFocus !== -1
                                              ? "has-text-link"
                                              : tw("text-gray-500")
                                          }
                                        />
                                      </span>
                                      <span>{t("commun_modifier_etat")}</span>
                                    </Menu.Item>
                                    <Menu.Item as="a" onClick={dialog.AdminCreate.toggle}>
                                      <span className="icon">
                                        <Fa icon="plus" className="has-text-link" />
                                      </span>
                                      <span>{t("commun_creer_etat")}</span>
                                    </Menu.Item>
                                    <Menu.Item as="a" onClick={deleteEtat}>
                                      <span className="icon">
                                        <Fa
                                          icon="trash"
                                          className={
                                            focusAction.highlightDelete === false
                                              ? "has-text-link"
                                              : "has-text-danger"
                                          }
                                        />
                                      </span>
                                      <span
                                        className={
                                          focusAction.highlightDelete === true
                                            ? "has-text-danger"
                                            : undefined
                                        }
                                      >
                                        {t(
                                          focusAction.highlightDelete === false
                                            ? "commun_supprimer_etat"
                                            : "commun_sur_de_suprimer"
                                        )}
                                      </span>
                                    </Menu.Item>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </Menu.Overlay>
                            </Menu>
                          </Control>
                        </Field>
                      </Toolbar.Item>
                      <Toolbar.Item>
                        <FocusInteractiveReport
                          focus={state.focus}
                          activeFocus={state.activeFocus}
                          onFocusChange={focusAction.onFocusChange}
                        />
                      </Toolbar.Item>
                      <Toolbar.Item>
                        {hasCustomFilter ? (
                          <a onClick={searchAction.clearFilters} title={t("commun_vider")}>
                            <span className="icon">
                              <Fa icon="filter" />
                            </span>
                          </a>
                        ) : null}
                      </Toolbar.Item>
                      {sjmoCode === "ADMIN_I_R" && (
                        <Toolbar.Item>
                          <Button
                            disabled={state.focus.length > 0 ? false : true}
                            onClick={() => setAdvanceMode(true)}
                          >
                            Avancé
                          </Button>
                        </Toolbar.Item>
                      )}
                    </Toolbar.Left>
                  </Toolbar>
                )
              }
              onRowClick={onRowClick}
            >
              <TableColumn
                column={SELECTION_TABLE}
                width={50}
                component={VALID_COMPO.TEXT}
                renderHeaderCell={({ key, style, className }) => {
                  return (
                    <div
                      key={key}
                      className={classNames(className, "cursor-pointer")}
                      style={style}
                    >
                      <input
                        ref={el => {
                          const isIndeterminate =
                            state.selectedRows.length > 0 &&
                            state.selectedRows.length < state.totalRecords;

                          if (el) el.indeterminate = isIndeterminate;
                        }}
                        type="checkbox"
                        checked={
                          state.selectedRows &&
                          state.totalRecords > 0 &&
                          state.selectedRows.length === state.totalRecords
                        }
                        className="cursor-pointer"
                        style={{
                          width: 20,
                          height: 20
                        }}
                        onChange={() => selectionsAction.toggleRowSelect("ALL")}
                      />
                    </div>
                  );
                }}
              >
                {({ className, style, rowIndex, colorRow }) => {
                  const entity = state.entities[rowIndex];
                  const isRowSelected = state.selectedRows.includes(rowIndex);

                  return (
                    <div
                      className={className}
                      style={{ ...style, cursor: "pointer" }}
                      data-state-row={colorRow}
                      onClick={() => {
                        checkEntityValid(entity) && selectionsAction.toggleRowSelect(rowIndex);
                        onRowClick(rowIndex);
                      }}
                    >
                      <input
                        tabIndex={-1}
                        style={{
                          width: 20,
                          height: 20,
                          cursor: "pointer"
                        }}
                        type="checkbox"
                        checked={isRowSelected}
                        onChange={() =>
                          checkEntityValid(entity) && selectionsAction.toggleRowSelect(rowIndex)
                        }
                      />
                    </div>
                  );
                }}
              </TableColumn>
              {columns.map((col, i) => {
                // on récupère la taille, soit de la largeur
                const width = computedState.customColumnsSize[col.column]
                  ? computedState.customColumnsSize[col.column]
                  : col.contentSize;

                return (
                  <TableColumn
                    key={col.column}
                    column={col}
                    component={VALID_COMPO[col.typeCompo] || VALID_COMPO.TEXT}
                    width={width}
                    renderHeaderCell={({ columnIndex, className, style, key, parent }) => {
                      const currentOrder = state.sortBy.find(sort => sort.column === col.column);
                      const isBreakActive =
                        state.breakRows.find(breakRow => breakRow === col.column) !== undefined;
                      return (
                        <HeaderDynamicTable
                          key={key}
                          index={columnIndex}
                          className={className}
                          style={style}
                          width={Math.max(150, width)}
                          order={
                            col.typeCompo !== "STATIC_HTML"
                              ? currentOrder && currentOrder.sort
                              : undefined
                          }
                          isBreakRow={isBreakActive}
                          disabledBreakRow={col.typeCompo === "STATIC_HTML"}
                          onClickBreak={() => {
                            if (state.breakRows.findIndex(c => c === col.column) !== -1) {
                              breakRowsAction.validateBreakRows(
                                state.breakRows.filter(c => c !== col.column)
                              );
                            } else {
                              breakRowsAction.validateBreakRows([...state.breakRows, col.column]);
                            }
                          }}
                          onClickAsc={() => {
                            if (col.typeCompo !== "STATIC_HTML") {
                              sortAction.toggleSort(state.sortBy, "ASC", col.column);
                            }
                          }}
                          onClickDesc={() => {
                            if (col.typeCompo !== "STATIC_HTML") {
                              sortAction.toggleSort(state.sortBy, "DESC", col.column);
                            }
                          }}
                          onResize={deltaX => {
                            columnsAction.onResizeColumn(
                              col.column,
                              deltaX,
                              (parent as any).props.width
                            );
                          }}
                          label={col.label}
                        >
                          {/* on désactive la suggestion de donnée lors d'un group by car cela génère des erreurs lors de la génération de la query */}
                          {state.groupBy.functionList.length === 0 && col.typeCompo !== "DATE" ? (
                            <DistinctListSuggestion
                              width={Math.max(150, width)}
                              tableName={state.tableName}
                              column={col.column}
                              columns={state.columns}
                              search={state.search}
                              interactions={interactions}
                              onSelectFilter={searchAction.onSelectFilter}
                              isStaticHTML={col.typeCompo === "STATIC_HTML"}
                            />
                          ) : null}
                        </HeaderDynamicTable>
                      );
                    }}
                  >
                    {({ className, data, style, rowIndex, columnIndex }) => {
                      const highlightsLine = state.highlights.filter(h => {
                        const highlightColumn = columns.find(c => c.column === h.column);
                        return (
                          h.typeHighlight === "line" &&
                          isHighlighted(
                            (highlightColumn && highlightColumn.typeCompo) || "",
                            data,
                            h
                          )
                        );
                      });
                      const selectedLineHighlight = highlightsLine.length > 0 && highlightsLine[0];

                      const highlightsColumn = state.highlights.filter(h => {
                        return (
                          h.typeHighlight === "column" &&
                          h.column === col.column &&
                          isHighlighted(col.typeCompo, data, h)
                        );
                      });
                      const selectedColumnHighlight =
                        highlightsColumn.length > 0 && highlightsColumn[0];

                      let customStyle: CSSProperties = style;
                      if (selectedLineHighlight) {
                        if (selectedLineHighlight.backgroundColor) {
                          customStyle["--highlight-row"] = selectedLineHighlight.backgroundColor;
                          // customStyle.backgroundColor = selectedLineHighlight.backgroundColor;
                        }
                        if (selectedLineHighlight.color) {
                          customStyle["--highlight-row-font"] = selectedLineHighlight.color;
                          // customStyle.color = selectedLineHighlight.color;
                        }
                      }

                      if (selectedColumnHighlight) {
                        if (selectedColumnHighlight.backgroundColor) {
                          customStyle.backgroundColor = selectedColumnHighlight.backgroundColor;
                        }
                        if (selectedColumnHighlight.color) {
                          customStyle.color = selectedColumnHighlight.color;
                        }
                      }

                      const Selected = VALID_COMPO[col.typeCompo] || VALID_COMPO.TEXT;

                      const options = { ...col.options };
                      const keyOptions = Object.keys(options);

                      for (let key of keyOptions) {
                        if (data[options[key]] !== undefined) {
                          options[key] = data[options[key]];
                        }
                      }

                      return (
                        <div
                          className={className}
                          style={customStyle}
                          onClick={() => onRowClick(rowIndex)}
                        >
                          <ProcessusInlineRefreshContext.Provider value={onAfterProcess}>
                            <Selected
                              {...options}
                              value={data[col.column]}
                              index={rowIndex}
                              name={col.column}
                              rowIndex={rowIndex}
                              colIndex={columnIndex}
                            />
                          </ProcessusInlineRefreshContext.Provider>
                        </div>
                      );
                    }}
                  </TableColumn>
                );
              })}
            </Table>
            {advanceMode === true && (
              <ModalAdvanceMode
                onClose={() => {
                  setAdvanceMode(false);
                  admin.resetAfterColumnUpdate();
                }}
                interactiveReportId={definitionId}
                sjmoCode={sjmoCode}
                columns={state.columns}
                focus={state.focus[state.activeFocus]}
              />
            )}
            {dialog.breakRows.isOpen && (
              <BreakRowDialog
                sjmoCode={sjmoCode}
                columns={columns.filter(col => col.typeCompo !== "STATIC_HTML")}
                initialBreakRow={state.breakRows}
                onClose={dialog.breakRows.toggle}
                onValidate={breakRowsAction.validateBreakRows}
              />
            )}
            {dialog.groupBy.isOpen && (
              <GroupByDialog
                columns={state.columns.filter(col => col.typeCompo !== "STATIC_HTML")}
                initialGroupBy={state.groupBy.columns}
                initialFunctionList={state.groupBy.functionList}
                onClose={dialog.groupBy.toggle}
                onValidate={groupByAction.validateGroupBy}
              />
            )}
            {dialog.computedColumn.isOpen && (
              <ComputedColumnDialog
                columns={columns.filter(col => col.typeCompo !== "STATIC_HTML")}
                computedColumns={state.computedColumns}
                onValidate={computedColumnAction.validateComputedColumn}
                onClose={dialog.computedColumn.toggle}
                onDelete={computedColumnAction.deleteComputedColumn}
                onClear={computedColumnAction.deleteAllComputedColumn}
              />
            )}
            {dialog.aggregate.isOpen && (
              <AggregateDialog
                columns={columnsWithNumber}
                aggregates={state.aggregates}
                onClose={dialog.aggregate.toggle}
                onValidate={aggregatesAction.validateAggregate}
                onDelete={aggregatesAction.deleteAggregate}
                onClear={aggregatesAction.deleteAllAggregate}
              />
            )}
            {dialog.highlights.isOpen && (
              <HighlightDialog
                columns={columns.filter(col => col.typeCompo !== "STATIC_HTML")}
                highlights={state.highlights}
                onValidate={highlightsAction.validateHighlights}
                onClose={dialog.highlights.toggle}
                onDelete={highlightsAction.deleteHighlights}
                onClear={highlightsAction.deleteAllHighlights}
              />
            )}
            {dialog.save.isOpen && (
              <SaveDialog
                currentFocus={
                  state.activeFocus !== -1 && state.focus[state.activeFocus].typeFocus === "user"
                    ? state.focus[state.activeFocus]
                    : null
                }
                onValidate={focusAction.onSave}
                onDelete={focusAction.onDelete}
                onClose={dialog.save.toggle}
              />
            )}
            {dialog.AdminCreate.isOpen && (
              <AdminCreateDialog
                currentFocus={state.activeFocus !== -1 ? state.focus[state.activeFocus] : null}
                onValidate={focusAction.onAdminCreate}
                onDelete={focusAction.onDelete}
                onClose={dialog.AdminCreate.toggle}
              />
            )}
            {dialog.AdminSave.isOpen && (
              <AdminUpdateDialog
                currentFocus={
                  state.activeFocus !== -1 &&
                  (state.focus[state.activeFocus].typeFocus === "global" ||
                    state.focus[state.activeFocus].isGlobal === true)
                    ? state.focus[state.activeFocus]
                    : null
                }
                onValidate={focusAction.onAdminUpdate}
                onDelete={focusAction.onDelete}
                onClose={dialog.AdminSave.toggle}
              />
            )}
            {dialog.filter.isOpen && (
              <FilterDialog
                isAdmin={isAdmin}
                columns={state.columns}
                filters={state.search.filters}
                onValidate={filters => {
                  searchAction.setFilters(filters);
                  dialog.filter.toggle();
                }}
                onClose={dialog.filter.toggle}
              />
            )}
          </InteractiveReportNavigationContext.Provider>
        </InteractiveReportContext.Provider>
      </InputChangeContext.Provider>
    </ProcessusProvider>
  );
};

const FocusInteractiveReport: FC<{
  focus: InteractiveReportConfiguration[];
  activeFocus: number;
  onFocusChange(index: number): void;
}> = props => {
  const { global, user, otherUser } = useMemo(() => {
    let global: { label: string; index: number }[] = [];
    let user: { label: string; index: number }[] = [];
    let otherUser: { label: string; index: number }[] = [];

    for (let [index, f] of props.focus.entries()) {
      const entry = { index, label: f.label };
      if (f.typeFocus === "global") {
        global.push(entry);
      } else {
        if (f.isUserFocus) {
          user.push(entry);
        } else {
          otherUser.push(entry);
        }
      }
    }
    return {
      global,
      user,
      otherUser
    };
  }, [props.focus]);

  function onFocusChange(e: ChangeEvent<HTMLSelectElement>) {
    const index = parseInt(e.currentTarget.value, 10);
    props.onFocusChange(index);
    track("ir::focus::change", { focusId: props.focus[index]?.id ?? "<unknown>" });
  }

  return props.focus.length > 0 ? (
    <div
      className="select"
      style={{ maxWidth: "15rem" }}
      title={
        props.activeFocus !== -1 && props.focus[props.activeFocus]
          ? props.focus[props.activeFocus].label
          : ""
      }
    >
      <select value={props.activeFocus} onChange={onFocusChange}>
        {user.map(userFocus => {
          return (
            <option key={userFocus.index} value={userFocus.index}>
              {userFocus.label}
            </option>
          );
        })}

        {otherUser.length > 0 && (
          <optgroup label={t("commun_interactive_report_focus_other_user")}>
            {otherUser.map(focusOtherUser => {
              return (
                <option key={focusOtherUser.index} value={focusOtherUser.index}>
                  {focusOtherUser.label}
                </option>
              );
            })}
          </optgroup>
        )}

        {global.length > 0 && (
          <optgroup label={t("commun_interactive_report_focus_global")}>
            {global.map(focusGlobal => {
              return (
                <option key={focusGlobal.index} value={focusGlobal.index}>
                  {focusGlobal.label}
                </option>
              );
            })}
          </optgroup>
        )}
      </select>
    </div>
  ) : null;
};

export default InteractiveReport;
