import React, { Component, CSSProperties } from "react";
import { Field } from "./form";

interface WithLabelProps {
  isHorizontal: boolean;
  wviState?: string;
  label: string | JSX.Element | JSX.Element[];
  wviMessage?: string;
  required?: boolean;
  styleLabel?: CSSProperties;
  labelSize?: number;
}

type WithLabelOptions = { passLabel: boolean; passRequired: boolean };
const defaultOptions: WithLabelOptions = { passLabel: false, passRequired: true };
export function withLabel<P extends object>(
  userOptions: Partial<WithLabelOptions> | undefined = undefined
) {
  const options = { ...defaultOptions, userOptions };
  return (WrappedComponent: React.ComponentType<P>) => {
    if (WrappedComponent === undefined || WrappedComponent === null) {
      throw new Error("cannot use withLabel with and undefined or null component");
    }

    const wrappedComponentName =
      WrappedComponent.displayName || WrappedComponent.name || "Component";
    const displayName = `withLabel(${wrappedComponentName})`;

    class WithLabel extends Component<P & WithLabelProps> {
      static displayName = displayName;

      static defaultProps = {
        isHorizontal: true
      };

      render() {
        const {
          isHorizontal,
          label,
          labelSize,
          wviMessage,
          required,
          styleLabel,
          ...otherProps
        } = this.props as WithLabelProps;

        const additionalWrappedProps = {
          label: options.passLabel ? label : undefined,
          required: options.passRequired ? required : undefined
        };
        return (
          <Field
            htmlFor={(this.props as any).id}
            isHorizontal={isHorizontal}
            label={label}
            help={wviMessage}
            helpState={this.props.wviState}
            required={required}
            styleLabel={styleLabel}
          >
            <WrappedComponent {...(additionalWrappedProps as P)} {...(otherProps as P)} />
          </Field>
        );
      }
    }

    return WithLabel;
  };
}
