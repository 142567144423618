import { AxiosPromise } from "axios";

import { apiAdn, getUIContext } from "./common";
import { PanelState } from "types/Dashboard";
import { ADMIN_GALAXIE } from "admin/integrateur/AdminGalaxy/AdminGalaxy";
import { Pojo } from "types/Galaxy";
import { FocusState } from "types/Focus";

/**
 * Permet de faire la sauvegarde du focus courant de l'admin
 * @param sjmoCode code du module
 * @param focusId id du focus de galaxie
 * @param panels les panels à sauvegarder
 */
export function saveAdminCurrentFocus(
  sjmoCode: string,
  focusId: string,
  panels: PanelState[]
): AxiosPromise<PanelState[]> {
  const body = { sjmoCode, focusId, panels };
  let uiContext = getUIContext({ sjmoCode });

  // simulation du super user
  uiContext.set("superUser", "true");
  return apiAdn.post(`/dashboard/focus?${uiContext}`, body);
}

/**
 * Permet de créer un nouveau panel pour une galaxie
 * @param sjmoCode code du module
 * @param title titre du panel
 * @param code code du panel
 * @param type type du panel
 */
export function createAdminGalaxyPanel(
  sjmoCode: string,
  title: string,
  code: string,
  type: string
): AxiosPromise<PanelState[]> {
  const body = { panelLabel: title, panelCode: code, panelType: type };
  let uiContext = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/galaxy/panel?${uiContext}`, body);
}

export function changeAdminPanelTitle(sjmoCode: string, panelId: string, panelTitle: string) {
  let params = getUIContext({ sjmoCode });
  params.set("panelId", panelId.toString());
  params.set("panelTitle", panelTitle.toString());
  return apiAdn.put(`/admin/galaxy/panel/change-title?${params}`);
}

export function createNewGalaxy(
  galaxyTitle: string,
  newSjmoCode: string,
  newSjmoIcon: string,
  sjgaType: string,
  syjTablesId: string | null
): AxiosPromise<void> {
  const sjmoCode = ADMIN_GALAXIE;
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/galaxy/new?${params}`, {
    galaxyTitle,
    sjmoCode: newSjmoCode,
    sjmoIcon: newSjmoIcon,
    sjgaType,
    syjTablesId
  });
}

export function createOrUpdateGalaxieFocus(values: {
  id: string;
  title: string;
  privilegie: boolean;
  code: string;
  position: number;
  resourceKey: string;
  syjModuleId: string;
  sysMenuGroupeId: string;
  personnelId: string;
}): AxiosPromise<FocusState> {
  const sjmoCode = ADMIN_GALAXIE;
  const params = getUIContext({ sjmoCode });

  return apiAdn.post(`/admin/galaxy/focus?${params}`, values);
}

export function deleteFocusGalaxyApi(focusId: string) {
  const sjmoCode = ADMIN_GALAXIE;
  const params = getUIContext({ sjmoCode });
  return apiAdn.delete(`/admin/galaxy/focus/${focusId}?${params}`);
}

export function getAdminDashboardFocus(sjmoCode: string): AxiosPromise<FocusState[]> {
  const params = getUIContext({ sjmoCode: ADMIN_GALAXIE });
  return apiAdn.get(`/admin/galaxy/${sjmoCode}/focus?${params}`);
}

/**
 * Recupère toutes les associations d'un focus de galaxie
 *
 * @export
 * @param {string} sjmoCode
 * @param {string} focusId
 * @returns {AxiosPromise<{ GLOBAL: Pojo[]; ROLE: Pojo[]; USER: Pojo[] }>}
 */
export function getGalaxyFocusAssociations(
  sjmoCode: string,
  focusId: string
): AxiosPromise<{ GLOBAL: Pojo[]; ROLE: Pojo[]; USER: Pojo[] }> {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/galaxy/associations/?${params}&focusId=${focusId}`);
}
/**
 * Permet d'associer un panel info à une Datatable
 * @param sjmoCode code du module
 * @param tableName nom de la table à associer
 */

export function getNewCtrlKey(tableName: string, sjmoCode: string) {
  const params = getUIContext({ sjmoCode });
  return apiAdn.get(`/admin/galaxy/ctrlKey?${params}&tableName=${tableName}`);
}
