import { differenceInSeconds } from "date-fns";
import { Pojo } from "types/Galaxy";

export const cachePojo = new Map<string, Pojo>();
export const cacheDate = new WeakMap<Pojo, Date>();
let cacheListeners: AutocompleteCacheListener[] = [];

export function getCacheKey(tableName: string | undefined, id: string | null) {
  if (!tableName || id === null) {
    return null;
  }
  return `${tableName}/${id}`;
}

export function getAutocompleteCache(cacheKey: string | null) {
  if (cacheKey === null) {
    return null;
  }
  return cachePojo.get(cacheKey) || null;
}

export function updateAutocompleteCache(cacheKey: string | null, pojo: Pojo) {
  const isCacheUpdated = setAutocompleteCache(cacheKey, pojo);
  if (isCacheUpdated) {
    window.requestIdleCallback(() => {
      cacheListeners.forEach(listener => {
        if (listener.key === cacheKey) {
          listener.callback();
        }
      });
    });
  }
}

export function registerAutocompleteCallback(key: string | null, callback: () => void) {
  if (key == null) return () => {};
  let listener = {
    key,
    callback
  };

  cacheListeners.push(listener);

  return () => {
    cacheListeners = cacheListeners.filter(it => it != listener);
  };
}

export function setAutocompleteCache(cacheKey: string | null, pojo: Pojo) {
  if (cacheKey !== null) {
    cachePojo.set(cacheKey, pojo);
    cacheDate.set(pojo, new Date());
    return true;
  }
  return false;
}

export function hasAutocompleteCache(cacheKey: string | null) {
  return cacheKey !== null ? cachePojo.has(cacheKey) : false;
}

export function isValidCache(cacheKey: string | null) {
  if (cacheKey === null) {
    return false;
  }

  const obj = cachePojo.get(cacheKey);
  const date = obj && cacheDate.get(obj);

  if (date && differenceInSeconds(date, new Date()) < 30) {
    return true;
  }

  return false;
}

export type AutocompleteCacheListener = { key: string; callback: () => void };
