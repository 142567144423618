import produce from "immer";

import Action from "reducers/Action";
import { GalaxyInformation } from "types/Galaxy";

import { START_GALAXY_LOADER, STOP_GALAXY_LOADER } from "constant/loader";

/**
 * type du state du reducer qui stocke les informations de la galaxie
 */
export type GalaxyLoaderState = Record<string, boolean>;

/**
 * Reducer de gestion des informations additionnelles de galaxie
 * Voir le type GalaxyInformation
 *
 * @param {GalaxyLoaderState} state state redux
 * @param {Action<GalaxyInformation>} action action redux
 * @returns {GalaxyLoaderState} nouveau state
 */
export default function reducerGalaxyLoader(
  state: GalaxyLoaderState = {},
  action: Action<GalaxyInformation>
): GalaxyLoaderState {
  switch (action.type) {
    case START_GALAXY_LOADER:
      return produce(state, draft => {
        if (draft[action.payload.sjmoCode]) {
          draft[action.payload.sjmoCode] = true;
        }
      });

    case STOP_GALAXY_LOADER:
      return produce(state, draft => {
        if (draft[action.payload.sjmoCode]) {
          draft[action.payload.sjmoCode] = false;
        }
      });

    default:
      return state;
  }
}
