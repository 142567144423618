import React, { FC, useState, SyntheticEvent, FormEvent, useEffect } from "react";
import Modal from "composants/Modal/Modal";

import { t } from "utils/i18n";
import { uuidv4 } from "utils/uuid.utils";
import { Field } from "composants/form";
import { SaveProperties } from "types/InteractiveReport";
import { InteractiveReportConfiguration } from "types/Component";

interface AdminCreateDialogProps {
  currentFocus: InteractiveReportConfiguration | null;
  onClose(): void;
  onValidate(saveProperties: SaveProperties): void;
  onDelete(id: number | string): void;
}

function initialSaveProperties(
  id: string | number | undefined | null,
  label: string | undefined | null,
  isGlobal?: boolean | undefined | null
): SaveProperties {
  return { id: id || uuidv4(), label: label || "", position: 0, isGlobal: isGlobal || false };
}

export const AdminCreateDialog: FC<AdminCreateDialogProps> = props => {
  //
  const isAnExistingFocus = props.currentFocus && props.currentFocus.id !== undefined;

  const [currentProperties, setCurrentProperties] = useState<SaveProperties>(
    initialSaveProperties(
      "",
      props.currentFocus && props.currentFocus.label,
      props.currentFocus && props.currentFocus.isGlobal
    )
  );

  function onChange(e: SyntheticEvent<any>) {
    const field = e.currentTarget.name;
    const value = e.currentTarget.value;

    setCurrentProperties({
      ...currentProperties,
      [field]: value
    });
  }

  function onValidate(e: FormEvent) {
    e.preventDefault();
    // // on autorise la sauvegarde sur des nouveaux focus **et** si le focus appartient à l'utilisateur
    props.onValidate(currentProperties);
    props.onClose();
  }

  return (
    <Modal title="Créer un nouvel état" onClose={props.onClose} hideFooter>
      <form onSubmit={onValidate}>
        <Field label={t("commun_titre")} isHorizontal>
          <input
            className="input"
            required
            name="label"
            value={currentProperties.label}
            onChange={onChange}
          />
        </Field>
        <div className="field is-grouped">
          <div className="control">
            <input type="submit" className="button is-primary" value={t("commun_valider")} />
          </div>
        </div>
      </form>
    </Modal>
  );
};
