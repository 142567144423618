import React, { Component } from "react";
import {
  List as VirtualizedList,
  InfiniteLoader,
  IndexRange,
  AutoSizer,
  ListRowRenderer,
  ListRowProps
} from "react-virtualized";
import classNames from "classnames";
/**************** TYPES ***********************/
import { Pojo } from "types/Galaxy";

export interface ListModuleProps {
  field: string;
  fieldId: string;
  optionnalField?: string;
  value: Pojo | null;
  entities: Record<string, Pojo | undefined>;
  rowCount: number;
  loadMoreData(index: IndexRange): Promise<any>;
  clickItem(Pojo: Pojo | undefined): void;
  render(props: ListRowProps & ListModuleProps): React.ReactNode;
}

interface ListModuleState {}

class ListSelection extends Component<ListModuleProps> {
  static defaultProps = {
    field: "label",
    fieldId: "id",
    render: (props: ListRowProps & ListModuleProps) => {
      const item = props.entities[props.index];
      return (
        <li
          className={classNames("list-item", {
            "is-active":
              props.value !== null &&
              item !== undefined &&
              props.value[props.fieldId] === item[props.fieldId]
            // props.value.findIndex(
            //   val => item !== undefined && val.id === item.id
            // ) !== -1
          })}
          key={props.key}
          style={{
            ...props.style,
            cursor: "pointer",
            paddingBottom: "10px"
          }}
          onClick={() => {
            props.clickItem(item);
          }}
        >
          {item ? item[props.field] : null}{" "}
          {props.optionnalField && item ? "(" + item[props.optionnalField] + ")" : null}
        </li>
      );
    },
    loadMoreData: () => {}
  };
  private listRef: VirtualizedList | null = null;

  resetScroll = () => {
    this.listRef && this.listRef.scrollToRow(0);
  };

  render() {
    return (
      <InfiniteLoader
        isRowLoaded={({ index }) => {
          return this.props.entities[index] !== undefined && this.props.entities[index] !== null;
        }}
        loadMoreRows={this.props.loadMoreData}
        rowCount={this.props.rowCount}
        minimumBatchSize={20}
        threshold={10}
      >
        {({ onRowsRendered, registerChild }) => {
          return (
            <AutoSizer>
              {({ height, width }) => (
                <VirtualizedList
                  onRowsRendered={onRowsRendered}
                  ref={el => {
                    registerChild(el);
                    this.listRef = el;
                  }}
                  rowCount={this.props.rowCount}
                  rowHeight={height / 6}
                  height={height}
                  width={width}
                  rowRenderer={listProps => this.props.render({ ...listProps, ...this.props })}
                />
              )}
            </AutoSizer>
          );
        }}
      </InfiniteLoader>
    );
  }
}

/**/

export default ListSelection;
