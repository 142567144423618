import { TokenType } from "./Token";

function keywords() {
  const keywords: Record<string, TokenType> = {};
  keywords["and"] = TokenType.AND;
  keywords["AND"] = TokenType.AND;
  keywords["or"] = TokenType.OR;
  keywords["OR"] = TokenType.OR;
  keywords["TRUE"] = TokenType.TRUE;
  keywords["true"] = TokenType.TRUE;
  keywords["FALSE"] = TokenType.FALSE;
  keywords["false"] = TokenType.FALSE;
  keywords["NULL"] = TokenType.NULL;
  keywords["null"] = TokenType.NULL;
  keywords["IS"] = TokenType.IS;
  keywords["is"] = TokenType.IS;
  keywords["NOT"] = TokenType.NOT;
  keywords["not"] = TokenType.NOT;
  keywords["BETWEEN"] = TokenType.BETWEEN;
  keywords["between"] = TokenType.BETWEEN;
  keywords["IN"] = TokenType.IN;
  keywords["in"] = TokenType.IN;
  return keywords;
}

export const KEYWORDS = keywords();
