import React, { Component, useMemo } from "react";
import { AxiosError } from "axios";
import Scrollbars from "react-custom-scrollbars";
import { URL_DATA, URL_DOWNLOAD_VIA_GED } from "customGlobal";
import { parse, getHours, getMinutes } from "date-fns";

import { addMessage } from "actions/messages";
import { fetchDocumentData, deleteDocument } from "api";
import { t } from "utils/i18n";
import TabHeader from "./TabHeader";
import { Message } from "types/Message";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import FileUpload from "../file/FileUpload";
import { Row, Col } from "../Layout";
import { Dropdown, DropdownButton, DropdownMenu } from "../DropDown/Dropdown";
import { Button } from "../button";
import { Fa } from "composants/Icon";
import { formatDate } from "utils/i18n";
import auth from "auth";
import DocumentViewer, { DocumentLightDto } from "./DocumentViewer";
import { Link } from "react-router-dom";

export interface Document {
  id: string;
  nom: string;
  nomGed: string;
  date: string;
  applicable: boolean;
  url: string;
  path: string;
  mimeType: string;
  codeGed: string;
}

interface TabDocumentState {
  documents: Document[];
  documentsToAdd: { key: string; src: string }[];
  selectedDocument?: string;
  displayedDocument?: Document;
}

function getFileExtension(str: string) {
  return str.split(".").reverse()[0];
}

function backSlashToSlash(str: string) {
  return str.replace(/\\/g, "/");
}

function UpdateFile(props: { file: Document }) {
  const url = useMemo(() => {
    const i = getFileExtension(props.file.nom);
    const urlClear = backSlashToSlash(props.file.path);
    if (i === "doc" || i === "docx" || i === "rtf") {
      return `ms-word:ofe|u|${urlClear}`;
    } else if (i === "pptx") {
      return `ms-powerpoint:ofe|u|${urlClear}`;
    } else if (i === "xlsx") {
      return `ms-excel:ofe|u|${urlClear}`;
    } else {
      return null;
    }
  }, [props.file.nom, props.file.path]);

  return (
    <>
      {url != null ? (
        <a className="level-item button is-text is-link is-inverted" href={url}>
          <span className="icon is-small">
            <Fa icon="pen" />
          </span>
        </a>
      ) : (
        <></>
      )}
    </>
  );
}

function formatDocumentDate(date: string) {
  const parsedDate = parse(date);
  if (getHours(parsedDate) === 0 && getMinutes(parsedDate) === 0) {
    return formatDate(date, {
      day: "numeric",
      month: "long",
      year: "numeric"
    });
  }

  return formatDate(date, {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit"
  });
}

class TabDocument extends Component<TabSatelliteProps, TabDocumentState> {
  state: TabDocumentState = {
    documents: [],
    documentsToAdd: [],
    displayedDocument: undefined
  };

  componentDidMount() {
    if (this.props.contextId) {
      this.refresh();
    }
  }

  componentDidUpdate(prevProps: TabSatelliteProps) {
    if (prevProps.contextId !== this.props.contextId && this.props.contextId) {
      this.refresh();
    }
  }

  refresh = () => {
    fetchDocumentData(this.props.tableName, this.props.contextId as string)
      .then(response => {
        this.setState({ documents: response.data });
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });

    this.props.countAction(this.props.tableName, this.props.contextId);
  };

  onClose = () => {
    this.setState({ displayedDocument: undefined });
  };

  deleteDocument = (document: Document) => {
    return deleteDocument(document.id)
      .then(() => {
        this.refresh();
      })
      .catch(e => {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        addMessage(message);
      });
  };

  render() {
    const docLight: DocumentLightDto = this.state.displayedDocument
      ? {
          name: this.state.displayedDocument.nomGed as string,
          id: this.state.displayedDocument.id,
          mimeType: this.state.displayedDocument.mimeType
        }
      : { id: undefined, mimeType: "", name: "" };

    return (
      <>
        <TabHeader
          i18nKey="commun_documents_liees"
          count={this.props.count}
          tableName={this.props.tableName}
          contextId={this.props.contextId}
          sjmoCode={this.props.sjmoCode}
        />
        <Scrollbars autoHide style={{ height: this.props.height }}>
          <FileUpload
            {...this.props}
            onFileUploadComplete={() => {
              this.refresh();
            }}
          />
          <Row style={{ width: "100%" }}>
            <Col span={6}>{this.buildListDocument(true)}</Col>
            <Col span={6}>{this.buildListDocument(false)}</Col>
          </Row>
        </Scrollbars>
        {this.state.displayedDocument && (
          <DocumentViewer document={docLight} onClose={this.onClose} />
        )}
      </>
    );
  }

  buildLeftArea(document: Document, disabledPreview: boolean) {
    if (!disabledPreview && document.mimeType.includes("image")) {
      return (
        <a
          className="level-item"
          onClick={() => {
            this.setState({
              displayedDocument: document
            });
          }}
        >
          <figure className="image is-128x128">
            <img
              src={`${URL_DATA()}/document/${document.id}?&access_token=${auth.token}`}
              alt={document.nomGed}
            />
          </figure>
        </a>
      );
    } else if (!disabledPreview && document.mimeType.includes("pdf")) {
      return (
        <a
          className="level-item"
          onClick={() => {
            this.setState({
              displayedDocument: document
            });
          }}
        >
          <h1 className="title" style={{ width: 128 }}>
            <Fa icon="file-pdf" />
          </h1>
        </a>
      );
    } else if (
      disabledPreview &&
      (document.mimeType.includes("pdf") || document.mimeType.includes("image"))
    ) {
      return (
        <span className="fa-stack fa-lg" style={{ width: 128 }}>
          <Fa icon="file" className="fa-stack-1x" />
          <Fa icon="ban" transform="rotate-90" className="fa-stack-2x has-text-danger" />
        </span>
      );
    } else {
      return (
        <h1 className="title" style={{ width: 128 }}>
          {document.mimeType.substring(document.mimeType.indexOf("/") + 1)}
        </h1>
      );
    }
  }

  buildListDocument = (isLeft: boolean) => {
    return this.state.documents
      .filter(document => {
        const even = isLeft ? 0 : 1;
        return this.state.documents.indexOf(document) % 2 === even;
      })
      .map(document => {
        let disabledPreview = false;
        const mimeType = document.mimeType ? document.mimeType : "/";

        if ((!mimeType.includes("image") && !mimeType.includes("pdf")) || document.url === null) {
          disabledPreview = true;
        }

        return (
          <div className="box donnees-satellite-container" key={document.id}>
            <article className="media">
              <div className="media-left">{this.buildLeftArea(document, disabledPreview)}</div>
              <div className="media-content">
                <div className="content">
                  <p>
                    <strong>{document.nomGed} </strong>
                    <span>
                      <Fa
                        icon={document.applicable ? "check" : "times"}
                        className={document.applicable ? "has-text-success" : "has-text-danger"}
                        title={t("commun_document_applicable")}
                      />
                    </span>
                  </p>
                  <p>{formatDocumentDate(document.date)}</p>
                  <p>{document.nom}</p>
                </div>
                <nav className="level is-mobile">
                  <div className="level-left">
                    {disabledPreview && (
                      <div className="level-item button is-text has-text-grey-light">
                        <span className="icon is-small">
                          <Fa icon="eye-slash" />
                        </span>
                      </div>
                    )}
                    {!disabledPreview && (
                      <a
                        className="level-item button is-text is-link is-inverted"
                        onClick={() => {
                          this.setState({
                            displayedDocument: document
                          });
                        }}
                      >
                        <span className="icon is-small">
                          <Fa icon="eye" />
                        </span>
                      </a>
                    )}
                    {document.url && <UpdateFile file={document} />}
                    {document.url && (
                      <a
                        className="level-item button is-text is-link is-inverted"
                        href={`${URL_DOWNLOAD_VIA_GED()}/document/${document.id}?access_token=${
                          auth.token
                        }&download=true`}
                      >
                        <span className="icon is-small">
                          <Fa icon="download" />
                        </span>
                      </a>
                    )}
                    {!document.url && (
                      <div className="level-item button is-text has-text-grey-light">
                        <span className="icon is-small">
                          <Fa icon="download" />
                        </span>
                      </div>
                    )}
                    <Link
                      to={`/page/GED_DOCUMENT/` + document.codeGed}
                      className="level-item button is-text is-link is-inverted"
                    >
                      <span className="icon">
                        <Fa icon="external-link" />
                      </span>
                    </Link>
                    <Dropdown autoclose className="is-right">
                      <DropdownButton
                        render={param => (
                          <Button className="is-text" ref={param.buttonRef} onClick={param.onOpen}>
                            <span className="icon">
                              <Fa icon="trash" />
                            </span>
                          </Button>
                        )}
                      />
                      <DropdownMenu
                        render={param => (
                          <div className="dropdown-item">
                            <p>{t("commun_sur_de_suprimer")}</p>
                            <button
                              className="button is-danger is-fullwidth is-small"
                              onClick={() => {
                                this.deleteDocument(document).then(param.onClose);
                              }}
                            >
                              {t("commun_oui")}
                            </button>
                          </div>
                        )}
                      />
                    </Dropdown>
                  </div>
                </nav>
              </div>
            </article>
          </div>
        );
      });
  };
}

export default TabDocument;
