import React, { useEffect } from "react";
import { TabSatelliteProps } from "containers/satellites/SatellitesData";
import Scrollbars from "react-custom-scrollbars";
import { fetchArticulationData } from "api";
import { AxiosError } from "axios";
import { Message } from "types/Message";

import ProcessusMenu from "../processus/ProcessusMenu";
import TabHeader from "./TabHeader";
import { ProcessusProvider } from "composants/processus/ProcessusProvider";
import { useQuery } from "react-query";
import { tw } from "twind";
import { useTranslation } from "react-i18next";

export interface Articulation {
  type: "ORIG" | "CSQ";
  id: number;
  table: string;
  tableLibelle: string;
  entityId: string;
  title: string;
  values: string[];
  commentaire: string;
}

function queryArticulationData(tableName: string, contextId: string) {
  return fetchArticulationData(tableName, contextId).then(res => res.data);
}

function TabArticulation(props: TabSatelliteProps) {
  const { t } = useTranslation();
  useEffect(() => {
    props.countAction(props.tableName, props.contextId);
    // il veut [props], c'est pas ce qu'on veut
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.tableName, props.contextId]);

  const { data: articulations } = useQuery(
    ["satellite", "articulation", props.tableName, props.contextId],
    () => queryArticulationData(props.tableName, props.contextId as string),
    {
      onError(e) {
        const er = e as AxiosError<any>;
        if (!er.response) {
          return;
        }

        const message: Message = {
          code: er.response.data.code,
          message: t(er.response.data.message),
          type: er.response.data.type,
          target: er.response.data.target
        };
        props.addMessage(message);
      }
    }
  );

  const origList = articulations?.filter(it => it.type === "ORIG");
  const csqList = articulations?.filter(it => it.type === "CSQ");

  return (
    <>
      <TabHeader
        i18nKey="commun_articulations_liees"
        count={props.count}
        tableName={props.tableName}
        contextId={props.contextId}
        sjmoCode={props.sjmoCode}
      />
      {origList && csqList && (
        <div className={tw("flex w-full gap-3")}>
          <div className={tw("flex-grow")}>
            <p className={tw("text-center font-semibold")}>{t("commun_articulationCsq")}</p>
            <Scrollbars autoHide style={{ height: props.height }}>
              {csqList.map(articulation => (
                <ArticulationCard
                  key={articulation.id}
                  articulation={articulation}
                  sjmoCode={props.sjmoCode}
                />
              ))}
            </Scrollbars>
          </div>
          <div className={tw("flex-grow")}>
            <p className={tw("text-center font-semibold")}>{t("commun_articulationOrig")}</p>
            <Scrollbars autoHide style={{ height: props.height }}>
              {origList.map(articulation => (
                <ArticulationCard
                  key={articulation.id}
                  articulation={articulation}
                  sjmoCode={props.sjmoCode}
                />
              ))}
            </Scrollbars>
          </div>
        </div>
      )}
    </>
  );
}

function ArticulationCard({
  sjmoCode,
  articulation
}: {
  sjmoCode: string;
  articulation: Articulation;
}) {
  return (
    <div className={tw("py-3 px-2 my-3 bg-white rounded-md shadow border border-gray-50")}>
      <ProcessusProvider
        sjmoCode={sjmoCode}
        tableName={articulation.table}
        selected={articulation.entityId}
      >
        <ProcessusMenu isAnchor isRight={false} color="link">
          {articulation.tableLibelle}, ({articulation.values})
        </ProcessusMenu>
      </ProcessusProvider>

      <p className={tw("font-semibold my-1")}>{articulation.title}</p>
      <p>{articulation.commentaire}</p>
    </div>
  );
}

export default TabArticulation;
