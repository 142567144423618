import immer from "immer";
import Action from "reducers/Action";

import {
  FETCH_EXPERT_MENU_SUCCESS,
  FETCH_EXPERT_GROUP_SUCCESS,
  FETCH_EXPERT_HEADER_SUCCESS,
  CLEAR_EXPERT_HEADER
} from "constant/expert";

import { FocusState } from "types/Focus";
import { ComponentGroupState } from "types/ComponentGroup";
import { Menu } from "types/Menu";
import { ComponentState } from "types/Component";
import { START_EXPERT_LOADER, STOP_EXPERT_LOADER } from "constant/loader";
// State d'un focus d'expert
export interface ExpertDefinitionState {
  groups: ComponentGroupState[];
  header: ComponentState[];
}

// State redux du mini-expert
export interface ExpertState {
  expertDefinitions: { [sjxdId: number]: ExpertDefinitionState };
  menu: Record<string, Menu[]>;
  loading: boolean;
}

// type alias pour l'action dans le reducer de dashboard
type ExpertAction = Action<any>;

const initialState = { expertDefinitions: {}, menu: {}, loading: false };

export default function reducer(
  state: ExpertState = initialState,
  action: ExpertAction
): ExpertState {
  switch (action.type) {
    case FETCH_EXPERT_MENU_SUCCESS:
      return addMenu(state, action);
    case FETCH_EXPERT_GROUP_SUCCESS:
      return addGroups(state, action);
    case FETCH_EXPERT_HEADER_SUCCESS:
      return addHeader(state, action);
    case START_EXPERT_LOADER:
      return immer(state, draft => {
        draft.loading = true;
      });

    case STOP_EXPERT_LOADER:
      return immer(state, draft => {
        draft.loading = false;
      });
    case CLEAR_EXPERT_HEADER:
      return clearExpertHeader(state, action);
    default:
      return state;
  }
}

function addGroups(state: ExpertState, action: ExpertAction): ExpertState {
  // on récupère la valeurs des actions
  const sjxdId: string = action.payload.sjxdId;
  const groups: ComponentGroupState[] = action.payload.groups;

  return immer(state, draft => {
    if (!draft.expertDefinitions[sjxdId]) {
      draft.expertDefinitions[sjxdId] = { groups: [] };
    }

    draft.expertDefinitions[sjxdId].groups = groups;
  });
}

function addHeader(state: ExpertState, action: ExpertAction): ExpertState {
  // on récupère la valeurs des actions
  const sjmoCode: string = action.payload.sjmoCode;
  const compos: ComponentState[] = action.payload.header;
  return immer(state, draft => {
    if (!draft.expertDefinitions[sjmoCode]) {
      draft.expertDefinitions[sjmoCode] = { header: [] };
    }

    draft.expertDefinitions[sjmoCode].header = compos;
  });
}

function addMenu(state: ExpertState, action: ExpertAction): ExpertState {
  // on récupère la valeurs des actions
  const sjmoCode: string = action.payload.sjmoCode;
  const menu: Menu[] = action.payload.menu;
  return immer(state, draft => {
    draft.menu[sjmoCode] = menu;
  });
}

function clearExpertHeader(state: ExpertState, action: ExpertAction): ExpertState {
  return immer(state, draft => {
    draft.expertDefinitions[action.payload.sjmoCode].header = [];
  });
}
