import React, { FC } from "react";
import classNames from "classnames";
import { getSizeClasses } from "../common";

import { t } from "utils/i18n";

interface PaginationProps {
  totalRecords: number;
  first: number;
  pageSize?: number;

  isRight?: boolean;
  isCentered?: boolean;
  isRounded?: boolean;
  size?: string;

  paginationChange(index: number): void;
}

const SUCCESSIVE_PAGINATION_LINK = 5;

export const Pagination: FC<PaginationProps> = ({
  totalRecords,
  first,
  pageSize = 10,
  paginationChange,
  isRight,
  isCentered,
  isRounded,
  size = ""
}) => {
  let listElement: JSX.Element[] = [];

  if (totalRecords > 0) {
    const paginationStep = Math.round(totalRecords / pageSize);

    if (first > SUCCESSIVE_PAGINATION_LINK) {
      listElement.push(
        <li key="first">
          <a
            className="pagination-link"
            aria-label={`Go to page ${0}`}
            onClick={() => paginationChange(0)}
          >
            {0}
          </a>
        </li>
      );
      listElement.push(
        <li key="ellipsis-first">
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
      );
    }

    const paginationIndexToCreate = Array.from(Array(first + 5).keys()).slice(
      first - SUCCESSIVE_PAGINATION_LINK > 0 ? first - SUCCESSIVE_PAGINATION_LINK : 0,
      paginationStep
    );

    listElement = listElement.concat(
      paginationIndexToCreate.map(index => {
        const className = first === index ? "is-current" : "";
        return (
          <li key={index}>
            <a
              className={`pagination-link ${className}`}
              aria-label={`Go to page ${index}`}
              onClick={() => paginationChange(index)}
            >
              {index + 1}
            </a>
          </li>
        );
      })
    );

    if (paginationIndexToCreate[paginationIndexToCreate.length - 1] !== paginationStep - 1) {
      listElement.push(
        <li key="ellipsis-last">
          <span className="pagination-ellipsis">&hellip;</span>
        </li>
      );
    }

    listElement.push(
      <li key="last">
        <a
          className={`pagination-link ${first === paginationStep ? "is-current" : ""}`}
          aria-label={`Go to page ${paginationStep}`}
          onClick={() => paginationChange(paginationStep)}
        >
          {paginationStep + 1}
        </a>
      </li>
    );
  }

  const classesPagniation = classNames("pagination", getSizeClasses(size), {
    "is-right": isRight,
    "is-centered": !isRight && isCentered,
    "is-rounded": isRounded
  });

  return (
    <div className={classesPagniation} aria-label="pagination">
      <button
        className="pagination-previous"
        onClick={() => paginationChange(first - 1)}
        disabled={first === 0}
      >
        {t("commun_precedent")}
      </button>
      <button
        onClick={() => paginationChange(first + 1)}
        className="pagination-next"
        disabled={first === Math.round(totalRecords / pageSize)}
      >
        {t("commun_suivant")}
      </button>
      <ul className="pagination-list">{listElement}</ul>
    </div>
  );
};
