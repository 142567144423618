import { Token, TokenType } from "./Token";
import { KEYWORDS } from "./keywords";

export class Scanner {
  private tokens: Token[] = [];
  private start = 0;
  private current = 0;
  private line = 0;

  constructor(private source: string) {}

  scanTokens() {
    while (!this.isAtEnd()) {
      this.start = this.current;
      this.scanToken();
    }

    this.tokens.push(new Token(TokenType.EOF, "", null, this.line));

    return this.tokens;
  }

  scanToken() {
    const c = this.advance();

    switch (c) {
      case "(":
        this.addToken(TokenType.PAREN_LEFT);
        break;
      case ")":
        this.addToken(TokenType.PAREN_RIGHT);
        break;
      case "!":
        // si on a un '!', on attend forcément un '=' derrière.
        // match permet de connaître la valeur n de notre source.
        // comme advance donne n-1, on a forcément la prochaine valeur.
        if (this.match("=")) {
          this.addToken(TokenType.BANG_EQUAL);
        } else {
          throw new Error("missing '=' at line :" + this.line);
        }
        break;

      case "=":
        this.addToken(TokenType.EQUAL);
        break;
      case "<":
        // on a deux choix ici, soit `<` soit `<=`
        if (this.match("=")) {
          this.addToken(TokenType.LESS_EQUAL);
        } else {
          this.addToken(TokenType.LESS);
        }
        break;
      case ">":
        // idem, soit `>`, soit `>=`
        if (this.match("=")) this.addToken(TokenType.GREATER_EQUAL);
        else this.addToken(TokenType.GREATER);
        break;
      case " ":
      case "\r":
      case "\t":
        // on ignore les espaces & les ":" à cause de l'ancien système.
        break;
      case "\n":
        this.line++;
        break;
      case "'":
        // on a un string ici, on va boucler autant de fois que nécessaire
        // si on arrive à la fin de la source sans avoir trouvé la quote fermante
        // on throw une erreur.
        this.string();
        break;
      case ",":
        this.addToken(TokenType.COMMA);
        break;
      default:
        // plus de caractère spéciaux :
        // on a donc, soit number, soit identifier. Sinon, on throw une erreur de source.
        if (this.isDigit(c)) {
          this.number();
        } else if (this.isAlpha(c)) {
          this.identifier();
        } else {
          throw new Error(
            "Unexpected character. At line " + this.line + " and offset " + this.current
          );
        }
    }
  }

  string() {
    while (this.peek() != "'" && !this.isAtEnd()) {
      if (this.peek() == "\n") this.line++;

      this.advance();
    }

    if (this.isAtEnd()) throw new Error("Unterminated string at line : " + this.line);

    // on ferme la quote.
    this.advance();

    // on récupère le string de notre source.
    let value = this.source.substring(this.start + 1, this.current - 1);

    this.addToken(TokenType.STRING, value);
  }

  number() {
    // on avance tant que l'on a
    // un nombre. On teste ensuite si le caractère qui suit est un "."
    // Si c'est le cas, on refait la même chose qu'ici, on boucle jusqu'à ne plus avoir de nombre.
    while (this.isDigit(this.peek())) this.advance();

    let isFloat = false;
    if (this.peek() == "." && this.isDigit(this.peekNext())) {
      isFloat = true;
      // on ignore le '.'
      this.advance();

      while (this.isDigit(this.peek())) this.advance();
    }

    // on tente de convertir le nombre que l'on trouve en source en BigDecimal.
    const numberStr = this.source.substring(this.start, this.current);
    this.addToken(
      TokenType.NUMBER,
      isFloat ? Number.parseFloat(numberStr) : Number.parseInt(numberStr, 10)
    );
  }

  identifier() {
    while (this.isAlphaNumeric(this.peek())) this.advance();

    let text = this.source.substring(this.start, this.current);
    // partie importante, on prend le mot clé si on en trouve un.

    let type: TokenType = TokenType.IDENTIFIER;

    if (KEYWORDS[text] != undefined) type = KEYWORDS[text];

    if (text.startsWith(":")) type = TokenType.VARIABLE;

    this.addToken(type);
  }

  match(expected: string) {
    if (this.isAtEnd()) return false;
    if (this.source[this.current] != expected) return false;
    this.current++;
    return true;
  }

  addToken(type: TokenType, literal: any = null) {
    let text = this.source.substring(this.start, this.current);
    this.tokens.push(new Token(type, text, literal, this.line));
  }

  peek() {
    if (this.isAtEnd()) return "\0";
    return this.source[this.current];
  }

  peekNext() {
    if (this.current + 1 >= this.source.length) return "\0";
    return this.source[this.current + 1];
  }

  advance() {
    this.current++;
    return this.source[this.current - 1];
  }

  isAtEnd() {
    return this.current >= this.source.length;
  }

  isDigit(c: string) {
    return c >= "0" && c <= "9";
  }

  isAlpha(c: string) {
    return (c >= "a" && c <= "z") || (c >= "A" && c <= "Z") || c == "_" || c == "." || c == ":";
  }

  isAlphaNumeric(c: string) {
    return this.isAlpha(c) || this.isDigit(c);
  }
}
