import React, { FC } from "react";
import { connect } from "react-redux";
import { ReducerState } from "reducers";

type CurrentUserPropsRedux = { userStr: string };
type CurrentUserAllProps = CurrentUserPropsRedux;

const CurrentUserInternal: FC<CurrentUserAllProps> = ({ userStr }) => {
  return <span className="username has-text-weight-semibold is-size-6">{userStr}</span>;
};

const mapStateToProps = ({ userSettings }: ReducerState) => {
  const { civilite = "", prenom = "", nom = "" } = userSettings;
  return {
    userStr: `${civilite} ${prenom} ${nom}`
  };
};

export const CurrentUser = connect<CurrentUserPropsRedux>(mapStateToProps)(CurrentUserInternal);
